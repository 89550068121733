import { inject, Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { User } from '../models/user';
import { EventSourcePolyfill } from 'ng-event-source';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventSourceService {
    private user = inject(User);
    private eventSource!: EventSourcePolyfill;

    private url = `${environment.apiUrl}/async/mynotifications`;
    constructor(private zone: NgZone) { }

    getEventSource(url: string): EventSourcePolyfill {
        const options = {
            headers: {
                'Authorization': `Bearer ${this.user?.access_token || ''}`
            },
            withCredentials: true
        } as EventSourceInit;
        return new EventSourcePolyfill(url, options);
    }

    connectToServerSentEvents(eventNames: string[] = []): Observable<Event> {
        this.eventSource = this.getEventSource(this.url);

        return new Observable((subscriber: Subscriber<Event>) => {
            this.eventSource.onerror = (error: any) => {
                this.zone.run(() => subscriber.error(error));
            };

            eventNames.forEach((event: string) => {
                this.eventSource.addEventListener(event, data => {
                    this.zone.run(() => subscriber.next(data));
                });
            });
        });
    }

    close(): void {
        if (!this.eventSource) {
            return;
        }

        this.eventSource.close();
    }
}