import { Component, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AccountsComponent } from '../../admin/organizations/components/soar-rules/components/accounts/accounts.component';
import { BulletinComponent } from '../../admin/organizations/components/soar-rules/components/bulletin/bulletin.component';
import { ServerComponent } from '../../admin/organizations/components/soar-rules/components/server/server.component';
import { TravelAdvisoriesComponent } from '../../admin/organizations/components/soar-rules/components/travel-advisories/travel-advisories.component';
import { VulnerabilityComponent } from '../../admin/organizations/components/soar-rules/components/vulnerability/vulnerability.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-soc-tuning',
  standalone: true,
  imports: [LayoutModule, ReactiveFormsModule, AccountsComponent, BulletinComponent, ServerComponent, TravelAdvisoriesComponent, VulnerabilityComponent],
  templateUrl: './soc-tuning.page.html',
  styleUrl: './soc-tuning.page.scss',
})
export class SocTuningPage {}
