import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TicketingEmail } from '../../../../../../../../core/models/entities';

export interface TicketingEmailForm {
  pk: FormControl<string | null>;
  email: FormControl<string | null>;
  is_primary: FormControl<boolean | null>;
}

@Injectable({
  providedIn: 'root',
})
export class TicketingEmailFormService {
  public createTicketingEmailForm(ticketingEmail = new TicketingEmail()): FormGroup<TicketingEmailForm> {
    return new FormGroup<TicketingEmailForm>({
      pk: new FormControl(ticketingEmail.pk || null),
      email: new FormControl(ticketingEmail.email || null, Validators.required),
      is_primary: new FormControl(ticketingEmail.is_primary),
    });
  }
}
