@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (status().length > 0) {
  <kendo-chart class="chart-container">
    <kendo-chart-series>
      <kendo-chart-series-item
        [autoFit]="autofit"
        type="donut"
        [data]="statusData()"
        categoryField="description"
        field="count"
      >
        <kendo-chart-series-item-labels
          align="circle"
          position="outsideEnd"
          [content]="labelContent"
          color="#000"
          background="none"
        >
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>

  <kendo-dialog
    *ngIf="showPopup"
    (close)="close()"
  >
    <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
    <kendo-grid
      class="mxh-80v"
      [filterable]="true"
      [data]="gridService | async"
      [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 100, 200]
      }"
      [pageSize]="gridService.state.take"
      [skip]="gridService.state.skip"
      [(filter)]="gridService.state.filter"
      [loading]="gridService.loading"
      (dataStateChange)="gridService.onStateChange($event)"
      [sort]="this.gridService.state.sort"
      [sortable]="true"
      [resizable]="true"
    >
      <ng-template kendoGridToolbarTemplate>
        <button
          type="button"
          kendoGridExcelCommand
        >
          Export to Excel
        </button>
      </ng-template>
      <kendo-grid-column
        [filterable]="false"
        field="timestamp"
        title="Lastest Observed Time"
      ></kendo-grid-column>
      <kendo-grid-column
        field="endpoint_name"
        title="Endpoint Name"
      ></kendo-grid-column>
      <kendo-grid-column
        field="network_status"
        title="Agent Status"
      >
        <ng-template kendoGridFilterCellTemplate>
          <kendo-dropdownlist
            [defaultItem]="{ text: 'All', value: null }"
            textField="text"
            valueField="value"
            (valueChange)="statusChange($event, 'network_status')"
            [data]="agentStatuses$ | async"
          ></kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="agent_version"
        title="Agent Version"
      ></kendo-grid-column>
      <kendo-grid-column
        field="group_name"
        title="EDR Group Name"
      ></kendo-grid-column>
      <kendo-grid-column
        field="source"
        title="Source"
      ></kendo-grid-column>
      <kendo-grid-column
        field="agent_id"
        title="Agent ID"
      ></kendo-grid-column>
      <kendo-grid-excel
        fileName="edr-agent-status-data.xlsx"
        [fetchData]="allData"
      ></kendo-grid-excel>
    </kendo-grid>
  </kendo-dialog>
} @else {
  <h1>No Data</h1>
}
