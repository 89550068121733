import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { Case } from '../../../../../../../../core/models/entities';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs';
import { environment } from '../../../../../../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: null })
export class EdrAgentStatusDetailsService extends ODataService<Case> {
  private field = 'timestamp';

  constructor() {
    super(inject(HttpClient), '');
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public setDateRange(dateRange: string[] | null) {
    const filters = this.state.filter?.filters.filter((f) => (f as FilterDescriptor).field !== this.field) || [];

    if (dateRange) {
      filters.push({
        field: this.field,
        operator: 'gt',
        value: dateRange[0],
      });

      filters.push({
        field: this.field,
        operator: 'lt',
        value: dateRange[1],
      });
    }

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: filters,
      },
    };
  }
}


@Injectable({ providedIn: null })
export class AgentFilterService {
  private http = inject(HttpClient);

  getAgentStatuses(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/agentstatus(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }
}


interface FilterItem {
  text: string;
  value: string | number;
}
