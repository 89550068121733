import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { ODataService } from '../../../../../../core/services/odata.service';

@Injectable({ providedIn: 'root' })
export class IngestorService extends ODataService<IngestorRecord> {
  constructor() {
    const url = `${environment.apiUrl}/api/v1/ingestor/ingestor/`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 25,
      sort: [{ field: 'created_at', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public setOrganization(pk: any){
    this.url = `${environment.apiUrl}/api/v1/ingestor/ingestor/record(${pk})/`;
  }

  public getIngestorAccountMap(pk: any) {
    const url = `${environment.apiUrl}/api/v1/ingestor/ingestor/mapaccounts(${pk})/`;
    return this.http.get<IngestorAccountMapResponse>(url).pipe(map((response) => response));
  }

  public getIngestorRecordsByOrganization(pk: any, ingestorId: number) {
    const url = `${environment.apiUrl}/api/v1/ingestor/ingestor/record(${pk})/`;
    const params = new HttpParams().set('$filter', `ingestor eq ${ingestorId}`);    
    return this.http.get<IngestorRecordResponse>(url, { params });
  }

  public addIngestorRecord(record: IngestorRecord) {
    const url = `${environment.apiUrl}/api/v1/ingestor/ingestor/record/`;
    return this.http.post<IngestorRecordResponse>(url, record);
  }

  public addMapAccount(mapAccount: MapAccount) {
    const url = `${environment.apiUrl}/api/v1/ingestor/ingestor/mapaccounts/`;
    return this.http.post<MapAccount>(url, mapAccount);
  }
}

export class IngestorRecord {
  id?: number | null = null;
  ingestor?: number | null = null;
  integration_url?: string | null = null;
  organization_parent?: number | null = null;
  api_key?: string | null = null;
  mapped_customers?: number | null = null;
  created_by?: string | null = null;
  created_at?: string | null = null;
  last_sync?: string | null = null;
}

export interface IngestorAccountMap {
  pk: number | null;
  ingestorrecord: number | null;
  organization_id: number | null;
  integration_unique_name: string | null;
  integration_id: number | null;
  active:boolean | null ;
  edited:boolean | null ;
}

export interface OrgNotAssigned{
  pk: number | null;
  name: string | null;
}

export interface IngestorAccountMapResponse{
  values: IngestorAccountMap[] ;
  orgs_not_assigned: OrgNotAssigned[]
}

export interface IngestorRecordResponse {
  values: IngestorRecord[]
}

export class MapAccount {
  integration_unique_name: string | null = null;
  integration_url: string | null = null;
  api_key: string | null = null;
}

