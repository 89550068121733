import { Component, CreateEffectOptions, effect, inject, input, output, signal } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { MttdData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-mttd',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, LabelModule, InputsModule, FormsModule],
  templateUrl: './mttd.component.html',
  styleUrl: './mttd.component.scss',
})
export class MttdComponent extends BaseComponent {
  public popupService = inject(ChartLabelsPopupService);
  public checked = false;
  public mttdData = input<MttdData[]>([]);
  public seriesClick = output<SeriesClickEvent>();
  public caseFilterChanged = output<boolean>();

  public isLoading = signal(true);
  public chartData = signal<MttdData[]>([]);

  constructor(private intl: IntlService) {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.mttdData();
        const filteredData = data ? data.filter((item) => item.description !== null) : [];
        this.chartData.set(filteredData);
        this.isLoading.set(false); // Set to false regardless of data presence
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public caseFilter() {
    this.caseFilterChanged.emit(this.checked);
  }

  public handleSeriesClick(event: SeriesClickEvent): void {
    this.seriesClick.emit(event);
  }
}
