import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../core/services/odata.service';
import { environment } from '../../../../../environments/environment';
import { Organization } from '../../../../core/models/entities';
import { map } from 'rxjs';

@Injectable({ providedIn: null })
export class OrganizationService extends ODataService<Organization> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization/`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public addOrg(organization: Organization) {
    const url = `${this.url}`;
    return this.http.post<Organization>(url, organization);
  }

  public updateOrg(payload: Organization) {
    if (!payload.id) {
      throw new Error('Organization ID is required for update');
    }
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${payload.id})/`;
    return this.http.patch<Organization>(url, payload);
  }

  deleteOrg(orgId: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/`;
    return this.http.delete(url);
  }

  public getOrganizationById(orgId: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/?expand=white_label`;
    return this.http.get<Organization>(url).pipe(map((response) => response));
  }

  public updateInitializationLevel(orgId: number, newValue: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/change_initialization_level/`;
    return this.http.post<Organization>(url, { initialization_level: newValue });
  }

  public getSubOrganizations(orgId: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/sub_organizations/`;
    return this.http.get<Organization[]>(url);
  }

  public getAccountTypes() {
    const url = `${environment.apiUrl}/v1/organization/administrative/accounttype/`;
    return this.http.get<{ '@odata.count': number; value: { id: number; name: string }[] }>(url);
  }
}
