import { CommonModule } from '@angular/common';
import { Component, effect, inject, output, signal } from '@angular/core';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons';
import { IngestorService, IngestorRecord } from '../../../services/ingestor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../../../../../../core/shared/common/base.component';

@Component({
  selector: 'app-ingestor-sources',
  standalone: true,
  imports: [CommonModule, GridModule, InputsModule, LabelModule, IconsModule, ButtonModule],
  templateUrl: './ingestor-sources.component.html',
  styleUrl: './ingestor-sources.component.scss',
  providers: [IngestorService],
})
export class IngestorSourcesComponent extends BaseComponent {
  public plusIcon: SVGIcon = plusIcon;
  public ingestorService = inject(IngestorService);

  public saved = output<void>();
  public organizationId = signal<number>(0);
  public data = signal<IngestorRecord[]>([]);
  public ingestorIntegrationId = signal<number>(0);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  constructor() {
    super();
    this.activatedRoute.queryParams.subscribe(params => {
      this.ingestorIntegrationId.set(parseInt(params['ingestorIntegrationId']));
      this.organizationId.set(parseInt(params['organizationId']));
    });
    this.initEffect();
  }

  private loadIngestorRecords() {
    if (this.organizationId()) {
      this.ingestorService.getIngestorRecordsByOrganization(
        this.organizationId(), 
        this.ingestorIntegrationId()
      ).subscribe((data) => {
        this.data.set(data.values);
      });
    }
  }

  initEffect() {
    effect(() => {
      console.log(this.organizationId());
      this.loadIngestorRecords();
    });
  }

  public addInjestorRecord() {
    this.router.navigate(['../record'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'preserve' // Preserve all existing query parameters
    });
  }
}
