import { Component, Input, OnInit } from '@angular/core';
import { SocAdvisoryResponse } from '../../../../../admin/advisories/services/advisory.service';

@Component({
  selector: 'app-trending-article',
  standalone: true,
  imports: [],
  templateUrl: './trending-article.component.html',
  styleUrls: ['./trending-article.component.scss'],
})
export class TrendingArticleComponent implements OnInit {
  @Input() public position!: number;
  @Input() public article!: SocAdvisoryResponse;

  public formattedDate = '';

  constructor() {}

  ngOnInit(): void {
    this.formattedDate = this.formatDate(this.article.published_at);
  }

  private formatDate(dateString: string | null): string {
    if (!dateString) {
      return 'Date not available';
    }
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
}
