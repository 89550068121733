<app-org-appbar></app-org-appbar><br />
<kendo-tilelayout
  [columns]="2"
  rowHeight=""
  [resizable]="true"
  [reorderable]="true"
>
  <kendo-tilelayout-item
    [col]="1"
    [colSpan]="2"
    [rowSpan]="2"
  >
    <kendo-tilelayout-item-header class="org-details-header d-flex justify-content-between align-items-center">
      <h4>Overview</h4>
      <div class="ms-auto">
        <button
          kendoButton
          class="details-edit-button"
          (click)="onButtonClickGeneral()"
        >
          {{ isReadOnlyGeneral() ? 'Edit' : 'Save' }}
        </button>
      </div>
    </kendo-tilelayout-item-header>
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else {
      <kendo-tilelayout-item-body>
        <div class="example">
          <form
            class="orgForm"
            [formGroup]="orgForm()"
          >
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Organization ID"
              ></kendo-label>
              <kendo-textbox formControlName="id"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Name"
              ></kendo-label>
              <kendo-textbox formControlName="name"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Owner"
              ></kendo-label>
              <kendo-textbox formControlName="owner"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Owner Email"
              >
              </kendo-label>
              <kendo-textbox
                formControlName="owner_email"
                #email
              ></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Plan"
              ></kendo-label>
              <kendo-dropdownlist
                formControlName="account_type"
                [defaultItem]="{ text: 'Select Account Type', value: null }"
                [data]="accountTypeItems()"
                [valuePrimitive]="true"
                textField="display_text"
                valueField="id"
              ></kendo-dropdownlist>
            </kendo-formfield>
          </form>
          <form
            class="orgForm"
            [formGroup]="orgForm()"
          >
            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Created"
              ></kendo-label>
              <kendo-textbox formControlName="created"></kendo-textbox>
            </kendo-formfield>

            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                text="Website"
              ></kendo-label>
              <kendo-textbox formControlName="website"></kendo-textbox>
            </kendo-formfield>

            <kendo-label
              labelCssClass="k-form-label"
              text="Status"
            ></kendo-label>
            <kendo-dropdownlist
              formControlName="status"
              [valuePrimitive]="true"
              [defaultItem]="{ text: 'Select Status', value: null }"
              textField="text"
              valueField="value"
              [data]="[
                { text: 'Active', value: 1 },
                { text: 'Disabled', value: 0 }
              ]"
            >
            </kendo-dropdownlist>

            <kendo-formfield>
              <kendo-label
                labelCssClass="k-form-label"
                [for]="orgDropDown"
                text="Parent Organization"
              ></kendo-label>
              <cyflare-virtual-dropdown
                #orgDropDown
                [textField]="'name'"
                [valueField]="'id'"
                [refreshOnClick]="true"
                [filterable]="true"
                [styles]="{ width: '225px', margin: '10px 0' }"
                [virtual]="{ itemHeight: 36 }"
                [dataService]="orgServiceDropdown"
                [filterLength]="2"
                formControlName="parentId"
                [valuePrimitive]="true"
                [defaultItem]="orgDefaultItem"
              >
              </cyflare-virtual-dropdown>
            </kendo-formfield>
          </form>
        </div>
      </kendo-tilelayout-item-body>
    }
  </kendo-tilelayout-item>
  @if (isAdmin === true) {
    <kendo-tilelayout-item
      [col]="1"
      [colSpan]="2"
    >
      <kendo-tilelayout-item-header class="org-details-header d-flex justify-content-between align-items-center">
        <h4>Users</h4>
        <div class="ms-auto">
          <cyflare-virtual-dropdown
            #userComboBox
            [textField]="'name'"
            [valueField]="'id'"
            [refreshOnClick]="true"
            [filterable]="true"
            [styles]="{ width: '225px' }"
            [virtual]="{ itemHeight: 36 }"
            [dataService]="userServiceDropdown"
            [mapper]="userMapper"
            (valueChanged)="onUserAssigned($event)"
            [filterField]="'first_name'"
            [filterLength]="2"
          >
          </cyflare-virtual-dropdown>
        </div>
      </kendo-tilelayout-item-header>
      @if (isLoading()) {
        <kendo-loader
          [type]="'infinite-spinner'"
          [size]="'large'"
        ></kendo-loader>
      } @else {
        <kendo-tilelayout-item-body class="flex-body">
          @for (user of users(); track $index) {
            <div class="contact">
              <div class="k-hstack">
                <kendo-avatar [initials]="user.email!"></kendo-avatar>
                <div>
                  <h5>{{ user.first_name }}</h5>
                  <h5>{{ user.last_name }}</h5>
                  <p>{{ user.groups }}</p>
                </div>
                <div style="display: flex; justify-content: start">
                  <kendo-dropdownbutton
                    [data]="['Remove User']"
                    (itemClick)="removeUser(user.id!, this.id())"
                    style="position: absolute; top: 0; right: 0; width: 40px; height: 40px; background-color: transparent; border: none; color: #000; font-size: 1.5rem; cursor: pointer; display: flex; align-items: flex-start; justify-content: center; padding: 0"
                  >
                    ...
                  </kendo-dropdownbutton>
                </div>
              </div>
            </div>
          }
        </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
  } @else {}

  <!-- mXDR Details -->

  <kendo-tilelayout-item [col]="2">
    <kendo-tilelayout-item-header class="org-details-header d-flex justify-content-between align-items-center">
      <h4>mXDR Details</h4>
      <div class="ms-auto">
        <button
          kendoButton
          class="details-edit-button"
          (click)="onButtonClickMxdr()"
        >
          {{ isReadOnlyMxdr() ? 'Edit' : 'Save' }}
        </button>
      </div>
    </kendo-tilelayout-item-header>
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else {
      <kendo-tilelayout-item-body class="max-width">
        <form
          class="k-form"
          [formGroup]="ckbForm()"
        >
          <kendo-label text="# of Windows Agents">
            <kendo-textbox formControlName="xdr_windows_agents"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of Linux Agents">
            <kendo-textbox formControlName="xdr_linux_agents"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of SysLog Senders">
            <kendo-textbox formControlName="xdr_syslog_senders"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of Network Sensors">
            <kendo-textbox formControlName="xdr_network_sensors"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of API Connectors">
            <kendo-textbox formControlName="xdr_api_connectors"></kendo-textbox>
          </kendo-label>
        </form>
      </kendo-tilelayout-item-body>
    }
  </kendo-tilelayout-item>

  <!--mEDR Details-->

  <kendo-tilelayout-item [col]="1">
    <kendo-tilelayout-item-header class="org-details-header d-flex justify-content-between align-items-center">
      <h4>mEDR Details</h4>
      <div class="ms-auto">
        <button
          kendoButton
          class="details-edit-button"
          (click)="onButtonClickMedr()"
        >
          {{ isReadOnlyMedr() ? 'Edit' : 'Save' }}
        </button>
      </div>
    </kendo-tilelayout-item-header>
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else {
      <kendo-tilelayout-item-body class="max-width">
        <form
          class="k-form"
          [formGroup]="ckbForm()"
        >
          <kendo-label text="# of Windows Asset">
            <kendo-textbox formControlName="mdr_windows"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of Linux Asset">
            <kendo-textbox formControlName="mdr_linux"></kendo-textbox>
          </kendo-label>
          <kendo-label text="# of Mac Asset">
            <kendo-textbox formControlName="mdr_mac"></kendo-textbox>
          </kendo-label>
        </form>
      </kendo-tilelayout-item-body>
    }
  </kendo-tilelayout-item>

  <!-- Account Mappings -->
  @if (isAdmin === true) {
    <kendo-tilelayout-item
      [col]="1"
      [colSpan]="2"
    >
      <kendo-tilelayout-item-header class="org-details-header d-flex justify-content-between align-items-center">
        <h4>Account Mappings</h4>
        <div class="ms-auto">
          <button
            kendoButton
            class="details-edit-button"
            (click)="onButtonClickAccountMappings()"
          >
            {{ isReadOnlyAccountMappings() ? 'Edit' : 'Save' }}
          </button>
        </div>
      </kendo-tilelayout-item-header>
      @if (isLoading()) {
        <kendo-loader
          [type]="'infinite-spinner'"
          [size]="'large'"
        ></kendo-loader>
      } @else {
        <kendo-tilelayout-item-body>
          <form
            class="k-form"
            [formGroup]="ckbForm()"
          >
            <div class="k-form-field">
              <div class="k-form-field-container two-column-form">
                <div class="k-form-field-wrap">
                  <label>Zoho Id</label>
                  <kendo-textbox formControlName="zoho_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Zoho Name</label>
                  <kendo-textbox formControlName="zoho_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Stellar Cyber Id</label>
                  <kendo-textbox formControlName="stellar_cyber_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Stellar Cyber Name</label>
                  <kendo-textbox formControlName="stellar_cyber_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Sentinel One Id</label>
                  <kendo-textbox formControlName="sentinel_one_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Sentinel One Name</label>
                  <kendo-textbox formControlName="sentinel_one_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Cyrisma Id</label>
                  <kendo-textbox formControlName="cyrisma_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Cyrisma Name</label>
                  <kendo-textbox formControlName="cyrisma_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Chronicle Id</label>
                  <kendo-textbox formControlName="chronicle_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Chronicle Name</label>
                  <kendo-textbox formControlName="chronicle_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Assure Id</label>
                  <kendo-textbox formControlName="assure_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Assure Name</label>
                  <kendo-textbox formControlName="assure_name"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Polymer Id</label>
                  <kendo-textbox formControlName="polymer_id"></kendo-textbox>
                </div>
                <div class="k-form-field-wrap">
                  <label>Polymer Name</label>
                  <kendo-textbox formControlName="polymer_name"></kendo-textbox>
                </div>
              </div>
            </div>
          </form>
        </kendo-tilelayout-item-body>
      }
    </kendo-tilelayout-item>
  } @else {}
</kendo-tilelayout>
