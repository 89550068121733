<kendo-grid
  [filterable]="true"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  [sort]="gridService.state.sort"
  [sortable]="true"
  (dataStateChange)="gridService.onStateChange($event)"
  (add)="addHandler($event)"
  (save)="saveHandler($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (cancel)="cancelHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      #adminorcustomer_organizations_soarrules_traveladvisories_addnew
      kendoGridAddCommand
    >
      Add new
    </button>
  </ng-template>
  <kendo-grid-column
    field="email"
    title="Travelers Email"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="destination.name"
    title="Destination"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <div [formGroup]="formGroup">
        <kendo-dropdownlist
          #adminorcustomer_organizations_soarrules_traveladvisories_destination
          [data]="data"
          [filterable]="true"
          (filterChange)="handleFilter($event)"
          textField="text"
          valueField="value"
          [defaultItem]="{ text: 'Select a country...', value: null }"
          [valuePrimitive]="true"
          formControlName="destination"
        ></kendo-dropdownlist>
      </div>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.destination.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    #adminorcustomer_organizations_soarrules_traveladvisories_startdate
    field="start_date"
    title="Trip Start Date"
    editor="date"
  >
  </kendo-grid-column>
  <kendo-grid-column
    #adminorcustomer_organizations_soarrules_traveladvisories_enddate
    field="end_date"
    title="Trip End Date"
    editor="date"
  >
  </kendo-grid-column>
  <kendo-grid-command-column
    title="Action"
    [width]="220"
  >
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        #adminorcustomer_organizations_soarrules_traveladvisories_edit
        kendoGridEditCommand
        [primary]="true"
      >
        Edit
      </button>
      <button
        #adminorcustomer_organizations_soarrules_traveladvisories_remove
        kendoGridRemoveCommand
      >
        Remove
      </button>
      <button
        #adminorcustomer_organizations_soarrules_traveladvisories_save
        kendoGridSaveCommand
        [disabled]="false"
      >
        {{ isNew ? 'Add' : 'Update' }}
      </button>
      <button
        #adminorcustomer_organizations_soarrules_traveladvisories_cancel
        kendoGridCancelCommand
      >
        {{ isNew ? 'Cancel' : 'Cancel' }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
