<app-org-appbar></app-org-appbar><br />
<kendo-label
  class="me-1"
  text="Filter by tag"
  labelCssClass="k-form-label"
  [for]="detectionTagsRef"
></kendo-label>
<kendo-multiselect
  style="width: 400px"
  #detectionTagsRef
  [(ngModel)] = "selectedTags"
  [filterable]="true"
  [data]="availableTags()"
  textField="name"
  valueField="id"
  [valuePrimitive]="false"
  (valueChange)="filterDetetections()"
  (filterChange)="onTagFilterChange($event)"
></kendo-multiselect>
<hr/>
<div>
<div class="k-card-list" (scroll)="onScroll($event)">
  @for (detection of filteredDetections(); track detection.detectionId) {
    <div class="item-container">
    <kendo-card class="k-card-item">
      <kendo-card-header>
        <h5>{{ detection.title }}</h5>
      </kendo-card-header>
      <kendo-card-body>
        <p>{{ detection.description }}</p>
        <p>
          @for (tag of detection.tags; track tag.id) {
            <kendo-chip [label]="tag.name" class="me-1 mb-1"></kendo-chip>
          }
        </p>
      </kendo-card-body>
      <kendo-card-footer>
        <kendo-switch
          [checked]="detection.active"
          onLabel="Active"
          offLabel="Muted"
          (valueChange)="onToggleChange(detection, $event)"
        ></kendo-switch>
      </kendo-card-footer>
    </kendo-card>
  </div>
  }
</div>
</div>
