<div>
  <div class="row p-4 tile-container">
    <div class="col tile">
      <div>
        <div class="tile-title">
          <h4 class="title">New Detections</h4>
        </div>
        <div class="content">
          <h1>148</h1>
        </div>
      </div>
    </div>
    <div class="col tile">
      <div>
        <div class="tile-title">
          <h4 class="title">Open Investigations</h4>
        </div>
        <div class="content">
          <h1>80</h1>
        </div>
      </div>
    </div>
    <div class="col tile">
      <div>
        <div class="tile-title">
          <h4 class="title">Escalated Investigations</h4>
        </div>
        <div class="content">
          <h1>9</h1>
        </div>
      </div>
    </div>
    <div class="col tile">
      <div>
        <div class="tile-title">
          <h4 class="title">Escalated Cases</h4>
        </div>
        <div class="content">
          <h1>2</h1>
        </div>
      </div>
    </div>
    <div class="col tile">
      <div>
        <div class="tile-title">
          <h4 class="title">In Tuning</h4>
        </div>
        <div class="content">
          <h1>0</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-4 tile-container">
    <div class="col tile">
      <div class="tile-title">
        <h4>Detections Levels</h4>
      </div>
      <kendo-chart>
        <kendo-chart-legend position="bottom"> </kendo-chart-legend>

        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item
            [categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
            [title]="{ text: 'Months' }"
          >
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>

        <kendo-chart-series-defaults [highlight]="{ inactiveOpacity: 0.3 }"> </kendo-chart-series-defaults>

        <kendo-chart-series>
          <kendo-chart-series-item
            [line]="{ style: 'smooth' }"
            type="area"
            [name]="'Low'"
            [data]="[123, 276, 310, 212, 240, 156, 98]"
          >
          </kendo-chart-series-item>
          <kendo-chart-series-item
            [line]="{ style: 'smooth' }"
            type="area"
            [name]="'Medium'"
            [data]="[165, 210, 287, 144, 190, 167, 212]"
          >
          </kendo-chart-series-item>
          <kendo-chart-series-item
            [line]="{ style: 'smooth' }"
            type="area"
            [name]="'High'"
            [data]="[56, 140, 195, 46, 123, 78, 95]"
          >
          </kendo-chart-series-item>
          <kendo-chart-series-item
            [line]="{ style: 'smooth' }"
            type="area"
            [name]="'Critical'"
            [data]="[100, 140, 220, 93, 238, 112, 132]"
          >
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  </div>
  <div class="row p-4 tile-container">
    <div class="col tile">
      <div>
        <h4>Detection Queue</h4>
      </div>
      <kendo-grid
        (cellClick)="viewCase($event)"
        style="margin-top: 20px"
        [data]="[
          { caseId: '12349', caseTitle: 'Successful Login Outside The US', status: 'reviewed', score: '80', lastUpdated: '2024-07-07' },
          { caseId: '12345', caseTitle: 'Custom Detections - Concecutive Failed Login Attempt', status: 'pending', score: '90', lastUpdated: '2024-07-07' },
          { caseId: '12383', caseTitle: 'Impossible Travel Anomoly', status: 'update', score: '90', lastUpdated: '2024-07-06' },
          { caseId: '12523', caseTitle: 'Microsoft Exchnage Auto Discover', status: 'update', score: '90', lastUpdated: '2024-07-05' },
          { caseId: '52131', caseTitle: 'Multiple Deny Action From A Single Source', status: 'reviewed', score: '40', lastUpdated: '2024-07-05' },
          { caseId: '43212', caseTitle: 'Multiple Deny Action From A Single Source', status: 'reviewed', score: '60', lastUpdated: '2024-07-05' },
          { caseId: '91462', caseTitle: 'Impossible Travel Anomoly', status: 'closed', score: '80', lastUpdated: '2024-07-05' },
          { caseId: '37921', caseTitle: 'Successful Login Outside The US', status: 'reviewed', score: '90', lastUpdated: '2024-07-04' },
          { caseId: '19428', caseTitle: 'Microsoft Exchnage Auto Discover', status: 'reviewed', score: '70', lastUpdated: '2024-07-04' },
          { caseId: '41314', caseTitle: 'Impossible Travel Anomoly', status: 'reviewed', score: '90', lastUpdated: '2024-07-03' }
        ]"
        [pageSize]="10"
        [pageable]="true"
        [sortable]="true"
        [filterable]="false"
        [groupable]="false"
        [height]="420"
      >
        <kendo-grid-column
          field="caseId"
          title="Case ID"
        ></kendo-grid-column>
        <kendo-grid-column
          field="caseTitle"
          title="Case Title"
        ></kendo-grid-column>
        <kendo-grid-column
          field="status"
          title="Status"
        ></kendo-grid-column>
        <kendo-grid-column
          field="score"
          title="Score"
        ></kendo-grid-column>
        <kendo-grid-column
          field="lastUpdated"
          title="Last Updated"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
