<app-admin-appbar></app-admin-appbar><br>
<kendo-grid filterable="row" [style.height.%]="100">
    <kendo-grid-column field="ip_address" title="IP Address">
    </kendo-grid-column>
    <kendo-grid-column field="role" title="Role">
    </kendo-grid-column>
    <kendo-grid-column field="user" title="User Name">
    </kendo-grid-column>
    <kendo-grid-column field="activity" title="Activity">
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Description">
    </kendo-grid-column>
    <kendo-grid-column field="created" title="Created">
    </kendo-grid-column>
</kendo-grid>