import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CancelEvent, CreateFormGroupArgs, EditEvent, GridComponent, GridModule, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { OrgAppbarComponent } from '../../../../../layout/components/org-appbar/org-appbar.component';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { ActivatedRoute } from '@angular/router';
import { TicketingEmailService, TicketingEmailResponse, TicketingEmailPostRequest } from './service/ticketing-email.service';
import { environment } from '../../../../../../../../environments/environment';
import { TicketingEmailFormService } from './service/ticketing-form.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ticketing',
  standalone: true,
  imports: [FormsModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, ReactiveFormsModule, DateInputsModule, GridModule, OrgAppbarComponent, ListViewModule, DropDownsModule, CommonModule],
  templateUrl: './ticketing.component.html',
  styleUrl: './ticketing.component.scss',
})
export class TicketingComponent extends BaseComponent {
  public formGroup: FormGroup | undefined;
  public formBuilder = inject(FormBuilder);
  public formService = inject(TicketingEmailFormService);
  public id!: number;
  public route = inject(ActivatedRoute);
  public gridService = inject(TicketingEmailService); // inject the service
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/ticketingemail`; // Store the base URL
  private editedRowIndex: number | undefined;

  constructor() {
    super();
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params['organizationId'];
    });
    this.loadTicketingEmail(this.id);
  }

  private loadTicketingEmail(orgId: number): void {
    this.gridService.url = `${this.baseUrl}(${orgId})/`; // Reset URL before appending orgId
    this.gridService.read();
  }
  public saveHandler(args: SaveEvent): void {
    if (this.formGroup?.valid) {
      const formData = this.formGroup?.value;

      if (args.isNew) {
        const payload: TicketingEmailPostRequest = {
          organization_id: this.id,
          ticketing_email: {
            pk: formData.pk,
            email: formData.email,
            is_primary: formData.is_primary || false,
          },
        };

        this.gridService.createEmail(payload).subscribe({
          next: () => {
            this.loadTicketingEmail(this.id);
            args.sender.closeRow(args.rowIndex);
          },
          error: (error) => {
            console.error('Error creating email:', error);
          },
        });
      } else {
        const payload: TicketingEmailResponse = {
          pk: formData.pk,
          email: formData.email,
          is_primary: formData.is_primary || false,
        };
        this.gridService.updateEmail(payload).subscribe(() => {
          this.loadTicketingEmail(this.id);
          args.sender.closeRow(args.rowIndex);
        });
      }
    }
  }

  public removeHandler(args: any): void {
    this.gridService.deleteEmail(args.dataItem.pk).subscribe(() => {
      this.loadTicketingEmail(this.id);
    });
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (this.formGroup) {
      grid.closeRow(rowIndex);
      this.formGroup = undefined;
    }
  }

  public cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public editHandler($event: EditEvent): void {
    const dataItem = $event.dataItem;

    this.formGroup = this.formService.createTicketingEmailForm(dataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  public addHandler(args: any): void {
    this.formGroup = this.formService.createTicketingEmailForm();
    args.sender.addRow(this.formGroup);
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const item = args.isNew ? {} : args.dataItem;
    console.log('Creating FormGroup for:', item);

    this.formGroup = this.formBuilder.group({
      pk: item.pk,
      email: item.email,
      is_primary: item.is_primary,
    });

    return this.formGroup;
  }
}
