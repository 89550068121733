import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Contact } from '../../../../../../../../core/models/entities';

export interface ContactForm {
  pk: FormControl<number | null>;
  name: FormControl<string | null>;
  phone: FormControl<string | null>;
  dispatch_line: FormControl<boolean | null>;
  working_days: FormControl<number[] | null>;
  available_from: FormControl<Date | null>;
  available_to: FormControl<Date | null>;
  timezone: FormControl<string | null>;
  organization_id: FormControl<number | null>;
}

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  public createContactForm(contact = new Contact()): FormGroup<ContactForm> {
    return new FormGroup<ContactForm>({
      pk: new FormControl(contact.pk || null),
      name: new FormControl(contact.name, Validators.required),
      phone: new FormControl(contact.phone, Validators.required),
      dispatch_line: new FormControl(contact.dispatch_line),
      working_days: new FormControl(contact.working_days),
      available_from: new FormControl(contact.available_from ? this.convertTimeStringToDate(contact.available_from) : null),
      available_to: new FormControl(contact.available_to ? this.convertTimeStringToDate(contact.available_to) : null),
      timezone: new FormControl(contact.timezone),
      organization_id: new FormControl(contact.organization_id),
    });
  }

  private convertTimeStringToDate(timeString: string): Date {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  // public createOrganizationForm(
  //   organization = {
  //     organization_id: null,
  //     soc_communication: {
  //       pk: null,
  //       initialization_level: 0,
  //       contacts: [],
  //     },
  //   },
  // ) {
  //   return new FormGroup({
  //     organization_id: new FormControl(organization.organization_id || 0, Validators.required),
  //     soc_communication: new FormGroup({
  //       pk: new FormControl(organization.soc_communication.pk || null),
  //       initialization_level: new FormControl(organization.soc_communication.initialization_level || 0, Validators.required),
  //       contacts: new FormArray(organization.soc_communication.contacts.map((contact: any) => this.createContactForm(contact))),
  //     }),
  //   });
  // }
}
