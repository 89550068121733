<kendo-grid
  (cellClick)="editOrg($event)"
  [filterable]="true"
  [data]="gridService | async"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="loader-container">
      <kendo-loader
        type="infinite-spinner"
        size="large"
      ></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      (click)="addOrg(bodyElement)"
      [svgIcon]="plusIcon"
      themeColor="primary"
    >
      Add Organization
    </button>
  </ng-template>
  <kendo-grid-column
    field="id"
    title="Id"
    [width]="140"
    [filterable]="false"
    [hidden]="true"
  ></kendo-grid-column>
  <kendo-grid-column
    field="name"
    title="Name"
    [width]="120"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="parent.name"
    title="Parent"
    [width]="130"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="status_display"
    title="Status"
    [width]="80"
    [filterable]="true"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event)"
        [data]="statuses"
      ></kendo-dropdownlist>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      @if (dataItem.status) {
      <span class="badge bg-success">Active</span>
      } @else {
      <span class="badge bg-danger">Inactive</span>
      }
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="account_type_display"
    title="Type"
    [width]="100"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-dropdownlist
        [defaultItem]="{ display_text: 'All', id: null }"
        textField="display_text"
        valueField="id"
        (valueChange)="accountTypeChange($event)"
        [data]="accountTypeItems()"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
