import { Component, effect, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, CellClickEvent, EditEvent, GridComponent, GridModule, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { OrgAppbarComponent } from '../../../../../layout/components/org-appbar/org-appbar.component';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { IrcContactRequest, IrcContactService } from './services/soccomms.service';
import { Contact, timezones } from '../../../../../../../core/models/entities';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { ContactForm, ContactFormService } from './services/contact-form.service';
import { OrganizationService } from '../../../../services/organization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-calltree',
  standalone: true,
  imports: [FormsModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, ReactiveFormsModule, DateInputsModule, GridModule, OrgAppbarComponent, ListViewModule, DropDownsModule],
  templateUrl: './calltree.component.html',
  styleUrl: './calltree.component.scss',
})
export class CalltreeComponent extends BaseComponent {
  public orgService = inject(OrganizationService);
  public ircContactService = inject(IrcContactService);
  private route = inject(ActivatedRoute);
  private formService = inject(ContactFormService);
  public timeZones = timezones;
  public formGroup: FormGroup<ContactForm> | undefined;
  public contacts = signal<Contact[]>([]);
  public weekdayMap = ['S', 'M', 'T', 'W', 'R', 'F', 'U'];
  private editedRowIndex: number | undefined;
  private id = 0;
  public initLevel: number | undefined;

  public initLevels = [
    { text: 'Critical', value: 3 },
    { text: 'High', value: 2 },
    { text: 'Medium', value: 1 },
  ];

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams
          .pipe(
            filter((params) => params['organizationId']),
            switchMap((params) => {
              this.id = parseInt(params['organizationId'], 10);
              // Fetch organization details
              return this.orgService.getOrganizationById(this.id).pipe(
                switchMap((organization) => {
                  const initLevelItem = this.initLevels.find((level) => level.value == organization.irc_initialization_level);
                  this.initLevel = initLevelItem ? initLevelItem.value : undefined;
                  return this.ircContactService.getContacts(this.id);
                }),
              );
            }),
          )
          .subscribe((data) => {
            this.contacts.set(data.value || []);
          });
      },
      { allowSignalWrites: true },
    );
  }

  public onInitLevelChange(newValue: number) {
    if (this.id) {
      this.orgService.updateInitializationLevel(this.id, newValue).subscribe({
        next: (updatedOrg) => {
          const initLevelItem = this.initLevels.find((level) => level.value == updatedOrg.irc_initialization_level);
          this.initLevel = initLevelItem ? initLevelItem.value : undefined;
        },
        error: (err) => {
          console.error('Failed to update initialization level', err);
        },
      });
    }
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    this.formGroup = this.formService.createContactForm();
    args.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  onAvailabilityChange(event: Date, formControl: FormControl): void {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    const militaryTimeString = event.toLocaleTimeString('en-GB', options);
    formControl.setValue(militaryTimeString);
  }

  onDaysChange(event: number[]): void {
    this.formGroup?.controls.working_days.setValue(event);
  }

  saveHandler($event: SaveEvent) {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      const formatToMilitaryTime = (date: Date | null): string | null => {
        if (!date) {
          return null;
        }
        const options: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };
        return date.toLocaleTimeString('en-GB', options);
      };

      const contact = {
        ...formValue,
        available_from: formValue.available_from ? formatToMilitaryTime(new Date(formValue.available_from)) : null,
        available_to: formValue.available_to ? formatToMilitaryTime(new Date(formValue.available_to)) : null,
      };

      const payload: IrcContactRequest = {
        organization_id: this.id,
        priority_order: $event.rowIndex,
        phone: contact.phone!,
        name: contact.name!,
        dispatch_line: contact.dispatch_line!,
        working_days: contact.working_days!,
        available_from: contact.available_from,
        available_to: contact.available_to,
        timezone: contact.timezone!,
      };

      if ($event.isNew) {
        this.ircContactService
          .createContact(payload)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.closeEditor($event.sender, $event.rowIndex);
              this.ircContactService.getContacts(this.id).subscribe((data) => {
                this.contacts.set(data.value || []);
              });
            },
            error: (error) => {
              console.error('error', error);
            },
          });
      } else {
        const pk = this.contacts()[$event.rowIndex].pk;
        const updatePayload: IrcContactRequest = {
          ...payload,
          pk: pk,
        };

        this.ircContactService
          .updateContact(updatePayload)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.closeEditor($event.sender, $event.rowIndex);
              this.ircContactService.getContacts(this.id).subscribe((data) => {
                this.contacts.set(data.value || []);
              });
            },
          });
      }
    }
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  cellClickHandler($event: CellClickEvent) {
    if ($event.columnIndex === $event.sender.columns.length - 1) return;
    this.formGroup = this.formService.createContactForm($event.dataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
    this.editedRowIndex = $event.rowIndex;
  }

  editHandler($event: EditEvent) {
    this.editedRowIndex = $event.rowIndex;
  }

  removeHandler($event: RemoveEvent) {
    const orgId = this.id;
    const pk = this.contacts()[$event.rowIndex].pk!;

    this.ircContactService
      .deleteContact(orgId, pk)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.ircContactService
            .getContacts(this.id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((data) => {
              this.contacts.set(data.value || []);
            });
        },
        error: (error) => {
          console.error('Error deleting contact:', error);
        },
      });
  }
}
