import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { WinEvent } from '../models/entities';

@Injectable()
export class WinEventsService extends ODataService<WinEvent> {
  private apiUrl = '';
  constructor() {
    const url = `${environment.apiUrl}/v1/analytics/administrative/wineventlog`;
    super(inject(HttpClient), url);

    this.apiUrl = url;
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public getWinEvents(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `(${organizationId})/` : this.apiUrl;
    return this.read();
  }
}

export interface WinEventResponse extends WinEvent {}
