import { Component, CreateEffectOptions, Input, effect, inject, input, signal } from '@angular/core';
import { WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule, ExcelModule, GridDataResult } from '@progress/kendo-angular-grid';
import { MenusModule } from '@progress/kendo-angular-menu';
import { SlaViolationsService } from './services/slaviolations.service';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sla-adherence',
  standalone: true,
  imports: [GaugesModule, LoaderModule, CommonModule, DialogsModule, GridModule, ExcelModule, MenusModule],
  templateUrl: './sla-adherence.component.html',
  styleUrl: './sla-adherence.component.scss',
  providers: [SlaViolationsService],
})
export class SlaAdherenceComponent extends BaseComponent {
  public gridService = inject(SlaViolationsService);

  public slaData = input<WidgetData>({ value: 0, colors: [] });
  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal(true);
  public widgetData = signal<WidgetData>({ value: 0, colors: [] });
  public items: any[] = [{ text: 'View Data' }];
  public showPopup = false;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.widgetData.set(this.slaData());
        this.isLoading.set(false);
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/slaviolationsdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
