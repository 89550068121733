import { Component } from '@angular/core';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { FormGroup, FormsModule, ReactiveFormsModule, FormBuilder } from "@angular/forms";
import { IconsModule } from "@progress/kendo-angular-icons";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { CreateFormGroupArgs, GridModule } from "@progress/kendo-angular-grid";
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { TicketingComponent } from './components/ticketing/ticketing.component';
import { OncallComponent } from './components/oncall/oncall.component';
import { CalltreeComponent } from './components/calltree/calltree.component';

@Component({
  selector: 'app-soc-comms',
  standalone: true,
  imports: [
    FormsModule,
    LayoutModule,
    IconsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    NavigationModule,
    ReactiveFormsModule,
    DateInputsModule,
    GridModule,
    OrgAppbarComponent,
    TicketingComponent,
    OncallComponent,
    CalltreeComponent
  ],
  templateUrl: './soc-comms.component.html',
  styleUrl: './soc-comms.component.scss'
})
export class SocCommsComponent {
  public formGroup: FormGroup | undefined;

  constructor(private formBuilder: FormBuilder) {
    this.createFormGroup = this.createFormGroup.bind(this);
  }

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    const item = args.isNew ? {} : args.dataItem;

    this.formGroup = this.formBuilder.group({
      Email: item.Email,
      isPrimary: item.isPrimary,
    });

    return this.formGroup;
  }

  public ticketing: any[] = [
    {
      Email: 'matthewf@cyflare.com',
      isPrimary: true
    },
    {
      Email: 'othermatt@cyflare.com',
      isPrimary: false
    },
  ]
}
