<div class="article k-d-flex">
  <div class="article-position k-d-inline-flex k-text-primary k-font-weight-bold">
    {{ position }}
  </div>
  <div class="article-description k-d-flex-col">
    <div class="title k-font-weight-bold">{{ article.title }}</div>
    <div class="date k-d-flex">
      <div>{{ formattedDate }}</div>
      <div class="separator">|</div>
      <div>{{ article.published_by }}</div>
    </div>
  </div>
</div>
