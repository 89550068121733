<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-8 text-center">
      <div class="card shadow">
        <div class="card-body">
          <h1 class="display-4 text-danger">Unauthorized</h1>
          <p class="lead">You do not have the necessary roles/permissions 🔐 to access this page.</p>
          <kendo-icon
            name="warning"
            class="text-danger"
            style="font-size: 3rem"
          ></kendo-icon>
          <hr class="my-4" />
          <p class="mt-4">
            <a
              class="btn btn-primary btn-lg"
              [routerLink]="['/customer/customer-dashboard']"
              role="button"
              >Go to Cyflare One Dashboard</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
