<div class="panelbar-wrapper">
  <kendo-panelbar>
    <kendo-panelbar-item
      *ngFor="let activity of threatHunting; let i = index"
      [title]="activity.activity"
      [expanded]="i === 0"
    >
      <ng-template kendoPanelBarContent>
        <div *ngFor="let result of activity.results">
          <div class="result-item">
            <strong>Query:</strong> {{ result.query }}<br />
            <strong>Result Count:</strong> {{ result.result }}<br />
            <strong>Success:</strong> {{ result.success }}
          </div>
        </div>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</div>
