import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SocAdvisory } from '../../../../../core/models/entities';

@Injectable({
  providedIn: 'root',
})
export class SocAdvisoryFormService {
  constructor() {}

  SocAdvisoryForm(socAdvisory = new SocAdvisory()) {
    return new FormGroup<SocAdvisoryForm>({
      pk: new FormControl(socAdvisory.pk || null, Validators.required),
      title: new FormControl(socAdvisory.title || null, Validators.required),
      advisory_for: new FormControl<string[] | null>(socAdvisory.advisory_for || [], Validators.required),
      content: new FormControl(socAdvisory.content || null),
    });
  }
}

export interface SocAdvisoryForm {
  pk: FormControl<number | null>;
  title: FormControl<string | null>;
  advisory_for: FormControl<string[] | null>;
  content: FormControl<string | null>;
}
