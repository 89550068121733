<app-org-appbar></app-org-appbar>
<br>
<kendo-tilelayout [columns]="2">
    <kendo-tilelayout-item title="Ticketing Emails" [col]="2">
        <kendo-tilelayout-item-body>
            <app-ticketing></app-ticketing>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item title="Currently On Call" [col]="1">
        <kendo-tilelayout-item-body>
            <app-oncall></app-oncall>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>

    <kendo-tilelayout-item title="IR Call Tree Setup" [col]="1" [colSpan]="2">
        <kendo-tilelayout-item-body>
            <app-calltree></app-calltree>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>