@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  @if (chartData().length > 0) {
    <kendo-chart
      class="chart-container"
      (seriesClick)="handleSeriesClick($event)"
      (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
    >
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      <kendo-chart-tooltip format="Average Resolution time: {0} hours"></kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="column"
          [data]="chartData()"
          field="count"
          categoryField="description"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [labels]="{ content: truncateCategoryLabel }"> </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [labels]="{ format: '{0}' }">
          <kendo-chart-value-axis-item-title text="Hours"></kendo-chart-value-axis-item-title>
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
    </kendo-chart>

    <kendo-dialog
      *ngIf="showPopup"
      [width]="'calc(100% - 60px)'"
      (close)="close()"
    >
      <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
      <div class="dialog-content">
        <kendo-grid
          class="mxh-80v"
          [filterable]="true"
          [data]="gridService | async"
          [pageable]="{
            buttonCount: 5,
            info: true,
            type: 'numeric',
            pageSizes: [25, 50, 100, 200]
          }"
          [pageSize]="gridService.state.take"
          [skip]="gridService.state.skip"
          [(filter)]="gridService.state.filter"
          [loading]="gridService.loading"
          (dataStateChange)="gridService.onStateChange($event)"
          [sort]="this.gridService.state.sort"
          [sortable]="true"
          [resizable]="true"
        >
          <ng-template kendoGridToolbarTemplate>
            <button
              type="button"
              kendoGridExcelCommand
            >
              Export to Excel
            </button>
          </ng-template>
          <kendo-grid-column
            [filterable]="false"
            field="created_time"
            title="Created On"
          ></kendo-grid-column>
          <kendo-grid-column
            [filterable]="false"
            field="ticket_closed_time"
            title="Closed On"
          ></kendo-grid-column>
          <kendo-grid-column
            field="request_id"
            title="Ticket #"
          ></kendo-grid-column>
          <kendo-grid-column
            field="subject"
            title="Event Name"
          ></kendo-grid-column>
          <kendo-grid-column
            field="priority"
            title="Priority"
          ></kendo-grid-column>
          <kendo-grid-excel
            fileName="mttr-data.xlsx"
            [fetchData]="allData"
          ></kendo-grid-excel>
        </kendo-grid>
      </div>
    </kendo-dialog>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
  } @else {
    <div class="no-data-message">
      <h2>No Data Available</h2>
      <p>There is currently no MTTR data to display.</p>
    </div>
  }
}
