import { Component, ViewEncapsulation, effect, inject } from '@angular/core';
import { BaseComponent } from '../../../../core/shared/common/base.component';
import { CellClickEvent, GridDataResult, GridModule, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { CommonModule } from '@angular/common';
import { DetectionData } from '../../../../core/models/entities';
import { detectionData } from '../../../../../assets/detections-data/detections-data';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DetectionsService } from '../service/detections.service';
import { DetectionBoardFormService } from '../service/detections-board-form.service';
import { DetectionsBoardForm as DetectionBoardForm } from '../../../../core/models/forms';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-detections-grid',
  standalone: true,
  imports: [GridModule, CommonModule, DropDownListModule, IndicatorsModule],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './detections-grid.component.html',
  styleUrls: ['./detections-grid.component.scss'],
})
export class DetectionsGridComponent extends BaseComponent {
  public gridService = inject(DetectionsService);
  public gridData: DetectionData[] = detectionData;
  public formService = inject(DetectionBoardFormService);
  public formGroup: FormGroup<DetectionBoardForm> | undefined;
  private editedRowIndex: number | undefined;

  public isAdmin = false;

  public statuses = [
    { text: 'Active', value: 'Active' },
    { text: 'Not Active', value: 'Not Active' },
    { text: 'New Detection', value: 'New Detection' },
    { text: 'Muted', value: 'Muted' },
    { text: 'Testing', value: 'Testing' },
  ];

  public adrStatuses = [
    { text: 'Full ADR', value: 'Full ADR' },
    { text: 'No ADR', value: 'No ADR' },
    { text: 'Hybrid ADR', value: 'Hybrid ADR' },
  ];

  public killChainStages = [
    { text: 'Initial Attempts', value: 'Initial Attempts' },
    { text: 'Persistent Foothold', value: 'Persistent Foothold' },
    { text: 'Propagation', value: 'Propagation' },
    { text: 'Exfiltration & Impact', value: 'Exfiltration & Impact' },
    { text: 'Exploration', value: 'Exploration' },
  ];

  public mitreTactics = [
    { text: 'Reconnaissance', value: 'Reconnaissance' },
    { text: 'XDR EBA', value: 'XDR EBA' },
    { text: 'Credential Access', value: 'Credential Access' },
    { text: 'Resource Development', value: 'Resource Development' },
    { text: 'Lateral Movement', value: 'Lateral Movement' },
    { text: 'Exfiltration', value: 'Exfiltration' },
    { text: 'Privilege Escalation', value: 'Privilege Escalation' },
    { text: 'XDR NBA', value: 'XDR NBA' },
    { text: 'XDR SBA', value: 'XDR SBA' },
    { text: 'Impact', value: 'Impact' },
    { text: 'Defense Evasion', value: 'Defense Evasion' },
    { text: 'Initial Access', value: 'Initial Access' },
    { text: 'Persistence', value: 'Persistence' },
    { text: 'Execution', value: 'Execution' },
    { text: 'XDR Intel', value: 'XDR Intel' },
    { text: 'Command and Control', value: 'Command and Control' },
    { text: 'Collection', value: 'Collection' },
    { text: 'XDR UBA', value: 'XDR UBA' },
    { text: 'XDR Malware', value: 'XDR Malware' },
    { text: 'Discovery', value: 'Discovery' },
  ];

  public connectors = [
    { text: 'Acronis Cyber Protect', value: 'Acronis Cyber Protect' },
    { text: 'Duo', value: 'Duo' },
    { text: 'Huntress', value: 'Huntress' },
    { text: 'Azure AD', value: 'Azure AD' },
    { text: 'GSuite', value: 'GSuite' },
    { text: 'SentinelOne', value: 'SentinelOne' },
    { text: 'Okta', value: 'Okta' },
    { text: 'Office 365', value: 'Office 365' },
    { text: 'AWS CloudTrail', value: 'AWS CloudTrail' },
    { text: 'Box', value: 'Box' },
    { text: 'Fortigate', value: 'Fortigate' },
    { text: 'Microsoft Defender', value: 'Microsoft Defender' },
    { text: 'JumpCloud', value: 'JumpCloud' },
    { text: 'Cynet', value: 'Cynet' },
    { text: 'AWS', value: 'AWS' },
    { text: 'FortiGate', value: 'FortiGate' },
    { text: 'Sophos', value: 'Sophos' },
    { text: 'Cybereason', value: 'Cybereason' },
    { text: 'Carbon Black', value: 'Carbon Black' },
    { text: 'Darktrace', value: 'Darktrace' },
    { text: 'Cylance', value: 'Cylance' },
    { text: 'BitDefender', value: 'BitDefender' },
    { text: 'Deep Instinct', value: 'Deep Instinct' },
    { text: 'Varonis-Datadvantage', value: 'Varonis-Datadvantage' },
    { text: 'Trend Micro Vision One', value: 'Trend Micro Vision One' },
    { text: 'Netskope', value: 'Netskope' },
    { text: 'ProofPoint TAP', value: 'ProofPoint TAP' },
    { text: 'FireEye HX', value: 'FireEye HX' },
    { text: 'Sonic FW', value: 'Sonic FW' },
    { text: 'Oracle Cloud Infrastructure', value: 'Oracle Cloud Infrastructure' },
    { text: 'Google Cloud Audit Logging', value: 'Google Cloud Audit Logging' },
    { text: 'LimaCharlie', value: 'LimaCharlie' },
    { text: 'Mimecast', value: 'Mimecast' },
    { text: 'CrowdStrike', value: 'CrowdStrike' },
    { text: 'AWS GuardDuty', value: 'AWS GuardDuty' },
  ];

  public detectionOrigins = [
    { text: 'Cyflare', value: 'Cyflare' },
    { text: 'Stellar', value: 'Stellar' },
  ];

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  constructor() {
    super();
    this.initEffect();
  }

  initEffect() {
    effect(() => {
      this.gridService.read();
    });
  }

  public statusChange($event: { text: string; value: string | null }, field: string): void {
    const currentFilters = this.filter.filters || [];

    const newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== field);

    if ($event.value !== null) {
      newFilters.push({
        field: field,
        operator: 'eq',
        value: $event.value,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  // Commenting out for this release. will implement in future release
  // cellClickHandler($event: CellClickEvent) {
  //   if ($event.columnIndex === $event.sender.columns.length - 1) return;
  //   this.formGroup = this.formService.createDetectionBoardForm($event.dataItem);
  //   $event.sender.editRow($event.rowIndex, this.formGroup);
  //   this.editedRowIndex = $event.rowIndex;
  // }
}
