import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { SocEvent } from '../models/entities';

@Injectable()
export class SlaAdherenceService extends ODataService<SocEvent> {
  private apiUrl = '';
  constructor() {
    const url = `${environment.apiUrl}/v1/analytics/administrative/slaviolationsdetails`;
    super(inject(HttpClient), url);

    this.apiUrl = url;
  }

  public getSlaAdherenceDetails(organizationId: number | null) {
    this.url = organizationId ? this.apiUrl + `(${organizationId})/` : this.apiUrl;
    return this.read();
  }
}

export interface SlaAdherenceResponse extends SocEvent {}
