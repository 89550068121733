@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (detectionDisposition().length > 0) {
  <kendo-chart
    #detectionDispositionChart
    class="chart-container"
    (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
  >
    <kendo-chart-tooltip> </kendo-chart-tooltip>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="column"
        [data]="detectionDisposition()"
        field="count"
        categoryField="closure_reason"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item>
        <kendo-chart-category-axis-item-labels
          [content]="truncateCategoryLabel"
          rotation="auto"
        ></kendo-chart-category-axis-item-labels>
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
  </kendo-chart>

  <ng-template #popupTemplate>
    <div class="chart-popup k-tooltip">
      {{ popupService.popupContent }}
    </div>
  </ng-template>
} @else {
  <h1>No Data</h1>
}
