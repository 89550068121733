import { Component, signal, CreateEffectOptions, effect, inject, input } from '@angular/core';
import { ChartsModule, SeriesLabelsContentArgs, SeriesVisualArgs } from '@progress/kendo-angular-charts';
import { geometry, Element, Layout, Text } from '@progress/kendo-drawing';
import { AnalyticsService, AgentStatusData } from '../../../../../../../core/services/analytics.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import 'hammerjs';
import { Observable } from 'rxjs';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { EdrAgentStatusDetailsService } from './services/edragentstatusdetails.service';
import { ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../../../../environments/environment';
import { MenusModule } from '@progress/kendo-angular-menu';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-agent-status',
  standalone: true,
  imports: [ChartsModule, LoaderModule, GridModule, ExcelModule, MenusModule, CommonModule, DialogsModule],
  templateUrl: './agent-status.component.html',
  styleUrl: './agent-status.component.scss',
  providers: [AnalyticsService, EdrAgentStatusDetailsService],
})
export class AgentStatusComponent extends BaseComponent {
  public gridService = inject(EdrAgentStatusDetailsService);

  public status = input<AgentStatusData[]>([]);

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal<boolean>(true);
  public autofit = true;
  private center!: geometry.Point;
  private radius!: number;
  public items: any[] = [{ text: 'View Data' }];
  public showPopup = false;

  // Necessary to bind `this` to the AppComponent instance
  public visualHandler = this.visual.bind(this);

  constructor(private intl: IntlService) {
    super();
    this.initEffect();
    this.labelContent = this.labelContent.bind(this);
  }

  private initEffect() {
    effect(
      () => {
        const data = this.status();
        if (data) {
          this.isLoading.set(false);
        } else {
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public visual(e: SeriesVisualArgs): Element {
    // Obtain parameters for the segments
    if (!e.center || e.innerRadius === undefined) {
      throw new Error('Required chart parameters are missing');
    }
    this.center = e.center;
    this.radius = e.innerRadius;

    // Create default visual
    return e.createVisual();
  }

    private getTotalValue(): number {
    return this.status().reduce((sum, item) => sum + item.count, 0);
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/edragentstatusdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
}

public allData = (): Observable<GridDataResult> => {
  return this.gridService.queryAll();
};

// Close popup
close() {
  this.showPopup = false;
}

  public labelContent(e: SeriesLabelsContentArgs): string {
    const percentage = e.value / this.getTotalValue();
    return `${e.category}: ${this.intl.formatNumber(percentage, 'p0')}`;
  }
}
