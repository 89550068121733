<div>
  <div class="row p-4 tile-container">
    <div class="col tile"><app-new-tickets-widget></app-new-tickets-widget></div>
    <div class="col tile"><app-progress-tickets-widget></app-progress-tickets-widget></div>
    <div class="col tile"><app-closed-today-widget></app-closed-today-widget></div>
    <div class="col tile"><app-mttd-widget></app-mttd-widget></div>
    <div class="col tile"><app-tpr-widget></app-tpr-widget></div>
  </div>
  <div class="row p-4 tile-container">
    <div class="col col-md-12 tile">
      <h5>
        Data Connection Issues
        <kendo-svg-icon
          kendoTooltip
          [icon]="infoCircleIcon"
          title="Data sources that appear to be down or offline"
        ></kendo-svg-icon>
      </h5>
      <app-connector-status></app-connector-status>
    </div>
  </div>
  <div class="row p-4 tile-container">
    <div class="col col-md-7 tile"><app-ticket-trends></app-ticket-trends></div>
    <div class="col tile"><app-advisories-main></app-advisories-main></div>
  </div>
  <div class="row p-4 tile-container">
    <div class="col col-md-7 tile"><app-quick-links></app-quick-links></div>
  </div>
</div>
