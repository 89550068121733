@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (ticketTrends() !== null) {
  <kendo-chart>
    <kendo-chart-tooltip> </kendo-chart-tooltip>
    <kendo-chart-legend position="bottom"> </kendo-chart-legend>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item
        [categories]="ticketTrends()?.categories || []"
        [title]="{ text: ticketTrends()?.title }"
      >
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
    <kendo-chart-series-defaults [highlight]="{ inactiveOpacity: 0.3 }"></kendo-chart-series-defaults>
    <kendo-chart-series>
      @for (seriesItem of ticketTrends()?.series; track $index) {
        <ng-container>
          <kendo-chart-series-item
            [line]="{ style: 'smooth' }"
            type="area"
            [name]="seriesItem.name"
            [data]="seriesItem.data || []"
            [zIndex]="getSeriesZIndex(seriesItem.name)"
          ></kendo-chart-series-item>
        </ng-container>
      }
    </kendo-chart-series>
  </kendo-chart>
} @else {
  <h1>No Data</h1>
}
