import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { VulnerabilityScanner } from '../../../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class VulnerabilityScannerService extends ODataService<VulnerabilityScannerResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/vulnerabilityscanner`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public createVulnerabilityScanner(request: VulnerabilityScannerRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/vulnerabilityscanner/`;
    return this.http.post<VulnerabilityScannerRequest>(url, request);
  }

  public updateVulnerabilityScanner(request: VulnerabilityScanner) {
    const url = `${this.url}`;
    return this.http.patch<VulnerabilityScannerResponse>(url, request);
  }

  public deleteVulnerabilityScanner(vulnerabilityScannerId: number) {
    const url = `${this.url}?pk=${vulnerabilityScannerId}`;
    return this.http.delete(url);
  }
}

export interface VulnerabilityScannerResponse extends VulnerabilityScanner {}

export interface VulnerabilityScannerRequest {
  organization_id: number;
  vulnerability_scanner: VulnerabilityScanner;
}

export interface VulnerabilityScannerResponse extends VulnerabilityScannerRequest {}
