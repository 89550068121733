import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IUser } from '../../../../../../core/models/entities';
import { User } from '../../../../../../core/models/user';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  userForm(user: IUser = User.createUser()): FormGroup<UserForm> {
    const orgs = user.organizations?.map(org => org?.id)
      .filter((id): id is number => id !== null && id !== undefined) ?? [];

    return new FormGroup<UserForm>({
      id: new FormControl<number | null>(user.id),
      first_name: new FormControl<string | null>(user.first_name),
      last_name: new FormControl<string | null>(user.last_name),
      email: new FormControl<string | null>(user.email, [Validators.email, Validators.required]),
      role: new FormControl<string | null>(user.groups![0] ?? '', Validators.required),      
      primary_organization_id: new FormControl<number>(user.primary_organization?.id!, [Validators.required]),
      organizations: new FormArray(this.createOrganizationControls(orgs) as any)
    });
  }

  private createOrganizationControls(orgs: number[]): FormControl<number | null>[] {
    return orgs.map(orgId => new FormControl<number | null>(orgId, Validators.required));
  }
}

export interface UserForm {
  id: FormControl<number | null>;
  first_name: FormControl<string | null>;
  last_name: FormControl<string | null>;
  email: FormControl<string | null>;
  role: FormControl<string | null>;
  primary_organization_id: FormControl<number | null>;
  organizations: FormArray<FormControl<number | null>>;
}
