<nav aria-label="breadcrumb">
  @if (loading$ | async; as loading) {
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Loading...</li>
    </ol>
  } @else {
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        {{ pageTitle$ | async }}
      </li>
    </ol>
  }
</nav>
