<div class="panel">
  <div class="row filters mb-3">
    <div class="col-6">
      <app-cyflare-period
        [(dateRange)]="selectedDateRange"
        label="Filter MITRE ATT&CK:"
      ></app-cyflare-period>
    </div>
  </div>
</div>
@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  <div class="mitremainapp">
    <kendo-stacklayout
      [gap]="15"
      [orientation]="orientation"
    >
      <kendo-card
        style="max-width: 150px"
        width="auto"
        *ngFor="let item of data()"
      >
        <kendo-card-header class="k-hstack">
          <div>
            <h5 kendoCardTitle>{{ item.name }}</h5>
            <div class="component">
              <p kendoCardSubtitle>
                {{ item.code }}
              </p>

              <kendo-badge
                rounded="medium"
                themeColor="warning"
                position="inside"
                >{{ item.count }}</kendo-badge
              >
            </div>
          </div>
        </kendo-card-header>

        <kendo-card-body>
          <kendo-stacklayout
            [gap]="10"
            class="innercards"
            orientation="vertical"
          >
            <kendo-card
              width="auto"
              *ngFor="let val of item.techniques"
            >
              <kendo-card-body [ngStyle]="{ 'background-color': val.color }">
                <div>
                  <p kendoCardTitle>{{ val.name }}</p>
                  <p kendoCardSubtitle>{{ val.count }}</p>
                </div>
              </kendo-card-body>
              <!-- <kendo-card-footer *ngIf="val.subtechniques.length > 0">
                <div class="panelbar-wrapper">
                  <kendo-panelbar>
                    <kendo-panelbar-item
                      title="----"
                      [expanded]="false"
                    >
                      <ng-template kendoPanelBarContent>
                        <div *ngFor="let subt of val.subtechniques">
                          <h5 kendoCardTitle>{{ subt.name }}</h5>
                          <p
                            class="sub-title-text"
                            kendoCardSubtitle
                          >
                            {{ subt.count }}
                          </p>
                        </div>
                      </ng-template>
                    </kendo-panelbar-item>
                  </kendo-panelbar>
                </div>
              </kendo-card-footer> -->
            </kendo-card>
          </kendo-stacklayout>
        </kendo-card-body>
      </kendo-card>
    </kendo-stacklayout>
  </div>
}
