import { Component, ComponentRef, ElementRef, ViewChild, ViewContainerRef, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportComponent, PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { filter, takeUntil } from 'rxjs';
import { CommonModule, formatDate } from '@angular/common';
import { OrganizationService } from '../../admin/organizations/services/organization.service';
import { ExecutiveSummaryComponent } from './components/reports/executive-summary/executive-summary.component';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { SchedulerComponent } from './components/forms/scheduler/scheduler.component';
import { PopupHostComponent } from '../../../core/components/popup-host.component';
import { CyflarePeriodComponent } from '../../../core/components/cyflare-period/cyflare-period.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CasesComponent } from './components/reports/cases/cases.component';
import { SocEventsComponent } from './components/reports/soc-events/soc-events.component';
import { WinAccountActivityComponent } from './components/reports/winevents/win-account-activity/win-account-activity.component';
import { WinAccountAccessComponent } from './components/reports/winevents/win-account-access/win-account-access.component';
import { WinSecurityAuditComponent } from './components/reports/winevents/win-security-audit/win-security-audit.component';
import { AzureDeviceActivityComponent } from './components/reports/azureadevents/azure-device-activity/azure-device-activity.component';
import { AzurePolicyActivityComponent } from './components/reports/azureadevents/azure-policy-activity/azure-policy-activity.component';
import { O365AccountActivityComponent } from './components/reports/o365events/o365-account-activity/o365-account-activity.component';
import { O365EmailCompromiseComponent } from './components/reports/o365events/o365-email-compromise/o365-email-compromise.component';
import { O365DataMonitoringComponent } from './components/reports/o365events/o365-data-monitoring/o365-data-monitoring.component';
import { O365MultiCountrySuccessComponent } from './components/reports/o365events/o365-multi-country-success/o365-multi-country-success.component';
import { O365MultisourceLoginFailureComponent } from './components/reports/o365events/o365-multisource-login-failure/o365-multisource-login-failure.component';
import { O365SinglesourceLoginFailureComponent } from './components/reports/o365events/o365-singlesource-login-failure/o365-singlesource-login-failure.component';
import { XdringestionComponent } from './components/reports/xdringestion/xdringestion.component';
import { ReportingService } from './services/reporting.services';
import { SlaAdherenceComponent } from './components/reports/sla-adherence/sla-adherence.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-reporting',
  standalone: true,
  imports: [CyflarePeriodComponent, ButtonsModule, CommonModule, PDFExportModule, LayoutModule],
  templateUrl: './reporting.page.html',
  styleUrls: ['./reporting.page.scss'],
  providers: [AnalyticsService],
})
export class ReportingComponent extends PopupHostComponent {
  @ViewChild('pdf', { static: true }) pdf!: PDFExportComponent;
  @ViewChild('container', { read: ViewContainerRef, static: false }) container!: ViewContainerRef;
  @ViewChild('headingDiv', { static: true }) headingDiv!: ElementRef;

  public xIcon: SVGIcon = xIcon;
  private route = inject(ActivatedRoute);
  private orgService = inject(OrganizationService);
  private organizationName: string = 'Unknown';
  public selectedDateRange = signal<string[]>([]);
  private organizationId: number | null = null;
  private componentRef: ComponentRef<any> | null = null;

  selectedReport: any = null;

  currentDate = new Date();
  formattedDate = this.currentDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  shortDate = formatDate(this.currentDate, 'yyyyMMdd', 'en-US');

  public reports: any[] = [];
  private reportingService = inject(ReportingService);

  public get exportOptions() {
    const options = [
      {
        id: 'pdf',
        text: 'PDF File',
      },
      {
        id: 'excel',
        text: 'Excel file',
      },
    ];
    if (this.selectedReport) {
      if (this.selectedReport.grid) {
        if(!this.selectedReport.export_pdf){
          return options.filter((X: any) => X.id != 'pdf');
        }else{
          return options;
        }
      } else {
        return options.filter((X: any) => X.id != 'excel');
      }
    } else {
      return [];
    }
  }

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => !!params['organizationId'])).subscribe({
          next: (params) => {
            this.organizationId = +params['organizationId'];
            this.fetchOrganizationName(this.organizationId);
            this.loadReports();
          },
          error: (error) => {
            console.error('Error processing query parameters:', error);
          },
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadReports(): void {
    this.reportingService
      .getReports(this.organizationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (reports) => {
          this.reports = reports.map((report) => this.mapReportToViewModel(report)).filter((report): report is NonNullable<typeof report> => report !== null);

          if (this.reports.length > 0) {
            this.selectReport(this.reports[0]);
          }
        },
        error: (error) => {
          console.error('Error loading reports:', error);
        },
      });
  }

  onItemClick(event: any): void {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}`;

    if (event.id === 'pdf') {
      if (this.selectedReport.grid) {
        if (this.componentRef) {
          this.componentRef.instance.headerHtml = this.headingDiv.nativeElement.innerHTML;
          this.componentRef.instance.landscape = this.selectedReport.landscape;
          this.componentRef.instance.exportPDF(fileName);
        }
      } else {
        this.pdf.saveAs(fileName);
      }
    } else if (event.id === 'excel' && this.selectedReport.grid) {
      if (this.componentRef) {
        this.componentRef.instance.exportExcel(fileName);
      }
    }
  }

  private fetchOrganizationName(organizationId: number) {
    this.orgService
      .getOrganizationById(organizationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.organizationName = response.name ?? 'Unknown';
        },
        error: (error) => {
          console.error('Error fetching Organization Name:', error);
        },
      });
  }

  public manageSchedule(): void {
    this.togglePopup();
  }

  public togglePopup(): void {
    console.log('Opening scheduler with:', {
      reportId: this.selectedReport?.report_identifier,
      orgId: this.organizationId,
    });

    if (!this.selectedReport?.report_identifier || !this.organizationId) {
      console.error('Missing required data:', {
        reportIdentifier: this.selectedReport?.report_identifier,
        organizationId: this.organizationId,
      });
      return;
    }

    const options = {
      animate: { type: 'slide', direction: 'left', duration: 200 },
      anchorAlign: { horizontal: 'right', vertical: 'top' },
      popupAlign: { horizontal: 'right', vertical: 'top' },
    };

    this.openPopup(this.bodyElement, SchedulerComponent, options);

    setTimeout(() => {
      const schedulerComponent = this.popupRef?.content.instance as SchedulerComponent;
      if (schedulerComponent) {
        schedulerComponent.reportIdentifier = this.selectedReport!.report_identifier;
        schedulerComponent.organizationId = this.organizationId!;
        schedulerComponent.isDefault = this.selectedReport?.is_default ?? false;

        schedulerComponent.loadSchedules();

        schedulerComponent.close.subscribe(() => {
          this.closePopup();
        });
      }
    });
  }

  selectReport(report: any): void {
    this.container.clear();

    if (!report || !report.component) {
      return;
    }

    if (this.selectedDateRange()) {
      this.selectedReport = report;
      this.componentRef = this.container.createComponent(report.component);
      this.componentRef.instance.selectedDateRange = this.selectedDateRange;
    }
  }

  generatePdf() {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}.pdf`;
    this.pdf.saveAs(fileName);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString.substring(0, 10), format, 'en-US');
  }

  generateExcel() {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}.pdf`;
    this.pdf.saveAs(fileName);
  }

  private mapReportToViewModel(report: any) {
    if (!report || !report.report_identifier) {
      return null;
    }

    const componentMap: { [key: string]: any } = {
      SOC_EVENTS_REPORT: {
        component: SocEventsComponent,
        title: 'SOC Events Report',
        landscape: true,
        grid: true,
        export_pdf: false,
      },
      ESCALATED_CASES_REPORT: {
        component: CasesComponent,
        title: 'Escalated Cases Report',
        landscape: true,
        grid: true,
      },
      AZURE_DEVICE_ACTIVITY_REPORT: {
        component: AzureDeviceActivityComponent,
        title: 'Azure Device Activity Report',
        landscape: true,
        grid: true,
      },
      AZURE_POLICY_ACTIVITY_REPORT: {
        component: AzurePolicyActivityComponent,
        title: 'Azure Policy Activity Report',
        landscape: true,
        grid: true,
      },
      WINDOW_ACCOUNT_ACCESS_REPORT: {
        component: WinAccountAccessComponent,
        title: 'Windows Account Access Report',
        landscape: true,
        grid: true,
      },
      WINDOW_ACCOUNT_ACTIVITY_REPORT: {
        component: WinAccountActivityComponent,
        title: 'Windows Account Activity Report',
        landscape: true,
        grid: true,
      },
      WINDOW_SECURITY_AUDIT_REPORT: {
        component: WinSecurityAuditComponent,
        title: 'Windows Security Audit Report',
        landscape: true,
        grid: true,
      },
      EXECUTIVE_REPORT: {
        component: ExecutiveSummaryComponent,
        title: 'Executive Report',
        landscape: false,
        grid: false,
      },
      O365_ACCOUNT_ACTIVITY_REPORT: {
        component: O365AccountActivityComponent,
        title: 'O365 Account Activity Report',
        landscape: true,
        grid: true,
      },
      O365_MULTI_COUNTRY_SUCCESSFUL_LOGINS_REPORT: {
        component: O365MultiCountrySuccessComponent,
        title: 'O365 Multi Country Successful Logins Report',
        landscape: true,
        grid: true,
      },
      O365_MULTI_SOURCE_FAILED_LOGINS_REPORT: {
        component: O365MultisourceLoginFailureComponent,
        title: 'O365 Multi Source Failed Logins Report',
        landscape: true,
        grid: true,
      },
      O365_SINGLE_SOURCE_FAILED_LOGINS_REPORT: {
        component: O365SinglesourceLoginFailureComponent,
        title: 'O365 Single Source Failed Logins Report',
        landscape: true,
        grid: true,
      },
      O365_BUSINESS_EMAIL_COMPROMISE_REPORT: {
        component: O365EmailCompromiseComponent,
        title: 'O365 Business Email Compromise Report',
        landscape: true,
        grid: true,
      },
      O365_DATA_MONITORING_REPORT: {
        component: O365DataMonitoringComponent,
        title: 'O365 Data Monitoring Report',
        landscape: true,
        grid: true,
      },
      XDR_INGESTION_DATA_REPORT: {
        component: XdringestionComponent,
        title: 'Xdr Ingestion Data Report',
        landscape: true,
        grid: true,
      },
      SLA_ADHERENCE_DATA_REPORT: {
        component: SlaAdherenceComponent,
        title: 'SLA Adherence Report',
        landscape: true,
        grid: true,
      },
    };

    const mapping = componentMap[report.report_identifier];

    if (!mapping) {
      return null;
    }

    return {
      title: mapping.title || report.report_name,
      description: report.report_description || '',
      report_identifier: report.report_identifier,
      report_type: report.report_type || 'PDF',
      component: mapping.component,
      landscape: mapping.landscape || false,
      grid: mapping.grid || false,
      is_default: report.is_default || false,
      scheduling_enabled: report.scheduling_enabled || false,
      heap_id: `customer_reporting_${(report.report_name || '').toLowerCase().replace(/\s+/g, '_')}`,
      export_pdf: "export_pdf" in mapping ? mapping.export_pdf : true,
    };
  }
}
