import { Routes } from '@angular/router';
import { LoginComponent } from './features/auth/login.component';
import { SimpleLayoutComponent } from './features/auth/components/simple-layout/simple-layout.component';
import { AdminLayoutComponent } from './features/admin/layout/admin-layout.component';
import { CustomerLayoutComponent } from './features/customer/layout/customer-layout.component';
import { DashboardPage } from './features/admin/dashboard/dashboard.page';
import { UsersPage } from './features/admin/users/users.page';
import { AdvisoriesPage } from './features/admin/advisories/advisories.page';
import { ChartsPage } from './features/admin/charts/charts.page';
import { SyslogPage } from './features/admin/syslog/syslog.page';
import { OrganizationsPage } from './features/admin/organizations/organizations.page';
import { UpdatePasswordComponent } from './features/auth/components/update-password/update-password.component';
import { ResetPasswordComponent } from './features/auth/components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './features/auth/components/forgot-password/forgot-password.component';
import { EnterMfaComponent } from './features/auth/components/enter-mfa/enter-mfa.component';
import { SetupMfaComponent } from './features/auth/components/setup-mfa/setup-mfa.component';
import { GeneralComponent } from './features/admin/organizations/components/general/general.component';
import { SoarRulesComponent } from './features/admin/organizations/components/soar-rules/soar-rules.component';
import { SocCommsComponent } from './features/admin/organizations/components/soc-comms/soc-comms.component';
import { AnalyticsComponent } from './features/customer/analytics/analytics.component';
import { ReportingComponent } from './features/customer/reporting/reporting.page';
import { CustomerDashboardComponent } from './features/customer/customer-dashboard/customer-dashboard.component';
import { CustomerSettingsComponent } from './features/customer/customer-settings/customer-settings.component';
import { NewAdvisoryComponent } from './features/admin/advisories/new-advisory/new-advisory.component';
import { ManagerPage } from './features/admin/manager/manager.page';
import { DetectionSettingsComponent } from './features/admin/organizations/components/detection-settings/detection-settings.component';
import { UnauthorizedPage } from './features/auth/unauthorized/unauthorized.page';
import { adminRouteGuard } from './core/shared/admin-route.guard';
import { customerRouteGuard } from './core/shared/customer-route.guard';
import { AdvisoryPage } from './features/customer/advisory/advisory.page';
import { SoarOperationsComponent } from './features/customer/soar-operations/soar-operations.component';
import { RedirectLandingComponent } from './features/auth/components/redirect-landing/redirect-landing.component';
import { DetectionsBoardComponent } from './features/customer/detections-board/detections-board.page';
import { RoadmapComponent } from './features/admin/roadmap/roadmap.component';
import { CaseDashboardComponent } from './features/customer/case-dashboard/case-dashboard.component';
import { TicketsLogPage } from './features/customer/tickets-log/tickets-log.page';
import { IntegrationsComponent } from './features/admin/organizations/components/integrations/integrations.component';
import { CustomersComponent } from './features/admin/organizations/components/integrations/components/connectwise/customers/customers.component';
import { MitreAttackComponent } from './features/customer/mitreattack/mitreattack.component';
import { routes as integrationsRoutes } from './features/admin/organizations/components/integrations/integrations-routing.module';
import { AlertPreferencesPage } from './features/customer/alert-preferences/alert-preferences.page';
import { CustomerSocCommsPage } from './features/customer/customer-soc-comms/customer-soc-comms.page';
import { SocTuningPage } from './features/customer/soc-tuning/soc-tuning.page';
import { WhiteLabelComponent } from './features/admin/organizations/components/white-label/white-label.component';

export const routes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    component: SimpleLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'update-password', component: UpdatePasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'enter-mfa', component: EnterMfaComponent },
      { path: 'setup-mfa', component: SetupMfaComponent },
      { path: 'redirect-landing', component: RedirectLandingComponent },
    ],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardPage, canActivate: [adminRouteGuard], data: { title: 'Dashboard' } },
      { path: 'users', component: UsersPage, canActivate: [adminRouteGuard], data: { title: 'Users' } },
      { path: 'advisories', component: AdvisoriesPage, canActivate: [adminRouteGuard], data: { title: 'Advisories' } },
      { path: 'charts', component: ChartsPage, canActivate: [adminRouteGuard], data: { title: 'Charts' } },
      { path: 'syslog', component: SyslogPage, canActivate: [adminRouteGuard], data: { title: 'System Log' } },
      { path: 'organizations', component: OrganizationsPage, canActivate: [adminRouteGuard], data: { title: 'Organizations' } },
      { path: 'organizations/general', component: GeneralComponent, canActivate: [adminRouteGuard], data: { title: 'General - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/soar-rules', component: SoarRulesComponent, canActivate: [adminRouteGuard], data: { title: 'Soar Rules - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/soc-comms', component: SocCommsComponent, canActivate: [adminRouteGuard], data: { title: 'SOC Comms - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/detection-settings', component: DetectionSettingsComponent, canActivate: [adminRouteGuard], data: { title: 'Detection Settings - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/integrations', component: IntegrationsComponent, canActivate: [adminRouteGuard], data: { title: 'Integrations - ${org.name}', requiresOrgName: true }, children: integrationsRoutes },
      { path: 'organizations/integrations/:integration/customers', component: CustomersComponent, canActivate: [adminRouteGuard], data: { title: 'Integration - Customers' } },
      { path: 'advisories/new-advisory', component: NewAdvisoryComponent, canActivate: [adminRouteGuard], data: { title: 'New Advisory' } },
      { path: 'manager', component: ManagerPage, canActivate: [adminRouteGuard], data: { title: 'Detections Manager' } },
      { path: 'roadmap', component: RoadmapComponent, canActivate: [adminRouteGuard], data: { title: 'Roadmap' } },
      { path: 'organizations/whitelabel', component: WhiteLabelComponent, canActivate: [adminRouteGuard], data: { title: 'White Label - ${org.name}', requiresOrgName: true } },
    ],
  },
  {
    path: 'customer',
    component: CustomerLayoutComponent,
    children: [
      { path: 'customer-dashboard', component: CustomerDashboardComponent, canActivate: [customerRouteGuard] },
      { path: 'customer-settings', component: CustomerSettingsComponent, canActivate: [customerRouteGuard] },
      { path: 'analytics', component: AnalyticsComponent, canActivate: [customerRouteGuard] },
      { path: 'soar-operations', component: SoarOperationsComponent, canActivate: [customerRouteGuard] },
      { path: 'reporting', component: ReportingComponent, canActivate: [customerRouteGuard] },
      { path: 'mitreattack', component: MitreAttackComponent, canActivate: [customerRouteGuard] },
      { path: 'tickets-log', component: TicketsLogPage, canActivate: [customerRouteGuard], data: { title: 'Tickets Log' } },
      { path: 'advisory', component: AdvisoryPage, canActivate: [customerRouteGuard] },
      { path: 'unauthorized', component: UnauthorizedPage },
      { path: 'organizations', component: OrganizationsPage, canActivate: [customerRouteGuard], data: { title: 'Organizations' } },
      { path: 'organizations/general', component: GeneralComponent, canActivate: [customerRouteGuard], data: { title: 'General - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/soar-rules', component: SoarRulesComponent, canActivate: [customerRouteGuard], data: { title: 'Soar Rules - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/soc-comms', component: SocCommsComponent, canActivate: [customerRouteGuard], data: { title: 'SOC Comms - ${org.name}', requiresOrgName: true } },
      { path: 'organizations/detection-settings', component: DetectionSettingsComponent, canActivate: [customerRouteGuard], data: { title: 'Detection Settings - ${org.name}', requiresOrgName: true } },
      { path: 'detections-board', component: DetectionsBoardComponent, canActivate: [customerRouteGuard] },
      { path: 'case-dashboard', component: CaseDashboardComponent, canActivate: [adminRouteGuard] },
      { path: 'organizations/integrations', component: IntegrationsComponent, canActivate: [customerRouteGuard], data: { title: 'Integrations - ${org.name}', requiresOrgName: true } },
      { path: 'alert-preferences', component: AlertPreferencesPage, canActivate: [customerRouteGuard] },
      { path: 'soc-tuning', component: SocTuningPage, canActivate: [customerRouteGuard] },
      { path: 'customer-soc-comms', component: CustomerSocCommsPage, canActivate: [customerRouteGuard] },
    ],
  },
  { path: '**', redirectTo: 'customer/unauthorized' }, // Update the wildcard route to redirect to the unauthorized page under customer
];
