<div class="container">
  <div class="row">
    <div class="col-6 p-3">
      <app-case-details></app-case-details>
    </div>
    <div class="col p-3">
      <app-threat-hunting></app-threat-hunting>
    </div>
  </div>
  <div class="row">
    <div class="col p-3">
      <app-entity-enrichment></app-entity-enrichment>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-3">
      <app-ticket-correlation></app-ticket-correlation>
    </div>
    <div class="col p-3">
      <app-threshold-checks></app-threshold-checks>
    </div>
    <div class="col p-3">
      <app-response-actions></app-response-actions>
    </div>
  </div>
</div>
