import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-threat-hunting',
  standalone: true,
  imports: [CommonModule, LayoutModule, FormsModule, IconsModule, ButtonsModule, InputsModule, LabelModule, DropDownsModule],
  templateUrl: './threat-hunting.component.html',
  styleUrl: './threat-hunting.component.scss',
})
export class ThreatHuntingComponent {
  public threatHunting = [
    {
      activity: 'stellar_searches',
      results: [
        {
          query: 'Search for alerts by SourceIP in the last 24hrs -> the step name from PB action',
          success: 'true',
          result: '12  -> the count of result JSON from PB action',
        },
        {
          query: 'Search for Windows events by SourceIP with failed login attempt in the last 24hrs  -> the step name from PB action',
          success: 'true',
          result: '120  -> the count of result JSON from PB action',
        },
        {
          query: 'Search for traffic events by SourceIP to the destination IP in the last 24hrs  -> the step name from PB action',
          success: 'true',
          result: '42   -> the count of result JSON from PB action',
        },
      ],
    },
    {
      activity: 'sentinelOne_searches',
      results: [
        {
          query: 'Search for alerts by SourceIP in the last 24hrs',
          success: 'true',
          result: '12',
        },
        {
          query: 'Search for Windows events by SourceIP with failed login attempt in the last 24hrs',
          success: 'true',
          result: '120',
        },
        {
          query: 'Search for traffic events by SourceIP to the destination IP in the last 24hrs',
          success: 'true',
          result: '42',
        },
      ],
    },
    // {
    //   activity: 'vicarius',
    //   results: [
    //     {
    //       Entity: '192.168.1.1',
    //       entity_type: 'ip',
    //       vrx_host_info: {
    //         hostname: 'TEST_machine',
    //         active_CVEs: '12',
    //         risk_score: '45',
    //         OS: 'Windows 10 Pro',
    //         patches_available: '5',
    //       },
    //     },
    //   ],
    // },
  ];
}
