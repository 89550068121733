import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DetectionData } from '../../../../core/models/entities';
import { environment } from '../../../../../environments/environment';
import { ODataService } from '../../../../core/services/odata.service';

@Injectable({
  providedIn: 'root',
})
export class DetectionsService extends ODataService<DetectionData> {
  constructor() {
    const url = `${environment.apiUrl}/v1/detection/administrative/detectiondashboard/`;
    super(inject(HttpClient), url);
    this.state = {
      skip: 0,
      take: 25,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }
}

export interface DetectionDataResponse extends DetectionData {}
