import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from '@progress/kendo-angular-icons';
import { BaseComponent } from '../../../core/shared/common/base.component';

@Component({
  standalone: true,
  imports: [
    IconModule,
    RouterModule
  ],
  templateUrl: './unauthorized.page.html',
  styleUrl: './unauthorized.page.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedPage extends BaseComponent {

  constructor() {
    super();
  }

}
