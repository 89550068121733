import { CommonModule } from '@angular/common';
import { Component, effect, inject, Input, signal, ViewChild } from '@angular/core';
import { GridModule, ExcelModule, PDFModule, ContextService, GridComponent, GridDataResult, PDFService } from '@progress/kendo-angular-grid';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { BaseComponent } from '../../../../../../core/shared/common/base.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { SlaAdherenceService } from '../../../../../../core/services/slaadherence.service';

@Component({
  selector: 'app-sla-adherence',
  standalone: true,
  imports: [GridModule, CommonModule, LoaderModule, ExcelModule, PDFModule],
  templateUrl: './sla-adherence.component.html',
  styleUrl: './sla-adherence.component.scss',
  providers: [SlaAdherenceService, PDFService, ContextService],
})
export class SlaAdherenceComponent extends BaseComponent {
  public gridService = inject(SlaAdherenceService);
  public organizationId: number | null = null;
  private route = inject(ActivatedRoute);
  @ViewChild('grid', { static: false }) grid!: GridComponent;

  private sanitizer = inject(DomSanitizer);
  headerHtml!: SafeHtml;
  @Input() set htmlContent(value: string) {
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  public landscape = false;
  public fileName: string = '';

  public selectedDateRange = signal<string[]>([]);
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams.subscribe((params) => {
        this.organizationId = params['organizationId'];
      });
      this.applyFilter();
    });
  }

  public exportPDF(fileName: string) {
    this.fileName = `${fileName}.pdf`;
    this.grid.saveAsPDF();
  }

  public exportExcel(fileName: string) {
    this.fileName = `${fileName}.xlsx`;
    this.grid.saveAsExcel();
  }

  applyFilter() {
    const dateRange = this.selectedDateRange();
    if (!dateRange) return;

    const filters = [];
    const field = 'alert_received_on';

    filters.push({
      field: field,
      operator: 'gt',
      value: dateRange[0],
    });

    filters.push({
      field: field,
      operator: 'lt',
      value: dateRange[1],
    });

    // Update the grid filter state
    this.gridService.state.filter = {
      logic: 'and',
      filters: filters,
    };

    // Reset pagination when applying new filter
    this.gridService.state.skip = 0;

    this.gridService.getSlaAdherenceDetails(this.organizationId);
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };
}
