import { Component, CreateEffectOptions, effect, input, signal } from '@angular/core';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ArcGaugeModule } from '@progress/kendo-angular-gauges';

@Component({
  selector: 'app-tp-rate',
  standalone: true,
  imports: [LoaderModule, ArcGaugeModule],
  templateUrl: './tp-rate.component.html',
  styleUrl: './tp-rate.component.scss',
  providers: [AnalyticsService],
})
export class TpRateComponent extends BaseComponent {
  truePositiveData = input<WidgetData>({ value: 0, colors: [] });
  public isLoading = signal(true);
  public tprData = signal<WidgetData>({ value: 0, colors: [] });
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const tpr = this.truePositiveData();
        if (tpr) {
          this.tprData.set(tpr);
          this.isLoading.set(false);
        } else {
          this.tprData.set({ value: 0, colors: [] });
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }
}
