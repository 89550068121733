import { Component, CreateEffectOptions, Input, Signal, effect, inject, signal } from '@angular/core';
import { ChartsModule, SeriesLabels } from '@progress/kendo-angular-charts';
import { TopFiveData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-top-detections',
  standalone: true,
  imports: [ChartsModule, LoaderModule],
  templateUrl: './top-detections.component.html',
  styleUrl: './top-detections.component.scss',
})
export class TopDetectionsComponent extends BaseComponent {  
  public popupService = inject(ChartLabelsPopupService);
  @Input() topDetectionsData!: Signal<TopFiveData[]>;

  public isLoading = signal(true);
  public ticketsByEventName = signal<TopFiveData[]>([]);

  public labels = {
    rotation: -25,
  };

  public seriesLabels: SeriesLabels = {
    visible: true,
    padding: 1,
    font: 'bold 10px Arial, sans-serif',
  };
  public colors: string[] = ['#6F42C1', '#7A54CB', '#8466D5', '#8F79DF', '#9A8BE9', '#A59DF3', '#B0AFFD', '#BBBFFF', '#C5D2FF', '#D0E4FF'];

  public getColor = (point: any): string => {
    return this.colors[point.index];
  };  

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const userDetailData = this.topDetectionsData();
        if (userDetailData) {
          this.ticketsByEventName.set(userDetailData.slice(0, 10) || []);
          this.isLoading.set(false);
        } else {
          this.ticketsByEventName.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }
}
