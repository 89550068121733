<div class="tile">
  <div class="tile-title">
    <h5>Ticket Correlation</h5>
  </div>
  <kendo-tilelayout>
    @for (dataItem of ticketCorrelationData; track $index) {
      <kendo-tilelayout-item>
        <div>
          <strong>Search Query:</strong> {{ dataItem.Search_query }}<br />
          <strong>Results:</strong> {{ dataItem.results }}
        </div>
      </kendo-tilelayout-item>
    }
  </kendo-tilelayout>
</div>
