import { Component, signal, CreateEffectOptions, effect, inject, input } from '@angular/core';
import { ChartsModule, SeriesLabelsContentArgs, SeriesVisualArgs } from '@progress/kendo-angular-charts';
import { geometry, Element, Layout, Text } from '@progress/kendo-drawing';
import { AnalyticsService, ChartData } from '../../../../../../../core/services/analytics.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import 'hammerjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../../../../environments/environment';
import { MenusModule } from '@progress/kendo-angular-menu';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EdrThreatStatusDetailsService, ThreatFilterService } from './services/edrthreatstatusdetails.service';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { tap } from 'rxjs/operators';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

interface FilterItem {
  text: string;
  value: string | number;
}

@Component({
  selector: 'app-threat-status',
  standalone: true,
  imports: [ChartsModule, LoaderModule, GridModule, ExcelModule, MenusModule, CommonModule, DialogsModule, DropDownListModule],
  templateUrl: './threat-status.component.html',
  styleUrl: './threat-status.component.scss',
  providers: [AnalyticsService, EdrThreatStatusDetailsService, ThreatFilterService],
})
export class DetectedThreatStatusComponent extends BaseComponent {
  public gridService = inject(EdrThreatStatusDetailsService);

  public threatStatus = input<ChartData[]>([]);

  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal<boolean>(true);
  public autofit = true;
  private center!: geometry.Point;
  private radius!: number;
  public items: any[] = [{ text: 'View Data' }];
  public showPopup = false;

  public threatFilterService = inject(ThreatFilterService);
  private threatStatusesSubject = new BehaviorSubject<FilterItem[]>([]);
  public threatStatuses$: Observable<FilterItem[]> = this.threatStatusesSubject.asObservable();

  // Necessary to bind `this` to the AppComponent instance
  public visualHandler = this.visual.bind(this);

  constructor(private intl: IntlService) {
    super();
    this.initEffect();
    this.labelContent = this.labelContent.bind(this);
  }

  private initEffect() {
    effect(
      () => {
        const data = this.threatStatus();
        if (data) {
          this.isLoading.set(false);
        } else {
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  private loadFilters(): void {
    this.threatFilterService
      .getThreatStatuses(this.organizationId()?.toString() ?? '')
      .pipe(
        tap((threatStatuses: FilterItem[]) => {
          this.threatStatusesSubject.next(threatStatuses);
        }),
      )
      .subscribe();
  }

  public visual(e: SeriesVisualArgs): Element {
    // Obtain parameters for the segments
    if (!e.center || e.innerRadius === undefined) {
      throw new Error('Required chart parameters are missing');
    }
    this.center = e.center;
    this.radius = e.innerRadius;

    // Create default visual
    return e.createVisual();
  }

    private getTotalValue(): number {
    return this.threatStatus().reduce((sum, item) => sum + item.count, 0);
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/edrthreatstatusdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
      this.loadFilters();
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }

  public labelContent(e: SeriesLabelsContentArgs): string {
    const percentage = e.value / this.getTotalValue();
    return `${e.category}: ${this.intl.formatNumber(percentage, 'p0')}`;
  }

  public statusChange($event: { text: string; value: string | null }, field: string): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    const newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== field);

    if ($event.value !== null) {
      newFilters.push({
        field: field,
        operator: 'eq',
        value: $event.value,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

}
