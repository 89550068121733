import { Injectable, signal, type Signal, type WritableSignal, effect, inject } from '@angular/core';
import { Organization } from '../models/entities';
import { User } from '../models/user';

class OrgChangeEvent extends Organization {
  constructor(org: Partial<Organization>) {
    super();
    Object.assign(this, org);
  }
}

class OrgDropdownChangeEvent extends OrgChangeEvent {
  static readonly type = 'orgDropdownChange' as const;
}

class OrgSelectionChangeEvent extends OrgChangeEvent {
  static readonly type = 'orgSelectionChange' as const;
}

class WhiteLabelChangeEvent extends OrgChangeEvent {
  static readonly type = 'whiteLabelChange' as const;
}

export class Events {
  static readonly OrgDropdownChange = OrgDropdownChangeEvent;
  static readonly OrgSelectionChange = OrgSelectionChangeEvent;
  static readonly WhiteLabelChange = WhiteLabelChangeEvent;
}

type EventType = 
  | typeof Events.OrgDropdownChange.type
  | typeof Events.OrgSelectionChange.type
  | typeof Events.WhiteLabelChange.type;

type EventPayload<T extends EventType> = 
  T extends typeof Events.OrgDropdownChange.type ? InstanceType<typeof Events.OrgDropdownChange> :
  T extends typeof Events.OrgSelectionChange.type ? InstanceType<typeof Events.OrgSelectionChange> :
  T extends typeof Events.WhiteLabelChange.type ? InstanceType<typeof Events.WhiteLabelChange> :
  never;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private readonly events = new Map<EventType, WritableSignal<any>>();
  private readonly user = inject(User);

  constructor() {
    console.log('EventService constructor');
    // Initialize signals with their unique types
    this.events.set(Events.OrgDropdownChange.type, signal(null));    
    this.events.set(Events.OrgSelectionChange.type, signal(null));
    this.events.set(Events.WhiteLabelChange.type, signal(null));
    console.log('Signals initialized:', Array.from(this.events.keys()));

    effect(() => {
      const org = this.getEvent(Events.OrgDropdownChange.type)();
      console.log('EventService effect - org changed:', org);
      if (org?.id) {
        this.user.selected_organization = org;
        this.user.updateUser({ selected_organization: org });                
      }
    });
  }

  getEvent<T extends EventType>(type: T): Signal<EventPayload<T>> {
    if (!this.events.has(type)) {
      console.warn(`Event type ${type} not initialized`);
      this.events.set(type, signal(null));
    }
    return this.events.get(type)!;
  }

  emit<T extends EventType>(type: T, payload: EventPayload<T>): void {
    console.log(`EventService.emit called with type ${type}`, payload);
    if (!this.events.has(type)) {
      console.warn(`Event type ${type} not initialized`);
      this.events.set(type, signal(payload));
    } else {
      const signalRef = this.events.get(type)! as WritableSignal<EventPayload<T>>;
      console.log('Before emit - current value:', signalRef());
      
      // Create a new instance of the payload based on the event type
      let newPayload: EventPayload<T>;
      if (type === Events.OrgDropdownChange.type) {
        newPayload = new Events.OrgDropdownChange({ ...(payload as any) }) as EventPayload<T>;
      } else if (type === Events.OrgSelectionChange.type) {
        newPayload = new Events.OrgSelectionChange({ ...(payload as any) }) as EventPayload<T>;
      } else if (type === Events.WhiteLabelChange.type) {
        newPayload = new Events.WhiteLabelChange({ ...(payload as any) }) as EventPayload<T>;
      } else {
        newPayload = { ...payload } as EventPayload<T>;
      }
      
      signalRef.set(newPayload);
      console.log('After emit - new value:', signalRef());
    }
  }
}
