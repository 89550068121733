<form [formGroup]="form" (ngSubmit)="submitForm()">
    <div formArrayName="sub_organizations">
        <kendo-grid
            [filterable]="true"
            [data]="gridService | async"
            [pageable]="{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: [25, 50, 100, 200]
            }"
            [pageSize]="gridService.state.take"
            [skip]="gridService.state.skip"
            [(filter)]="gridService.state.filter"
            [loading]="gridService.loading"
            (dataStateChange)="gridService.onStateChange($event)"
            [sort]="this.gridService.state.sort"
            [sortable]="true"
        >
        <ng-template kendoGridLoadingTemplate>
            <div class="loader-container">
            <kendo-loader
                type="infinite-spinner"
                size="large"
            ></kendo-loader>
            </div>
        </ng-template>
        <ng-template kendoGridToolbarTemplate>
            <button
            kendoButton
            themeColor="primary"
            >
            Save
            </button>
        </ng-template>
        <kendo-grid-column
            field="id"
            title="Id"
            [width]="140"
            [filterable]="false"
            [hidden]="true"
        ></kendo-grid-column>
        <kendo-grid-column
            field="name"
            title="Organization"
            [width]="120"
        >
        </kendo-grid-column>
        <kendo-grid-column
            title="Connectwise Company Name"
            field="name"
            [width]="130"
            [filterable]="false"
        >
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
            @if(filterByControlName('organization_name', dataItem['name'])[0]) {
                <div [formGroup]="filterByControlName('organization_name', dataItem['name'])[0]">
                    <kendo-textbox formControlName="CustomerName"></kendo-textbox>
                </div>
            }
        </ng-template>
        
        </kendo-grid-column>
        <kendo-grid-column
            title="Agreement"
            field="name"
            [width]="130"
            [filterable]="false"
        >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            @if(filterByControlName('organization_name', dataItem['name'])[0]) {
                <div [formGroup]="filterByControlName('organization_name', dataItem['name'])[0]">
                    <kendo-dropdownlist
                    [defaultItem]="{ display_text: 'Select One', id: 0 }"
                    textField="display_text"
                    valueField="id"
                    formControlName="AgreementId"
                    [valuePrimitive]="true"
                    [data]="agreements()"
                ></kendo-dropdownlist>
                </div>
            }
            <div [formGroupName]="rowIndex">
                
            </div>
        </ng-template>
        
        </kendo-grid-column>
        </kendo-grid>
    </div>
</form>

@if (saveConfirmDialog === true) {
    <kendo-dialog title="Successful" (close)="closeDialog('cancel')" [minWidth]="250" [width]="450">
      <p style="margin: 30px; text-align: center">Data Saved Successfully.</p>
      <kendo-dialog-actions>
        <button kendoButton themeColor="primary" type="button" (click)="closeDialog('no')">
          Ok
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
    }
