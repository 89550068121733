import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  private http = inject(HttpClient);

  public testServiceNowCredentials(request: any) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationdetails/test_connection/`;
    return this.http.post(url, request);
  }

  public addServiceNowCredentials(request: any) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationdetails/`;
    return this.http.post(url, request);
  }

  public updateServiceNowCredentials(request: any) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationdetails(${request.organization_id})/`;
    return this.http.put(url, request);
  }

  public getActiveIntegration(organizationId: number) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationdetails(${organizationId})/`;
    return this.http.get(url);
  }

  public getActiveIntegrationFieldValues(organizationId: number) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationfielretrievedvaluesmap(${organizationId})/`;
    return this.http.get(url);
  }

  public updateFieldMappings(request: any) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationfieldvaluesmap(${request.pk})/`;
    return this.http.put(url, request);
  }

  public addFieldMappings(request: any) {
    const url = `${environment.apiUrl}/v1/ticketintegration/administrative/integrationfieldvaluesmap/`;
    return this.http.post(url, request);
  }
  
  public updateConnectWiseCkb(request: any) {
    const url = `${environment.apiUrl}/v1/organization/administrative/ckb(${request.organizationId})/update_connect_wise_ckb/`;
    return this.http.put(url, request);
  }
}