<div class="period-picker">
    <div class="drop-down">
        <kendo-label [text]="label()"></kendo-label>
        <kendo-dropdownlist
        style="width: 180px; margin-left: 15px"
        [data]="dateRanges"
        textField="text"
        valueField="value"
        [value]="selectedPeriod()"
        (valueChange)="onDateRangeChange($event)"
        ></kendo-dropdownlist>
    </div>
    
    @if(selectedPeriod().value == 'custom') {
      <div class="vr"></div>
      <kendo-daterange>
        <kendo-label text="Start">
          <kendo-dateinput
            kendoDateRangeStartInput
            [(value)]="range.start"
          ></kendo-dateinput>
        </kendo-label>
        <kendo-label text="End">
          <kendo-dateinput
            kendoDateRangeEndInput
            [(value)]="range.end"
          ></kendo-dateinput>
        </kendo-label>
        <kendo-daterange-popup
          (close)="setDateRange()"
        >
        </kendo-daterange-popup>
      </kendo-daterange>
    }
  </div>