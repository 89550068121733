<app-admin-appbar></app-admin-appbar><br />

<kendo-grid
  (cellClick)="editDetection($event)"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  #grid
>
  <ng-template kendoGridToolbarTemplate>
    <!-- commenting out until after MVP and we can spend the time wiring up search for this grid -->
    <!-- <kendo-textbox placeholder="Search Detections" [style.width.px]="180"></kendo-textbox> -->
    <button
      kendoButton
      (click)="addDetection(bodyElement)"
      [svgIcon]="plusIcon"
      themeColor="primary"
    >
      Add Detection
    </button>
  </ng-template>
  <kendo-grid-column
    field="title"
    title="Title"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="description"
    title="Description"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [width]="100"
    field="status"
    title="Status"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [width]="100"
    field="default_state"
    title="Default State"
  >
  </kendo-grid-column>
</kendo-grid>
