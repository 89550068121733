import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { OneApiAuthInterceptor } from './core/interceptors/interceptor';
import { NotificationService } from '@progress/kendo-angular-notification';
import { IconsService } from '@progress/kendo-angular-icons';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule]),
    provideHttpClient(withInterceptors([OneApiAuthInterceptor])),
    NotificationService,
    IconsService
  ],
};
