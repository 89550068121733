@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (sourceData() !== null) {
  <div class="map-container">
    <kendo-map
      [center]="[40, 0]"
      [zoom]="1"
      [zoomable]="false"
    >
      <kendo-map-layers>
        <kendo-map-tile-layer
          [subdomains]="tileSubdomains"
          [urlTemplate]="tileUrl"
        >
        </kendo-map-tile-layer>
        <kendo-map-bubble-layer
          [data]="sourceData()"
          locationField="location"
          valueField="count"
          [style]="bubbleStyle"
        >
          <kendo-map-bubble-layer-tooltip>
            <ng-template
              kendoMapBubbleTooltipTemplate
              let-dataItem="dataItem"
              let-value="value"
            >
              {{ dataItem.region }}: {{ value }}
            </ng-template>
          </kendo-map-bubble-layer-tooltip>
        </kendo-map-bubble-layer>
      </kendo-map-layers>
    </kendo-map>
  </div>
} @else {
  <h1>No Data</h1>
}
