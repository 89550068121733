<div class="content">
  <div>
    <div class="popout-box">
      <h2 class="k-form-title">
        VIP Support
        <kendo-chip
          size="small"
          fillMode="solid"
          themeColor="info"
          >BETA</kendo-chip
        >
      </h2>
      <button
        #header_slideout_xbutton
        class="head-slideout-xbutton"
        kendoButton
        [svgIcon]="xIcon"
        (click)="cancel()"
        fillMode="flat"
      ></button>
    </div>
    <div>
      <div>
        <div style="position: relative; padding: 10px">
          @if (showBackButton) {
            <button
              kendoButton
              [svgIcon]="arrowLeftIcon"
              (click)="showLobbyButtons()"
              style="position: absolute; left: 0; top: 50%; transform: translateY(-50%)"
            ></button>
          }
          <h5 style="text-align: center; margin: 0">Select a Support Option</h5>
        </div>
      </div>

      @if (!showBackButton) {
        <div style="text-align: center; display: flex; flex-direction: column; gap: 10px">
          <div>
            <div class="row p-2">
              <button
                class="office-button"
                kendoButton
                (click)="navigateToSection('soc')"
              >
                <h6>Security Operations Center</h6>
                <p>24 hours a day, 7 days a week</p>
              </button>
            </div>
            <div class="row p-2">
              <button
                class="office-button"
                kendoButton
                (click)="navigateToSection('csm')"
              >
                <h6>Customer Success</h6>
                <p>Available Weekdays 9AM-5PM (EST)</p>
              </button>
            </div>
            <div class="row p-2">
              <button
                class="office-button"
                kendoButton
                (click)="navigateToSection('one')"
              >
                <h6>ONE Product</h6>
                <p>Available Weekdays 9AM-5PM (EST)</p>
              </button>
            </div>
            <div class="row p-2">
              <button
                class="office-button"
                kendoButton
                (click)="navigateToSection('techops')"
              >
                <h6>Technical Operations</h6>
                <p>Available Weekdays 9AM-5PM (EST)</p>
              </button>
            </div>
          </div>
        </div>
      }
      @if (showBackButton) {
        <div style="margin-top: 20px">
          <div
            id="roam-lobby"
            style="min-width: 320px"
          ></div>
        </div>
      }
    </div>
  </div>
</div>
