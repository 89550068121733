import { Component, ViewChild, computed, effect, inject, output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule, SVGIcon } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { Organization } from '../../../../../core/models/entities';
import { OrganizationForm, SubdomainForm } from '../../../../../core/models/forms';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { Subscription, takeUntil } from 'rxjs';
import { OrganizationService } from '../../services/organization.service';
import { xIcon } from '@progress/kendo-svg-icons';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-organization',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputsModule, ButtonsModule, LabelModule, IconsModule, DropDownsModule],
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  providers: [OrganizationService],
})
export class OrganizationComponent extends BaseComponent {
  @ViewChild('parent', { static: false }) orgDropDown: DropDownListComponent | undefined;
  private orgService = inject(OrganizationService);
  public xIcon: SVGIcon = xIcon;
  close = output<void>();
  public orgs = signal<{ id: number; name: string }[]>([]);
  public accountTypes = signal<{ id: number; name: string }[]>([]);
  public orgForm = signal(this.getOrganizationForm());
  public id = computed(() => this.orgForm().get('id')?.value ?? 0);
  public opened = false;

  private scrollSubscription: Subscription | null = null;
  private page = 0;
  private totalRecords = 0;
  private pageSize = 10;

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadAccountTypes();
  }

  private loadAccountTypes() {
    this.orgService.getAccountTypes().subscribe({
      next: (response) => {
        this.accountTypes.set(response.value);
      },
      error: (error) => {
        console.error('Error loading account types:', error);
      },
    });
  }

  onOrgDropdownOpened($event: any) {
    if (this.orgDropDown?.optionsList?.popupListScroll && !this.scrollSubscription) {
      this.orgDropDown.optionsList.popupListScroll.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(this.onScroll.bind(this));
    }
  }

  onScroll(event: any) {
    const listContainer = event.target as HTMLElement;
    const scrollTop = listContainer.scrollTop;
    const scrollHeight = listContainer.scrollHeight;
    const offsetHeight = listContainer.offsetHeight;

    if (scrollTop + offsetHeight >= scrollHeight && this.orgs().length < this.totalRecords) {
      this.page++;

      this.orgService.state = {
        skip: this.page * this.pageSize,
        take: this.pageSize,
        filter: {
          logic: 'and',
          filters: [],
        },
      };

      this.orgService.read((result) => {
        const newOrgs = result.data.map((org) => ({
          id: org.id,
          name: org.name,
        }));
        this.orgs.update((existingOrgs) => [...existingOrgs, ...newOrgs]);
        this.totalRecords = result.total;
      });
    }
  }

  onOrgDropdownFilterChange(value: string) {
    if (value.length >= 3) {
      this.orgService.state = {
        skip: 0,
        take: this.pageSize,
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'name',
              operator: 'contains',
              value: value,
            },
          ],
        },
      };
      this.page = 0;
      this.loadOrgs();
    } else if (value.length === 0) {
      this.orgService.state = {
        skip: 0,
        take: this.pageSize,
        filter: {
          logic: 'and',
          filters: [],
        },
      };
      this.page = 0;
      this.loadOrgs();
    }
  }

  private loadOrgs(callback?: () => void) {
    this.orgService.read((result) => {
      this.totalRecords = result.total;
      this.orgs.set(
        result.data.map((org) => ({
          id: org.id,
          name: org.name,
          account_type: org.account_type,
        })),
      );
      if (callback) {
        callback();
      }
    });
  }

  onOrgDropdownChange($event: any) {
    const form = this.orgForm();
    form.get('parent')?.setValue($event);
    this.orgForm.set(form);
  }

  onOrgDropdownOpen($event: any) {
    this.loadOrgs();
  }

  getOrganizationForm(organization = new Organization()): FormGroup<OrganizationForm> {
    return new FormGroup({
      subdomains: new FormArray<FormGroup<SubdomainForm>>(organization.subdomains?.map((subdomain) => new FormGroup({ subdomain: new FormControl(subdomain.subdomain!) })) ?? []),
      tags: new FormArray<FormControl<number | null>>(organization.tags?.map((tag) => new FormControl(tag)) ?? []),
      name: new FormControl(organization.name, [Validators.required]),
      status: new FormControl(organization.status, [Validators.required]),
      account_type: new FormControl(organization.account_type, [Validators.required]),
      owner: new FormControl(organization.owner, [Validators.required]),
      owner_email: new FormControl(organization.owner_email, [Validators.required, Validators.email]),
      do_not_call_client: new FormControl(organization.do_not_call_client, [Validators.required]),
      num_of_employee: new FormControl(organization.num_of_employee, [Validators.required, Validators.min(0)]),
      website: new FormControl(organization.website, [Validators.pattern('https?://.+')]),
      billing_street: new FormControl(organization.billing_street, [Validators.required]),
      billing_city: new FormControl(organization.billing_city, [Validators.required]),
      billing_country: new FormControl(organization.billing_country, [Validators.required]),
      billing_state: new FormControl(organization.billing_state, [Validators.required]),
      use_same_billing_address_for_shipping: new FormControl(organization.use_same_billing_address_for_shipping, [Validators.required]),
      shipping_street: new FormControl(organization.shipping_street, [Validators.required]),
      shipping_city: new FormControl(organization.shipping_city, [Validators.required]),
      shipping_country: new FormControl(organization.shipping_country, [Validators.required]),
      shipping_state: new FormControl(organization.shipping_state, [Validators.required]),
      account_manager_name: new FormControl(organization.account_manager_name, [Validators.required]),
      account_manager_phone: new FormControl(organization.account_manager_phone, [Validators.required, Validators.pattern('^\\+?[1-9]\\d{1,14}$')]),
      account_manager_email: new FormControl(organization.account_manager_email, [Validators.required, Validators.email]),
      assigned_csm: new FormControl(organization.assigned_csm, [Validators.required]),
      assigned_csm_phone: new FormControl(organization.assigned_csm_phone, [Validators.required, Validators.pattern('^\\+?[1-9]\\d{1,14}$')]),
      assigned_csm_email: new FormControl(organization.assigned_csm_email, [Validators.required, Validators.email]),
      soc_phone: new FormControl(organization.soc_phone, [Validators.required, Validators.pattern('^\\+?[1-9]\\d{1,14}$')]),
      soc_escalation_email: new FormControl(organization.soc_escalation_email, [Validators.required, Validators.email]),
      soc_ir_email: new FormControl(organization.soc_ir_email, [Validators.required, Validators.email]),
      soc_subdomain: new FormControl(organization.soc_subdomain, [Validators.required]),
      id: new FormControl({ value: organization.id, disabled: true }, [Validators.required]),
      created_at: new FormControl(organization.created_at && new Date(organization.created_at), [Validators.required]),
      parent: new FormControl(organization.parent?.name!, [Validators.required]),
      parentId: new FormControl(organization.parent?.id!, [Validators.required]),
    });
  }

  addOrg() {
    console.log(this.orgForm().value);
    this.orgService
      .addOrg(this.orgForm().value as Organization)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (org) => {
          this.orgForm.set(this.getOrganizationForm(org));
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public clear() {
    this.orgForm().reset();
  }

  onClose() {
    this.close.emit();
  }
}
