import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceAccount } from '../../../../../../../../core/models/entities';

export interface ServiceAccountsForm {
  pk: FormControl<string | null>;
  username: FormControl<string | null>;
  related_function: FormControl<string | null>;
  sensitivity: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class AccountsFormService {
  public createAccountsForm(account = new ServiceAccount()) {
    return new FormGroup<ServiceAccountsForm>({
      pk: new FormControl(account.pk, Validators.required),
      username: new FormControl(account.username, Validators.required),
      related_function: new FormControl(account.related_function, Validators.required),
      sensitivity: new FormControl(account.sensitivity, Validators.required),
    });
  }
}
