<kendo-card style="display: flex; flex-direction: column; width: 500px">
  <kendo-card-header>
    <h5>Case Details</h5>
  </kendo-card-header>
  <kendo-card-body>
    <dl>
      <dt>Case Name:</dt>
      <dd>Login outside the US</dd>
      <dt>Created Time:</dt>
      <dd>7/2/2024 12:05:12 UTC-4</dd>
      <dt>Assigned Time:</dt>
      <dd>7/2/2024 12:06:34 UTC-4</dd>
      <dt>Ticket Number:</dt>
      <dd>12345</dd>
      <dt>Case Source:</dt>
      <dd>Stellar Cyber</dd>
      <dt>Organization:</dt>
      <dd>CyFlare</dd>
    </dl>
  </kendo-card-body>
</kendo-card>
