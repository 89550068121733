import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { filter, Observable, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-mttd-widget',
  standalone: true,
  imports: [LoaderModule],
  templateUrl: './mttd-widget.component.html',
  styleUrl: './mttd-widget.component.scss',
  providers: [AnalyticsService],
})
export class MttdWidgetComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);
  public useDateRange = input<boolean>();
  public dateRange = input<any>(false);

  public mttdData = signal<WidgetData>({ value: 0, colors: [] });
  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      const dateRange = this.dateRange();
      this.route.queryParams
        .pipe(
          filter((params) => {
            return !!params['organizationId'];
          }),
          switchMap((params) => {
            return this.getMttdData(params, dateRange);
          }),
        )
        .subscribe({
          next: (data) => {
            this.mttdData.set(data);
            this.isLoading.set(false);
          },
          error: (error) => {
            console.error(error);
            this.isLoading.set(false);
          },
        });
    },
    { allowSignalWrites: true } as CreateEffectOptions);
  }

  private getMttdData(params: any, dateRange: any) {
    if(dateRange) {
      return this.analyticsService.getMttd(params['organizationId'], dateRange);
    }
    else if (!this.useDateRange()) {
      return this.analyticsService.getMttd(params['organizationId']);
    }

    return new Observable<WidgetData>();
  }
}
