import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { ChartsModule, SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { AdrManualData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-adr-manual',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, GridModule],
  templateUrl: './adr-manual.component.html',
  styleUrl: './adr-manual.component.scss',
})
export class AdrManualComponent extends BaseComponent {
  // public gridService = inject();
  public autofit = true;
  public adrManualData = input<AdrManualData[]>([]);
  public chartData = signal<AdrManualData[]>([]);
  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public isLoading = signal(true);
  public showPopup = false;

  constructor(private intl: IntlService) {
    super();
    this.labelContent = this.labelContent.bind(this);
    this.initEffect();
  }

  initEffect() {
    effect(
      () => {
        if (this.adrManualData()) {
          this.chartData.set(this.adrManualData() || []);
          this.isLoading.set(false);
        } else {
          this.chartData.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public labelContent(args: SeriesLabelsContentArgs): string {
    return `${args.dataItem.category}: ${this.intl.formatNumber(args.dataItem.value, 'p2')}`;
  }

  // onContextMenuSelect(event: any) {
  //   if (event?.text === 'View Data') {
  //     this.gridService.setDateRange(this.dateRange());
  //     this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/{UPDATE WITH API ENDPOINT}(${this.organizationId()})`;
  //     this.gridService.read();
  //     this.showPopup = true;
  //   } else {
  //     console.warn('Invalid event structure:', event);
  //   }
  // }

  // public allData = (): Observable<GridDataResult> => {
  //   return this.gridService.queryAll();
  // };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
