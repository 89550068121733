<kendo-grid
  [filterable]="true"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
  [filterable]="false"
  (add)="addHandler($event)"
  (save)="saveHandler($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (cancel)="cancelHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <kendo-grid-column
    field="username"
    title="Username"
    filter="text"
  ></kendo-grid-column>
  <kendo-grid-column
    field="related_function"
    title="Related Function"
    filter="text"
  ></kendo-grid-column>
  <kendo-grid-column
    field="sensitivity"
    title="Sensitivity/Function"
    filter="text"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <div [formGroup]="formGroup">
        <kendo-dropdownlist
          [data]="['Critical', 'High', 'Medium', 'Low']"
          [valuePrimitive]="true"
          formControlName="sensitivity"
        ></kendo-dropdownlist>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column
    title="Action"
    [width]="220"
  >
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        kendoGridEditCommand
        [primary]="true"
      >
        Edit
      </button>
      <button kendoGridRemoveCommand>Remove</button>
      <button kendoGridSaveCommand>
        {{ isNew ? 'Add' : 'Update' }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? 'Cancel' : 'Cancel' }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
