<div class="content">
  <div class="example-wrap">
    <form
      class="k-form k-form-md"
      [formGroup]="orgForm()"
    >
      <div class="k-form-buttons">
        <h2 class="k-form-title">Add New Organization</h2>
        <button
          kendoButton
          [svgIcon]="xIcon"
          (click)="onClose()"
          fillMode="flat"
        ></button>
      </div>
      <fieldset class="k-form-fieldset">
        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            text="Organization Name (Required)"
          ></kendo-label>
          <kendo-textbox formControlName="name"></kendo-textbox>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            text="Plan Type (Required)"
          ></kendo-label>
          <kendo-dropdownlist
            formControlName="account_type"
            [valuePrimitive]="true"
            [defaultItem]="{ text: 'Select Account Type', value: null }"
            [data]="[
              { text: 'Customer', value: 1 },
              { text: 'MSP', value: 2 },
              { text: 'MMSP', value: 3 },
              { text: 'Enterprise', value: 4 }
            ]"
            textField="text"
            valueField="value"
          ></kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="parent"
            text="Parent Organization"
          >
          </kendo-label>
          <kendo-dropdownlist
            [defaultItem]="{ name: 'Select Organization', id: null }"
            formControlName="parent"
            #parent
            (open)="onOrgDropdownOpen($event)"
            (opened)="onOrgDropdownOpened($event)"
            (valueChange)="onOrgDropdownChange($event)"
            (filterChange)="onOrgDropdownFilterChange($event)"
            [filterable]="true"
            [valuePrimitive]="false"
            [textField]="'name'"
            [valueField]="'id'"
            [data]="orgs()"
          >
          </kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            text="Website (Optional)"
          ></kendo-label>
          <kendo-textbox formControlName="website"></kendo-textbox>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            text="Status"
          ></kendo-label>
          <kendo-dropdownlist
            formControlName="status"
            [valuePrimitive]="true"
            [defaultItem]="{ text: 'Select Status', value: null }"
            textField="text"
            valueField="value"
            [data]="[
              { text: 'Active', value: 1 },
              { text: 'Disabled', value: 0 }
            ]"
          >
          </kendo-dropdownlist>
        </kendo-formfield>

        <div class="k-form-buttons">
          <button
            #admin_organizations_addneworganization_addorganization
            kendoButton
            themeColor="primary"
            (click)="addOrg()"
          >
            Add Organization
          </button>
          <button
            #admin_organizations_addneworganization_clear
            kendoButton
            (click)="clear()"
          >
            Clear
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</div>
