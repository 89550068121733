<kendo-grid
  [filterable]="true"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
  [filterable]="false"
  (add)="addHandler($event)"
  (save)="saveHandler($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (cancel)="cancelHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      #adminorcustomer_organizations_soarrules_vulnerabilityscanners_addnew
      kendoGridAddCommand
    >
      Add new
    </button>
  </ng-template>
  <kendo-grid-column
    field="ip_address"
    title="IP Address"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="notes"
    title="Notes"
    filter="text"
  ></kendo-grid-column>
  <kendo-grid-command-column
    title="Action"
    [width]="220"
  >
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        #adminorcustomer_organizations_soarrules_vulnerabilityscanners_edit
        kendoGridEditCommand
        [primary]="true"
      >
        Edit
      </button>
      <button
        #adminorcustomer_organizations_soarrules_vulnerabilityscanners_remove
        kendoGridRemoveCommand
      >
        Remove
      </button>
      <button
        [id]="isNew ? 'adminorcustomer_organizations_soarrules_vulnerabilityscanners_add' : 'adminorcustomer_organizations_soarrules_vulnerabilityscanners_update'"
        kendoGridSaveCommand
        [disabled]="false"
      >
        {{ isNew ? 'Add' : 'Update' }}
      </button>
      <button
        #adminorcustomer_organizations_soarrules_vulnerabilityscanners_cancel
        kendoGridCancelCommand
      >
        {{ isNew ? 'Cancel' : 'Cancel' }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
