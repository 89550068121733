import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { User } from '../models/user';

export const adminRouteGuard: CanActivateFn = (route, state) => {
  let router = inject(Router);
  let user = inject(User);

  if (!user.access_token) {
    router.navigate(['/auth/login'], { queryParams: { redirectUrl: state.url } });
    return false;
  }

  const adminGroups = ['admin', 'partner_admin'];
  if (user?.groups?.some((group) => adminGroups.includes(group))) {
    return true;
  }

  router.navigate(['customer/unauthorized']); // Navigate to a not authorized page or any other action
  return false;
};
