<div class="container-fluid p-0">
  <div
    class="row"
    style="height: calc(100vh - 78px)"
  >
    <!-- Report List Section -->
    <div class="col-4 report-list-container">
      <div #reportactions>
        <div class="row">
          <div class="report-container">
            <div>
              <h5>Reports</h5>
            </div>
            <div class="p-2">
              <kendo-dropdownbutton
                themeColor="primary"
                size="medium"
                #reportDownloadButton
                kendoButton
                class="transparent-button"
                [data]="exportOptions"
                (itemClick)="onItemClick($event)"
              >
                Download
              </kendo-dropdownbutton>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="p-2">
            <app-cyflare-period
              [(dateRange)]="selectedDateRange"
              label="Reporting Period:"
            ></app-cyflare-period>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <kendo-tilelayout-item-body class="flex-body p-0">
          @for (report of reports; track $index) {
          <div
            (click)="selectReport(report)"
            class="report"
          >
            <div class="container report-item-container">
              <div class="row">
                <h5>{{ report.title }}</h5>
              </div>
              <div class="row">
                <p>{{ report.description }}</p>
              </div>
              <div class="report-schedule-button">
                <!-- Commented out until scheduling is ready for release -->
                <!-- <kendo-dropdownbutton
                  (itemClick)="manageSchedule()"
                  class="manage-button"
                  size="small"
                  [data]="['Manage Schedule']"
                  style="position: absolute; top: 0; right: 0; width: 40px; height: 40px; background-color: transparent; border: none; color: #000; font-size: 1.5rem; cursor: pointer; display: flex; align-items: flex-start; justify-content: center; padding: 0"
                >
                  ...
                </kendo-dropdownbutton> -->
              </div>
            </div>
          </div>
          }
        </kendo-tilelayout-item-body>
      </div>
    </div>
    <div class="col-8 report-preview-container">
      <div style="padding: 0 20px 20px 20px; width: 900px">
        <div class="pdf-container">
          <kendo-pdf-export
            #pdf
            paperSize="A4"
            [landscape]="selectedReport?.landscape || false"
            margin="1cm"
            [scale]="0.6"
            forcePageBreak=".page-break"
            [imageResolution]="300"
          >
            <ng-template
              kendoPDFTemplate
              let-pageNum="pageNum"
              let-totalPages="totalPages"
            >
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </ng-template>
            <div
              class="title-page"
              #headingDiv
            >
              <hr />
              @if(selectedReport && selectedDateRange()) {
              <h1>{{ selectedReport.title }}</h1>
              <div>
                <h3>Period: {{ formatDate(selectedDateRange()[0], 'MMM dd, yyyy') }} - {{ formatDate(selectedDateRange()[1], 'MMM dd, yyyy') }}</h3>
                <h4>Generated: {{ formattedDate }}</h4>
              </div>
              <hr />
              }
            </div>
            <div class="page-break"></div>

            <!-- Future need to have a variable display here for organization logo. Need to ensure proper height and width container so any uploaded logo is resized correctly -->
            <!-- <div class="page-break"></div> -->
            <!-- Page break after title page -->
            <!-- Dynamically display the selected report component -->

            <ng-container #container></ng-container>
          </kendo-pdf-export>
        </div>
      </div>
    </div>
  </div>
</div>
