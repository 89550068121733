<div class="container p-1">
  <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-12">
        <app-hud
          [threats]="threats()"
          [threatReboot]="threatReboot()"
          [outdatedAgents]="outdatedAgents()"
          [coverage]="coverage()"
          (refresh)="refreshHudData()"
        ></app-hud>
      </div>
    </div>
  </div>

  <div class="row g-3">
    <div class="col-lg-12">
      <div>
        <app-cyflare-period
          [(dateRange)]="selectedDateRange"
          label="Filter EDR Analytics:"
          (dateRangeChange)="applyFilter($event)"
        ></app-cyflare-period>
      </div>
    </div>
  </div>
  <br />
  <div class="row g-3">
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Agent ConnectionStatus
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Distribution of observed EDR agents. Can be connected, disconnected, connecting, or disconnecting"
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #edr_agent_status_viewdata
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'edragentstatus' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        <app-agent-status
          [status]="status()"
          [organizationId]="organizationId"
          [dateRange]="selectedDateRange()"
        ></app-agent-status>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Detected Threat Status
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Status of reported malicious or potentially malicious files"
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #edr_threat_status_viewdata
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'edrthreatstatus' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        <app-threat-status
          [threatStatus]="threatStatus()"
          [organizationId]="organizationId"
          [dateRange]="selectedDateRange()"
        ></app-threat-status>
      </div>
    </div>
  </div>
  <!-- <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-12 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <div style="flex: 1; display: flex; justify-content: center">
                <h5>
                  Risky Assets
                  <span
                    kendoTooltip
                    tooltipClass="custom-tooltip"
                    title="Current status distribution of all EDR agents"
                  >
                    <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <app-risky-assets-heatmap> </app-risky-assets-heatmap>
        </div>
      </div>
    </div>
  </div> -->
</div>
