import { inject, Injectable } from '@angular/core';
import { IUser, Organization, WhiteLabel } from './entities';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class User implements IUser {
  private router = inject(Router);
  private _user: IUser | null;

  constructor() {
    this._user = this.loadUser();
  }

  get checksum(): string | null {
    const checkSum = localStorage.getItem('checksum');
    return checkSum;
  }

  set checksum(value: string) {
    localStorage.setItem('checksum', value);
  }

  get encryptedData(): string | null {
    const data = localStorage.getItem('userEnc');
    return data;
  }

  set encryptedData(value: string) {
    localStorage.setItem('userEnc', value);
  }

  get decryptedUserData(): any {
    const data = CryptoJS.enc.Base64.parse(this.encryptedData!);
    const json = JSON.parse(CryptoJS.enc.Utf8.stringify(data));
    return json;
  }

  get userData(): any {
    const data = JSON.parse(localStorage.getItem('user')!);
    return data;
  }

  get id(): number {
    this.verifyCheckSum();
    return this._user!.id;
  }

  set id(value: number) {
    if (this._user) {
      this._user.id = value;
      this.saveUser();
    }
  }

  get email(): string {
    this.verifyCheckSum();
    return this._user!.email!;
  }

  set email(value: string) {
    if (this._user) {
      this._user.email = value;
      this.saveUser();
    }
  }

  get last_login(): Date {
    this.verifyCheckSum();
    return new Date(this._user!.last_login!);
  }

  set last_login(value: Date) {
    if (this._user) {
      this._user.last_login = value;
      this.saveUser();
    }
  }

  get is_superuser(): boolean {
    this.verifyCheckSum();
    return this._user!.is_superuser!;
  }

  set is_superuser(value: boolean) {
    if (this._user) {
      this._user.is_superuser = value;
      this.saveUser();
    }
  }

  get username(): string {
    this.verifyCheckSum();
    return this._user!.username!;
  }

  set username(value: string) {
    if (this._user) {
      this._user.username = value;
      this.saveUser();
    }
  }

  get first_name(): string {
    this.verifyCheckSum();
    return this._user!.first_name!;
  }

  set first_name(value: string) {
    if (this._user) {
      this._user.first_name = value;
      this.saveUser();
    }
  }

  get last_name(): string {
    this.verifyCheckSum();
    return this._user!.last_name!;
  }

  set last_name(value: string) {
    if (this._user) {
      this._user.last_name = value;
      this.saveUser();
    }
  }

  get is_staff(): boolean {
    this.verifyCheckSum();
    return this._user!.is_staff!;
  }

  set is_staff(value: boolean) {
    if (this._user) {
      this._user.is_staff = value;
      this.saveUser();
    }
  }

  get is_active(): boolean {
    this.verifyCheckSum();
    return this._user!.is_active!;
  }

  set is_active(value: boolean) {
    if (this._user) {
      this._user.is_active = value;
      this.saveUser();
    }
  }

  get date_joined(): Date {
    this.verifyCheckSum();
    return new Date(this._user!.date_joined!);
  }

  set date_joined(value: Date) {
    if (this._user) {
      this._user.date_joined = value;
      this.saveUser();
    }
  }

  get access_token(): string {
    return this._user!.access_token!;
  }

  set access_token(value: string) {
    if (this._user) {
      this._user.access_token = value;
      this.saveUser();
    }
  }

  get expires_in(): number {
    this.verifyCheckSum();
    return this._user!.expires_in!;
  }

  set expires_in(value: number) {
    if (this._user) {
      this._user.expires_in = value;
      this.saveUser();
    }
  }

  get token_type(): string {
    this.verifyCheckSum();
    return this._user!.token_type!;
  }

  set token_type(value: string) {
    if (this._user) {
      this._user.token_type = value;
      this.saveUser();
    }
  }

  get refresh_token(): string {
    this.verifyCheckSum();
    return this._user!.refresh_token!;
  }

  set refresh_token(value: string) {
    if (this._user) {
      this._user.refresh_token = value;
      this.saveUser();
    }
  }

  get mfa_setup_required(): boolean {
    this.verifyCheckSum();
    return this._user!.mfa_setup_required!;
  }

  set mfa_setup_required(value: boolean) {
    if (this._user) {
      this._user.mfa_setup_required = value;
      this.saveUser();
    }
  }

  get session(): string | undefined {
    this.verifyCheckSum();
    return this._user!.session;
  }

  set session(value: string) {
    if (this._user) {
      this._user.session = value;
      this.saveUser();
    }
  }

  get organizations(): Organization[] {
    this.verifyCheckSum();
    return this._user!.organizations!;
  }

  set organizations(value: Organization[]) {
    if (this._user) {
      this._user.organizations = value;
      this.saveUser();
    }
  }

  get groups(): string[] {
    this.verifyCheckSum();
    return this._user!.groups!;
  }

  set groups(value: string[]) {
    if (this._user) {
      this._user.groups = value;
      this.saveUser();
    }
  }

  get organization_id() {
    this.verifyCheckSum();
    return this._user!.organization_id!;
  }

  set organization_id(value: number) {
    if (this._user) {
      this._user.organization_id = value;
      this.saveUser();
    }
  }

  get organization_account_type() {
    this.verifyCheckSum();
    return this._user!.organization_account_type!;
  }

  set organization_account_type(value: number) {
    if (this._user) {
      this._user.organization_account_type = value;
      this.saveUser();
    }
  }

  get selected_organization(): Organization | undefined {
    this.verifyCheckSum();
    return this._user?.selected_organization;
  }

  set selected_organization(value: Organization | undefined) {
    if (this._user) {
      this._user.selected_organization = value;
      this.saveUser();
    }
  }

  get primary_organization(): Organization | undefined {
    this.verifyCheckSum();
    return this._user?.primary_organization;
  }

  set primary_organization(value: Organization | undefined) {
    if (this._user) {
      this._user.primary_organization = value;
      this.saveUser();
    }
  }

  public updateUser(newUser: Partial<IUser>): void {
    if (this._user) {
      this._user = { ...this._user, ...newUser };
      
      // @longle - Ensure whitelabel updates go through proper setters
      if (newUser.primary_organization?.whitelabel) {
        if (!this._user.primary_organization) {
          this._user.primary_organization = new Organization();          
        }
        this._user.primary_organization.whitelabel = new WhiteLabel(newUser.primary_organization.whitelabel);
        if (newUser.primary_organization.parent_whitelabel) {
          this._user.primary_organization.parent_whitelabel = new WhiteLabel(newUser.primary_organization.parent_whitelabel);
        }
      }      
      this.saveUser();
    }
  }

  public clearUserData(): void {
    this._user = User.createUser();
    this.saveUser();
  }

  private verifyCheckSum() {
    if (this.checksum) {
      const data = CryptoJS.enc.Base64.parse(this.encryptedData!);
      const hash = CryptoJS.SHA256(data);
      const hexHash = hash.toString(CryptoJS.enc.Hex);

      let verifyChecksum = hexHash == this.checksum;
      if (!verifyChecksum)
        this.router.navigate(['customer/unauthorized']);
    }
  }

  private loadUser(): IUser | null {
    const userData = localStorage.getItem('user');
    if (!userData) {
      const user = User.createUser();
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    }
    const parsedUser = JSON.parse(userData);
    
    // @longle - Apply whitelabel settings if they exist
    if (parsedUser?.primary_organization?.whitelabel) {
      const whitelabel = new WhiteLabel(parsedUser.primary_organization.whitelabel);
      // @longle - The setters will handle updating the UI
      if (whitelabel.title) {
        document.title = whitelabel.title;
      }
      if (whitelabel.favicon_url) {
        const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
        if (favicon) {
          favicon.href = whitelabel.favicon_url;
        } else {
          const link = document.createElement('link');
          link.rel = 'icon';
          link.href = whitelabel.favicon_url;
          document.head.appendChild(link);
        }
      }
    }
    
    return parsedUser;
  }

  public static createUser(): IUser {
    return {
      id: 0,
      email: '',
      last_login: new Date(),
      is_superuser: false,
      username: '',
      first_name: '',
      last_name: '',
      is_staff: false,
      is_active: false,
      date_joined: new Date(),
      access_token: '',
      expires_in: 0,
      token_type: '',
      refresh_token: '',
      mfa_setup_required: false,
      session: '',
      organizations: [],
      groups: [],
      organization_id: 0,
      organization_account_type: 0,
      primary_organization: new Organization(),
      selected_organization: undefined
    };
  }

  private saveUser(): void {
    if (this._user) {
      localStorage.setItem('user', JSON.stringify(this._user));
    }
  }
}
