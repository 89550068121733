import { BadgeModule, LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { Orientation } from '@progress/kendo-angular-layout';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormsModule } from '@angular/forms';
import { menuIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MitreAttackData, MitreService } from './mitreattack.service';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { Component, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { IconsModule } from '@progress/kendo-angular-icons';
import { CyflarePeriodComponent } from '../../../core/components/cyflare-period/cyflare-period.component';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mitreattack',
  standalone: true,
  imports: [CommonModule, FormsModule, LayoutModule, ToolBarModule, FormsModule, IconsModule, InputsModule, LabelModule, ButtonsModule, BadgeModule, PanelBarModule, DropDownsModule, LoaderModule, CyflarePeriodComponent],
  templateUrl: './mitreattack.component.html',
  styleUrl: './mitreattack.component.scss',
  providers: [MitreService],
})
export class MitreAttackComponent extends BaseComponent {
  public menuIcon: SVGIcon = menuIcon;
  public searchIcon: SVGIcon = searchIcon;
  public orientation: Orientation = 'horizontal';
  private route = inject(ActivatedRoute);
  private mitreService = inject(MitreService);
  private organizationId: number = 0;
  public data = signal<MitreAttackData[]>([]);
  public isLoading = signal<boolean>(true);
  public selectedDateRange = signal<string[]>([]);

  public toggleOrientation(orientation: Orientation): void {
    this.orientation = orientation;
  }
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe({
          next: (params) => {
            this.organizationId = +params['organizationId'];
            this.applyFilter();
          },
          error: (error) => {
            console.error('Error processing query parameters:', error);
          },
        });
      },
      { allowSignalWrites: true },
    );
  }

  applyFilter() {
    if (this.selectedDateRange().length > 0) {
      this.isLoading.set(true);
      const params = {
        alert_received_on__range: this.selectedDateRange().join(','),
      };
      this.mitreService.getMitreattack(this.organizationId, params).subscribe((response) => {
        this.data.set(response);
        this.isLoading.set(false);
      });
    }
  }
}
