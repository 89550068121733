import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { VirtualDropdownComponent } from '../../../../../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { IntegrationService } from '../../../../../../../core/services/integration.service';
import { requiredIf, updateValidatorsOnDependencyChange } from '../../../../../../../core/validators/validators';
import { IntegrationUtil } from '../../services/integration-util.service';

@Component({
  selector: 'app-servicenow',
  standalone: true,
  imports: [ReactiveFormsModule, LabelModule, InputsModule, DateInputsModule, ButtonsModule, DropDownsModule, DialogsModule, CommonModule, VirtualDropdownComponent],
  templateUrl: './servicenow.component.html',
  styleUrl: './servicenow.component.scss',
  providers: [IntegrationService],
})
export class ServicenowComponent extends BaseComponent {
  public integrationUtil = inject(IntegrationUtil);

  public saved = output<void>();
  public integrationData = input<any>();
  public organizationId = input<any>();

  public integrationForm!: FormGroup;
  public testConnectionMessage: string | undefined;

  authValueChanged = false;
  public authenticationTypes = [
    { text: 'Bearer', value: 'bearer' },
    { text: 'Basic', value: 'basic' },
    { text: 'oauth2 Legacy App', value: 'oauth2_legacy_app' },
    { text: 'oauth2 Backend App', value: 'oauth2_backend_app' },
  ];

  constructor() {
    super();
    const organizationId = this.organizationId();
    this.integrationForm = new FormGroup({
      organization_id: new FormControl(organizationId, [Validators.required]),
      ticket_integration_name: new FormControl('ServiceNow', [Validators.required]),
      ticket_integration_user: new FormControl('', requiredIf(() => this.authType === 'basic' || this.authType === 'oauth2_legacy_app')),
      ticket_integration_secret: new FormControl('', requiredIf(() => this.authType !== 'oauth2_backend_app')),
      ticket_integration_auth_client_id: new FormControl('', requiredIf(() => this.authType === 'oauth2_legacy_app' || this.authType === 'oauth2_backend_app')),
      ticket_integration_auth_client_secret: new FormControl('', requiredIf(() => this.authType === 'oauth2_legacy_app' || this.authType === 'oauth2_backend_app')),
      ticket_integration_url: new FormControl('', requiredIf(() => this.authType === 'basic' || this.authType === 'bearer')),
      ticket_integration_auth_url: new FormControl('', requiredIf(() => this.authType === 'oauth2_legacy_app' || this.authType === 'oauth2_backend_app')),
      ticket_integration_auth_method: new FormControl(null, [Validators.required]),
      field_map_subject: new FormControl('subject'),
      field_map_description: new FormControl('description'),
      field_map_status_type: new FormControl('new'),
      field_map_status_type_as_obj: new FormControl(true),
      field_map_priority: new FormControl('low'),
      field_map_priority_as_obj: new FormControl(true),
      pk: new FormControl(),
      fieldMappings: new FormArray([])
    });
    updateValidatorsOnDependencyChange(this.integrationForm, ['ticket_integration_auth_method'], ['ticket_integration_secret', 'ticket_integration_user', 'ticket_integration_auth_client_id', 'ticket_integration_auth_client_secret']);
    
    this.integrationForm?.get('ticket_integration_auth_method')?.valueChanges.subscribe(() => {
      this.authValueChanged ? this.resetForm() : this.authValueChanged = true;
    });
    this.integrationUtil.integrationForm = this.integrationForm;
    this.initEffect();
  }

  get authType() {
    return this.integrationForm?.get('ticket_integration_auth_method')?.value?.toLowerCase();
  }

  initEffect() {
    effect(() => {
      this.integrationForm.patchValue({
        organization_id: this.organizationId()
      });
      this.initForm(this.integrationData());
    });
  }

  resetForm() {
    const controlsToReset = [
      'ticket_integration_user',
      'ticket_integration_secret', 
      'ticket_integration_auth_client_id', 
      'ticket_integration_auth_client_secret', 
      'ticket_integration_url', 
      'ticket_integration_auth_url'
    ];
    for (const controlName in this.integrationForm.controls) {
      if (this.integrationForm.controls.hasOwnProperty(controlName)) {
        if (controlsToReset.includes(controlName)) {
          this.integrationForm.controls[controlName].reset('');
          
        }
      }
    }
    this.authValueChanged = false
  }

  initForm(data?: any) {
    this.integrationUtil.addMappingFormControls('ticket', ['Subject','Description','Status','Priority']);
    this.integrationUtil.addMappingFormControls('priority', ['Severe','High','Medium','Low']);
    this.integrationUtil.addMappingFormControls('status_type', ['None','TP - No Action Needed','TP - Confirmed Incident','FP','Tuning Required','Not Relevant - Place Exclusion']);;
    
    if (data) {
      this.integrationForm.patchValue({
        ticket_integration_name: data?.ticket_integration_name,
        ticket_integration_user: data?.ticket_integration_user,
        ticket_integration_secret: data?.ticket_integration_secret || '',
        ticket_integration_url: data?.ticket_integration_url,
        ticket_integration_auth_method: data?.ticket_integration_auth_method,
        ticket_integration_auth_client_id: data?.ticket_integration_auth_client_id,
        ticket_integration_auth_url: data?.ticket_integration_auth_url,
        field_map_subject: data?.field_map_subject,
        field_map_description: data?.field_map_description,
        field_map_status_type: data?.field_map_status_type,
        field_map_status_type_as_obj: data?.field_map_status_type_as_obj,
        field_map_priority: data?.field_map_priority,
        pk: data?.pk
      });
      this.integrationUtil.patchIntegrationFormControls(data)
    }
  }

  testConnection() {
    this.integrationUtil.testConnection((result: any) => this.afterTestConnection(result));
  }

  saveConnection() {
    this.integrationUtil.saveConnection(() => this.afterSaveConnection());
  }

  afterSaveConnection() {
    this.authValueChanged = false;
    this.saved.emit();
  }

  afterTestConnection(result: string) {
    this.testConnectionMessage = result;
  }
}
