import { Component } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    GridModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent {
  public gridData = [
    { channel: "Organic Search", conversion: 8232, users: 70500 },
    { channel: "Direct", conversion: 6574, users: 24900 },
    { channel: "Referral", conversion: 4932, users: 20000 },
    { channel: "Social Media", conversion: 2928, users: 19500 },
    { channel: "Email", conversion: 2456, users: 18100 },
    { channel: "Other", conversion: 1172, users: 16540 },
  ];
}
