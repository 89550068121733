import { Component } from '@angular/core';
import { ListViewModule } from '@progress/kendo-angular-listview';

@Component({
  selector: 'app-threshold-checks',
  standalone: true,
  imports: [ListViewModule],
  templateUrl: './threshold-checks.component.html',
  styleUrl: './threshold-checks.component.scss',
})
export class ThresholdChecksComponent {
  public checks: string[] = ['Check for 50 or more failed login attempts', 'Check if source IP was malicious', 'Check if login location is common for the specific user'];
}
