<div class="content-container">
  <div class="description">
    <h5>What is my Mean Time to Disposition (MTTD)?</h5>
    <p>Mean Time to Disposition specifically measures the time from when an alert is triggered until the time CyFlare closes the case or escalates the case for customer action.</p>
  </div>
  <div style="height: 200px; width: 40%; margin-left: 20px; background-color: #f5f5f5; border-radius: 6px; display: flex; align-items: center; justify-content: center">
    <app-mttd-widget
      [useDateRange]="true"
      [dateRange]="soarParams()"
    ></app-mttd-widget>
  </div>
</div>
<div class="content-container">
  <div class="description">
    <h5>How many security detections happened?</h5>
    <p>A Security detection is any SOC processed alarm that originated as an event from source security tools.</p>
  </div>
  <div style="height: 200px; width: 40%; margin-left: 20px; background-color: #f5f5f5; border-radius: 6px; display: flex; align-items: center; justify-content: center">
    <app-detection-count [funnelDataGet]="funnelDataGet()"></app-detection-count>
  </div>
</div>
<div class="content-container">
  <div class="description">
    <h5>How many SLA violations happened?</h5>
    <p>SLA or Service Level Agreement includes any detections that were not escalated within the defined time period as defined in your agreement. *May include detections that are under development</p>
  </div>
  <div style="height: 200px; width: 40%; margin-left: 20px; background-color: #f5f5f5; border-radius: 6px; display: flex; align-items: center; justify-content: center">
    <app-sla-violations [slaData]="slaData()"></app-sla-violations>
  </div>
</div>
<div class="content-container">
  <div class="description">
    <h5>How many SOC tickets were created?</h5>
    <p>SOC tickets are all detections that were further escalated into tickets requiring action.</p>
  </div>
  <div style="height: 200px; width: 40%; margin-left: 20px; background-color: #f5f5f5; border-radius: 6px; display: flex; align-items: center; justify-content: center">
    <app-ticket-count [ticketsData]="ticketsData()"></app-ticket-count>
  </div>
</div>
<div class="content-container">
  <div class="description">
    <h5>What is my True Positive Rate?</h5>
    <p>True Positive Rate (TPR) tells you how effective a security system is at correctly identifying threats when they are present. *TPR Includes all tickets not resulting in a False Positive verdict.</p>
  </div>
  <div style="height: 200px; width: 40%; margin-left: 20px; background-color: #f5f5f5; border-radius: 6px; display: flex; align-items: center; justify-content: center">
    <app-tp-rate [truePositiveData]="truePositiveData()"></app-tp-rate>
  </div>
</div>
<!-- page 2 pagebreak -->
<div class="page-break"></div>
<div
  class="content-container"
  style="height: 350px"
>
  <div class="description">
    <h5>SOC Distillation</h5>
    <p>The SOC distillation funnel visualizes the volume from raw events all the way through SOC processing and escalation. This represents the volume/workload that the SOC has managed for you.</p>
  </div>
  <div
    class="chart"
    style="height: 350px"
  >
    <app-distillation-funnel [funnelDataGet]="funnelDataGet()"></app-distillation-funnel>
  </div>
</div>
<div
  class="content-container"
  style="height: 400px"
>
  <div class="description">
    <h5>Top Escalated Events</h5>
    <p>Top escalated events based on the Indicators of Compromise (IoC) type of all events within the report date range.</p>
  </div>
  <div
    class="chart"
    style="height: 400px"
  >
    <app-top-detections [topDetectionsData]="topDetectionsData"></app-top-detections>
  </div>
</div>
<div
  class="content-container"
  style="height: 350px"
>
  <div class="description">
    <h5>Login Attempts From Outside The US</h5>
    <p>Login attempts from outside the US shows any login attempt originating from outside of the US as determined by GeoLocation from the event meta-data.</p>
  </div>
  <div
    class="chart"
    style="height: 350px"
  >
    <app-source-map [data]="sourceMapData"></app-source-map>
  </div>
</div>
<!-- Page 3 pagebreak -->
<div class="page-break"></div>
<div class="content-container">
  <div class="description">
    <h5>Top 5 Suspect Users</h5>
    <p>Suspect users are determined by the aggregate number of tickets associated with a given user.</p>
  </div>
  <div
    class="chart"
    style="height: 400px"
  >
    <app-suspect-users
      [topSuspectUsersData]="topSuspectUsersData()"
      [topUserDetailData]="topUserDetailData()"
    ></app-suspect-users>
  </div>
</div>
<div class="content-container">
  <div class="description">
    <h5>Top 5 Suspect Assets</h5>
    <p>Suspect assets are determined by the aggregate number of tickets associated with a given asset.</p>
  </div>
  <div
    class="chart"
    style="height: 375px"
  >
    <app-suspect-assets
      [topSuspectAssetsData]="topSuspectAssetsData"
      [topAssetDetailData]="topAssetDetailData"
    ></app-suspect-assets>
  </div>
</div>
