<br />
<div style="background-color: #ffffff; padding: 10px; border-radius: 6px">
  <div style="display: flex; justify-content: space-between; margin-bottom: 1rem">
    <button
      kendoButton
      (click)="back()"
    >
      Go Back
    </button>
    <button
      kendoButton
      themeColor="error"
      (click)="delete($event)"
    >
      Delete
    </button>
  </div>
  <form
    [formGroup]="formGroup"
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <fieldset class="advisory-form">
      <div style="display: flex; flex-direction: column; gap: 1rem">
        <kendo-formfield>
          <kendo-label
            text="Advisory Title"
            labelCssClass="k-form-label"
            [for]="title"
          ></kendo-label>
          <kendo-textbox
            style="width: 400px; margin-right: 2rem"
            formControlName="title"
            #title
          ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="Advisory For:"
            labelCssClass="k-form-label"
            [for]="advisoryFor"
          ></kendo-label>
          <kendo-multiselect
            style="width: 400px"
            formControlName="advisory_for"
            [checkboxes]="true"
            [autoClose]="false"
            [data]="listItems"
            #advisoryFor
            (valueChange)="onValueChange($event)"
          ></kendo-multiselect>
        </kendo-formfield>
      </div>
    </fieldset>
    <div style="display: flex; align-items: flex-end; padding: 10px">
      <div style="display: flex; justify-content: space-between; column-gap: 20px">
        <button
          kendoButton
          themeColor="primary"
          (click)="saveDraft($event)"
        >
          Save Draft
        </button>
        <button
          kendoButton
          themeColor="primary"
          (click)="publish($event)"
        >
          Publish
        </button>
      </div>
    </div>
  </form>
</div>
<div [formGroup]="formGroup">
  <kendo-editor
    class="cyflare-editor"
    formControlName="content"
  ></kendo-editor>
</div>
