import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../core/services/odata.service';
import { environment } from '../../../../../environments/environment';
import { DetectionManager } from '../../../../core/models/entities';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DetectionManagerService extends ODataService<DetectionManagerResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/detection/administrative/detection/`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'title', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public addDetectionManagerItem(detection: any) {
    return this.http.post(this.url, detection);
  }

  public updateDetectionManagerItem(pk: string, detection: any) {
    const url = `${environment.apiUrl}/v1/detection/administrative/detection(${pk})/`;
    return this.http.put(url, detection);
  }

  public deleteDetectionManagerItem(pk: string) {
    const url = `${environment.apiUrl}/v1/detection/administrative/detection(${pk})/`;
    return this.http.delete(url);
  }

  getAvailableDetections(): Observable<DetectionManager[]> {
    return this.http.get<any>(this.url).pipe(
      map((response) =>
        response.value.map((detection: any) => {
          const detectionManager = new DetectionManager();
          detectionManager.pk = detection.pk;
          detectionManager.title = detection.title;
          detectionManager.category.name = detection.category.name;
          detectionManager.entity_id = detection.entity_id;
          detectionManager.description = detection.description;
          detectionManager.status = detection.status;
          detectionManager.default_state = detection.default_state;
          return detectionManager;
        }),
      ),
    );
  }
}

export interface DetectionManagerResponse extends DetectionManager {}

export interface DetectionManagerRequest {
  value: DetectionManager;
}

export interface DetectionManagerResponse extends DetectionManagerRequest {}
