import { Component } from '@angular/core';

@Component({
  selector: 'app-country-list',
  standalone: true,
  template: '',
})
export class CountryListComponent {
  public countries: Array<{ text: string, value: string }> = [
    { text: 'Algeria', value: 'Algeria' },
    { text: 'Angola', value: 'Angola' },
    { text: 'Anguilla', value: 'Anguilla' },
    { text: 'Argentina', value: 'Argentina' },
    { text: 'Bahrain', value: 'Bahrain' },
    { text: 'Barbados', value: 'Barbados' },
    { text: 'Belgium', value: 'Belgium' },
    { text: 'Belize', value: 'Belize' },
    { text: 'Bermuda', value: 'Bermuda' },
    { text: 'Bosnia And Herzegovina', value: 'Bosnia And Herzegovina' },
    { text: 'Botswana', value: 'Botswana' },
    { text: 'Brazil', value: 'Brazil' },
    { text: 'Brunei Darussalam', value: 'Brunei Darussalam' },
    { text: 'Christmas Island', value: 'Christmas Island' },
    { text: 'Congo', value: 'Congo' },
    { text: 'Congo, The Democratic Republic Of The', value: 'Congo, The Democratic Republic Of The' },
    { text: 'Cook Islands', value: 'Cook Islands' },
    { text: 'Croatia', value: 'Croatia' },
    { text: 'Cyprus', value: 'Cyprus' },
    { text: 'Albania', value: 'Albania' },
    { text: 'Aruba', value: 'Aruba' },
    { text: 'Antarctica', value: 'Antarctica' },
    { text: 'Antigua And Barbuda', value: 'Antigua And Barbuda' },
    { text: 'Armenia', value: 'Armenia' },
    { text: 'Austria', value: 'Austria' },
    { text: 'Burkina Faso', value: 'Burkina Faso' },
    { text: 'Cambodia', value: 'Cambodia' },
    { text: 'Canada', value: 'Canada' },
    { text: 'Cape Verde', value: 'Cape Verde' },
    { text: 'Central African Republic', value: 'Central African Republic' },
    { text: 'Chile', value: 'Chile' },
    { text: 'China', value: 'China' },
    { text: 'Costa Rica', value: 'Costa Rica' },
    { text: 'Cuba', value: 'Cuba' },
    { text: 'Denmark', value: 'Denmark' },
    { text: 'Dominica', value: 'Dominica' },
    { text: 'Australia', value: 'Australia' },
    { text: 'Azerbaijan', value: 'Azerbaijan' },
    { text: 'Bhutan', value: 'Bhutan' },
    { text: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
    { text: 'Bulgaria', value: 'Bulgaria' },
    { text: 'Cayman Islands', value: 'Cayman Islands' },
    { text: 'Chad', value: 'Chad' },
    { text: 'Cocos (keeling) Islands', value: 'Cocos (keeling) Islands' },
    { text: 'Colombia', value: 'Colombia' },
    { text: 'Comoros', value: 'Comoros' },
    { text: 'Czech Republic', value: 'Czech Republic' },
    { text: 'East Timor', value: 'East Timor' },
    { text: 'Ecuador', value: 'Ecuador' },
    { text: 'Afghanistan', value: 'Afghanistan' },
    { text: 'American Samoa', value: 'American Samoa' },
    { text: 'Andorra', value: 'Andorra' },
    { text: 'Bahamas', value: 'Bahamas' },
    { text: 'Bangladesh', value: 'Bangladesh' },
    { text: 'Belarus', value: 'Belarus' },
    { text: 'Benin', value: 'Benin' },
    { text: 'Bolivia', value: 'Bolivia' },
    { text: 'Bouvet Island', value: 'Bouvet Island' },
    { text: 'Burundi', value: 'Burundi' },
    { text: 'Cameroon', value: 'Cameroon' },
    { text: 'Cote D\'ivoire', value: 'Cote D\'ivoire' },
    { text: 'Djibouti', value: 'Djibouti' },
    { text: 'Dominican Republic', value: 'Dominican Republic' },
    { text: 'Egypt', value: 'Egypt' },
    { text: 'Eritrea', value: 'Eritrea' },
    { text: 'El Salvador', value: 'El Salvador' },
    { text: 'Falkland Islands (malvinas)', value: 'Falkland Islands (malvinas)' },
    { text: 'Fiji', value: 'Fiji' },
    { text: 'French Polynesia', value: 'French Polynesia' },
    { text: 'Georgia', value: 'Georgia' },
    { text: 'Holy See (vatican City State)', value: 'Holy See (vatican City State)' },
    { text: 'Honduras', value: 'Honduras' },
    { text: 'Hong Kong', value: 'Hong Kong' },
    { text: 'Kiribati', value: 'Kiribati' },
    { text: 'Lao People\'s Democratic Republic', value: 'Lao People\'s Democratic Republic' },
    { text: 'Lebanon', value: 'Lebanon' },
    { text: 'Liberia', value: 'Liberia' },
    { text: 'Liechtenstein', value: 'Liechtenstein' },
    { text: 'Luxembourg', value: 'Luxembourg' },
    { text: 'France', value: 'France' },
    { text: 'Gabon', value: 'Gabon' },
    { text: 'Gambia', value: 'Gambia' },
    { text: 'Gibraltar', value: 'Gibraltar' },
    { text: 'Greenland', value: 'Greenland' },
    { text: 'Guadeloupe', value: 'Guadeloupe' },
    { text: 'Guam', value: 'Guam' },
    { text: 'Guyana', value: 'Guyana' },
    { text: 'Haiti', value: 'Haiti' },
    { text: 'Iraq', value: 'Iraq' },
    { text: 'Jordan', value: 'Jordan' },
    { text: 'Kosovo', value: 'Kosovo' },
    { text: 'Kuwait', value: 'Kuwait' },
    { text: 'Macedonia, The Former Yugoslav Republic Of', value: 'Macedonia, The Former Yugoslav Republic Of' },
    { text: 'Equatorial Guinea', value: 'Equatorial Guinea' },
    { text: 'Estonia', value: 'Estonia' },
    { text: 'Ethiopia', value: 'Ethiopia' },
    { text: 'Finland', value: 'Finland' },
    { text: 'Grenada', value: 'Grenada' },
    { text: 'Hungary', value: 'Hungary' },
    { text: 'Iceland', value: 'Iceland' },
    { text: 'Indonesia', value: 'Indonesia' },
    { text: 'Iran, Islamic Republic Of', value: 'Iran, Islamic Republic Of' },
    { text: 'Ireland', value: 'Ireland' },
    { text: 'Israel', value: 'Israel' },
    { text: 'Italy', value: 'Italy' },
    { text: 'Kazakstan', value: 'Kazakstan' },
    { text: 'Kenya', value: 'Kenya' },
    { text: 'Korea, Democratic People\'s Republic Of', value: 'Korea, Democratic People\'s Republic Of' },
    { text: 'Kyrgyzstan', value: 'Kyrgyzstan' },
    { text: 'Latvia', value: 'Latvia' },
    { text: 'Lithuania', value: 'Lithuania' },
    { text: 'Faroe Islands', value: 'Faroe Islands' },
    { text: 'French Guiana', value: 'French Guiana' },
    { text: 'French Southern Territories', value: 'French Southern Territories' },
    { text: 'Germany', value: 'Germany' },
    { text: 'Ghana', value: 'Ghana' },
    { text: 'Greece', value: 'Greece' },
    { text: 'Guatemala', value: 'Guatemala' },
    { text: 'Guinea', value: 'Guinea' },
    { text: 'Guinea-bissau', value: 'Guinea-bissau' },
    { text: 'Heard Island And Mcdonald Islands', value: 'Heard Island And Mcdonald Islands' },
    { text: 'India', value: 'India' },
    { text: 'Jamaica', value: 'Jamaica' },
    { text: 'Japan', value: 'Japan' },
    { text: 'Korea, Republic Of', value: 'Korea, Republic Of' },
    { text: 'Lesotho', value: 'Lesotho' },
    { text: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya' },
    { text: 'Macau', value: 'Macau' },
    { text: 'Malawi', value: 'Malawi' },
    { text: 'Madagascar', value: 'Madagascar' },
    { text: 'Malaysia', value: 'Malaysia' },
    { text: 'Montserrat', value: 'Montserrat' },
    { text: 'Morocco', value: 'Morocco' },
    { text: 'Myanmar', value: 'Myanmar' },
    { text: 'Nauru', value: 'Nauru' },
    { text: 'Niue', value: 'Niue' },
    { text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
    { text: 'Palau', value: 'Palau' },
    { text: 'Papua New Guinea', value: 'Papua New Guinea' },
    { text: 'Russian Federation', value: 'Russian Federation' },
    { text: 'Saint Lucia', value: 'Saint Lucia' },
    { text: 'Samoa', value: 'Samoa' },
    { text: 'Senegal', value: 'Senegal' },
    { text: 'Maldives', value: 'Maldives' },
    { text: 'Mauritania', value: 'Mauritania' },
    { text: 'Micronesia, Federated States Of', value: 'Micronesia, Federated States Of' },
    { text: 'Montenegro', value: 'Montenegro' },
    { text: 'Mozambique', value: 'Mozambique' },
    { text: 'Nepal', value: 'Nepal' },
    { text: 'Netherlands', value: 'Netherlands' },
    { text: 'Netherlands Antilles', value: 'Netherlands Antilles' },
    { text: 'New Caledonia', value: 'New Caledonia' },
    { text: 'New Zealand', value: 'New Zealand' },
    { text: 'Niger', value: 'Niger' },
    { text: 'Nigeria', value: 'Nigeria' },
    { text: 'Norway', value: 'Norway' },
    { text: 'Panama', value: 'Panama' },
    { text: 'Philippines', value: 'Philippines' },
    { text: 'Pitcairn', value: 'Pitcairn' },
    { text: 'Poland', value: 'Poland' },
    { text: 'Puerto Rico', value: 'Puerto Rico' },
    { text: 'Saint Pierre And Miquelon', value: 'Saint Pierre And Miquelon' },
    { text: 'Sao Tome And Principe', value: 'Sao Tome And Principe' },
    { text: 'Saudi Arabia', value: 'Saudi Arabia' },
    { text: 'Malta', value: 'Malta' },
    { text: 'Marshall Islands', value: 'Marshall Islands' },
    { text: 'Martinique', value: 'Martinique' },
    { text: 'Mauritius', value: 'Mauritius' },
    { text: 'Mexico', value: 'Mexico' },
    { text: 'Monaco', value: 'Monaco' },
    { text: 'Oman', value: 'Oman' },
    { text: 'Pakistan', value: 'Pakistan' },
    { text: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied' },
    { text: 'Peru', value: 'Peru' },
    { text: 'Portugal', value: 'Portugal' },
    { text: 'Reunion', value: 'Reunion' },
    { text: 'Romania', value: 'Romania' },
    { text: 'Rwanda', value: 'Rwanda' },
    { text: 'Saint Helena', value: 'Saint Helena' },
    { text: 'Saint Kitts And Nevis', value: 'Saint Kitts And Nevis' },
    { text: 'Saint Vincent And The Grenadines', value: 'Saint Vincent And The Grenadines' },
    { text: 'Mali', value: 'Mali' },
    { text: 'Mayotte', value: 'Mayotte' },
    { text: 'Moldova, Republic Of', value: 'Moldova, Republic Of' },
    { text: 'Mongolia', value: 'Mongolia' },
    { text: 'Namibia', value: 'Namibia' },
    { text: 'Nicaragua', value: 'Nicaragua' },
    { text: 'Norfolk Island', value: 'Norfolk Island' },
    { text: 'Paraguay', value: 'Paraguay' },
    { text: 'Qatar', value: 'Qatar' },
    { text: 'San Marino', value: 'San Marino' },
    { text: 'Serbia', value: 'Serbia' },
    { text: 'Seychelles', value: 'Seychelles' },
    { text: 'Sierra Leone', value: 'Sierra Leone' },
    { text: 'Solomon Islands', value: 'Solomon Islands' },
    { text: 'Tanzania, United Republic Of', value: 'Tanzania, United Republic Of' },
    { text: 'Tokelau', value: 'Tokelau' },
    { text: 'Trinidad And Tobago', value: 'Trinidad And Tobago' },
    { text: 'Tunisia', value: 'Tunisia' },
    { text: 'Ukraine', value: 'Ukraine' },
    { text: 'Zambia', value: 'Zambia' },
    { text: 'Slovakia', value: 'Slovakia' },
    { text: 'Slovenia', value: 'Slovenia' },
    { text: 'Sri Lanka', value: 'Sri Lanka' },
    { text: 'Suriname', value: 'Suriname' },
    { text: 'Svalbard And Jan Mayen', value: 'Svalbard And Jan Mayen' },
    { text: 'Switzerland', value: 'Switzerland' },
    { text: 'Taiwan, Province Of China', value: 'Taiwan, Province Of China' },
    { text: 'Togo', value: 'Togo' },
    { text: 'Tonga', value: 'Tonga' },
    { text: 'Turkey', value: 'Turkey' },
    { text: 'Uganda', value: 'Uganda' },
    { text: 'United Kingdom', value: 'United Kingdom' },
    { text: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
    { text: 'Venezuela', value: 'Venezuela' },
    { text: 'Wallis And Futuna', value: 'Wallis And Futuna' },
    { text: 'Yemen', value: 'Yemen' },
    { text: 'Somalia', value: 'Somalia' },
    { text: 'South Georgia And The South Sandwich Islands', value: 'South Georgia And The South Sandwich Islands' },
    { text: 'Swaziland', value: 'Swaziland' },
    { text: 'Sweden', value: 'Sweden' },
    { text: 'Tajikistan', value: 'Tajikistan' },
    { text: 'Turkmenistan', value: 'Turkmenistan' },
    { text: 'Turks And Caicos Islands', value: 'Turks And Caicos Islands' },
    { text: 'Tuvalu', value: 'Tuvalu' },
    { text: 'United States', value: 'United States' },
    { text: 'Uruguay', value: 'Uruguay' },
    { text: 'Vanuatu', value: 'Vanuatu' },
    { text: 'Viet Nam', value: 'Viet Nam' },
    { text: 'Virgin Islands, British', value: 'Virgin Islands, British' },
    { text: 'Zimbabwe', value: 'Zimbabwe' },
    { text: 'Singapore', value: 'Singapore' },
    { text: 'South Africa', value: 'South Africa' },
    { text: 'Spain', value: 'Spain' },
    { text: 'Sudan', value: 'Sudan' },
    { text: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
    { text: 'Thailand', value: 'Thailand' },
    { text: 'United Arab Emirates', value: 'United Arab Emirates' },
    { text: 'Uzbekistan', value: 'Uzbekistan' },
    { text: 'Virgin Islands, U.s.', value: 'Virgin Islands, U.s.' },
    { text: 'Western Sahara', value: 'Western Sahara' }
  ];
}