<div class="date-filter"></div>
<kendo-tabstrip>
  <kendo-tabstrip-tab
    #customer_analytics_securityoperationscenter
    title="Security Operations Center"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-soc></app-soc>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>

  @if (showEdrTab()) {
    <kendo-tabstrip-tab
      #customer_analytics_endpointdetectionandresponse
      title="Endpoint Detection and Response"
      [selected]="false"
    >
      <ng-template kendoTabContent>
        <div class="content">
          <app-edr></app-edr>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  }
  <!-- Commenting out for deployment to production. These components are still under development. MF -->
  <!-- <kendo-tabstrip-tab
    #customer_analytics_vulnerabilityscanservice
    title="Vulnerability Scanning Service"
    [selected]="false"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-vss></app-vss>
      </div>
    </ng-template>
  </kendo-tabstrip-tab> -->

  <kendo-tabstrip-tab
    #customer_analytics_mitreattack
    title="MITRE ATT&CK"
    [selected]="false"
  >
    <ng-template kendoTabContent>
      <div class="content">
        <app-mitreattack></app-mitreattack>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
