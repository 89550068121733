<app-admin-appbar></app-admin-appbar><br />
<!-- Frill Widget (https://frill.co) -->
<div class="full-view">
  <iframe
    src="https://cyflare.frill.co/embed/widget/d08750fa-0245-40ac-bb70-08aa34e54acf"
    sandbox="allow-same-origin allow-scripts allow-top-navigation allow-popups allow-forms allow-popups-to-escape-sandbox"
    style="border: 0px; outline: 0px; width: 100%; height: 100%"
  ></iframe>
</div>
<!-- End Frill Widget -->
