@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (ticketsByAsset().length > 0) {
  <div class="chart-container">
    <kendo-chart-breadcrumb [chart]="instance"></kendo-chart-breadcrumb>

    <kendo-chart
      #instance
      (seriesClick)="onSeriesClick($event)"
      (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
    >
      <kendo-chart-tooltip> </kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="column"
          [data]="ticketsByAsset()"
          field="count"
          categoryField="description"
          drilldownField="description"
        >
          <kendo-chart-series-item
            *kendoChartDrilldownSeries="let make = drilldownValue"
            [data]="ticketsByEventName()"
            field="count"
            categoryField="description"
          >
          </kendo-chart-series-item>
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item>
          <kendo-chart-category-axis-item-labels
            [content]="truncateCategoryLabel"
            rotation="auto"
          ></kendo-chart-category-axis-item-labels>
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
    </kendo-chart>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
  </div>
} @else {
  <h1>No Data</h1>
}
