import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DrawerStateService {
    public drawerExpanded: boolean = true;

    constructor() { }

    toggleDrawer(): void {
        this.drawerExpanded = !this.drawerExpanded;
    }
}
