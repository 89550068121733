import { Component, effect, inject, output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DetectionManagerService } from '../../services/manager.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { takeUntil } from 'rxjs';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { DetectionManager } from '../../../../../core/models/entities';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DetectionManagerFormService } from './service/manager-form.service';

@Component({
  selector: 'app-manager-form',
  standalone: true,
  imports: [InputsModule, LabelModule, IconsModule, ButtonModule, FormsModule, DialogsModule, ReactiveFormsModule, GridModule, DropDownListModule, LayoutModule, PopupModule],
  templateUrl: './manager-form.component.html',
  styleUrl: './manager-form.component.scss',
})
export class ManagerFormComponent extends BaseComponent {
  private detectionManagerService = inject(DetectionManagerService);
  private forms = inject(DetectionManagerFormService);
  public xIcon: SVGIcon = xIcon;
  public detection = signal<DetectionManager>(new DetectionManager());
  public detectionForm = this.forms.createDetectionManagerForm();
  close = output<void>();
  opened = false;
  status: any;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      const detection = {
        ...this.detection(),
      };

      this.detectionForm.patchValue({
        pk: detection.pk,
        title: detection.title,
        category: detection.category.name,
        entity_id: detection.entity_id,
        description: detection.description,
        status: detection.status,
        default_state: detection.default_state === 'On',
      });
    });
  }

  public save($event: Event) {
    $event.preventDefault();
    let detectionForm = {
      title: this.detectionForm.value.title,
      category: { name: this.detectionForm.value.category },
      entity_id: this.detectionForm.value.entity_id,
      description: this.detectionForm.value.description,
      status: this.detectionForm.value.status,
      default_state: this.detectionForm.value.default_state ? 'On' : 'Muted',
    };

    this.detectionManagerService
      .addDetectionManagerItem(detectionForm)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public clear() {
    this.detectionForm.reset();
  }

  public cancel() {
    this.clear();
    this.close.emit();
  }

  update($event: Event) {
    $event.preventDefault();
    let payload = {
      title: this.detectionForm.value.title,
      category: { name: this.detectionForm.value.category },
      entity_id: this.detectionForm.value.entity_id,
      description: this.detectionForm.value.description,
      status: this.detectionForm.value.status,
      default_state: this.detectionForm.value.default_state ? 'On' : 'Muted',
    };
    
    const id = this.detectionForm.value.pk!;
    this.detectionManagerService
      .updateDetectionManagerItem(id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  delete($event: Event) {
    $event.preventDefault();
    const id = this.detectionForm.value.pk!;
    this.detectionManagerService
      .deleteDetectionManagerItem(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public open(): void {
    this.opened = true;
  }

  public closeDialog(status: string): void {
    this.opened = false;
  }

  public onStatusChange(event: any): void {
    this.status = event;
    this.open();
  }
}
