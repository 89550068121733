import { Component } from '@angular/core';
import { SeriesType, Legend, ChartsModule } from "@progress/kendo-angular-charts";

@Component({
  selector: 'app-visitors',
  standalone: true,
  imports: [
    ChartsModule
  ],
  templateUrl: './visitors.component.html',
  styleUrl: './visitors.component.scss'
})
export class VisitorsComponent {
  public chartConfig = {
    series: [
      {
        type: <SeriesType>"donut",
        field: "value",
        categoryField: "type",
        startAngle: 70,
        holeSize: 30,
        data: [
          { value: 70, type: "True Positive" },
          { value: 30, type: "False Positive" },
        ],
      },
    ],
    legend: <Legend>{
      position: "bottom",
    },
  };
}
