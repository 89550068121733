import { Component, CreateEffectOptions, Input, Signal, effect, inject, signal } from '@angular/core';
import { TileUrlTemplateArgs } from '@progress/kendo-angular-map';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MapModule, ShapeCreatedEvent } from '@progress/kendo-angular-map';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ShapeOptions } from '@progress/kendo-drawing';
import { AnalyticsService, MapData } from '../../../../../core/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-source-map',
  standalone: true,
  imports: [MapModule, LayoutModule, GridModule, MapModule, LoaderModule],
  templateUrl: './source-map.component.html',
  styleUrl: './source-map.component.scss',
  providers: [AnalyticsService],
})
export class SourceMapComponent extends BaseComponent {
  @Input() data!: Signal<MapData[]>;
  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);

  public sourceData = signal<MapData[]>([
    {
      region: '',
      count: 0,
      location: [0, 0],
    },
  ]);
  public isLoading = signal<boolean>(true);
  tileSubdomains = ['a', 'b', 'c'];
  tileUrl = (e: TileUrlTemplateArgs): string => `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;

  bubbleStyle: ShapeOptions = {
    fill: {
      color: 'orange',
      opacity: 0.5,
    },
    stroke: {
      width: 1,
      color: 'black',
    },
  };

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const sourceMapData = this.data();
        if (sourceMapData) {
          this.sourceData.set(sourceMapData);
          this.isLoading.set(false);
        } else {
          this.sourceData.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onShapeCreated(e: ShapeCreatedEvent): void {
    const shape = e.shape;
    const users = e.dataItem.properties.users;
    if (users) {
      const opacity = users / 1000;
      shape.options.set('fill.opacity', opacity);
    }
  }
}
