import { Component, ElementRef, inject, Renderer2, OnDestroy } from '@angular/core';
import { PopupService, PopupRef } from '@progress/kendo-angular-popup';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from '../shared/common/base.component';

@Component({
  selector: 'app-popup-host',
  template: ''
})
export abstract class PopupHostComponent extends BaseComponent implements OnDestroy {
  private popupService = inject(PopupService);
  private renderer = inject(Renderer2);

  protected popupRef: PopupRef | undefined;
  private documentClickListener: (() => void) | undefined;
  protected document: Document = inject(DOCUMENT);
  protected bodyElement = new ElementRef(this.document.body);

  protected openPopup(anchor: ElementRef, content: any, options: any): void {
    if (this.popupRef) {
      this.closePopup();
    } else {
      this.popupRef = this.popupService.open({
        anchor: anchor,
        content: content,
        ...options
      });
  
      // Delay the document click listener registration
      setTimeout(() => {
        this.documentClickListener = this.renderer.listen(this.document, 'click', this.handleDocumentClick.bind(this));
      }, 0);
    }
  }
  
  private handleDocumentClick(event: MouseEvent): void {
    if (!this.popupRef) return;

    const popupComponentRef = this.popupRef.popup as any;
    const popupElement = popupComponentRef.location.nativeElement as HTMLElement;
    if (!popupElement) {
      console.error('Popup element is undefined');
      return;
    }

    if (!popupElement.contains(event.target as Node)) {
      this.closePopup();
    }
  }

  protected closePopup(): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
      if (this.documentClickListener) {
        this.documentClickListener();
        this.documentClickListener = undefined;
      }
    }
  }

  override ngOnDestroy(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = undefined;
    }

    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    }

    super.ngOnDestroy();
  }
}
