<app-org-appbar></app-org-appbar>
<br />
<kendo-tabstrip>
  <kendo-tabstrip-tab
    title="Travel Advisories"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <app-travel-advisories></app-travel-advisories>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Service Accounts">
    <ng-template kendoTabContent>
      <app-accounts></app-accounts>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Vulnerability Scanners">
    <ng-template kendoTabContent>
      <app-vulnerability></app-vulnerability>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Server Exceptions">
    <ng-template kendoTabContent>
      <app-server></app-server>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="SOC Bulletins">
    <ng-template kendoTabContent>
      <app-bulletin></app-bulletin>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
