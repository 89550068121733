<div class="layout-container">
  @if (isLoading()) {
    <kendo-loader
      [type]="'infinite-spinner'"
      [size]="'large'"
    ></kendo-loader>
  } @else if (truePositivePercentage === 0 && falsePositivePercentage === 0 && unknownPercentage === 0) {
    <h1>No Data</h1>
  } @else if (data() !== null) {
    <kendo-chart class="chart-container">
      <kendo-chart-series>
        <kendo-chart-series-item
          [autoFit]="autofit"
          type="donut"
          [data]="data()"
          categoryField="description"
          field="count"
        >
          <kendo-chart-series-item-labels
            align="circle"
            position="outsideEnd"
            [content]="labelContent"
            color="#000"
            background="none"
          >
          </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    </kendo-chart>

    <kendo-dialog
      *ngIf="showPopup"
      (close)="close()"
    >
      <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
      <kendo-grid
        class="mxh-80v"
        [filterable]="true"
        [data]="gridService | async"
        [pageable]="{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [25, 50, 100, 200]
        }"
        [pageSize]="gridService.state.take"
        [skip]="gridService.state.skip"
        [(filter)]="gridService.state.filter"
        [loading]="gridService.loading"
        (dataStateChange)="gridService.onStateChange($event)"
        [sort]="this.gridService.state.sort"
        [sortable]="true"
        [resizable]="true"
      >
        <ng-template kendoGridToolbarTemplate>
          <button
            type="button"
            kendoGridExcelCommand
          >
            Export to Excel
          </button>
        </ng-template>
        <kendo-grid-column
          [filterable]="false"
          field="created_time"
          title="Created On"
        ></kendo-grid-column>
        <kendo-grid-column
          field="id"
          title="Ticket #"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ticket_status"
          title="Status"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ticket_closed_time"
          title="Closed"
        ></kendo-grid-column>
        <kendo-grid-column
          field="priority"
          title="Priority"
        ></kendo-grid-column>
        <kendo-grid-column
          field="ticket_source"
          title="Source"
        ></kendo-grid-column>
        <kendo-grid-excel
          fileName="sla-data.xlsx"
          [fetchData]="allData"
        ></kendo-grid-excel>
      </kendo-grid>
    </kendo-dialog>
  } @else if (data() === null) {
    <h1>No Data</h1>
  }
</div>
