import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { OrgAppbarComponent } from '../../../../../layout/components/org-appbar/org-appbar.component';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';

@Component({
  selector: 'app-oncall',
  standalone: true,
  imports: [
    FormsModule,
    LayoutModule,
    IconsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    NavigationModule,
    ReactiveFormsModule,
    DateInputsModule,
    GridModule,
    OrgAppbarComponent,
    ListViewModule,
    DropDownsModule
  ],
  templateUrl: './oncall.component.html',
  styleUrl: './oncall.component.scss'
})
export class OncallComponent extends BaseComponent {
  form = new FormGroup({
    name: new FormControl(''),
    phone: new FormControl(''),
  });
}
