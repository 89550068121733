import { Component } from '@angular/core';
import { ResponseActionsComponent } from './components/response-actions/response-actions.component';
import { CaseDetailsComponent } from './components/case-details/case-details.component';
import { EntityEnrichmentComponent } from './components/entity-enrichment/entity-enrichment.component';
import { TicketCorrelationComponent } from './components/ticket-correlation/ticket-correlation.component';
import { ThreatHuntingComponent } from './components/threat-hunting/threat-hunting.component';
import { ThresholdChecksComponent } from './components/threshold-checks/threshold-checks.component';

@Component({
  selector: 'app-case-dashboard',
  standalone: true,
  imports: [ResponseActionsComponent, EntityEnrichmentComponent, TicketCorrelationComponent, CaseDetailsComponent, ThreatHuntingComponent, ThresholdChecksComponent],
  templateUrl: './case-dashboard.component.html',
  styleUrl: './case-dashboard.component.scss',
})
export class CaseDashboardComponent {}
