@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (chartData() != null) {
  <kendo-chart>
    <kendo-chart-series>
      <kendo-chart-series-item
        [autoFit]="true"
        type="pyramid"
        [data]="chartData()"
        field="count"
        categoryField="stat"
        [dynamicHeight]="false"
      >
        <kendo-chart-series-item-labels background="none"> </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-tooltip>
      <ng-template
        kendoChartSeriesTooltipTemplate
        let-category="category"
      >
        {{ category }}
      </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend
      position="bottom"
      [visible]="true"
    ></kendo-chart-legend>
  </kendo-chart>
} @else {
  <h1>No Data</h1>
}
