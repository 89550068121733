import { effect, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Events, EventService } from 'src/app/core/services/event.service';
import { User } from 'src/app/core/models/user';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  public readonly logo = signal<string>('');

  constructor(
    private readonly http: HttpClient,
    private readonly eventService: EventService,
    private readonly user: User,
  ) {}

  public uploadLogo(organization_id: number, file: File): Observable<WhiteLabelResponse> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${environment.apiUrl}/v1/organization/whitelabels/${organization_id}/upload-logo/`;
    return this.http.post<WhiteLabelResponse>(url, formData);
  }

  public uploadFavicon(organization_id: number, file: File): Observable<WhiteLabelResponse> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${environment.apiUrl}/v1/organization/whitelabels/${organization_id}/upload-favicon/`;
    return this.http.post<WhiteLabelResponse>(url, formData);
  }

  public get(organization_id: number): Observable<WhiteLabelParentResponse> {
    const url = `${environment.apiUrl}/v1/organization/whitelabels/${organization_id}/`;
    return this.http.get<WhiteLabelParentResponse>(url);
  }

  public upsert(organization_id: number, request: WhiteLabelRequest) {
    const url = `${environment.apiUrl}/v1/organization/whitelabels/${organization_id}/`;
    return this.http.put<WhiteLabelParentResponse>(url, request);
  }

  public whiteLabelEffect() {
    effect(
      () => {
        this.eventService.getEvent(Events.WhiteLabelChange.type)();
        let whitelabel = this.user.primary_organization?.whitelabel?.enabled ? this.user.primary_organization?.whitelabel : this.user.primary_organization?.parent_whitelabel!;
          this.logo.set(whitelabel.logo_url ?? '../assets/logos/cyflare_one_logo.png');
          if(whitelabel.favicon_url) {
            this.updateFaviconUrl(whitelabel.favicon_url ?? this.getFaviconUrl());
          }
          if(whitelabel.title) {
            document.title = whitelabel.title ?? document.title;
          }
      },
      { allowSignalWrites: true },
    );
  }

  private updateFaviconUrl(favicon_url: string | null | undefined) {
    document.querySelector('link[rel="icon"]')?.setAttribute('href', favicon_url ?? '../assets/logos/cyflare_one_logo.png');
  }

  private getFaviconUrl() {
    return document.querySelector('link[rel="icon"]')?.getAttribute('href');
  }
}

export interface WhiteLabelResponse {
  message?: string;
  url: string | null;
  logo_url: string;
  organization_id: string;
  whitelabel_id?: number;
  enabled?: boolean | null;
  title?: string | null;
  favicon_url: string | null;
}

export interface WhiteLabelRequest {
  url?: string | null;
  enabled?: boolean | null;
  title?: string | null;
}

export interface WhiteLabelParentResponse {
  parent_whitelabel: WhiteLabelResponse;
  whitelabel: WhiteLabelResponse;
}
