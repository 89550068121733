import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { TravelAdvisory } from '../../../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class TravelAdvisoryService extends ODataService<TravelAdvisory> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/traveladvisory`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'email', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public createTravelAdvisory(request: TravelAdvisoryRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/traveladvisory/`;
    return this.http.post<TravelAdvisoryResponse>(url, request);
  }

  public updateTravelAdvisory(request: TravelAdvisory) {
    const url = `${this.url}`;
    return this.http.patch(url, request);
  }

  public deleteTravelAdvisory(travelAdvisoryId: number) {
    const url = `${this.url}?pk=${travelAdvisoryId}`;
    return this.http.delete(url);
  }
}

export interface TravelAdvisoryResponse extends TravelAdvisory {}

export interface TravelAdvisoryRequest {
  organization_id: number;
  travel_advisory: TravelAdvisory;
}

export interface TravelAdvisoryResponse extends TravelAdvisoryRequest {}

export interface ErrorResponse extends HttpErrorResponse {
  error: {
    detail: string;
  };
}
