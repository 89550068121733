<div>
  <h4>Quick Links</h4>
</div>
<div class="links-container">
  <a
    href="https://cyflare.stellarcyber.cloud/login"
    target="_blank"
    class="k-hstack"
  >
    <kendo-card class="centered-card">
      <img
        src="../../../assets/logos/stellarcyberlogo.png"
        alt="stellarcyber"
      />
    </kendo-card>
  </a>
  <a
    href="https://usea1-008.sentinelone.net/login"
    target="_blank"
    class="k-hstack"
  >
    <kendo-card class="centered-card">
      <img
        src="../../../assets/logos/sentinelonelogo.png"
        alt="sentinelone"
      />
    </kendo-card>
  </a>
</div>
