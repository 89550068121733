<div style="padding: 20px">
  <form
    class="form-signin w-100 border"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
  >
    <div class="logo-container">
      <img
        src="/assets/logos/cyflare_one_logo.png"
        alt="logo"
      />
    </div>
    <div class="header-container">
      <h2>Sign In</h2>
    </div>
    <div>
      <kendo-floatinglabel text="Your Email Address">
        <input
          kendoTextBox
          formControlName="email"
          class="form-control"
          type="email"
          (input)="onEmailInput($event)"
        />
      </kendo-floatinglabel>
    </div>
    <div>
      <kendo-floatinglabel text="Enter Your Password">
        <input
          kendoTextBox
          formControlName="password"
          type="password"
          class="form-control"
        />
      </kendo-floatinglabel>
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-between">
        <div>
          <button
            class="text-primary"
            kendoButton
            fillMode="clear"
            type="button"
            (click)="forgot()"
          >
            Forgot Password
          </button>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        themeColor="primary"
        type="submit"
        [disabled]="!loginForm.valid"
      >
        Sign in
      </button>
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <label class="form-check-label">Having trouble signing in?</label>
        </div>
        <div>
          <a
            href="mailto:support@cyflare.com"
            class="text-primary"
            kendoButton
            fillMode="clear"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </form>
</div>
