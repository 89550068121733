import { ChangeDetectionStrategy, Component, inject, signal, type OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { WhiteLabelService } from './services/white-label.service';
import { JsonPipe } from '@angular/common';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/shared/common/base.component';

@Component({
  selector: 'app-white-label',
  standalone: true,
  imports: [
    OrgAppbarComponent,
    FormsModule,
    ReactiveFormsModule,
    UploadsModule,
    ButtonModule,
    TabStripModule,
    LabelModule,
    LayoutModule,
    JsonPipe
  ],
  templateUrl: './white-label.component.html',
  styleUrl: './white-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteLabelComponent extends BaseComponent implements OnInit {
  private wlabelService = inject(WhiteLabelService);
  private route = inject(ActivatedRoute);
  private organizationId: string | null = null;
  public images: File[] = [];
  public filename = signal<string | null>(null);
  public restrictions = {
    allowedExtensions: ['.png', '.jpg', '.jpeg', '.svg', '.gif', 'txt'],
    maxFileSize: 1024 * 1024 * 5, // 5MB
  };

  public whiteLabelForm = new FormGroup({
    files: new FormControl<File[]>([], Validators.required),
  });

  public submitted = false;

  ngOnInit(): void {
    this.organizationId = this.route.snapshot.queryParamMap.get('organizationId');
    this.wlabelService.getLogo(Number(this.organizationId))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.filename.set(res.url);
      });
  }

  save(_value: any, valid: boolean): void {
    this.submitted = true;

    if (valid && this.organizationId) {
      const formFiles = this.whiteLabelForm.get('files');
      const files = formFiles?.value as unknown as File[];
      if (files && files.length > 0) {
        const file = files[0];
        this.wlabelService.uploadLogo(Number(this.organizationId), file)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.filename.set(res.url);
            this.submitted = false;
            this.whiteLabelForm.reset();
          });
      }
    }
  }
}
