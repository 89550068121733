import { Component, CreateEffectOptions, effect, input, signal } from '@angular/core';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { FunnelData } from '../../../../../core/services/analytics.service';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ticket-closure-breakdown',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule],
  templateUrl: './ticket-closure-breakdown.component.html',
  styleUrl: './ticket-closure-breakdown.component.scss'
})
export class TicketClosureBreakdownComponent extends BaseComponent {
  public ticketClosureBreakdownData =input<FunnelData[]>([]);
  public chartData =signal<FunnelData[]>([]);
  
  public isLoading = signal(true);

  constructor() {
    super();
    this.initEffect();
  }

  initEffect() {
    effect(() => {
        if (this.ticketClosureBreakdownData()) {
          this.chartData.set(this.ticketClosureBreakdownData() || []);
          this.isLoading.set(false);
        } else {
          this.chartData.set([]);
          this.isLoading.set(true);
        }
    },
    { allowSignalWrites: true } as CreateEffectOptions);
  }
}
