import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MapAccount } from '../../../../../../services/ingestor.service';

@Injectable({
  providedIn: 'root'
})
export class MapAccountsFormsService {

  constructor() { }

  init(mapAccount: MapAccount = new MapAccount()): FormGroup<MapAccountForm> {
    return new FormGroup<MapAccountForm>({
      integration_unique_name: new FormControl<string | null>(mapAccount.integration_unique_name || null),
      integration_url: new FormControl<string | null>(mapAccount.integration_url || null),
      api_key: new FormControl<string | null>(mapAccount.api_key || null),
    });
  }
}

export interface MapAccountForm {
  integration_unique_name: FormControl<string | null>;
  integration_url: FormControl<string | null>;
  api_key: FormControl<string | null>;
}


