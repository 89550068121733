import { Component, effect, inject, signal } from '@angular/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { AnalyticsService, TrendingData, SeriesData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ActivatedRoute } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-ticket-trends',
  standalone: true,
  imports: [ChartsModule, LoaderModule],
  templateUrl: './ticket-trends.component.html',
  styleUrl: './ticket-trends.component.scss',
  providers: [AnalyticsService],
})
export class TicketTrendsComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);

  public isLoading = signal(true);
  public ticketTrends = signal<TrendingData | null>(null);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => !!params['organizationId']),
          switchMap((params) => this.analyticsService.getTicketTrends(params['organizationId'])),
          map((data) => this.filterSeries(data, ['Severe', 'Informative', 'Informational', 'None','medium','high','low', 'Unchanged', 'Unknown', '-None-'])),
          map((data) => this.sortSeries(data)),
          map((data) => this.reverseData(data)),
        )
        .subscribe({
          next: (data) => {
            this.ticketTrends.set(data);
            this.isLoading.set(false);
          },
          error: () => {
            this.ticketTrends.set(null);
            this.isLoading.set(false);
          },
        });
    });
  }

  private filterSeries(data: TrendingData, excludedNames: string[]): TrendingData {
    return {
      ...data,
      series: data.series.filter((series: SeriesData) => !excludedNames.includes(series.name)),
    };
  }

  private sortSeries(data: TrendingData): TrendingData {
    const severityOrder = ['Critical', 'High', 'Medium', 'Low'];
    return {
      ...data,
      series: data.series.sort((a, b) => severityOrder.indexOf(a.name) - severityOrder.indexOf(b.name)),
    };
  }

  private reverseData(data: TrendingData): TrendingData {
    return {
      ...data,
      categories: data.categories.reverse(),
      series: data.series.map((series) => ({
        ...series,
        data: series.data.reverse(),
      })),
    };
  }

  public getSeriesZIndex(name: string): number {
    const zIndexMap: { [key: string]: number } = {
      Critical: 4,
      High: 3,
      Medium: 2,
      Low: 1,
    };
    return zIndexMap[name] || 0;
  }
}
