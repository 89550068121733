import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IngestorRecord } from '../../../../../../services/ingestor.service';

@Injectable({
  providedIn: 'root'
})
export class RecordFormService {

  constructor() { }

  init(record: IngestorRecord = new IngestorRecord()): FormGroup<RecordForm> {
    return new FormGroup<RecordForm>({
      ingestor: new FormControl<number | null>(record.ingestor || record.id || null),
      integration_url: new FormControl<string | null>(record.integration_url || null),
      organization_parent: new FormControl<number | null>(record.organization_parent || null),
      api_key: new FormControl<string | null>(record.api_key || null),
    });
  }
}

export interface RecordForm {
  ingestor: FormControl<number | null>;
  integration_url: FormControl<string | null>;
  organization_parent: FormControl<number | null>;
  api_key: FormControl<string | null>;
}
