import { Component, HostListener, inject, signal } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ArcGaugeModule } from '@progress/kendo-angular-gauges';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { TrendingArticleComponent } from './components/advisories-main/trending-article/trending-article.component';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { TicketTrendsComponent } from './components/ticket-trends/ticket-trends.component';
import { ProgressTicketsWidgetComponent } from './components/progress-tickets-widget/progress-tickets-widget.component';
import { TprWidgetComponent } from './components/tpr-widget/tpr-widget.component';
import { NewTicketsWidgetComponent } from './components/new-tickets-widget/new-tickets-widget.component';
import { MttdWidgetComponent } from './components/mttd-widget/mttd-widget.component';
import { ClosedTodayWidgetComponent } from './components/closed-today-widget/closed-today-widget.component';
import { AdvisoriesMainComponent } from './components/advisories-main/advisories-main.component';
import { ConnectorStatusComponent } from './components/connector-status/connector-status.component';
import { ConnectorStatusService } from '../../../core/services/connector-status.service';
import { ConnectorStatus } from '../../../core/models/entities';
import { ActivatedRoute } from '@angular/router';
import { infoCircleIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-customer-dashboard',
  standalone: true,
  imports: [FormsModule, AdvisoriesMainComponent, QuickLinksComponent, ProgressTicketsWidgetComponent, TprWidgetComponent, NewTicketsWidgetComponent, MttdWidgetComponent, ClosedTodayWidgetComponent, TicketTrendsComponent, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, ArcGaugeModule, ChartsModule, TrendingArticleComponent, GridModule, ConnectorStatusComponent, TooltipsModule],
  templateUrl: './customer-dashboard.component.html',
  styleUrl: './customer-dashboard.component.scss',
  providers: [ConnectorStatusService],
})
export class CustomerDashboardComponent extends BaseComponent {
  public infoCircleIcon: SVGIcon = infoCircleIcon;
  private route = inject(ActivatedRoute);
  private connectorStatusService = inject(ConnectorStatusService);

  public connectorStatusData = signal<ConnectorStatus[] | null>(null);
  private organizationId: number | null = null;

  constructor() {
    super();
    this.updateLayout(window.innerWidth);
  }

  public items: any[] = [
    { content: 'Tile 1', cols: 1, rows: 1 },
    { content: 'Tile 2', cols: 1, rows: 1 },
    { content: 'Tile 3', cols: 1, rows: 1 },
    { content: 'Tile 4', cols: 1, rows: 1 },
    { content: 'Tile 5', cols: 1, rows: 1 },
  ];
  public columns: number = 1; // Default columns
  public gap: number = 10; // Gap between tiles

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateLayout(event.target.innerWidth);
  }

  updateLayout(width: number): void {
    if (width >= 992) {
      // Large devices
      this.columns = 5;
    } else if (width >= 768) {
      // Medium devices
      this.columns = 3;
    } else {
      // Small devices
      this.columns = 1;
    }
  }
}
