import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class AnalyticsService {
  private http = inject(HttpClient);

  private buildUrl(endpoint: string, organizationId: number, params: any = {}) {
    let url = `${environment.apiUrl}/v1/analytics/stats/${endpoint}/${organizationId}/`;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null) {
        httpParams = httpParams.set(key, params[key]);
      }
    }

    return { url, params: httpParams };
  }

  getMttd(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('mttd', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getMttrAverage(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('mttraverage', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getTpr(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('truepositiverate', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getDestinationMap(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('destinationmap', organizationId, params);
    return this.http.get<MapData[]>(url, { params: httpParams });
  }

  getSourceMap(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('sourcemap', organizationId, params);
    return this.http.get<MapData[]>(url, { params: httpParams });
  }

  getTopSuspectUsers(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('top5suspectuser', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getTopDetectionData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('topdetections', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getTopUserDetail(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('top5userdetail', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getTopSuspectAssets(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('top5suspectsourceip', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getTopAssetDetail(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('top5sourceipdetail', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getOutcomeStatus(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('truepositivevsfalsepositive', organizationId, params);
    return this.http.get<TopFiveData[]>(url, { params: httpParams });
  }

  getInProgressTickets(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('inprogresschart', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getClosedTodayTickets(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('closedtodaychart', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getNewTickets(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('newticketswidget', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getTicketTrends(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('tickettrends', organizationId, params);
    return this.http.get<TrendingData>(url, { params: httpParams });
  }

  getFunnelData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('socdistillationfunnel', organizationId, params);
    return this.http.get<FunnelData[]>(url, { params: httpParams });
  }

  getSlaViolations(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('slaviolations', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getTotalTickets(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('numberoftickets', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getDisposition(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('detectiondisposition', organizationId, params);
    return this.http.get<DispositionData[]>(url, { params: httpParams });
  }

  getAdrManualData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('adrmanual', organizationId, params);
    return this.http.get<AdrManualData[]>(url, { params: httpParams });
  }

  getTicketClosureBreakdownData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('totalalertcases', organizationId, params);
    return this.http.get<FunnelData[]>(url, { params: httpParams });
  }

  getMttrData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('mttr', organizationId, params);
    return this.http.get<MttrData[]>(url, { params: httpParams });
  }

  getMttdData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('mttdbypriority', organizationId, params);
    return this.http.get<MttdData[]>(url, { params: httpParams });
  }

  getConnectorStatusData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('downconnects', organizationId, params);
    return this.http.get<ConnectorStatusData[]>(url, { params: httpParams });
  }
  getSlaAdherence(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('slaadherenceviewset', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }
  getOutcomeData(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('outcomerelevancydetails', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getOpenThreats(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('edropenthreatswidget', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getThreatRebootCount(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('edrthreatrebootsrequiredwidget', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getAgentStatus(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('agentstatuschart', organizationId, params);
    return this.http.get<AgentStatusData[]>(url, { params: httpParams });
  }

  getOutdatedAgents(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('edroutdatedagentswidget', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }

  getCoverage(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('edrcoveragewidget', organizationId, params);
    return this.http.get<WidgetData>(url, { params: httpParams });
  }
}

export interface WidgetData {
  value: number;
  colors: {
    to: number;
    color: string;
    from: number;
  }[];
}

export interface MapData {
  region: string;
  count: number;
  location: [number, number];
}

export interface DispositionData {
  closure_reason: string;
  count: number;
}

export interface TopFiveData {
  description: string;
  count: number;
}

export interface SeriesData {
  name: string;
  data: number[];
}

export interface AdrManualData {
  category: string;
  value: number;
}

export interface TrendingData {
  title: string;
  categories: string[];
  series: SeriesData[];
}

export interface FunnelData {
  stat: string;
  count: number;
}

export interface MttrData {
  description: string;
  count: string;
}

export interface MttdData {
  description: string;
  value: number;
}

export interface ConnectorStatusData {
  connector_name: string;
  duration: string;
}

export interface AgentStatusData {
  description: string;
  count: number;
  color: string;
}
