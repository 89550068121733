<form
  class="form-signin"
  [formGroup]="passwordForm"
>
  <div class="logo-container">
    <img src="/assets/logos/cyflare_one_logo.png" />
  </div>
  <div class="header-container">
    <h2>Reset Password</h2>
  </div>
  <div>
    <kendo-floatinglabel text="Enter New Password">
      <input
        kendoTextBox
        #password
        required
        formControlName="password"
        type="password"
        class="form-control"
      />
    </kendo-floatinglabel>
  </div>
  <div>
    <kendo-floatinglabel text="Re-Enter New Password">
      <input
        kendoTextBox
        #confirmPassword
        required
        formControlName="confirmPassword"
        type="password"
        class="form-control"
      />
    </kendo-floatinglabel>
  </div>
  <div>
    @if (passwordForm.hasError('mismatch') && (passwordForm.get('confirmPassword')?.touched || passwordForm.get('confirmPassword')?.dirty)) {
      <div>
        <p class="text-danger">Password do not match</p>
      </div>
    }
  </div>
  <div class="d-grid gap-2 mt-4">
    <button
      kendoButton
      themeColor="primary"
      (click)="resetPassword($event)"
      [disabled]="passwordForm.invalid"
    >
      Update and Sign In
    </button>
    <kendo-formhint>Password Must Contain:<br />- Minimum of 10 characters<br />- At least one uppercase charater<br />- At least one lowercase character<br />- At least one special character (no spaces allowed)</kendo-formhint>
  </div>
  <div class="mt-2">
    <div class="d-flex justify-content-between">
      <div class="form-check d-flex align-items-center">
        <label
          class="form-check-label"
          for="rememberMe"
          >Having trouble signing in?</label
        >
      </div>
      <div>
        <a
          href="mailto:support@cyflare.com"
          class="text-primary"
          kendoButton
          fillMode="clear"
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
  <div class="d-grid gap-2 mt-4">
    <button
      kendoButton
      size="small"
      (click)="back()"
    >
      Go Back
    </button>
  </div>
</form>
