import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { SocBulletin } from '../../../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class SocBulletinService extends ODataService<SocBulletinResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/bulletin`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }
  public createSocBulletin(request: SocBulletinRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/bulletin/`;
    return this.http.post<SocBulletinRequest>(url, request);
  }
  public updateSocBulletin(request: SocBulletin) {
    const url = `${this.url}`;
    return this.http.patch<SocBulletinResponse>(url, request);
  }

  public deleteSocBulletin(socBulletinId: number) {
    const url = `${this.url}?pk=${socBulletinId}`;
    return this.http.delete(url);
  }
}

export interface SocBulletinResponse extends SocBulletin {}

export interface SocBulletinRequest {
  organization_id: number;
  soc_response_bulletin: SocBulletin;
}

export interface SocBulletinResponse extends SocBulletinRequest {}
