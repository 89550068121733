import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { CellClickEvent, GridModule } from '@progress/kendo-angular-grid';
import { UserService } from './services/user.service';
import { CommonModule } from '@angular/common';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { IconsModule } from '@progress/kendo-angular-icons';
import { SVGIcon, plusIcon, xIcon } from '@progress/kendo-svg-icons';
import { UserComponent } from './component/user/user.component';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopupHostComponent } from '../../../core/components/popup-host.component';
import { IUser } from '../../../core/models/entities';
import { User } from '../../../core/models/user';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [GridModule, CommonModule, DropDownListModule, LayoutModule, ButtonsModule, InputsModule, PopupModule, FormsModule, ReactiveFormsModule, DateInputsModule, LabelModule, IconsModule, AdminAppbarComponent, UserComponent, IndicatorsModule],
  encapsulation: ViewEncapsulation.None,

  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage extends PopupHostComponent implements OnInit {
  @ViewChild('grid', { read: ElementRef })
  gridElement!: ElementRef;
  public xIcon: SVGIcon = xIcon;
  public plusIcon: SVGIcon = plusIcon;
  public gridService = inject(UserService);

  public statuses = [
    { text: 'Active', value: 1 },
    { text: 'Disabled', value: 0 },
  ];
  show: boolean | undefined;

  ngOnInit(): void {
    this.initEffect();
  }

  initEffect(): void {
    this.gridService.read();
  }

  public statusChange($event: { text: string; value: number }): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    let newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== 'is_active');

    if ($event.value !== null) {
      newFilters.push({
        field: 'is_active',
        operator: 'eq',
        value: $event.value,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  public min: Date = new Date(1917, 0, 1);
  public max: Date = new Date(2020, 4, 31);

  public userForm: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    birthDate: new FormControl(new Date(2000, 10, 10)),
    email: new FormControl('', Validators.email),
    acceptNews: new FormControl(),
  });

  public addUser(): void {
    this.togglePopup();
  }

  public editUser($event: CellClickEvent): void {
    this.togglePopup($event.dataItem as IUser);
  }

  public togglePopup(user: IUser = User.createUser()): void {
    const options = {
      animate: { type: 'slide', direction: 'left', duration: 200 },
      anchorAlign: { horizontal: 'right', vertical: 'top' },
      popupAlign: { horizontal: 'right', vertical: 'top' },
    };

    this.openPopup(this.bodyElement, UserComponent, options);

    const userComponent = this.popupRef?.content.instance as UserComponent;

    if (user) userComponent.user.set(user);

    userComponent.close.subscribe(() => {
      this.gridService.read();
      this.closePopup();
    });
  }

  get organizations(): FormArray {
    return this.userForm.get('organizations') as FormArray;
  }
}
