import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BaseComponent } from '../../core/shared/common/base.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends BaseComponent {
  private router = inject(Router);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  public redirectUrl: string | null = null;

  public loginForm: FormGroup = new FormGroup<LoginForm>({
    email: new FormControl<string | null>(null, [Validators.email, this.lowercaseValidator]),
    password: new FormControl<string | null>(null, Validators.required),
  });

  constructor() {
    super();
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      this.redirectUrl = params['redirectUrl'] || params['next'] ;
    });
  }

  public signup() {
    console.log('signup');
  }

  public forgot() {
    this.router.navigate(['auth/forgot-password']);
  }

  private lowercaseValidator(control: FormControl) {
    if (control.value && control.value !== control.value.toLowerCase()) {
      control.setValue(control.value.toLowerCase(), { emitEvent: false });
    }
    return null;
  }

  public login() {
    const email = this.loginForm.get('email')?.value?.toLowerCase();
    const password = this.loginForm.get('password')?.value;

    if (email && password) {
      this.authService
        .login(email, password)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response: any) => {
            const user = this.authService.user;
            user.email = email;
            let responseData;
            if(typeof(response) == 'string')
            {
              const data = window.atob(response);
              responseData = JSON.parse(data);
              this.authService.user.updateUser(responseData);
            }
            if (responseData?.mfa_setup_required === false) {
              user.access_token = responseData.access_token!;
              user.refresh_token = responseData.refresh_token!;
            } else if (response.next_step === 'new_password_for_auth') {
              user.session = response.session!;
              this.router.navigate(['auth/update-password']);
            } else if (response.next_step === 'verify_mfa_for_auth') {
              this.router.navigate(['auth/enter-mfa'], {
                queryParams: {
                  session: response.session,
                  email: email,
                  redirectUrl: this.redirectUrl
                },
              });
            } else if (responseData?.mfa_setup_required) {
              this.router.navigate(['auth/setup-mfa']);
            }
          },
        });
    }
  }

  public onEmailInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const emailControl = this.loginForm.get('email');
    if (emailControl) {
      emailControl.setValue(input.value.toLowerCase());
    }
  }
}

export interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}
