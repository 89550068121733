import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { ServiceAccount } from '../../../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class SoarServiceAccountService extends ODataService<ServiceAccountResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/serviceaccounts`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 25,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public createSoarServiceAccount(request: ServiceAccountRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/serviceaccounts/`;
    return this.http.post<ServiceAccountResponse>(url, request);
  }

  public updateSoarServiceAccount(request: ServiceAccount) {
    const url = `${this.url}`;
    return this.http.patch(url, request);
  }

  public deleteSoarServiceAccount(serviceAccountId: number) {
    const url = `${this.url}?pk=${serviceAccountId}`;
    return this.http.delete(url);
  }
}

export interface ServiceAccountResponse extends ServiceAccount {}

export interface ServiceAccountRequest {
  organization_id: number;
  service_account: ServiceAccount;
}

export interface ServiceAccountResponse extends ServiceAccountRequest {}
