import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-quick-links',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './quick-links.component.html',
  styleUrl: './quick-links.component.scss',
})
export class QuickLinksComponent {}
