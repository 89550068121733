<app-org-appbar></app-org-appbar>
<br />
<kendo-tabstrip>
  <kendo-tabstrip-tab
    #adminorcustomer_organizations_soarrules_traveladvisories
    title="Travel Advisories"
    [selected]="true"
  >
    <ng-template kendoTabContent>
      <app-travel-advisories></app-travel-advisories>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    #adminorcustomer_organizations_soarrules_serviceaccounts
    title="Service Accounts"
  >
    <ng-template kendoTabContent>
      <app-accounts></app-accounts>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    #adminorcustomer_organizations_soarrules_vulnerabilityscanners
    title="Vulnerability Scanners"
  >
    <ng-template kendoTabContent>
      <app-vulnerability></app-vulnerability>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    #adminorcustomer_organizations_soarrules_serverexceptions
    title="Server Exceptions"
  >
    <ng-template kendoTabContent>
      <app-server></app-server>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab
    #adminorcustomer_organizations_soarrules_socbulletins
    title="SOC Bulletins"
  >
    <ng-template kendoTabContent>
      <app-bulletin></app-bulletin>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
