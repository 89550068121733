import { Component, CreateEffectOptions, effect, input, signal } from '@angular/core';
import { AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-sla-violations',
  standalone: true,
  imports: [LoaderModule],
  templateUrl: './sla-violations.component.html',
  styleUrl: './sla-violations.component.scss',
  providers: [AnalyticsService],
})
export class SlaViolationsComponent extends BaseComponent {
  slaData = input<WidgetData>({ value: 0, colors: [] });
  public slaCount = signal<WidgetData>({ value: 0, colors: [] });

  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.slaData();
        if (data && data.value > 0) {
          this.slaCount.set(data);
          this.isLoading.set(false);
        } else {
          this.slaCount.set({ value: 0, colors: [] });
          this.isLoading.set(false);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }
}
