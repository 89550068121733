import { Component } from '@angular/core';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-admin-appbar',
  standalone: true,
  imports: [NavigationModule, ButtonsModule, IndicatorsModule, BreadcrumbComponent],
  templateUrl: './admin-appbar.component.html',
  styleUrl: './admin-appbar.component.scss',
})
export class AdminAppbarComponent {
  actions: Array<any> = [{ text: 'Action 1' }, { text: 'Action 2' }, { text: 'Action 3' }];
}
