<kendo-grid [data]="gridData" [style.height]="'100%'">
    <kendo-grid-column
      field="channel"
      title="Channel"
      [width]="100"
    ></kendo-grid-column>
    <kendo-grid-column
      field="users"
      title="Users"
      [width]="80"
      format="n0"
    ></kendo-grid-column>
  </kendo-grid>