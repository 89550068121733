<div class="date-filter">
  <div>
    <app-cyflare-period
      [(dateRange)]="selectedDateRange"
      label="Filter Analytics:"
    ></app-cyflare-period>
  </div>
</div>
<div class="container p-3">
  <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="tile">
          <div class="tile-title">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <div style="flex: 1; display: flex; justify-content: center">
                <h5>
                  ADR Vs Manual
                  <span
                    kendoTooltip
                    tooltipClass="custom-tooltip"
                    title="The comparison between alerts that needed manual intervention and those automatically triaged by Cyflare playbooks."
                  >
                    <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                  </span>
                </h5>
              </div>
              <kendo-dropdownbutton
                #adrmanualMoreButton
                kendoButton
                [svgIcon]="moreVerticalIcon"
                class="transparent-button"
                [data]="[{ text: 'View Data', name: 'adrmanual' }]"
                (itemClick)="onContextMenuSelect($event)"
              ></kendo-dropdownbutton>
            </div>
          </div>
          <app-adr-manual
            [organizationId]="organizationId"
            [adrManualData]="adrManualData()"
            [dateRange]="dateRange"
          ></app-adr-manual>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="tile">
          <div class="tile-title">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <div style="flex: 1; display: flex; justify-content: center">
                <h5>
                  Escalation Relevancy
                  <span
                    kendoTooltip
                    tooltipClass="custom-tooltip"
                    title="Indication of whether the escalation was important to you (True Positive) or not (False Positive). 'Unknown' indicates no response was received from you or your team."
                  >
                    <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                  </span>
                </h5>
              </div>
              <kendo-dropdownbutton
                #adrmanualMoreButton
                kendoButton
                [svgIcon]="moreVerticalIcon"
                class="transparent-button"
                [data]="[{ text: 'View Data', name: 'escalationrelevancy' }]"
                (itemClick)="onContextMenuSelect($event)"
              ></kendo-dropdownbutton>
            </div>
          </div>
          <app-outcome-status
            [organizationId]="organizationId"
            [data]="outcomeStatusData()"
            [dateRange]="dateRange"
          ></app-outcome-status>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Ticket Closure Breakdown
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="Displays the number of alerts where Cyflare needed your input to classify the alert."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-ticket-closure-breakdown [ticketClosureBreakdownData]="ticketClosureData()"></app-ticket-closure-breakdown>
        </div>
      </div>
    </div>
  </div>
  <div class="row g-3">
    <div class="col-lg-6 col-md-12 mx-auto">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                SLA Adherence
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Provides insight into how well CyFlare is meeting agreed disposition times for both SOAR cases and escalated tickets"
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #slaMoreButton
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'sla' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        <app-sla-adherence
          [organizationId]="organizationId"
          [slaData]="slaVoilationsData()"
          [dateRange]="dateRange"
        ></app-sla-adherence>
      </div>
    </div>
  </div>
  <div class="row g-3 p-3">
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Mean Time To Resolve (MTTR)
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="The time span between the initial ticket creation and final closure classification when your input was necessary. A high MTTR may suggest delays caused by higher complexity events or extended communication or slower responses from your team."
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #customer_analytics_mttrviewdata
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'mttr' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        @if (averageMttr) {
          <div class="card-content">
            <kendo-card
              width="auto"
              style="padding-left: 10px; padding-right: 10px"
            >
              <div>
                <h5 class="card-text">Average: {{ averageMttr }} Hours</h5>
              </div>
            </kendo-card>
          </div>
        } @else {
          <div class="card-content">
            <kendo-card
              width="auto"
              style="padding-left: 10px; padding-right: 10px"
            >
              <div>
                <h5 class="card-text">Average: No Date</h5>
              </div>
            </kendo-card>
          </div>
        }
        <app-mttr
          [organizationId]="organizationId"
          [mttrData]="mttrData()"
          [dateRange]="dateRange"
        ></app-mttr>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Meantime to Disposition (MTTD) by Case Priority
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="The time span between the initial alert notification to the SOC and the decision to either close the alert or escalate as a ticket to your incident handler for additional input."
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #customer_analytics_mttrviewdata
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'mttr' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        @if (averageMttd) {
          <div class="card-content">
            <kendo-card
              width="auto"
              style="padding-left: 10px; padding-right: 10px"
            >
              <div>
                <h5 class="card-text">Average: {{ averageMttd }} Minutes</h5>
              </div>
            </kendo-card>
          </div>
        } @else {
          <div class="card-content">
            <kendo-card
              width="auto"
              style="padding-left: 10px; padding-right: 10px"
            >
              <div>
                <h5 class="card-text">Average: No Date</h5>
              </div>
            </kendo-card>
          </div>
        }
        <app-mttd
          [organizationId]="organizationId"
          [dateRange]="dateRange"
          [mttdData]="mttdData()"
          (caseFilterChanged)="onCaseEscalatedCheckboxChanged($event)"
        ></app-mttd>
      </div>
    </div>
  </div>

  <div class="row g-3">
    <div class="col-lg-12 col-md-12">
      <div class="tile">
        <div class="tile-title">
          <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="flex: 1; display: flex; justify-content: center">
              <h5>
                Top Detections
                <span
                  kendoTooltip
                  tooltipClass="custom-tooltip"
                  title="Events that triggered an alert, listed in order from most to least for the selected period."
                >
                  <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                </span>
              </h5>
            </div>
            <kendo-dropdownbutton
              #top_detections_viewdata
              kendoButton
              [svgIcon]="moreVerticalIcon"
              class="transparent-button"
              [data]="[{ text: 'View Data', name: 'topdetections' }]"
              (itemClick)="onContextMenuSelect($event)"
            ></kendo-dropdownbutton>
          </div>
        </div>
        <app-top-detections 
          [organizationId]="organizationId"
          [dateRange]="dateRange"
          [topDetectionsData]="topDetectionsData">
        </app-top-detections>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-6 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Top 5 Suspect Users
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="Usernames that were part of an alert, listed most to least for the selected period."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-suspect-users
            (seriesClick)="onSuspectUsersSeriesClick($event)"
            [topSuspectUsersData]="topSuspectUsersData()"
            [topUserDetailData]="topUserDetailData()"
          ></app-suspect-users>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Top 5 Suspect Assets
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="Devices or device IPs that were part of an alert, listed most to least for the selected period."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-suspect-assets
            (seriesClick)="onSuspectAssetsSeriesClick($event)"
            [topSuspectAssetsData]="topSuspectAssetsData"
            [topAssetDetailData]="topAssetDetailData"
          ></app-suspect-assets>
        </div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-12 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Detections Disposition
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="The final outcome status of closed tickets for the selected period."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-disposition-column [dispositionData]="dispositionData"></app-disposition-column>
        </div>
      </div>
    </div>
    <div class="row g-3">
      <div class="col-lg-6 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Destination GeoLocations
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="The physical location where a potential threat or suspicious activity is aimed."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-destination-map [data]="destinationMapData"></app-destination-map>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <h5>
              Source GeoLocations
              <span
                kendoTooltip
                tooltipClass="custom-tooltip"
                title="The physical location where a potential threat or suspicious activity originated from."
              >
                <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
              </span>
            </h5>
          </div>
          <app-source-map [data]="sourceMapData"></app-source-map>
        </div>
      </div>
    </div>
  </div>
</div>
