

<form [formGroup]="integrationForm" class="k-form k-form-md" (ngSubmit)="saveConnection()">
  <div class="k-form-buttons">
    <h2 class="k-form-title">ConnectWise</h2>
  </div>
  @if (formInitilized()) {
  <div class="row">
    <div class="col-6">
      <fieldset class="k-form-fieldset">
        <kendo-label
            labelCssClass="k-form-label"
            [for]="application_key"
            text="Application Key"
          ></kendo-label>
          <kendo-textbox
            formControlName="ticket_integration_secret"
            #application_key
          ></kendo-textbox>
      </fieldset>
    </div>
  </div>
  <hr/>
    <fieldset>
      <div class="row field-mappings">
        <div class="col-12" formArrayName="fieldMappings">
          <div class="row mt-5">
            <div class="col-6">Cyflare Ticket Fields</div>
            <div class="col-6">ConnectWise Fields</div>
          </div>
          <div *ngFor="let itemControl of integrationUtil.filterByControlName('ticket')">
            <div [formGroup]="itemControl" class="row">
              <div class="col-6">
                <kendo-formfield>
                  <kendo-textbox formControlName="one_field_value"></kendo-textbox>
                </kendo-formfield>
              </div>
              <div class="col-6">
                <kendo-formfield>
                  <kendo-textbox formControlName="integration_field_value"></kendo-textbox>
                </kendo-formfield>              
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="k-form-buttons">
                <button kendoButton themeColor="primary" type="button" (click)="testConnection()" [disabled]="integrationForm.invalid">
                  Test Connection
                </button>
              </div>
                <p *ngIf="testConnectionMessage">{{testConnectionMessage}}</p>
              <div class="k-form-buttons">
                <button kendoButton themeColor="primary" type="submit" [disabled]="integrationForm.invalid">
                  Save Connection
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  }
</form>
