<div class="tile">
  <div class="tile-title">
    <h5 class="title">Tickets</h5>
  </div>
  <div class="content">
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else if (ticketCount().value !== null) {
      <h1>{{ ticketCount().value }}</h1>
    } @else {
      <h1>No Data</h1>
    }
  </div>
</div>
