import { Component, effect, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, EditEvent, GridModule, GridComponent, SaveEvent, CancelEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { ErrorResponse, TravelAdvisoryRequest, TravelAdvisoryService } from './services/travel-advisory.service';
import { environment } from '../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { CountryListComponent } from './services/countrylist';
import { TravelAdvisoryForm, TravelAdvisoryFormService } from './services/travel-advisory-form.service';
import { TravelAdvisory } from '../../../../../../../core/models/entities';
import { filter, throwError } from 'rxjs';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-travel-advisories',
  standalone: true,
  imports: [CommonModule, NotificationModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, GridModule, ReactiveFormsModule, FormsModule, CountryListComponent],
  templateUrl: './travel-advisories.component.html',
  styleUrl: './travel-advisories.component.scss',
  providers: [CountryListComponent],
})
export class TravelAdvisoriesComponent extends BaseComponent {
  public formGroup: FormGroup<TravelAdvisoryForm> | undefined;
  public formBuilder = inject(FormBuilder);
  public gridService = inject(TravelAdvisoryService);
  public route = inject(ActivatedRoute);
  public notificationService = inject(NotificationService);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/traveladvisory`;
  private formService = inject(TravelAdvisoryFormService);
  public serviceAccounts = signal<TravelAdvisory[]>([]);
  private editedRowIndex: number | undefined;
  public selectedCountry: string = '';
  public data: Array<{ text: string; value: string }>;
  public listData = inject(CountryListComponent);

  constructor() {
    super();
    this.initEffect();
    this.data = this.listData.countries.slice();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadTravelAdvisory(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private showNotification(message: string, type: 'success' | 'error') {
    this.notificationService.show({
      content: message,
      type: { style: type, icon: true },
      position: { horizontal: 'center', vertical: 'top' },
    });
  }

  private loadTravelAdvisory(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }

  public addHandler($event: AddEvent): void {
    this.closeEditor($event.sender);
    this.formGroup = this.formService.createTravelAdvisoryForm();
    $event.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (this.formGroup) {
      grid.closeRow(rowIndex);
      this.formGroup = undefined;
    }
  }

  handleFilter(value: string) {
    this.data = this.listData.countries.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public saveHandler($event: SaveEvent): void {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      console.log('Form Value:', formValue);
      console.log('Destination:', formValue.destination);

      const startDate = formValue.start_date ? new Date(formValue.start_date) : null;
      const endDate = formValue.end_date ? new Date(formValue.end_date) : null;

      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd HH:mm:ss') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd HH:mm:ss') : null;

      const payload: TravelAdvisoryRequest = {
        organization_id: this.id,
        travel_advisory: {
          pk: $event.isNew ? null : formValue?.pk!,
          email: formValue.email!,
          destination: { name: formValue.destination || '' },
          start_date: formattedStartDate!,
          end_date: formattedEndDate!,
        },
      };

      if ($event.isNew) {
        this.gridService.createTravelAdvisory(payload).subscribe({
          next: (response) => {
            this.loadTravelAdvisory(this.id);
            $event.sender.closeRow($event.rowIndex);
            this.showNotification('Travel Advisory created successfully', 'success');
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.gridService.updateTravelAdvisory(payload.travel_advisory).subscribe({
          next: (response) => {
            this.loadTravelAdvisory(this.id);
            $event.sender.closeRow($event.rowIndex);
            this.showNotification('Travel Advisory updated successfully', 'success');
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    }
  }

  public editHandler($event: EditEvent): void {
    const dataItem = $event.dataItem;

    const transformedDataItem = {
      ...dataItem,
      start_date: dataItem.start_date ? parseISO(dataItem.start_date) : null,
      end_date: dataItem.end_date ? parseISO(dataItem.end_date) : null,
    };

    this.formGroup = this.formService.createTravelAdvisoryForm(transformedDataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler($event: any): void {
    this.gridService.deleteTravelAdvisory($event.dataItem.pk).subscribe(() => {
      this.loadTravelAdvisory(this.id);
      this.showNotification('Travel Advisory Deleted', 'success');
    });
  }
}
