<app-org-appbar></app-org-appbar>
<br />

<kendo-tilelayout [columns]="1">
    <kendo-tilelayout-item title="White Labeling Configuration" [col]="1">
        <kendo-tilelayout-item-body>
            @if (filename()) {
                <img [src]="filename()" alt="Logo" class="logo-image" />
                <hr class="mt-3 mb-3">
            }
            <form [formGroup]="whiteLabelForm" novalidate
                (ngSubmit)="save(whiteLabelForm.value.files, whiteLabelForm.valid)">
                <kendo-label text="Custom Logo Image Upload" for="files">
                    <kendo-fileselect class="mt-3" required name="files" #files
                        [restrictions]="restrictions" [formControl]="whiteLabelForm.controls.files" [multiple]="false">
                    </kendo-fileselect>
                </kendo-label>
                <p style="color: red; font-size: .8em; margin-top: .5em;"
                    [hidden]="whiteLabelForm.controls.files.valid || (whiteLabelForm.controls.files.pristine && !submitted)">
                    Selecting a file is required.
                </p>
                <button kendoButton themeColor="primary" type="submit" class="mt-4" [disabled]="!whiteLabelForm.valid"
                    (click)="save(whiteLabelForm.value.files, whiteLabelForm.valid)">
                    Upload
                </button>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>