import 'hammerjs';
import { Component } from '@angular/core';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { GridModule } from "@progress/kendo-angular-grid";
import { PageViewsComponent } from "./components/page-views/page-views.component";
import { MostVisitedComponent } from "./components/most-visited/most-visited.component";
import { ConversionComponent } from "./components/conversion/conversion.component";
import { VisitorsComponent } from "./components/visitors/visitors.component";
import { UsersComponent } from "./components/users/users.component";
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { BaseComponent } from '../../../core/shared/common/base.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    LayoutModule,
    ChartsModule,
    GridModule,
    PageViewsComponent,
    MostVisitedComponent,
    ConversionComponent,
    VisitorsComponent,
    UsersComponent,
    AdminAppbarComponent
  ],
  templateUrl: './dashboard.page.html',
  styleUrl: './dashboard.page.scss'
})
export class DashboardPage extends BaseComponent {

}
