import { AfterViewInit, Component, EventEmitter, Output, Renderer2, inject } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { fromEvent, debounceTime } from 'rxjs';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-analyst-pdi',
  standalone: true,
  imports: [LayoutModule, NavigationModule, ButtonsModule, DialogsModule],
  templateUrl: './analyst-pdi.component.html',
  styleUrl: './analyst-pdi.component.scss',
})
export class AnalystPdiComponent extends BaseComponent implements AfterViewInit {
  @Output() close = new EventEmitter<void>();
  private renderer = inject(Renderer2);
  public opened = false;

  caseDetails = [
    {
      alert_generated_time: '7/2/2024 12:00:12 UTC-4',
      case_created_time: '7/2/2024 12:05:12 UTC-4',
      case_assigned_time: '7/2/2024 12:06:34 UTC-4',
      ticket_creation_time: '7/2/2024 12:15:45 UTC-4',
      ticket_number: '12345',
      case_closed_time: '7/2/2024 12:15:45 UTC-4',
      case_name: 'Login outside the US',
      case_source: 'Stellar Cyber',
      org_name: 'CyFlare',
      msp_name: 'CyFlare',
    },
  ];

  threatHunting = {
    stellar_searches: [
      {
        query: 'Search for alerts by SourceIP in the last 24hrs -> the step name from PB action',
        success: 'true',
        result: '12  -> the count of result JSON from PB action',
      },
      {
        query: 'Search for Windows events by SourceIP with failed login attempt in the last 24hrs  -> the step name from PB action',
        success: 'true',
        result: '120  -> the count of result JSON from PB action',
      },
      {
        query: 'Search for traffic events by SourceIP to the destination IP in the last 24hrs  -> the step name from PB action',
        success: 'true',
        result: '42   -> the count of result JSON from PB action',
      },
    ],
    sentinelOne_searches: [
      {
        query: 'Search for alerts by SourceIP in the last 24hrs',
        success: 'true',
        result: '12',
      },
      {
        query: 'Search for Windows events by SourceIP with failed login attempt in the last 24hrs',
        success: 'true',
        result: '120',
      },
      {
        query: 'Search for traffic events by SourceIP to the destination IP in the last 24hrs',
        success: 'true',
        result: '42',
      },
    ],
    vicarius: [
      {
        Entity: '192.168.1.1',
        entity_type: 'ip',
        vrx_host_info: {
          hostname: 'TEST_machine',
          active_CVEs: '12',
          risk_score: '45',
          OS: 'Windows 10 Pro',
          patches_available: '5',
        },
      },
    ],
  };

  constructor() {
    super();
  }

  ngAfterViewInit() {
    this.initScrollEffects('summary-card');
    this.initScrollEffects('details-card');
    this.initScrollEffects('chronology-card');
  }

  public open(): void {
    this.opened = true;
  }

  public closeDialog(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  scrollToCard(cardId: string): void {
    document.getElementById(cardId)?.scrollIntoView({ behavior: 'smooth' });
  }

  initScrollEffects(cardId: string): void {
    const cardBody = this.renderer.selectRootElement(`#${cardId} .scrollable-card-body`, true);
    const scrollIndicator = this.renderer.selectRootElement(`#${cardId}-scroll-indicator`, true);

    this.updateIndicator(cardBody, scrollIndicator);

    fromEvent(cardBody, 'scroll')
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.updateIndicator(cardBody, scrollIndicator);
      });
  }

  updateIndicator(cardBody: HTMLElement, scrollIndicator: HTMLElement): void {
    if (cardBody.scrollTop === 0) {
      this.renderer.setStyle(scrollIndicator, 'opacity', '1');
    } else {
      this.renderer.setStyle(scrollIndicator, 'opacity', '0');
    }

    if (cardBody.scrollHeight - cardBody.scrollTop <= cardBody.clientHeight) {
      this.renderer.setStyle(scrollIndicator, 'display', 'none');
    } else {
      this.renderer.setStyle(scrollIndicator, 'display', 'block');
    }
  }

  checkScroll(event: any, cardId: string): void {
    const element = event.target;
    const topIndicator = document.getElementById(`${cardId}-top-indicator`);
    const bottomIndicator = document.getElementById(`${cardId}-bottom-indicator`);

    if (element.scrollTop === 0) {
      topIndicator!.style.display = 'none';
    } else {
      topIndicator!.style.display = 'block';
    }

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      bottomIndicator!.style.display = 'none';
    } else {
      bottomIndicator!.style.display = 'block';
    }
  }

  public closePopup(): void {
    this.close.emit();
  }
}
