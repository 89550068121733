<app-admin-appbar></app-admin-appbar><br />
<kendo-tilelayout
  [columns]="4"
  [rowHeight]="255"
  [resizable]="true"
  [reorderable]="true"
>
  <kendo-tilelayout-item
    title="Customers in the Yellow"
    [col]="1"
    [colSpan]="3"
  >
    <kendo-tilelayout-item-body> </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="New Customers MTD"
    [col]="4"
  >
    <kendo-tilelayout-item-body>
      <h3>9%</h3>
      <div>Visitor to Customer</div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="Most Visited Pages"
    [col]="1"
    [colSpan]="2"
  >
    <kendo-tilelayout-item-body>
      <app-most-visited></app-most-visited>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="Active Users"
    [col]="3"
  >
    <kendo-tilelayout-item-body>
      <h3>2399</h3>
      <div>Active right now</div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="TP vs FP"
    [col]="4"
    [rowSpan]="2"
  >
    <kendo-tilelayout-item-body>
      <app-visitors></app-visitors>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="Customers by Industry"
    [col]="1"
    [colSpan]="2"
    [rowSpan]="2"
  >
    <kendo-tilelayout-item-body>
      <app-users></app-users>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="Incidents YTD"
    [col]="3"
  >
    <kendo-tilelayout-item-body>
      <h3>8</h3>
      <div>The number of total escilations resulting in Confirmed Incident outcome</div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>

  <kendo-tilelayout-item
    title="XDR Event Distillation"
    [col]="3"
    [colSpan]="2"
    [rowSpan]="2"
  >
    <kendo-tilelayout-item-body>
      <app-conversion></app-conversion>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>  
</kendo-tilelayout>
