<kendo-grid
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
  [filterable]="false"
>
  <kendo-grid-column
    field="connector_name"
    title="Connection Name"
  ></kendo-grid-column>
  <kendo-grid-column
    field="duration"
    title="Duration"
  ></kendo-grid-column>
</kendo-grid>
