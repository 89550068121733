<form
  class="form-signin"
  [formGroup]="enterMfaForm"
>
  <div class="logo-container">
    <img src="/assets/logos/cyflare_one_logo.png" />
  </div>
  <div class="header-container">
    <h3>Setup Two-Factor Authentication</h3>
  </div>
  <p class="description">Please download an authenticator app of your choice on your iOS or Android mobile device then use the app to scan the QR code below.</p>

  <!-- QR CODE Image goes here. Temp image placed below for visual reference -->
  <div style="display: flex; flex-direction: row; gap: 30px">
    <div class="qr-code">
      <img
        [src]="image()"
        alt="QR Code Image"
        class="qr-code-image"
      />
    </div>
    <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
      <div>
        <kendo-floatinglabel text="Enter One Time Code">
          <input
            kendoTextBox
            class="form-control"
            formControlName="oneTimeCode"
          />
        </kendo-floatinglabel>
      </div>
      <div class="d-grid gap-2 mt-4">
        <button
          kendoButton
          themeColor="primary"
          (click)="setupMfa()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
  <p class="description">Alternatively, type the following code into your app:</p>
  <div>
    <kendo-textarea
      fillMode="flat"
      [readonly]="true"
      [value]="secretCode() ?? ''"
      placeholder="QR CODE STRING"
      [style.width.px]="300"
    ></kendo-textarea>
  </div>

  <div style="display: flex; flex-direction: row; gap: 30px">
    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        (click)="back()"
      >
        Go Back
      </button>
    </div>
  </div>
  <div class="mt-2">
    <div class="d-flex justify-content-between">
      <div class="form-check d-flex align-items-center">
        <label
          class="form-check-label"
          for="rememberMe"
          >Having trouble signing in?</label
        >
      </div>
      <div>
        <a
          href="mailto:support@cyflare.com"
          class="text-primary"
          kendoButton
          fillMode="clear"
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
</form>
