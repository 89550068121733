@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (threatStatus().length > 0) {
  <kendo-chart class="chart-container">
    <kendo-chart-series>
      <kendo-chart-series-item
        [autoFit]="autofit"
        type="donut"
        [data]="threatStatus()"
        categoryField="description"
        field="count"
      >
        <kendo-chart-series-item-labels
          align="circle"
          position="outsideEnd"
          [content]="labelContent"
          color="#000"
          background="none"
        >
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
  <kendo-dialog
      *ngIf="showPopup"
      (close)="close()"
    >
      <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
      <kendo-grid
        class="mxh-80v"
        [filterable]="true"
        [data]="gridService | async"
        [pageable]="{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [25, 50, 100, 200]
        }"
        [pageSize]="gridService.state.take"
        [skip]="gridService.state.skip"
        [(filter)]="gridService.state.filter"
        [loading]="gridService.loading"
        (dataStateChange)="gridService.onStateChange($event)"
        [sort]="this.gridService.state.sort"
        [sortable]="true"
        [resizable]="true"
      >
        <ng-template kendoGridToolbarTemplate>
          <button
            type="button"
            kendoGridExcelCommand
          >
            Export to Excel
          </button>
        </ng-template>
        <kendo-grid-column
          [filterable]="false"
          field="timestamp"
          title="Lastest Observed Time"
        ></kendo-grid-column>
        <kendo-grid-column
          [filterable]="false"
          field="created_at"
          title="Threat Initially Reported"
        ></kendo-grid-column>
        <kendo-grid-column
          field="threat_name"
          title="Threat"
        ></kendo-grid-column>
        <kendo-grid-column
          field="endpoint_name"
          title="Endpoint"
        ></kendo-grid-column>
        <kendo-grid-column
          field="mitigation_status"
          title="Status"
        >
          <ng-template kendoGridFilterCellTemplate>
            <kendo-dropdownlist
              [defaultItem]="{ text: 'All', value: null }"
              textField="text"
              valueField="value"
              (valueChange)="statusChange($event, 'mitigation_status')"
              [data]="threatStatuses$ | async"
            ></kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-excel
          fileName="edr-threat-status-data.xlsx"
          [fetchData]="allData"
        ></kendo-grid-excel>
      </kendo-grid>
    </kendo-dialog>
} @else {
  <h1>No Data</h1>
}
