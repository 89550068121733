import { Component, CreateEffectOptions, Input, Signal, effect, inject, input, signal } from '@angular/core';
import { ChartsModule, SeriesLabels } from '@progress/kendo-angular-charts';
import { TopFiveData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule, ExcelModule, GridDataResult } from '@progress/kendo-angular-grid';
import { MenusModule } from '@progress/kendo-angular-menu';
import { TopDetectionsDetailsService } from '../sla-adherence/services/topdetectionsdetails.service';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-top-detections',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule, GridModule, DialogsModule, ExcelModule, MenusModule],
  templateUrl: './top-detections.component.html',
  styleUrl: './top-detections.component.scss',
    providers: [TopDetectionsDetailsService],
})
export class TopDetectionsComponent extends BaseComponent {  
  public popupService = inject(ChartLabelsPopupService);
  @Input() topDetectionsData!: Signal<TopFiveData[]>;
  public gridService = inject(TopDetectionsDetailsService);

  public isLoading = signal(true);
  public ticketsByEventName = signal<TopFiveData[]>([]);

  public labels = {
    rotation: -25,
  };

  public seriesLabels: SeriesLabels = {
    visible: true,
    padding: 1,
    font: 'bold 10px Arial, sans-serif',
  };
  public colors: string[] = ['#6F42C1', '#7A54CB', '#8466D5', '#8F79DF', '#9A8BE9', '#A59DF3', '#B0AFFD', '#BBBFFF', '#C5D2FF', '#D0E4FF'];

  public getColor = (point: any): string => {
    return this.colors[point.index];
  };  
  public organizationId = input<number | null>();
  public dateRange = input<string[] | null>(null);

  public showPopup = false;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const userDetailData = this.topDetectionsData();
        if (userDetailData) {
          this.ticketsByEventName.set(userDetailData.slice(0, 10) || []);
          this.isLoading.set(false);
        } else {
          this.ticketsByEventName.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.setDateRange(this.dateRange());
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/slaviolationsdetails(${this.organizationId()})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }
  
  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };
  
  
  // Close popup
  close() {
    this.showPopup = false;
  }
}
