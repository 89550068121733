import { CommonModule } from '@angular/common';
import { Component, inject, Input, signal, ViewChild } from '@angular/core';
import { ContextService, ExcelModule, GridComponent, GridDataResult, GridModule, PDFModule, PDFService } from '@progress/kendo-angular-grid';
import { TicketsService } from '../../../../tickets-log/services/tickets.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { BaseComponent } from '../../../../../../core/shared/common/base.component';
import { ActivatedRoute } from '@angular/router';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-cases',
  standalone: true,
  imports: [GridModule, CommonModule, LoaderModule, ExcelModule, PDFModule],
  templateUrl: './cases.component.html',
  styleUrl: './cases.component.scss',
  providers: [TicketsService, PDFService, ContextService],
})
export class CasesComponent extends BaseComponent {
  public gridService = inject(TicketsService);
  public organizationId: number | null = null;
  private route = inject(ActivatedRoute);
  @ViewChild('grid', { static: false }) grid!: GridComponent;

  private sanitizer = inject(DomSanitizer);
  headerHtml!: SafeHtml;
  @Input() set htmlContent(value: string) {
    this.headerHtml = this.sanitizer.bypassSecurityTrustHtml(value);
  }
  public landscape = false;
  public fileName: string = '';

  public selectedDateRange = signal<string[]>([]);
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  constructor() {
    super();
  }

  ngOnInit() {
    this.initEffect();
  }

  private initEffect() {
    this.route.queryParams.subscribe((params) => {
      this.organizationId = params['organizationId'];
    });
    this.applyFilter();
  }

  public exportPDF(fileName: string) {
    this.fileName = `${fileName}.pdf`;
    this.grid.saveAsPDF();
  }

  public exportExcel(fileName: string) {
    this.fileName = `${fileName}.xlsx`;
    this.grid.saveAsExcel();
  }

  applyFilter() {
    const dateRange = this.selectedDateRange();
    if (!dateRange) return;

    const field = 'created_time';
    this.filter.filters.push({
      field: field,
      operator: 'gt',
      value: dateRange[0],
    });

    this.filter.filters.push({
      field: field,
      operator: 'lt',
      value: dateRange[1], //
    });

    // Update the grid filter state
    this.gridService.state.filter = {
      logic: 'and',
      filters: this.filter.filters,
    };

    this.gridService.getTickets(this.organizationId);
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };
}
