import { Component, ComponentRef, effect, inject, signal } from '@angular/core';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ConnectwiseComponent } from './components/connectwise/connectwise.component';
import { ServicenowComponent } from './components/servicenow/servicenow.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { PopupHostComponent } from '../../../../../core/components/popup-host.component';
import { IngestorIntegration, IntegrationService } from '../../../../../core/services/integration.service';
import { ActivatedRoute, RouterOutlet, Router, NavigationExtras } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { combineLatestWith, map } from 'rxjs';
import { IngestorSourcesComponent } from './components/ingestors/ingestor-sources/ingestor-sources.component';

type IntegrationType = 'connectWise' | 'serviceNow';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [OrgAppbarComponent, LayoutModule, IndicatorsModule, PopupModule, ButtonsModule, CommonModule, DialogsModule, ServicenowComponent, ConnectwiseComponent, IngestorSourcesComponent, RouterOutlet],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss',
  providers: [IntegrationService],
})
export class IntegrationsComponent extends PopupHostComponent {
  private integrationService = inject(IntegrationService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  show: boolean | undefined;
  activeIntegration = signal<any | null>(null);
  selectedIngestorIntegrationType: any;
  componentToLoad: ComponentRef<any> | undefined;
  organizationId: number;
  saveConfirmDialog = false;
  ingestorIntegrations = signal<IngestorIntegrationWithComponent[]>([]);

  constructor() {
    super();
    const queryParams = this.route.snapshot.queryParams;
    this.organizationId = parseInt(queryParams['organizationId']);
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.getActiveIntegration();
      this.getIngestors();
    });
  }

  getActiveIntegration() {
    this.integrationService
      .getActiveIntegration(this.organizationId)
      .pipe(combineLatestWith(this.integrationService.getActiveIntegrationFieldValues(this.organizationId)))
      .subscribe({
        next: ([activeIntegrationResponse, fieldMappingResponse]: any) => {
          if (activeIntegrationResponse && activeIntegrationResponse.pk) {
            activeIntegrationResponse.fieldMappings = fieldMappingResponse;
            this.activeIntegration.set(activeIntegrationResponse);
          }
        },
        error: (error) => {
          console.error('Error fetching existing connection:', error);
        },
      });
  }

  isIntegrationActive(integrationName: IntegrationType) {
    return this.activeIntegration()?.ticket_integration_name.toLowerCase() == integrationName.toLowerCase();
  }

  isDisabled(integrationName: IntegrationType) {
    return this.activeIntegration()?.ticket_integration_name && !this.isIntegrationActive(integrationName);
  }

  onSave() {
    this.openSaveConfirmDialog();
    setTimeout(() => {
      this.getActiveIntegration();
    }, 600);
  }

  getIngestors() {
    this.integrationService
      .getIngestorIntegrations()
      .pipe(
        map((data) => {
          return data.map((x) => {
            if (x.name.toLowerCase() === 'connectwise' || x.name.toLowerCase() === 'servicenow') {
              return {
                id: x.id,
                name: x.name,
                icon: x.icon.replace(/^.*media\//, 'assets/logos/'),
                component: x.name.toLowerCase() === 'connectwise' ? ConnectwiseComponent : ServicenowComponent,
                integrationType: x.name.toLowerCase() === 'connectwise' ? 'connectWise' : 'serviceNow',
                isRegularIntegration: true,
              };
            }
            return {
              id: x.id,
              name: x.name,
              icon: x.icon.replace(/^.*media\//, 'assets/logos/'),
              component: IngestorSourcesComponent,
              isRegularIntegration: false,
            };
          });
        }),
      )
      .subscribe((data) => {
        this.ingestorIntegrations.set(data);
        this.selectedIngestorIntegrationType = data[0];
        this.router.navigate(['./ingestor-sources'], {
          relativeTo: this.route,
          queryParams: { ingestorIntegrationId: data[0].id },
          queryParamsHandling: 'merge'
        });
      });
  }

  public addIntegration(integrationType: IntegrationType): void {
    const integrationMap = this.ingestorIntegrations().filter((x) => x.isRegularIntegration);
    if (!this.isDisabled(integrationType)) {
      this.selectedIngestorIntegrationType = integrationMap.find((x) => x.integrationType == integrationType);
    }
  }

  public ingestorIntegrationClick(ingestor: any): void {
    this.selectedIngestorIntegrationType = {
      id: ingestor.id,
      integrationType: ingestor.integrationType,
      name: ingestor.name,
      component: ingestor.component,
    };

    const navigationExtras: NavigationExtras = {
      relativeTo: this.route,
      queryParams: { ingestorIntegrationId: this.selectedIngestorIntegrationType.id },
      queryParamsHandling: 'merge'
    };

    if (ingestor.integrationType === 'serviceNow') {
      this.router.navigate(['./servicenow'], navigationExtras);
    } else if (ingestor.integrationType === 'connectWise') {
      this.router.navigate(['./connectwise'], navigationExtras);
    } else {
      this.router.navigate(['./ingestor-sources'], navigationExtras);
    }
  }

  public openSaveConfirmDialog(): void {
    this.saveConfirmDialog = true;
  }

  public closeDialog(status: string): void {
    this.saveConfirmDialog = false;
  }
}

export interface IngestorIntegrationWithComponent extends IngestorIntegration {
  component?: any;
  isRegularIntegration?: boolean;
  integrationType?: string;
}
