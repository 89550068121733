<kendo-grid
  #grid
  [height]="600"
  [data]="gridService | async"
  [filterable]="false"
  [skip]="gridService.state.skip"
  [loading]="gridService.loading"
  [sortable]="false"
  (dataStateChange)="gridService.onStateChange($event)"
  [(filter)]="gridService.state.filter"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="gridService.state.take"
>
  <kendo-grid-column
    [width]="200"
    field="timestamp"
    title="Timestamp"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="msg_class"
    title="Activity Type"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="operation"
    title="Event Name"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="source_ip"
    title="Source IP"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="filename"
    title="Filename"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="file_extension"
    title="File Extension"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="file_relative_url"
    title="File URL"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="file_size_in_bytes"
    title="File Size (Bytes)"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="application_name"
    title="Application"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="action_performed_by_username"
    title="Activity By"
  ></kendo-grid-column>
  <kendo-grid-pdf
    #pdfExport
    [fileName]="fileName"
    [allPages]="true"
    paperSize="A4"
    [scale]="0.4"
    [landscape]="landscape"
    [repeatHeaders]="true"
  >
    <kendo-grid-pdf-margin
      top="3.5cm"
      left="1cm"
      right="1cm"
      bottom="2cm"
    ></kendo-grid-pdf-margin>
    <ng-template
      kendoGridPDFTemplate
      let-pageNum="pageNum"
      let-totalPages="totalPages"
    >
      <div class="page-template">
        <div class="header">
          <div [innerHTML]="headerHtml"></div>
        </div>
        <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
  <kendo-grid-excel
    [fileName]="fileName"
    [fetchData]="allData"
  ></kendo-grid-excel>
</kendo-grid>
