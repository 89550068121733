import { Component, effect, inject, signal } from '@angular/core';
import { AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { filter, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-progress-tickets-widget',
  standalone: true,
  imports: [LoaderModule],
  templateUrl: './progress-tickets-widget.component.html',
  styleUrl: './progress-tickets-widget.component.scss',
  providers: [AnalyticsService],
})
export class ProgressTicketsWidgetComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);

  public inProgressTicketData = signal<WidgetData>({ value: 0, colors: [] });
  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => {
            return !!params['organizationId'];
          }),
          switchMap((params) => {
            return this.analyticsService.getInProgressTickets(params['organizationId']);
          }),
        )
        .subscribe({
          next: (data) => {
            this.inProgressTicketData.set(data);
            this.isLoading.set(false);
          },
          error: (error) => {
            console.error(error);
            this.isLoading.set(false);
          },
        });
    });
  }
}
