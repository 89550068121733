import { Component, effect, signal, CreateEffectOptions, input, output, inject } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { TopFiveData } from '../../../../../core/services/analytics.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-suspect-users',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule],
  templateUrl: './suspect-users.component.html',
  styleUrls: ['./suspect-users.component.scss'],
})
export class SuspectUsersComponent extends BaseComponent {
  public popupService = inject(ChartLabelsPopupService);  

  public topSuspectUsersData = input<TopFiveData[]>([]);
  public topUserDetailData = input<TopFiveData[]>([]);
  public seriesClick = output<SeriesClickEvent>();

  public isLoading = signal(true);
  public ticketsByUser = signal<TopFiveData[]>([]);
  public ticketsByEventName = signal<TopFiveData[]>([]);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const suspectUsersData = this.topSuspectUsersData();
        const userDetailData = this.topUserDetailData();
        if (suspectUsersData && userDetailData) {
          this.ticketsByUser.set(suspectUsersData.slice(0, 5) || []);
          this.ticketsByEventName.set(userDetailData.slice(0, 5) || []);
          this.isLoading.set(false);
        } else {
          this.ticketsByUser.set([]);
          this.ticketsByEventName.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public handleSeriesClick(event: SeriesClickEvent): void {
    this.seriesClick.emit(event);
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }
}
