import { Component, effect, inject, signal, ElementRef, HostListener } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LoaderModule, IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { TagsService } from '../../../core/services/tags.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { OrgDetections, Tag, DetectionSetting } from '../../../core/models/entities';
import { DetectionSettingsService } from '../../../core/services/detection-settings-service';
import { PopupModule } from '@progress/kendo-angular-popup';

@Component({
  selector: 'app-alert-preferences',
  standalone: true,
  imports: [LoaderModule, LayoutModule, ChartsModule, GridModule, FormsModule, ReactiveFormsModule, InputsModule, ButtonsModule, LabelModule, IconsModule, DropDownsModule, NavigationModule, IndicatorsModule, PopupModule],
  templateUrl: './alert-preferences.page.html',
  styleUrl: './alert-preferences.page.scss',
  providers: [TagsService],
})
export class AlertPreferencesPage extends BaseComponent {
  private route = inject(ActivatedRoute);
  private tagsService = inject(TagsService);
  detections = signal<OrgDetections[]>([]);
  filteredDetections = signal<OrgDetections[]>([]);
  private detectionsService = inject(DetectionSettingsService);
  error?: string;
  public isLoading = signal<boolean>(true);
  public availableTags = signal<Tag[]>([]);
  public selectedTags: Tag[] = [];
  organizationId = signal<number>(0);
  public totalRecords = 0;
  private page = 0;
  public showNewOnly = false;
  public showTagFilter = false;
  private elementRef = inject(ElementRef);
  public updatingDetectionId = signal<number | null>(null);

  constructor() {
    super();
    this.initializeOrganization();
    this.initEffect();
  }

  private initializeOrganization(): void {
    // Subscribe to query parameter changes
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      const newOrgId = parseInt(params['organizationId']);
      if (newOrgId && newOrgId !== this.organizationId()) {
        this.isLoading.set(true);
        this.organizationId.set(newOrgId);
        // Reset data
        this.detections.set([]);
        this.filteredDetections.set([]);
        this.page = 0;

        // Reset detection service state
        this.detectionsService.state = {
          skip: 0,
          take: 50,
          sort: [{ field: 'detection_id__title', dir: 'asc' }],
          filter: {
            logic: 'and',
            filters: [],
          },
        };

        // Reload all data
        this.loadTags();
        this.loadData();
      }
    });
  }

  public initEffect(): void {
    effect(
      () => {
        // Only update the state object in the effect
        this.detectionsService.state = {
          skip: 0,
          take: 50,
          sort: [{ field: 'detection_id__title', dir: 'asc' }],
          filter: {
            logic: 'and',
            filters: [],
          },
        };
      },
      { allowSignalWrites: true },
    ); // Add allowSignalWrites option

    // Move these calls outside the effect
    this.loadTags();
    this.loadData();
  }

  private isNewDetection(createdAt: string): boolean {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return new Date(createdAt) > thirtyDaysAgo;
  }

  loadData() {
    this.isLoading.set(true);
    this.detectionsService.getDetections(this.organizationId(), (data: any) => {
      console.log('Loading data for org:', this.organizationId(), data);
      if (data) {
        this.totalRecords = data.total;
        const pagingDetections: OrgDetections[] = data.data
          .map((d: any) => ({
            detectionId: d.detection_id.pk,
            title: d.detection_id.title,
            category: d.detection_id.category.name,
            entityId: d.detection_id.entity_id,
            description: d.detection_id.description,
            status: d.detection_id.status,
            defaultState: d.detection_id.default_state,
            active: d.active,
            organizationId: d.organization_id,
            tags: d.detection_id.tags,
            createdAt: d.detection_id.created_at,
            updatedAt: d.detection_id.updated_at,
            isNew: this.isNewDetection(d.detection_id.created_at),
          }))
          .sort((a: any, b: any) => a.title.localeCompare(b.title));

        // Append new items instead of replacing the entire array
        if (this.page === 0) {
          // First page - replace the array
          this.detections.set(pagingDetections);
        } else {
          // Subsequent pages - append to existing array
          this.detections.update((current) => [...current, ...pagingDetections]);
        }

        this.filterDetetections();
      }
      this.isLoading.set(false);
    });
  }

  onScroll(event: any) {
    const alertListContainer = event.target;
    const scrollTop = alertListContainer.scrollTop;
    const scrollHeight = alertListContainer.scrollHeight;
    const offsetHeight = alertListContainer.offsetHeight;

    // Add a small buffer to prevent edge cases
    const scrollThreshold = scrollHeight - offsetHeight - 10;

    // Only load more if we're near the bottom and not currently loading
    if (scrollTop >= scrollThreshold && !this.isLoading() && this.detections().length < this.totalRecords) {
      this.page++;
      // Update the state with new skip value
      this.detectionsService.state = {
        ...this.detectionsService.state,
        skip: this.page * 50, // Using 50 directly since that's our take value
      };

      this.loadData();
    }
  }

  private loadTags(): void {
    this.tagsService.getTags().subscribe({
      next: (tags) => {
        this.availableTags.set(tags); // Now "tags" is an array
      },
      error: (error) => {
        console.error('Error loading tags:', error);
      },
    });
  }

  public onTagFilterChange(searchTerm: string): void {
    this.tagsService.searchTags(searchTerm).subscribe({
      next: (data) => {
        this.availableTags.set(data);
      },
      error: (error) => {
        console.error('Error adding new tag:', error);
      },
    });
  }

  public filterDetetections(): void {
    let filtered = this.detections();

    // Apply tag filter
    if (this.selectedTags.length > 0) {
      filtered = filtered.filter((obj: OrgDetections) => this.selectedTags.some((selectedTag: Tag) => obj.tags?.some((tag) => tag.name === selectedTag.name)));
    }

    // Apply new filter
    if (this.showNewOnly) {
      filtered = filtered.filter((obj: OrgDetections) => obj.isNew);
    }

    this.filteredDetections.set(filtered);
  }

  public onToggleChange(detection: OrgDetections, active: boolean): void {
    if (detection.detectionId !== null) {
      const updatedDetection: DetectionSetting = {
        detection_id: detection.detectionId,
        active: active,
      };

      this.updatingDetectionId.set(detection.detectionId);

      this.detections.update((detections) => detections.map((d) => (d.detectionId === detection.detectionId ? { ...d, active: active } : d)));

      this.detectionsService.setOrganizationSettings(detection.organizationId!, updatedDetection).subscribe({
        next: () => {
          console.log('Update successful');
          this.updatingDetectionId.set(null);
        },
        error: (error) => {
          console.error('Update failed', error);
          this.updatingDetectionId.set(null);
        },
      });
    } else {
      console.error('Invalid detection ID');
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    // Check if click was outside the popup and its anchor button
    const targetElement = event.target as HTMLElement;
    const popupElement = this.elementRef.nativeElement.querySelector('.tag-filter-popup');
    const buttonElement = this.elementRef.nativeElement.querySelector('.tag-filter-button');

    if (popupElement && buttonElement) {
      const clickedInside = popupElement.contains(targetElement) || buttonElement.contains(targetElement);
      if (!clickedInside) {
        this.showTagFilter = false;
      }
    }
  }
}
