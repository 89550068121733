import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServerException } from '../../../../../../../../core/models/entities';

export interface ServerExceptionForm {
  pk: FormControl<string | null>;
  ip_address: FormControl<string | null>;
  server_name: FormControl<string | null>;
  notes_for_analyst: FormControl<string | null>;
  server_type: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class ServerExceptionFormService {
  public createServerExceptionForm(serverException = new ServerException()) {
    return new FormGroup<ServerExceptionForm>({
      pk: new FormControl(serverException.pk, Validators.required),
      ip_address: new FormControl(serverException.ip_address, Validators.required),
      server_name: new FormControl(serverException.server_name, Validators.required),
      notes_for_analyst: new FormControl(serverException.notes_for_analyst, Validators.required),
      server_type: new FormControl(serverException.server_type, Validators.required),
    });
  }
}
