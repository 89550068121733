import { Component } from '@angular/core';
import { ChartModule } from '@progress/kendo-angular-charts';
import { SeriesPoint } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-risky-assets-heatmap',
  standalone: true,
  imports: [ChartModule],
  templateUrl: './risky-assets-heatmap.component.html',
  styleUrl: './risky-assets-heatmap.component.scss',
})
export class RiskyAssetsHeatmapComponent {
  public data = [
    { agent_version: '23.1.5.886', threats_open_count: 23, endpoint_name: 'matts mac' },
    { agent_version: '23.3.3.334', threats_open_count: 52, endpoint_name: 'office-laptop' },
    { agent_version: '23.4.4.223', threats_open_count: 21, endpoint_name: 'server-01' },
    { agent_version: '23.4.5.445', threats_open_count: 12, endpoint_name: 'server-02' },
    { agent_version: '24.1.5.234', threats_open_count: 1, endpoint_name: 'dev-laptop' },
    { agent_version: '24.1.4.252', threats_open_count: 59, endpoint_name: 'test-pc' },
    { agent_version: '23.1.5.886', threats_open_count: 102, endpoint_name: 'prod-server' },
    { agent_version: '23.3.3.334', threats_open_count: 205, endpoint_name: 'backup-server' },
    { agent_version: '23.4.4.223', threats_open_count: 3, endpoint_name: 'qa-desktop' },
    { agent_version: '23.4.5.445', threats_open_count: 92, endpoint_name: 'hr-laptop' },
    { agent_version: '24.1.5.234', threats_open_count: 15, endpoint_name: 'manager-pc' },
    { agent_version: '24.1.4.252', threats_open_count: 62, endpoint_name: 'lab-pc' },
  ];

  public categories = {
    x: this.getSortedXAxisCategories(),
    y: this.getYAxisScale(),
  };

  public normalizedData = this.getNormalizedData();

  public yAxisMin = 0; // Set minimum value for the Y-axis
  public yAxisMax = 250; // Set maximum value for the Y-axis

  public seriesColors: string[] = [
    'rgb(128,0,128)', // Purple
    'rgb(255,0,0)', // Red
  ];

  public color = (e: SeriesPoint): string => {
    // Access the threats_open_count from the dataItem array
    // dataItem is in format [agent_version, threats_open_count, endpoint_name]
    const value = e.dataItem[1];

    // Ensure we're using numbers for the calculation
    const numericValue = Number(value);
    const percentage = (numericValue - this.yAxisMin) / (this.yAxisMax - this.yAxisMin);

    // Linear interpolation between rgb(111, 66, 193) and rgb(255, 0, 0)
    const r = Math.round(111 + 144 * percentage); // 111 to 255
    const g = Math.round(66 * (1 - percentage)); // 66 to 0
    const b = Math.round(193 * (1 - percentage)); // 193 to 0

    return `rgb(${r},${g},${b})`;
  };

  private getYAxisScale(): number[] {
    const yValues = this.data.map((d) => d.threats_open_count);
    return Array.from(new Set(yValues)).sort((a, b) => a - b);
  }

  private getNormalizedData(): [string, number, string][] {
    return this.data.map((d) => [d.agent_version, d.threats_open_count, d.endpoint_name]);
  }

  private getSortedXAxisCategories(): string[] {
    const xValues = this.data.map((d) => d.agent_version);
    return Array.from(new Set(xValues)).sort((a, b) => (a > b ? -1 : 1)); // Sort in descending order
  }
}
