import { Component, ElementRef, Inject, OnInit, inject, viewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CellClickEvent, GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { SVGIcon, plusIcon, xIcon } from '@progress/kendo-svg-icons';
import { CommonModule, DOCUMENT } from '@angular/common';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { ManagerFormComponent } from './components/manager-form/manager-form.component';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { DetectionManagerService } from './services/manager.service';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { DetectionManager } from '../../../core/models/entities';

@Component({
  selector: 'app-manager',
  standalone: true,
  imports: [AdminAppbarComponent, GridModule, InputsModule, LabelModule, IconsModule, ButtonModule, FormsModule, DialogsModule, ReactiveFormsModule, GridModule, CommonModule, DropDownListModule, LayoutModule, PopupModule],
  templateUrl: './manager.page.html',
  styleUrl: './manager.page.scss',
})
export class ManagerPage extends BaseComponent implements OnInit {
  private popupService = inject(PopupService);
  public plusIcon: SVGIcon = plusIcon;
  public xIcon: SVGIcon = xIcon;
  show: boolean | undefined;
  private popupRef: PopupRef | undefined;
  public gridService = inject(DetectionManagerService);
  bodyElement: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.bodyElement = new ElementRef(this.document.body);
  }

  ngOnInit(): void {
    this.gridService.read();
  }

  public statusChange($event: { text: string; value: number }): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    let newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== 'status');

    if ($event.value !== null) {
      newFilters.push({ field: 'status', operator: 'eq', value: $event.value });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  public addDetection(anchor: ElementRef | HTMLElement): void {
    this.togglePopup();
  }

  public editDetection($event: CellClickEvent): void {
    this.togglePopup($event.dataItem as DetectionManager);
  }

  public togglePopup(detection = new DetectionManager()): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      this.popupRef = this.popupService.open({
        anchor: this.bodyElement,
        content: ManagerFormComponent,
        animate: { type: 'slide', direction: 'left', duration: 200 },
        anchorAlign: { horizontal: 'right', vertical: 'top' },
        popupAlign: { horizontal: 'right', vertical: 'top' },
      });

      const managerComponent = this.popupRef.content.instance as ManagerFormComponent;

      if (detection) managerComponent.detection.set(detection);

      managerComponent.close.subscribe(() => {
        this.gridService.read();
        this.togglePopup();
      });
    }
  }
}
