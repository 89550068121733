<kendo-grid
  #grid
  [height]="600"
  [data]="gridService | async"
  [filterable]="false"
  [skip]="0"
  [loading]="gridService.loading"
  [sortable]="false"
  [sort]="[{ field: 'alert_received_on', dir: 'desc' }]"
  (dataStateChange)="gridService.onStateChange($event)"
  [filter]="{
    logic: 'and',
    filters: []
  }"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="10"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="loader-container">
      <kendo-loader
        type="infinite-spinner"
        size="large"
      ></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    [filterable]="false"
    field="alert_received_on"
    title="Received On"
  ></kendo-grid-column>
  <kendo-grid-column
    field="case_id"
    title="SOC Event #"
  ></kendo-grid-column>
  <kendo-grid-column
    field="event_name"
    title="Event Name"
  ></kendo-grid-column>
  <kendo-grid-column
    field="case_severity"
    title="Severity"
  ></kendo-grid-column>
  <kendo-grid-column
    field="case_responded_on"
    title="First Response"
  ></kendo-grid-column>
  <kendo-grid-column
    field="sla_exceeded"
    title="SLA Exceeded"
  ></kendo-grid-column>
  <kendo-grid-column
    field="case_source"
    title="Source"
  ></kendo-grid-column>
  <kendo-grid-pdf
    #pdfExport
    [fileName]="fileName"
    [allPages]="true"
    paperSize="A4"
    [scale]="0.4"
    [landscape]="landscape"
    [repeatHeaders]="true"
  >
    <kendo-grid-pdf-margin
      top="3.5cm"
      left="1cm"
      right="1cm"
      bottom="2cm"
    ></kendo-grid-pdf-margin>
    <ng-template
      kendoGridPDFTemplate
      let-pageNum="pageNum"
      let-totalPages="totalPages"
    >
      <div class="page-template">
        <div class="header">
          <div [innerHTML]="headerHtml"></div>
        </div>
        <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
  <kendo-grid-excel
    [fileName]="fileName"
    [fetchData]="allData"
  ></kendo-grid-excel>
</kendo-grid>
