import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable()
export class MitreService {
  private http = inject(HttpClient);

  private buildUrl(endpoint: string, organizationId: number, params: any = {}) {
    let url = `${environment.apiUrl}/v1/analytics/stats/${endpoint}/${organizationId}/`;
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null) {
        httpParams = httpParams.set(key, params[key]);
      }
    }

    return { url, params: httpParams };
  }
  getMitreattack(organizationId: number, params?: any) {
    const { url, params: httpParams } = this.buildUrl('mitreattacknavigator', organizationId, params);
    return this.http.get<MitreAttackData[]>(url, { params: httpParams });
  }
}
export interface MitreAttackData {
    name: string,
    description: string,
    count: number,
    code: string,
    techniques: {
        description: string,
        name: string,
        count: number,
        color: string,
        subtechniques: {
            name: string,
            description: string,
            count: number
        }[]
    }[];
  }