import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { DetectionData } from '../../../../core/models/entities';
import { DetectionsBoardForm } from '../../../../core/models/forms';

@Injectable({
  providedIn: 'root',
})
export class DetectionBoardFormService {
  public createDetectionBoardForm(detection = new DetectionData()) {
    return new FormGroup<DetectionsBoardForm>({
      name: new FormControl(detection.name, Validators.required),
      subitems: new FormControl(detection.subitems),
      defaultStatus: new FormControl(detection.defaultStatus),
      killChainStage: new FormControl(detection.killChainStage),
      mitreTactic: new FormControl(detection.mitreTactic),
      mitreTacticId: new FormControl(detection.mitreTacticId),
      mitreTechnique: new FormControl(detection.mitreTechnique),
      mitreTechniqueId: new FormControl(detection.mitreTechniqueId),
      connector: new FormControl(detection.connector),
      dateActivated: new FormControl(detection.dateActivated),
      xdrEventName: new FormControl(detection.xdrEventName),
      adrStatus: new FormControl(detection.adrStatus),
      detectionOrigin: new FormControl(detection.detectionOrigin),
    });
  }
}
