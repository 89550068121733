import { Component, inject, output, signal } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { SVGIcon, checkIcon, xIcon } from '@progress/kendo-svg-icons';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NotificationService } from '../../../../../core/services/notification.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [ButtonsModule, ListViewModule, LayoutModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent {
  private notificationService = inject(NotificationService);

  public xIcon: SVGIcon = xIcon;
  notifications = signal<any[]>([]);
  close = output<void>();
  public checkIcon: SVGIcon = checkIcon;

  public cancel() {
    this.close.emit();
  }

  markAsRead(notification: any) {
    this.notificationService.updateStatus(notification.id, 'hide').subscribe({
      next: () => {
        this.notifications.set(this.notifications().filter((x) => x.id !== notification.id));
      },
      error: (error) => console.error('Update failed', error),
    });
  }

  markAsReadWithDelay(notification: any) {
    this.notificationService.updateStatus(notification.id, 'hide').subscribe({
      next: () => {
        setTimeout(() => {
          this.notifications.set(this.notifications().filter((x) => x.id !== notification.id));
        }, 5000);
      },
      error: (error) => console.error('Update failed', error),
    });
  }
}
