import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { ConnectorStatus } from '../models/entities';

@Injectable()
export class ConnectorStatusService extends ODataService<ConnectorStatus> {
  constructor() {
    const url = `${environment.apiUrl}/v1/analytics/administrative/downconnects`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'id', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }
}

export interface ConnectorStatusResponse extends ConnectorStatus {}
