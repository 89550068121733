import { Component, effect, input, output, signal } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';

@Component({
  selector: 'app-cyflare-period',
  standalone: true,
  imports: [DropDownListModule, DateInputsModule, ButtonsModule, LabelModule],
  templateUrl: './cyflare-period.component.html',
  styleUrl: './cyflare-period.component.scss',
})
export class CyflarePeriodComponent {
  public selectedPeriod = signal<{ text: string; value: string }>({ text: 'This Week', value: 'thisWeek' });
  public label = input<string>('');
  public dateRange = input<string[]>([]);
  public dateRangeChange = output<string[] | null>();
  public dateRanges = [
    { text: 'This Week', value: 'thisWeek' },
    { text: 'Past 7 Days', value: 'past7Days' },
    { text: 'Past 30 Days', value: 'past30Days' },
    { text: 'Last Month', value: 'lastMonth' },
    { text: 'Year to Date', value: 'yearToDate' },
    { text: 'Custom', value: 'custom' },
  ];
  public range = { start: null, end: null };

  constructor() {
    effect(() => {
      const periodValue = this.selectedPeriod().value;

      if (periodValue && periodValue !== 'custom') {
        this.setDateRange();
      }
    });
  }

  private getDateRange() {
    const now = new Date();
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    if (this.selectedPeriod().value !== 'custom') {
      this.range.start = null;
      this.range.end = null;
    }
    switch (this.selectedPeriod().value) {
      case 'thisWeek':
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        startDate = startOfWeek;
        endDate = new Date(now);
        break;
      case 'past7Days':
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 7);
        endDate = new Date(now);
        break;
      case 'past30Days':
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 30);
        endDate = new Date(now);
        break;
      case 'lastMonth':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'yearToDate':
        startDate = new Date(now.getFullYear(), 0, 1);
        endDate = new Date(now);
        break;
      case 'custom':
        startDate = this.range.start!;
        endDate = this.range.end!;
        break;
      default:
        startDate = new Date();
        endDate = new Date();
        break;
    }

    if (startDate && endDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      const startUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0));
      const endUTC = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999));

      return [startUTC.toISOString(), endUTC.toISOString()];
    }

    return null;
  }

  onDateRangeChange(value: { text: string; value: string }) {
    queueMicrotask(() => {
      this.selectedPeriod.set(value);
    });
  }

  setDateRange() {
    const selectedDates = this.getDateRange();
    if (selectedDates) {
      this.dateRangeChange.emit(selectedDates);
    }
  }
}
