import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private http: HttpClient;
  constructor() {
    this.http = inject(HttpClient);
  }

  public globalSignOut(id: number) {
    const url = `${environment.apiUrl}/v1/user/administrative/user(${id})/global_sign_out/`;
    return this.http.post(url, {});
  }
}
