/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { isDevMode, Directive, Input, ViewChild, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { Barcode, QRCode, barcodeValidator, qrcodeValidator } from '@progress/kendo-charts';
import { isDocumentAvailable, ResizeSensorComponent, ResizeBatchService } from '@progress/kendo-angular-common';
import { exportImage, exportSVG } from '@progress/kendo-drawing';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const _c0 = ["surface"];
const packageMetadata = {
  name: '@progress/kendo-angular-barcodes',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1728372653,
  version: '16.11.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
class BaseComponent {
  constructor(element, renderer, ngZone) {
    this.element = element;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.resizeRateLimit = 10;
    validatePackage(packageMetadata);
  }
  get autoResize() {
    return this.resizeRateLimit > 0;
  }
  get canRender() {
    return isDocumentAvailable() && Boolean(this.element);
  }
  ngAfterViewInit() {
    this.refresh();
  }
  ngOnChanges(changes) {
    // Need to create a new instance if the rendering mode changes.
    if (changes['renderAs'] && this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
    this.refresh();
  }
  /**
   * Detects the size of the container and redraws the component.
   * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
   */
  resize() {
    if (this.instance) {
      this.instance.redraw();
    }
  }
  /**
   * @hidden
   */
  onResize() {
    if (this.autoResize) {
      this.resize();
    }
  }
  /**
   * Exports the component as an image. The export operation is asynchronous and returns a promise.
   *
   * @param {ImageExportOptions} options - The parameters for the exported image.
   * @returns {Promise<string>} - A promise that will be resolved with a PNG image encoded as a Data URI.
   */
  exportImage(options = {}) {
    return exportImage(this.exportVisual(), options);
  }
  /**
   * Exports the component as an SVG document. The export operation is asynchronous and returns a promise.
   *
   * @param options - The parameters for the exported file.
   * @returns - A promise that will be resolved with an SVG document that is encoded as a Data URI.
   */
  exportSVG(options = {}) {
    return exportSVG(this.exportVisual(), options);
  }
  /**
   * Exports the component as a Drawing Group.
   *
   * @returns - The exported Group.
   */
  exportVisual() {
    return this.instance.exportVisual();
  }
  refresh() {
    if (!this.canRender) {
      return;
    }
    if (!this.instance) {
      const element = this.element.nativeElement;
      this.instance = this.createInstance(element, this.options);
    } else {
      this.instance.setOptions(this.options);
    }
  }
  isDevMode() {
    return isDevMode();
  }
}
BaseComponent.ɵfac = function BaseComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BaseComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
BaseComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseComponent,
  viewQuery: function BaseComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.surfaceElement = _t.first);
    }
  },
  inputs: {
    resizeRateLimit: "resizeRateLimit"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseComponent, [{
    type: Directive
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    resizeRateLimit: [{
      type: Input
    }],
    surfaceElement: [{
      type: ViewChild,
      args: ['surface', {
        static: true
      }]
    }]
  });
})();

/**
 * Represents the Kendo UI Barcode component for Angular.
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-barcode type="EAN8" value="1234567">
 *        </kendo-barcode>
 *    `
 * })
 * export class AppComponent {
 * }
 * ```
 */
class BarcodeComponent extends BaseComponent {
  constructor(element, renderer, ngZone) {
    super(element, renderer, ngZone);
    this.element = element;
    this.renderer = renderer;
    this.ngZone = ngZone;
    /**
     * Limits the automatic resizing of the Barcode. Sets the maximum number of times per second
     * that the component redraws its content when the size of its container changes.
     * Defaults to `10`. To disable the automatic resizing, set it to `0`.
     *
     * @example
     * ```ts
     * _@Component({
     *     selector: 'my-app',
     *     template: `
     *         <kendo-barcode type="EAN8" [value]="1234567"
     *                        [resizeRateLimit]="2">
     *         </kendo-barcode>
     *   `
     * })
     * export class AppComponent {
     * }
     * ```
     */
    this.resizeRateLimit = 10;
  }
  get options() {
    return {
      renderAs: this.renderAs,
      background: this.background,
      border: this.border,
      checksum: this.checksum,
      color: this.color,
      height: this.height,
      padding: this.padding,
      text: this.text,
      type: this.type,
      value: this.value,
      width: this.width
    };
  }
  createInstance(element, options) {
    return new Barcode(element, options, this.onError.bind(this));
  }
  onError(error) {
    error.name = packageMetadata.productName + ' Barcode';
    if (this.isDevMode()) {
      throw error;
    } else {
      console.warn(error);
    }
  }
}
BarcodeComponent.ɵfac = function BarcodeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarcodeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
BarcodeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BarcodeComponent,
  selectors: [["kendo-barcode"]],
  inputs: {
    background: "background",
    border: "border",
    checksum: "checksum",
    color: "color",
    height: "height",
    padding: "padding",
    renderAs: "renderAs",
    text: "text",
    type: "type",
    value: "value",
    width: "width",
    resizeRateLimit: "resizeRateLimit"
  },
  exportAs: ["kendoBarcode"],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  consts: [[3, "resize", "rateLimit"]],
  template: function BarcodeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-resize-sensor", 0);
      i0.ɵɵlistener("resize", function BarcodeComponent_Template_kendo_resize_sensor_resize_0_listener() {
        return ctx.onResize();
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoBarcode',
      selector: 'kendo-barcode',
      template: `
        <kendo-resize-sensor (resize)="onResize()" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
    `,
      standalone: true,
      imports: [ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    background: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    checksum: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    padding: [{
      type: Input
    }],
    renderAs: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    resizeRateLimit: [{
      type: Input
    }]
  });
})();
const DEFAULT_COLOR = '#000';
const DEFAULT_BACKGROUND = '#fff';
const DEFAULT_ERROR_CORRECTION = 'L';
/**
 * Represents the Kendo UI QR Code component for Angular.
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *    selector: 'my-app',
 *    template: `
 *        <kendo-qrcode value="https://www.telerik.com/kendo-angular-ui">
 *        </kendo-qrcode>
 *    `
 * })
 * export class AppComponent {
 * }
 * ```
 */
class QRCodeComponent extends BaseComponent {
  constructor(element, renderer, ngZone) {
    super(element, renderer, ngZone);
    this.element = element;
    this.renderer = renderer;
    this.ngZone = ngZone;
    /**
     * Limits the automatic resizing of the QR Code. Sets the maximum number of times per second
     * that the component redraws its content when the size of its container changes.
     * Defaults to `10`. To disable the automatic resizing, set it to `0`.
     *
     * @example
     * ```ts
     * _@Component({
     *     selector: 'my-app',
     *     template: `
     *         <kendo-qrcode value="https://www.telerik.com/kendo-angular-ui"
     *                       [resizeRateLimit]="2">
     *         </kendo-qrcode>
     *   `
     * })
     * export class AppComponent {
     * }
     * ```
     */
    this.resizeRateLimit = 10;
  }
  get options() {
    return {
      background: this.background || DEFAULT_BACKGROUND,
      border: this.border,
      color: this.color || DEFAULT_COLOR,
      encoding: this.encoding,
      errorCorrection: this.errorCorrection || DEFAULT_ERROR_CORRECTION,
      overlay: this.overlay || {},
      padding: this.padding,
      renderAs: this.renderAs,
      size: this.size,
      value: this.value
    };
  }
  createInstance(element, options) {
    return new QRCode(element, options, this.onError.bind(this));
  }
  onError(error) {
    error.name = packageMetadata.productName + ' QRCode';
    if (this.isDevMode()) {
      throw error;
    } else {
      console.warn(error);
    }
  }
}
QRCodeComponent.ɵfac = function QRCodeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || QRCodeComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
QRCodeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: QRCodeComponent,
  selectors: [["kendo-qrcode"]],
  inputs: {
    background: "background",
    border: "border",
    color: "color",
    encoding: "encoding",
    errorCorrection: "errorCorrection",
    overlay: "overlay",
    padding: "padding",
    renderAs: "renderAs",
    size: "size",
    value: "value",
    resizeRateLimit: "resizeRateLimit"
  },
  exportAs: ["kendoQRCode"],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 1,
  vars: 1,
  consts: [[3, "resize", "rateLimit"]],
  template: function QRCodeComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-resize-sensor", 0);
      i0.ɵɵlistener("resize", function QRCodeComponent_Template_kendo_resize_sensor_resize_0_listener() {
        return ctx.onResize();
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QRCodeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-qrcode',
      exportAs: 'kendoQRCode',
      template: `
        <kendo-resize-sensor (resize)="onResize()" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
    `,
      standalone: true,
      imports: [ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    background: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    encoding: [{
      type: Input
    }],
    errorCorrection: [{
      type: Input
    }],
    overlay: [{
      type: Input
    }],
    padding: [{
      type: Input
    }],
    renderAs: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    resizeRateLimit: [{
      type: Input
    }]
  });
})();

/**
 * Utility array that contains all `Barcode` related components and directives
 */
const KENDO_BARCODE = [BarcodeComponent];
/**
 * Utility array that contains all `QRCode` related components and directives
 */
const KENDO_QRCODE = [QRCodeComponent];
/**
 * Utility array that contains all `@progress/kendo-angular-barcodes` related components and directives
 */
const KENDO_BARCODES = [...KENDO_BARCODE, ...KENDO_QRCODE];

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Barcode component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Barcode module
 * import { BarcodeModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BarcodeModule], // import Barcode module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class BarcodeModule {}
BarcodeModule.ɵfac = function BarcodeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarcodeModule)();
};
BarcodeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BarcodeModule
});
BarcodeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ResizeBatchService],
  imports: [KENDO_BARCODE]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodeModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_BARCODE],
      exports: [...KENDO_BARCODE],
      providers: [ResizeBatchService]
    }]
  }], null, null);
})();

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the QR Code component.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the QR Code module
 * import { QRCodeModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, QRCodeModule], // import QRCodeModule module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class QRCodeModule {}
QRCodeModule.ɵfac = function QRCodeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || QRCodeModule)();
};
QRCodeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: QRCodeModule
});
QRCodeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ResizeBatchService],
  imports: [KENDO_QRCODE]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QRCodeModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_QRCODE],
      exports: [...KENDO_QRCODE],
      providers: [ResizeBatchService]
    }]
  }], null, null);
})();

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Barcode and QR Code components.
 *
 * @example
 *
 * ```ts-no-run
 * // Import the Barcodes module
 * import { BarcodesModule } from '@progress/kendo-angular-barcodes';
 *
 * // The browser platform with a compiler
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *
 * import { NgModule } from '@angular/core';
 *
 * // Import the app component
 * import { AppComponent } from './app.component';
 *
 * // Define the app module
 * _@NgModule({
 *     declarations: [AppComponent], // declare app component
 *     imports:      [BrowserModule, BarcodesModule], // import Barcodes module
 *     bootstrap:    [AppComponent]
 * })
 * export class AppModule {}
 *
 * // Compile and launch the module
 * platformBrowserDynamic().bootstrapModule(AppModule);
 *
 * ```
 */
class BarcodesModule {}
BarcodesModule.ɵfac = function BarcodesModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BarcodesModule)();
};
BarcodesModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BarcodesModule
});
BarcodesModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ResizeBatchService],
  imports: [KENDO_BARCODES]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BarcodesModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_BARCODES],
      exports: [...KENDO_BARCODES],
      providers: [ResizeBatchService]
    }]
  }], null, null);
})();

/**
 * @hidden
 */
const isPresent = value => value !== null && value !== undefined;
/**
 * Creates a value validator for a particular Barcode type.
 *
 * @param {BarcodeType} type The type of the Barcode.
 * @param {Size} size The size of the barcode, excluding the text label, padding and border. Optional.
 * @returns {ValidatorFn}  A validator function that returns an error map with the `barcode` property if the validation check fails, otherwise `null`.
 *
 * @example
 * ```ts-no-run
 * const control = new FormControl('1234', createBarcodeValidator('EAN8'));
 * console.log(control.errors);
 *
 * // {
 * //   barcode: {
 * //     message: 'The value of the "EAN13" encoding should be 12 symbols',
 * //     value: '1234',
 * //     type: 'EAN13'
 * //   }
 * // }
 * ```
 */
const createBarcodeValidator = (type, size) => {
  const validator = barcodeValidator(type, size);
  return control => {
    if (!isPresent(control.value) || control.value === '') {
      return null;
    }
    const result = validator(control.value);
    if (result.valid === true) {
      return null;
    }
    return {
      barcode: {
        message: result.error.message,
        value: control.value,
        type: type
      }
    };
  };
};

/**
 * Creates a value validator for a particular Barcode type.
 *
 * @param {QRCodeEncoding} encoding The QR Code encoding. Defaults to 'ISO_8859_1'.
 * @returns {ValidatorFn}  A validator function that returns an error map with the `qrcode` property if the validation check fails, otherwise `null`.
 *
 * @example
 * ```ts-no-run
 * const control = new FormControl('Фоо', createQRCodeValidator());
 * console.log(control.errors);
 *
 * // {
 * //   qrcode: {
 * //     message: 'Unsupported character in QR Code: "Ф".',
 * //     value: '1234',
 * //     type: 'EAN13'
 * //   }
 * // }
 * ```
 */
const createQRCodeValidator = (encoding = 'ISO_8859_1') => {
  const validator = qrcodeValidator(encoding);
  return control => {
    if (!control.value) {
      return null;
    }
    const result = validator(control.value);
    if (result.valid === true) {
      return null;
    }
    return {
      qrcode: {
        message: result.error.message,
        value: control.value,
        encoding: encoding
      }
    };
  };
};

/**
 * Generated bundle index. Do not edit.
 */

export { BarcodeComponent, BarcodeModule, BarcodesModule, KENDO_BARCODE, KENDO_BARCODES, KENDO_QRCODE, QRCodeComponent, QRCodeModule, createBarcodeValidator, createQRCodeValidator };