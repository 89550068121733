import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { DetectionSettings, Tag } from '../models/entities';

@Injectable({ providedIn: 'root' })
export class DetectionSettingsService extends ODataService<DetectionSettings> {
  constructor() {
    const url = `${environment.apiUrl}/v1/detection/administrative/detectionsettings(orgId)`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 25,
      sort: [{ field: 'detection_id__title', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public getOrganizationDetections(orgId: number) {
    const url = `${this.url}(${orgId})/`;
    return this.http.get<DetectionSettingsResponse>(url);
  }

  public getDetections(orgId: number, callback: any) {
    this.url = `${environment.apiUrl}/v1/detection/administrative/detectionsettings(${orgId})/`;
    return this.read((response) => {
      callback(response);
    });
  }

  public setOrganizationSettings(orgId: number, settings: DetectionSetting) {
    const url = `${environment.apiUrl}/v1/detection/administrative/detectionsettings(${orgId})/`;
    return this.http.put<DetectionSetting>(url, settings);
  }
}

export interface DetectionSettingsResponse {
  value: [
    {
      detection_id: {
        pk: number;
        title: string;
        category: {
          name: string;
        };
        entity_id: string;
        description: string;
        status: string;
        default_state: string;
        tags: Tag[];
      };
      active: boolean;
      organization_id: number;
    },
  ];
}

export interface DetectionSetting {
  detection_id: number;
  active: boolean;
}
