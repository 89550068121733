<kendo-dropdownlist
  #dropdownList
  (open)="onDropdownOpen($event)"
  (opened)="onDropdownOpened($event)"
  (valueChange)="onDropdownChange($event)"
  (filterChange)="onDropdownFilterChange($event)"
  [filterable]="filterable()"
  [valuePrimitive]="valuePrimitive()"
  [textField]="textField()"
  [valueField]="valueField()"
  [virtual]="{ itemHeight: 36 }"
  [data]="data()"
  [ngStyle]="styles()"
  [value]="value()"  
>
  <kendo-svg-icon
    [icon]="chevronDownIcon"
    themeColor="inherit"
  ></kendo-svg-icon>
</kendo-dropdownlist>
