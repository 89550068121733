import { ChangeDetectionStrategy, Component, inject, signal, effect, computed, ElementRef, Inject, ViewChild, InjectionToken } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ComboBoxComponent, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule, LoaderModule } from '@progress/kendo-angular-indicators';
import { filter, forkJoin, switchMap, takeUntil, tap } from 'rxjs';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { AccountTypeResult, Ckb, Organization, UserList } from '../../../../../core/models/entities';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from '../../services/organization.service';
import { OrganizationForm, SubdomainForm, CkbFormGrup } from '../../../../../core/models/forms';
import { GeneralOrganizationServiceCkbRequest, GeneralOrganizationService } from './service/general-organization.service';
import { UserService } from '../../../users/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../../../../../core/models/user';
import { VirtualDropdownComponent } from '../../../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';

export const USER_SERVICE_DROPDOWN = new InjectionToken<UserService>('UserServiceDropdown');
export const ORG_SERVICE_DROPDOWN = new InjectionToken<OrganizationService>('OrganizationServiceDropDown');

@Component({
  selector: 'app-general',
  standalone: true,
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoaderModule, LayoutModule, ChartsModule, FormsModule, ReactiveFormsModule, InputsModule, ButtonsModule, LabelModule, IconsModule, DropDownsModule, NavigationModule, IndicatorsModule, OrgAppbarComponent, VirtualDropdownComponent, SubscriptionsComponent],
  providers: [
    OrganizationService,
    GeneralOrganizationService,
    // we need a serparate user service instance for dropdown since services are singleton in Angular
    // and we don't want any collisions with the existing user service instance that is already being used in component
    // please leave this comment in the code for now - @Le
    { provide: USER_SERVICE_DROPDOWN, useFactory: () => new UserService() },
    { provide: ORG_SERVICE_DROPDOWN, useFactory: () => new OrganizationService() },
  ],
})
export class GeneralComponent extends BaseComponent {
  public router = inject(Router);
  private route = inject(ActivatedRoute);
  public orgService = inject(OrganizationService);

  public userService = inject(UserService);
  public userServiceDropdown = inject(USER_SERVICE_DROPDOWN);
  public orgServiceDropdown = inject(ORG_SERVICE_DROPDOWN);

  public location = inject(Location);
  public generalOrgService = inject(GeneralOrganizationService);
  public user = inject(User);
  users = signal<UserList[]>([]);
  allUsers = signal<{ id: number; username: string }[]>([]);
  bodyElement: ElementRef;
  @ViewChild('userComboBox') userComboBox!: ComboBoxComponent;
  accountTypeItems = signal<AccountTypeResult[]>([]);

  public orgForm = signal(this.getOrganizationForm());
  public ckbForm = signal(this.getCkbForm());

  public id = computed(() => this.orgForm().get('id')?.value ?? 0);
  public sentinelOneId = computed(() => this.ckbForm().get('sentinel_one_id')?.value ?? null);
  public isReadOnly = signal(true);
  public buttonMxdrText: string = 'Edit';
  public isReadOnlyGeneral = signal(true);
  public isReadOnlyMxdr = signal(true);
  public isReadOnlyMedr = signal(true);
  public isReadOnlyAccountMappings = signal(true);
  public groups = signal<string[]>([]);
  isAdmin: boolean = false;
  isCustomer: boolean = false;
  public isLoading = signal<boolean>(true);
  orgDefaultItem = { id: 0, name: 'Select Organization' };

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.bodyElement = new ElementRef(this.document.body);
    this.initEffect();
  }

  ngOnInit() {
    this.loadAccountTypes();
  }
  private initEffect() {
    effect(
      () => {
        this.loadUserData();
        this.route.queryParams
          .pipe(
            filter((params) => params['organizationId']),
            switchMap((params) => {
              const orgId = +params['organizationId'];
              return forkJoin({
                organization: this.orgService.getOrganizationById(orgId),
                ckbData: this.generalOrgService.getCkbById(orgId),
                users: this.generalOrgService.listOrganizationUsers(orgId),
                orgId: this.orgService.getOrganizationById(orgId),
                accountTypes: this.generalOrgService.getAccountTypes(),
              });
            }),
          )
          .subscribe(({ organization, ckbData, users }) => {
            this.orgForm.set(this.getOrganizationForm(organization as Organization));
            this.orgDefaultItem = { id: organization.parent?.id ?? 0, name: organization.parent?.name ?? 'Select Organization' };
            this.ckbForm.set(this.getCkbForm(ckbData));
            this.users.set(users);
            this.setInitialFormState();
            this.isLoading.set(false);
          });
      },
      { allowSignalWrites: true }, // Enabling signal writes within this effect
    );
  }

  loadUserData(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const groups = user.groups || [];

    this.isAdmin = groups.includes('admin');
    this.isCustomer = groups.includes('customer');
  }

  private loadAccountTypes() {
    this.generalOrgService
      .getAccountTypes()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (accountTypes) => {
          this.accountTypeItems.set(accountTypes.results as AccountTypeResult[]);
        },
        error: (error) => {
          console.error('Error fetching account types:', error);
        },
      });
  }

  private setInitialFormState() {
    this.isReadOnlyGeneral.set(true);
    this.isReadOnlyMxdr.set(true);
    this.isReadOnlyMedr.set(true);
    this.isReadOnlyAccountMappings.set(true);

    this.toggleFormControls('general', true);
    this.toggleFormControls('mxdr', true);
    this.toggleFormControls('medr', true);
    this.toggleFormControls('accountMappings', true);
  }

  getOrganizationForm(organization = new Organization()): FormGroup<OrganizationForm> {
    return new FormGroup({
      subdomains: new FormArray<FormGroup<SubdomainForm>>(organization.subdomains?.map((subdomain) => new FormGroup({ subdomain: new FormControl({ value: subdomain.subdomain!, disabled: true }) })) ?? []),
      tags: new FormArray<FormControl<number | null>>(organization.tags?.map((tag) => new FormControl({ value: tag, disabled: true })) ?? []),
      name: new FormControl({ value: organization.name, disabled: true }, [Validators.required]),
      status: new FormControl({ value: organization.status, disabled: true }, [Validators.required]),
      account_type: new FormControl({ value: organization.account_type, disabled: true }, [Validators.required]),
      owner: new FormControl({ value: organization.owner, disabled: true }, [Validators.required]),
      owner_email: new FormControl({ value: organization.owner_email, disabled: true }, [Validators.required]),
      do_not_call_client: new FormControl({ value: organization.do_not_call_client, disabled: true }, [Validators.required]),
      num_of_employee: new FormControl({ value: organization.num_of_employee, disabled: true }, [Validators.required]),
      website: new FormControl({ value: organization.website, disabled: true }, [Validators.required]),
      billing_street: new FormControl({ value: organization.billing_street, disabled: true }, [Validators.required]),
      billing_city: new FormControl({ value: organization.billing_city, disabled: true }, [Validators.required]),
      billing_country: new FormControl({ value: organization.billing_country, disabled: true }, [Validators.required]),
      billing_state: new FormControl({ value: organization.billing_state, disabled: true }, [Validators.required]),
      use_same_billing_address_for_shipping: new FormControl({ value: organization.use_same_billing_address_for_shipping, disabled: true }, [Validators.required]),
      shipping_street: new FormControl({ value: organization.shipping_street, disabled: true }, [Validators.required]),
      shipping_city: new FormControl({ value: organization.shipping_city, disabled: true }, [Validators.required]),
      shipping_country: new FormControl({ value: organization.shipping_country, disabled: true }, [Validators.required]),
      shipping_state: new FormControl({ value: organization.shipping_state, disabled: true }, [Validators.required]),
      account_manager_name: new FormControl({ value: organization.account_manager_name, disabled: true }, [Validators.required]),
      account_manager_phone: new FormControl({ value: organization.account_manager_phone, disabled: true }, [Validators.required]),
      account_manager_email: new FormControl({ value: organization.account_manager_email, disabled: true }, [Validators.required]),
      assigned_csm: new FormControl({ value: organization.assigned_csm, disabled: true }, [Validators.required]),
      assigned_csm_phone: new FormControl({ value: organization.assigned_csm_phone, disabled: true }, [Validators.required]),
      assigned_csm_email: new FormControl({ value: organization.assigned_csm_email, disabled: true }, [Validators.required]),
      soc_phone: new FormControl({ value: organization.soc_phone, disabled: true }, [Validators.required]),
      soc_escalation_email: new FormControl({ value: organization.soc_escalation_email, disabled: true }, [Validators.required]),
      soc_ir_email: new FormControl({ value: organization.soc_ir_email, disabled: true }, [Validators.required]),
      soc_subdomain: new FormControl({ value: organization.soc_subdomain, disabled: true }, [Validators.required]),
      id: new FormControl({ value: organization.id, disabled: true }, [Validators.required]),
      created_at: new FormControl({ value: organization.created_at && new Date(organization.created_at), disabled: true }, [Validators.required]),
      parent: new FormControl({ value: organization.parent?.name ?? '', disabled: true }, [Validators.required]),
      parentId: new FormControl({ value: organization.parent?.id ?? null, disabled: true }, [Validators.required]),
    });
  }

  getCkbForm(ckb = new Ckb()): FormGroup<CkbFormGrup> {
    return new FormGroup({
      xdr_windows_agents: new FormControl({ value: ckb.xdr_windows_agents ?? '', disabled: true }, [Validators.required]),
      xdr_linux_agents: new FormControl({ value: ckb.xdr_linux_agents ?? '', disabled: true }, [Validators.required]),
      xdr_network_sensors: new FormControl({ value: ckb.xdr_network_sensors ?? '', disabled: true }, [Validators.required]),
      xdr_api_connectors: new FormControl({ value: ckb.xdr_api_connectors ?? '', disabled: true }, [Validators.required]),
      xdr_syslog_senders: new FormControl({ value: ckb.xdr_syslog_senders ?? '', disabled: true }, [Validators.required]),
      mdr_windows: new FormControl({ value: ckb.mdr_windows ?? '', disabled: true }, [Validators.required]),
      mdr_mac: new FormControl({ value: ckb.mdr_mac ?? '', disabled: true }, [Validators.required]),
      mdr_linux: new FormControl({ value: ckb.mdr_linux ?? '', disabled: true }, [Validators.required]),
      zoho_id: new FormControl({ value: ckb.zoho_id ?? '', disabled: true }, [Validators.required]),
      zoho_name: new FormControl({ value: ckb.zoho_name ?? '', disabled: true }, [Validators.required]),
      stellar_cyber_id: new FormControl({ value: ckb.stellar_cyber_id ?? '', disabled: true }, [Validators.required]),
      stellar_cyber_name: new FormControl({ value: ckb.stellar_cyber_name ?? '', disabled: true }, [Validators.required]),
      sentinel_one_id: new FormControl({ value: ckb.sentinel_one_id ?? '', disabled: true }, [Validators.required]),
      sentinel_one_name: new FormControl({ value: ckb.sentinel_one_name ?? '', disabled: true }, [Validators.required]),
      cyrisma_id: new FormControl({ value: ckb.cyrisma_id ?? '', disabled: true }, [Validators.required]),
      cyrisma_name: new FormControl({ value: ckb.cyrisma_name ?? '', disabled: true }, [Validators.required]),
      assure_id: new FormControl({ value: ckb.assure_id ?? '', disabled: true }, [Validators.required]),
      assure_name: new FormControl({ value: ckb.assure_name ?? '', disabled: true }, [Validators.required]),
      chronicle_id: new FormControl({ value: ckb.chronicle_id ?? '', disabled: true }, [Validators.required]),
      chronicle_name: new FormControl({ value: ckb.chronicle_name ?? '', disabled: true }, [Validators.required]),
      polymer_id: new FormControl({ value: ckb.polymer_id ?? '', disabled: true }, [Validators.required]),
      polymer_name: new FormControl({ value: ckb.polymer_name ?? '', disabled: true }, [Validators.required]),
    });
  }

  private toggleFormControls(group: 'general' | 'mxdr' | 'medr' | 'accountMappings', isReadOnly: boolean) {
    const controlsToToggle = () => {
      let general = ['name', 'owner', 'owner_email', 'website', 'billing_street', 'billing_city', 'billing_country', 'billing_state', 'use_same_billing_address_for_shipping', 'shipping_street', 'shipping_city', 'shipping_country', 'shipping_state', 'account_manager_name', 'account_manager_phone', 'account_manager_email', 'assigned_csm', 'assigned_csm_phone', 'assigned_csm_email', 'soc_phone', 'soc_escalation_email', 'soc_ir_email', 'soc_subdomain', 'parentId'];

      if (this.user.groups.includes('admin')) {
        general.push('account_type', 'status');
      }

      return {
        general: general,
        mxdr: ['xdr_windows_agents', 'xdr_linux_agents', 'xdr_network_sensors', 'xdr_api_connectors', 'xdr_syslog_senders'],
        medr: ['mdr_windows', 'mdr_linux', 'mdr_mac'],
        accountMappings: ['zoho_id', 'zoho_name', 'stellar_cyber_id', 'stellar_cyber_name', 'sentinel_one_id', 'sentinel_one_name', 'cyrisma_id', 'cyrisma_name', 'assure_id', 'assure_name', 'chronicle_id', 'chronicle_name', 'polymer_id', 'polymer_name'],
      };
    };

    const orgForm = this.orgForm();
    const ckbForm = this.ckbForm();

    controlsToToggle()[group].forEach((control: string) => {
      let formControl = orgForm.get(control);
      if (!formControl) {
        formControl = ckbForm.get(control);
      }

      if (formControl) {
        if (isReadOnly) {
          formControl.disable();
        } else {
          formControl.enable();
        }
      }
    });
  }

  onButtonClickGeneral() {
    const isReadOnlyBefore = this.isReadOnlyGeneral();
    this.isReadOnlyGeneral.set(!isReadOnlyBefore);
    this.toggleFormControls('general', !isReadOnlyBefore);
    if (!isReadOnlyBefore) {
      this.saveOrgChanges();
    }
  }

  onButtonClickMxdr() {
    const isReadOnlyBefore = this.isReadOnlyMxdr();
    this.isReadOnlyMxdr.set(!isReadOnlyBefore);
    this.toggleFormControls('mxdr', !isReadOnlyBefore);
    if (!isReadOnlyBefore) {
      this.saveChanges();
    }
  }

  onButtonClickMedr() {
    const isReadOnlyBefore = this.isReadOnlyMedr();
    this.isReadOnlyMedr.set(!isReadOnlyBefore);
    this.toggleFormControls('medr', !isReadOnlyBefore);

    if (!isReadOnlyBefore) {
      this.saveChanges();
    }
  }

  onButtonClickAccountMappings() {
    const isReadOnlyBefore = this.isReadOnlyAccountMappings();
    this.isReadOnlyAccountMappings.set(!isReadOnlyBefore);
    this.toggleFormControls('accountMappings', !isReadOnlyBefore);
    if (!isReadOnlyBefore) {
      this.saveChanges();
    }
  }

  private saveOrgChanges() {
    this.toggleFormControls('general', false);

    const orgFormData = this.convertEmptyStringsToNull(this.orgForm().getRawValue());
    delete orgFormData.parent;

    this.toggleFormControls('general', true);

    const payload: Organization = {
      id: this.id(),
      ...orgFormData,
      parent: { id: orgFormData.parentId, name: orgFormData.parent },
    };

    this.orgService
      .updateOrg(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {});
  }

  private saveChanges() {
    const ckbFormData = this.convertEmptyStringsToNull(this.ckbForm().value);

    const payload: GeneralOrganizationServiceCkbRequest = {
      organization_id: this.id(),
      ...ckbFormData,
    };

    this.generalOrgService.updateCkbApi(payload).subscribe((response) => {});
  }

  private convertEmptyStringsToNull(formData: any): any {
    const transformedData: any = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        transformedData[key] = formData[key] === '' ? null : formData[key];
      }
    }
    return transformedData;
  }

  onUserAssigned($event: any) {
    const userId = $event ? $event.id : null;
    if (userId) {
      const orgId = this.id();
      this.assignUser(userId, orgId);
    }
  }

  public assignUser(userId: number, orgId: number): void {
    this.generalOrgService
      .assignUserToOrganization(orgId, userId)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(() => this.generalOrgService.listOrganizationUsers(orgId)),
      )
      .subscribe((updatedUsers) => {
        this.users.set(updatedUsers);
        this.userComboBox.value.set(null);
      });
  }

  public removeUser(userId: number, orgId: number): void {
    this.generalOrgService
      .removeUserFromOrganization(orgId, userId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.users.set(this.users().filter((u) => u.id !== userId));
      });
  }

  public userMapper(users: any[]) {
    return users.map((user: any) => {
      return {
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      };
    });
  }
}
