import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../core/services/odata.service';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class UserService extends ODataService<User> {
  constructor() {
    const url = `${environment.apiUrl}/v1/user/administrative/user/`;
    super(inject(HttpClient), url);
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'first_name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public addUser(user: any) {
    let { accept_news, birth_date, ...restOfUser } = user;
    return this.http.post(this.url, restOfUser);
  }

  updateUser(id: number, user: any) {
    const url = `${environment.apiUrl}/v1/user/administrative/user(${id})/`;
    return this.http.put(url, user);
  }

  deleteUser(id: number) {
    const url = `${environment.apiUrl}/v1/user/administrative/user(${id})/`;
    return this.http.delete(url);
  }

  resetPassword(id: number) {
    const url = `${environment.apiUrl}/v1/user/administrative/user(${id})/reset_user_password/`;
    return this.http.post(url, {});
  }

  resetMFA(id: number) {
    const url = `${environment.apiUrl}/v1/user/administrative/user(${id})/reset_mfa/`;
    return this.http.put(url, {});
  }
}

export interface UsersResponse {
  value: [
    {
      id: number;
      avatar: string;
      is_admin: boolean;
      groups: string[];
      last_login: Date;
      username: string;
      first_name: string;
      last_name: string;
      email: string;
      is_active: boolean;
      date_joined: Date;
    },
  ];
}
