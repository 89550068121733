@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (ticketsByEventName().length > 0) {
  <kendo-chart (render)="popupService.attachSurfaceHandlers($event, popupTemplate)">
    <kendo-chart-tooltip> </kendo-chart-tooltip>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="line"
        [style]="'smooth'"
        [data]="ticketsByEventName()"
        [color]="getColor"
        field="count"
        categoryField="description"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item>
        <kendo-chart-category-axis-item-labels
          [content]="truncateCategoryLabel"
          rotation="auto"
        ></kendo-chart-category-axis-item-labels>
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
  </kendo-chart>

  <ng-template #popupTemplate>
    <div class="chart-popup k-tooltip">
      {{ popupService.popupContent }}
    </div>
  </ng-template>
} @else {
  <h1>No Data</h1>
}
