<div class="content">
  <div class="example-wrap">
    <form
      class="k-form k-form-md"
      [formGroup]="schedulerForm"
    >
      <div class="k-form-buttons">
        <h2 class="k-form-title">Report Schedule</h2>
        <button
          kendoButton
          [svgIcon]="xIcon"
          (click)="cancel()"
          fillMode="flat"
          title="Close"
        ></button>
      </div>
      <h5>{{ reportTitle }}</h5>
      <fieldset class="k-form-fieldset">
        <!-- Monthly Reporting -->
        <div>
          <div class="k-form-buttons">
            <h5>Monthly Emails</h5>
            <kendo-switch
              formControlName="monthlySwitch"
              (valueChange)="monthlySchedule($event)"
            ></kendo-switch>
          </div>

          <!-- Use @if for conditional rendering of monthlyEmail form fields -->
          @if (schedulerForm.get('monthlySwitch')?.value) {
            <div>
              <p>Sent on the first of each month, summarizing the previous month's data and insights.</p>
              <kendo-formfield>
                <kendo-label
                  labelCssClass="k-form-label"
                  text="Email Report To (comma-separated for multiple addresses)"
                ></kendo-label>
                <kendo-textbox formControlName="monthlyEmail"></kendo-textbox>
                <kendo-formerror>Error: At least 1 Email Address Is Required</kendo-formerror>
              </kendo-formfield>
            </div>
          }
        </div>

        <!-- Weekly Reporting -->
        <div>
          <div class="k-form-buttons">
            <h5>Weekly Emails</h5>
            <kendo-switch
              formControlName="weeklySwitch"
              (valueChange)="weeklySchedule($event)"
            ></kendo-switch>
          </div>

          <!-- Use @if for conditional rendering of weeklyEmail form fields -->
          @if (schedulerForm.get('weeklySwitch')?.value) {
            <div>
              <p>Sent weekly on Mondays, summarizing the previous week's data and insights.</p>
              <kendo-formfield>
                <kendo-label
                  labelCssClass="k-form-label"
                  text="Email Report To (comma-separated for multiple addresses)"
                ></kendo-label>
                <kendo-textbox formControlName="weeklyEmail"></kendo-textbox>
                <kendo-formerror>Error: At least 1 Email Address Is Required</kendo-formerror>
              </kendo-formfield>
            </div>
          }
        </div>

        <!-- Form Buttons -->
        <div class="k-form-buttons">
          <button
            kendoButton
            themeColor="primary"
            (click)="save($event)"
            [disabled]="schedulerForm.invalid"
          >
            Save Schedule
          </button>
          <button
            kendoButton
            themeColor="secondary"
            [disabled]="schedulerForm.invalid"
          >
            Send Test Email
          </button>
        </div>
      </fieldset>
    </form>
  </div>
</div>
