<button
  kendoButton
  style="margin-top: 12px"
  (click)="goBack()"
  class="back-button"
>
  Back
</button>
<div class="advisory-container">
  @if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
  } @else if (advisory() !== null) {
  <div class="advisory-header">
    <h1>{{ advisory()?.title }}</h1>
    <div class="advisory-meta">
      <span>By {{ advisory()?.author }}</span> |
      <span>{{ advisory()?.published_at }}</span>
    </div>
  </div>
  <div class="advisory-content">
    <div [innerHTML]="sanitizedContent()"></div>
  </div>
  } @else {
  <div class="advisory-content">
    <div>No content available</div>
  </div>
  }
</div>
