import { Component, OnInit, effect, inject, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { DetectionSetting, OrgDetections } from '../../../../../core/models/entities';
import { DetectionSettingsService } from './service/detection-settings-service';
import { filter, switchMap } from 'rxjs';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-detection-settings',
  standalone: true,
  imports: [LoaderModule, LayoutModule, ChartsModule, GridModule, FormsModule, ReactiveFormsModule, InputsModule, ButtonsModule, LabelModule, IconsModule, DropDownsModule, NavigationModule, IndicatorsModule, OrgAppbarComponent],
  templateUrl: './detection-settings.component.html',
  styleUrl: './detection-settings.component.scss',
})
export class DetectionSettingsComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  detections = signal<OrgDetections[]>([]);
  private detectionsService = inject(DetectionSettingsService);
  error?: string;
  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  public initEffect(): void {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => !!params['organizationId']),
          switchMap((params) => this.detectionsService.getOrganizationDetections(params['organizationId'])),
        )
        .subscribe({
          next: (data) => {
            if (data) {
              // Correctly extract and map detections from nested structure
              const detections: OrgDetections[] = data.value
                .map((d) => ({
                  detectionId: d.detection_id.pk,
                  title: d.detection_id.title,
                  category: d.detection_id.category.name,
                  entityId: d.detection_id.entity_id,
                  description: d.detection_id.description,
                  status: d.detection_id.status,
                  defaultState: d.detection_id.default_state,
                  active: d.active,
                  organizationId: d.organization_id,
                }))
                .sort((a, b) => a.title.localeCompare(b.title)); // Sort alphabetically by title

              this.detections.set(detections);
            }
            this.isLoading.set(false);
          },
          error: (error) => {
            console.error(error);
            this.isLoading.set(false);
          },
        });
    });
  }

  public onToggleChange(detection: OrgDetections, active: boolean): void {
    if (detection.detectionId !== null) {
      const updatedDetection: DetectionSetting = {
        detection_id: detection.detectionId,
        active: active,
      };

      this.detections.update((detections) => detections.map((d) => (d.detectionId === detection.detectionId ? { ...d, active: active } : d)));

      this.detectionsService.setOrganizationSettings(detection.organizationId!, updatedDetection).subscribe({
        next: () => console.log('Update successful'),
        error: (error) => console.error('Update failed', error),
      });
    } else {
      console.error('Invalid detection ID');
    }
  }
}
