import { Component, ElementRef, Inject, inject } from '@angular/core';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { SVGIcon, plusIcon, xIcon } from '@progress/kendo-svg-icons';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { PopupModule, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { ChartFormComponent } from './components/chart-form/chart-form.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-charts',
  standalone: true,
  imports: [
    AdminAppbarComponent,
    GridModule,
    InputsModule,
    LabelModule,
    IconsModule,
    ButtonModule,
    FormsModule,
    DialogsModule,
    ReactiveFormsModule,
    PopupModule
  ],
  templateUrl: './charts.page.html',
  styleUrl: './charts.page.scss',
})
export class ChartsPage extends BaseComponent {
  private popupService = inject(PopupService);
  public plusIcon: SVGIcon = plusIcon;
  public xIcon: SVGIcon = xIcon;

  show: boolean | undefined;
  private popupRef: PopupRef | undefined;

  bodyElement: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.bodyElement = new ElementRef(this.document.body);
  }

  public addChart(anchor: ElementRef | HTMLElement): void {
    this.togglePopup(anchor);
  }

  public togglePopup(anchor: ElementRef | HTMLElement): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      this.popupRef = this.popupService.open({
        anchor: anchor,
        content: ChartFormComponent,
        animate: { type: 'slide', direction: 'left', duration: 200 },
        anchorAlign: { horizontal: 'right', vertical: 'top' },
        popupAlign: { horizontal: 'right', vertical: 'top' },
      });

      const userComponent = this.popupRef.content.instance as ChartFormComponent;
      userComponent.close.subscribe(() => {
        this.togglePopup(anchor);
      });
    }
  }
}
