import { ChangeDetectionStrategy, Component, inject, signal, ViewEncapsulation } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AdminAppbarComponent } from '../../../../../../layout/components/admin-appbar/admin-appbar.component';
import { OrganizationService } from '../../../../../services/organization.service';
import { BaseComponent } from '../../../../../../../../core/shared/common/base.component';
import { environment } from '../../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { IntegrationService } from '../../../../../../../../core/services/integration.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-customers',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, GridModule, LabelModule, ButtonsModule, DialogsModule, InputsModule, DropDownListModule, IndicatorsModule, AdminAppbarComponent],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganizationService],
})
export class CustomersComponent extends BaseComponent {
  public gridService = inject(OrganizationService);
  public integrationService = inject(IntegrationService);
  private route = inject(ActivatedRoute);

  agreements = signal<any[]>([]);
  show: boolean | undefined;
  form!: FormGroup;
  organizationId: number;
  saveConfirmDialog = false;

  constructor(private fb: FormBuilder) {
    super();
    const queryParams = this.route.snapshot.queryParams;
    this.organizationId = parseInt(queryParams['organizationId']);
    this.initEffect();
  }  

  get organizations(): FormArray {
    return this.form.get('sub_organizations') as FormArray;
  }

  initEffect(): void {
    this.gridService.url = `${environment.apiUrl}/v1/organization/administrative/organization(${this.organizationId})/list_child_organizations`;    
    this.gridService.read();
    this.gridService.subscribe((data) => {
      this.initGridFormControls(data.data);
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      organization_id: [this.organizationId, Validators.required],
      sub_organizations: this.fb.array([])
    });
    
    this.loadAgreements();    
  }
  
  private loadAgreements() {
    this.agreements.set([
      {id: 0, display_text: 'Agreement 1', disabled: false, selected: false},
      {id: 0, display_text: 'Agreement 2', disabled: false, selected: false},
      {id: 0, display_text: 'Agreement 3', disabled: false, selected: false}
    ])
  }

  initGridFormControls(organizations: any[]): void {
    organizations?.forEach(organization => {
      if(!this.organizations.value.find((x: any) => x.organization_id == organization.id))
        this.organizations.push(this.createFormGroup(organization));
    });
  }

  filterByControlName(name: string, value: any): FormGroup[] {
    return this.organizations.controls
      .filter((control) => (control as FormGroup).get(name)?.value === value)
      .map(control => control as FormGroup);
  }

  createFormGroup(data: any = {}): FormGroup {
    return this.fb.group({
      organization_id: [data.id, Validators.required],
      organization_name: [data.name],
      CustomerName: [''],
      AgreementId: [undefined]
    });
  }

  submitForm(): void {
    console.log(this.form.value);
    this.integrationService.updateConnectWiseCkb(this.form.value)
    .subscribe({
      next: () => {
          this.openSaveConfirmDialog();
      },
      error: (error) => {
        console.error('Error adding connection:', error);
      },
    });
  }

  public openSaveConfirmDialog(): void {
    this.saveConfirmDialog = true;
  }

  public closeDialog(status: string): void {
    this.saveConfirmDialog = false;
  }
}

