import { ChangeDetectionStrategy, Component, ElementRef, inject, Inject, signal, ViewEncapsulation } from '@angular/core';
import { OrganizationService } from './services/organization.service';
import { CellClickEvent, DataItem, GridModule } from '@progress/kendo-angular-grid';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { Router } from '@angular/router';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { OrganizationComponent } from './components/organization/organization.component';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons';
import { AccountTypeResult, Organization } from '../../../core/models/entities';
import { GeneralOrganizationService } from './components/general/service/general-organization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Events, EventService } from 'src/app/core/services/event.service';

@Component({
  selector: 'app-organizations',
  standalone: true,
  imports: [FormsModule, CommonModule, GridModule, LabelModule, ButtonsModule, InputsModule, DropDownListModule, IndicatorsModule, AdminAppbarComponent],
  templateUrl: './organizations.page.html',
  styleUrl: './organizations.page.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OrganizationService],
})
export class OrganizationsPage extends BaseComponent {
  public gridService = inject(OrganizationService);
  public router = inject(Router);
  private popupService = inject(PopupService);
  private eventService = inject(EventService);
  public plusIcon: SVGIcon = plusIcon;
  accountTypeItems = signal<AccountTypeResult[]>([]);

  public generalOrgService = inject(GeneralOrganizationService);

  show: boolean | undefined;
  private popupRef: PopupRef | undefined;

  public statuses = [
    { text: 'Active', value: 1 },
    { text: 'Disabled', value: 0 },
  ];

  public parent = [];

  bodyElement: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    this.initEffect();
    this.bodyElement = new ElementRef(this.document.body);
  }

  initEffect(): void {
    this.gridService.read();
  }
  ngOnInit() {
    this.loadAccountTypes();
  }
  public statusChange($event: { text: string; value: number }): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    let newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== 'status');

    if ($event.value !== null) {
      newFilters.push({
        field: 'status',
        operator: 'eq',
        value: $event.value,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  private loadAccountTypes() {
    this.generalOrgService
      .getAccountTypes()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (accountTypes) => {
          this.accountTypeItems.set(accountTypes.results as AccountTypeResult[]);
        },
        error: (error) => {
          console.error('Error fetching account types:', error);
        },
      });
  }

  public addOrg(anchor: ElementRef | HTMLElement): void {
    this.togglePopup(anchor);
  }

  public togglePopup(anchor: ElementRef | HTMLElement): void {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = undefined;
    } else {
      this.popupRef = this.popupService.open({
        anchor: anchor,
        content: OrganizationComponent,
        animate: { type: 'slide', direction: 'left', duration: 200 },
        anchorAlign: { horizontal: 'right', vertical: 'top' },
        popupAlign: { horizontal: 'right', vertical: 'top' },
      });

      const userComponent = this.popupRef.content.instance as OrganizationComponent; // Change const userComponent to organizationComponent
      userComponent.close.subscribe(() => {
        this.gridService.read();
        this.togglePopup(anchor);
      });
    }
  }

  public accountTypeChange($event: { display_text: string; id: number }) {
    const currentFilters = this.gridService.state.filter?.filters || [];

    let newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== 'account_type');

    if ($event.id !== null) {
      newFilters.push({
        field: 'account_type',
        operator: 'eq',
        value: $event.id,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  editOrg($event: CellClickEvent) {
    const organization = $event.dataItem as Organization;
    this.router.navigate(['/admin/organizations/general'], {
      queryParams: {
        organizationId: organization.id,
        account_type: organization.account_type,
      },
    });
    this.eventService.emit(Events.OrgSelectionChange.type, new Events.WhiteLabelChange(organization));
  }
}
