<div class="content">
  <div class="example-wrap">
    <form
      [formGroup]="detectionForm"
      class="k-form k-form-md"
    >
      <div class="k-form-buttons">
        @if (detection().pk) {
          <h2 class="k-form-title">Edit Existing Detection</h2>
        } @else {
          <h2 class="k-form-title">Add New Detection</h2>
        }
        <button
          kendoButton
          [svgIcon]="xIcon"
          (click)="cancel()"
          fillMode="flat"
        ></button>
      </div>
      <fieldset class="k-form-fieldset">
        <kendo-formfield>
          <kendo-label
            text="Title"
            labelCssClass="k-form-label"
            [for]="title"
          ></kendo-label>
          <kendo-textbox
            formControlName="title"
            #title
          ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="Entity ID"
            labelCssClass="k-form-label"
            [for]="entityId"
          ></kendo-label>
          <kendo-textbox
            formControlName="entity_id"
            [readonly]="detection().pk != null"
            #entityId
          ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="Description"
            labelCssClass="k-form-label"
            [for]="description"
          ></kendo-label>
          <kendo-textbox
            formControlName="description"
            #description
          ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="Tags"
            labelCssClass="k-form-label"
            [for]="detectionTagsRef"
          >
          </kendo-label>
          <kendo-multiselect
            style="width: 400px"
            #detectionTagsRef
            formControlName="tags"
            [filterable]="true"
            [data]="availableTags()"
            textField="name"
            valueField="id"
            [valuePrimitive]="false"
            [checkboxes]="true"
            [allowCustom]="customValues()"
            (valueChange)="onTagValueChange($event)"
            (filterChange)="onTagFilterChange($event)"
          ></kendo-multiselect>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="Status"
            labelCssClass="k-form-label"
          ></kendo-label>
          <kendo-dropdownlist
            [data]="['Active', 'Disabled']"
            [valuePrimitive]="true"
            formControlName="status"
            (valueChange)="onStatusChange($event)"
          >
          </kendo-dropdownlist>
          @if (opened === true && status === 'Disabled') {
            <kendo-dialog
              title="Update Status Warning"
              (close)="closeDialog('close')"
              [minWidth]="250"
              [width]="450"
            >
              <p style="margin: 30px; text-align: center">This action will delete the detection from ALL existing customers SOAR policies.</p>
              <kendo-dialog-actions>
                <button
                  kendoButton
                  (click)="closeDialog('confirm')"
                >
                  Confirm
                </button>
              </kendo-dialog-actions>
            </kendo-dialog>
          } @else if (opened === true && status === 'Active') {
            <kendo-dialog
              title="Update Status Warning"
              (close)="closeDialog('confirm')"
              [minWidth]="250"
              [width]="450"
            >
              <p style="margin: 30px; text-align: center">This action will add the detection to ALL existing customers SOAR policies.</p>
              <kendo-dialog-actions>
                <button
                  kendoButton
                  (click)="closeDialog('no')"
                >
                  Confirm
                </button>
              </kendo-dialog-actions>
            </kendo-dialog>
          }
        </kendo-formfield>
        <kendo-formfield style="display: flex; padding: 5px">
          <kendo-label text="Default State ON"></kendo-label>
          <kendo-checkbox
            formControlName="default_state"
            value="On"
            style="margin-left: 10px"
          ></kendo-checkbox>
        </kendo-formfield>
        <div class="k-form-buttons">
          @if (detection().pk) {
            <button
              kendoButton
              themeColor="primary"
              (click)="update($event)"
              [disabled]="detectionForm.invalid || detectionForm.pristine"
            >
              Update Detection
            </button>
            <button
              kendoButton
              themeColor="error"
              (click)="delete($event)"
            >
              Remove Detection
            </button>
          } @else {
            <button
              kendoButton
              themeColor="primary"
              (click)="save($event)"
            >
              Add Detection
            </button>
            <button
              kendoButton
              (click)="clear()"
            >
              Clear
            </button>
          }
        </div>
      </fieldset>
    </form>
  </div>
</div>
