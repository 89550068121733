import { ChangeDetectionStrategy, Component, inject, signal, type OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { WhiteLabelRequest, WhiteLabelService } from './services/white-label.service';
import { JsonPipe } from '@angular/common';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/core/shared/common/base.component';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { User } from 'src/app/core/models/user';
import { Events, EventService } from 'src/app/core/services/event.service';
import { effect } from '@angular/core';
import { WhiteLabel } from 'src/app/core/models/entities';

@Component({
  selector: 'app-white-label',
  standalone: true,
  imports: [
    OrgAppbarComponent,
    FormsModule,
    ReactiveFormsModule,
    UploadsModule,
    ButtonModule,
    TabStripModule,
    LabelModule,
    LayoutModule,
    JsonPipe,
    TextBoxModule,
    InputsModule
  ],
  templateUrl: './white-label.component.html',
  styleUrl: './white-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhiteLabelComponent extends BaseComponent implements OnInit {
  public eventService = inject(EventService);
  public user = inject(User);
  private readonly wlabelService = inject(WhiteLabelService);
  private readonly route = inject(ActivatedRoute);
  private organizationId: string | null = null;
  public images: File[] = [];
  public logoFilename = signal<string | null>(null);
  public faviconFilename = signal<string | null>(null);
  public restrictions = {
    allowedExtensions: ['.png', '.jpg', '.jpeg', '.svg', '.gif', '.txt', '.ico'],
    maxFileSize: 1024 * 1024 * 5, // 5MB
  };

  public whiteLabelFormLogo = new FormGroup({
    files: new FormControl<File[]>([], Validators.required),
  });

  public whiteLabelFormFavicon = new FormGroup({
    files: new FormControl<File[]>([], Validators.required),
  });

  public whiteLabelSettingsForm = new FormGroup({
    enabled: new FormControl<boolean | undefined>(undefined),
    url: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern('^(?!http(s)?://).*')
    ]),
    title: new FormControl<string | null>(null, [
      Validators.required,
    ]),
  } as const);

  public whiteLabelFormLogoSubmitted = false;
  public whiteLabelFormFaviconSubmitted = false;

  ngOnInit(): void {
    this.organizationId = this.route.snapshot.queryParamMap.get('organizationId');
    this.wlabelService.get(Number(this.organizationId))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        let whitelabel = res.whitelabel;    
        this.logoFilename.set(whitelabel.logo_url);
        this.faviconFilename.set(whitelabel.favicon_url);
        const cleanUrl = whitelabel.url?.replace(/^https?:\/\//, '');
        this.whiteLabelSettingsForm.patchValue({
          enabled: whitelabel.enabled,
          url: cleanUrl,
          title: whitelabel.title
        });
        if(this.user?.primary_organization?.id === Number(this.organizationId)) {
          const currentWhitelabel = new WhiteLabel(this.user.primary_organization?.whitelabel ?? {});
          currentWhitelabel.favicon_url = whitelabel.favicon_url;
          this.user.primary_organization.whitelabel = currentWhitelabel;
        }
      });
  }

  uploadLogo(payload: { files?: File[] | null | undefined }, valid: boolean): void {
    this.whiteLabelFormLogoSubmitted = true;
    if (valid && this.organizationId) {
      const files = payload.files;
      if (files && files.length > 0) {
        const file = files[0];
        this.wlabelService.uploadLogo(Number(this.organizationId), file)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.logoFilename.set(res.logo_url);
            this.whiteLabelFormLogoSubmitted = false;
            this.whiteLabelFormLogo.reset();
            if(this.user?.primary_organization?.id === Number(this.organizationId)) {
              const currentWhitelabel = new WhiteLabel(this.user.primary_organization?.whitelabel ?? {});
              currentWhitelabel.logo_url = res.logo_url;
              currentWhitelabel.enabled = res.enabled;
              this.user.primary_organization.whitelabel = currentWhitelabel;
            }
            this.eventService.emit(Events.WhiteLabelChange.type, new Events.WhiteLabelChange(this.user.primary_organization!));    
          });
      }
    }
  }

  uploadFavicon(payload: { files?: File[] | null | undefined }, valid: boolean): void {
    this.whiteLabelFormFaviconSubmitted = true;
    if (valid && this.organizationId) {
      const files = payload.files;
      if (files && files.length > 0) {
        const file = files[0];
        this.wlabelService.uploadFavicon(Number(this.organizationId), file)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((res) => {
            this.faviconFilename.set(res.favicon_url);
            this.whiteLabelFormFaviconSubmitted = false;
            this.whiteLabelFormFavicon.reset();
            if(this.user?.primary_organization?.id === Number(this.organizationId)) {
              const currentWhitelabel = new WhiteLabel(this.user.primary_organization?.whitelabel ?? {});
              currentWhitelabel.favicon_url = res.favicon_url;                            
              this.user.updateUser({                
                primary_organization: {                
                  ...this.user.primary_organization,                
                  whitelabel: currentWhitelabel,                
                  parent_whitelabel: this.user.primary_organization.parent_whitelabel                
                }                
              });                
            }
            this.eventService.emit(Events.WhiteLabelChange.type, new Events.WhiteLabelChange(this.user.primary_organization!));    
          });
      }
    }
  }

  save(payload: WhiteLabelRequest): void {
    if (this.whiteLabelSettingsForm.dirty && this.organizationId) {      
      this.wlabelService.upsert(Number(this.organizationId), payload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          let whitelabel = res.whitelabel;
          this.logoFilename.set(whitelabel.logo_url);
          this.whiteLabelSettingsForm.patchValue({
            url: whitelabel.url,
          });
          this.whiteLabelSettingsForm.markAsPristine();

          if(this.user?.primary_organization?.id === Number(this.organizationId)) {
            const currentWhitelabel = new WhiteLabel(this.user.primary_organization?.whitelabel ?? {});
            currentWhitelabel.title = whitelabel.title;
            currentWhitelabel.enabled = whitelabel.enabled;
            this.user.updateUser({                
              primary_organization: {                
                ...this.user.primary_organization,                
                whitelabel: currentWhitelabel,                
                parent_whitelabel: this.user.primary_organization.parent_whitelabel                
              }                
            });                
          }
          this.eventService.emit(Events.WhiteLabelChange.type, new Events.WhiteLabelChange(this.user.primary_organization!));
        });
    }
  }
}
