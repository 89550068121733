import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private http = inject(HttpClient);
  updateStatus (id: number, state: string) {
    const url = `${environment.apiUrl}/v1/notifications/administrative/notification(${id})/`;
    const request = {state: state}
    return this.http.put(url, request);
  }
}
