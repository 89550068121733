import { Component, CreateEffectOptions, effect, inject, input, output, signal } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { MttrData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { CommonModule } from '@angular/common';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-mttr',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule],
  templateUrl: './mttr.component.html',
  styleUrl: './mttr.component.scss',
})
export class MttrComponent extends BaseComponent {
  public popupService = inject(ChartLabelsPopupService);
  public mttrData = input<MttrData[]>([]);
  public seriesClick = output<SeriesClickEvent>();

  public isLoading = signal(true);
  public chartData = signal<MttrData[]>([]);
  public chartTitle = 'Mean Time to Resolve (MTTR)';

  constructor(private intl: IntlService) {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.mttrData();
        const filteredData = data ? data.filter((item) => item.description !== null) : [];
        this.chartData.set(filteredData);
        this.isLoading.set(false); // Set to false regardless of data presence
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public handleSeriesClick(event: SeriesClickEvent): void {
    this.seriesClick.emit(event);
  }

  public labelContent = (args: any): string => {
    return this.intl.formatNumber(parseFloat(args.value), 'n2');
  };

  public truncateCategoryLabel = (args: any): string => {
    const label = args.value;
    const maxLength = 20;
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  };
}
