import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DetectionManager } from '../../../../../../core/models/entities';

@Injectable({
  providedIn: 'root',
})
export class DetectionManagerFormService {
  constructor() {}

  createDetectionManagerForm(detectionManager = new DetectionManager()) {
    return new FormGroup<DetectionManagerForm>({
      pk: new FormControl(detectionManager.pk || null, Validators.required),
      title: new FormControl(detectionManager.title || null, Validators.required),
      category: new FormControl(detectionManager.category ? detectionManager.category.name : null, Validators.required),
      entity_id: new FormControl(detectionManager.entity_id || null, Validators.required),
      description: new FormControl(detectionManager.description || null, Validators.required),
      status: new FormControl(detectionManager.status || null, Validators.required),
      default_state: new FormControl(detectionManager.default_state === 'On' || null),
    });
  }
}

export interface DetectionManagerForm {
  pk: FormControl<string | null>;
  title: FormControl<string | null>;
  category: FormControl<string | null>;
  entity_id: FormControl<string | null>;
  description: FormControl<string | null>;
  status: FormControl<string | null>;
  default_state: FormControl<boolean | null>;
}
