@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  @if (chartData().length > 0) {
    <kendo-chart
      class="chart-container"
      (seriesClick)="handleSeriesClick($event)"
      (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
    >
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
      <kendo-chart-tooltip format="Average Resolution time: {0} hours"></kendo-chart-tooltip>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="column"
          [data]="chartData()"
          field="count"
          categoryField="description"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [labels]="{ content: truncateCategoryLabel }"> </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [labels]="{ format: '{0}' }">
          <kendo-chart-value-axis-item-title text="Hours"></kendo-chart-value-axis-item-title>
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
    </kendo-chart>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
  } @else {
    <div class="no-data-message">
      <h2>No Data Available</h2>
      <p>There is currently no MTTR data to display.</p>
    </div>
  }
}
