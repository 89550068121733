import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { User } from '../models/user';

export const customerRouteGuard: CanActivateFn = (route, state) => {
  let router = inject(Router);
  let user = inject(User);

  if (!user.access_token) {
    return router.createUrlTree(['/auth/login'], {queryParams: {redirectUrl: state.url}});
  }

  // Check if the user is an admin
  if (user.groups.includes('admin')) {
    return true; // Allow unrestricted access for admins
  }

  // Check if the user is in the "customer" group and trying to access the "/customer/organizations" route
  if (user.groups.includes('customer') && state.url === '/customer/organizations') {
    return router.createUrlTree(['customer/unauthorized']);
  }

  const url = state.url;
  const params = new URLSearchParams(url.split('?')[1] || '');
  const organizationId = Number(params.get('organizationId'));

  // Allow access if there's no organizationId or it's 0 (initial login state)
  // Initial login state will NOT have query string.
  if (isNaN(organizationId) || organizationId === 0) {
    return true;
  }

  // Check if the organizationId exists in the user's organizations
  const hasAccess = user.organizations.some((org) => org.id === organizationId);

  if (!hasAccess) {
    return router.createUrlTree(['customer/unauthorized']);
  }

  user.organization_id = organizationId;
  user.organization_account_type = user.organizations.find((org) => org.id === organizationId)?.account_type || 0;

  return true;
};
