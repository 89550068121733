import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { OrgAppbarComponent } from '../../../layout/components/org-appbar/org-appbar.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { BulletinComponent } from './components/bulletin/bulletin.component';
import { ServerComponent } from './components/server/server.component';
import { TravelAdvisoriesComponent } from './components/travel-advisories/travel-advisories.component';
import { VulnerabilityComponent } from './components/vulnerability/vulnerability.component';
import { WhitelistComponent } from './components/whitelist/whitelist.component';

@Component({
  selector: 'app-soar-rules',
  standalone: true,
  imports: [LayoutModule, OrgAppbarComponent, ReactiveFormsModule, AccountsComponent, BulletinComponent, ServerComponent, TravelAdvisoriesComponent, VulnerabilityComponent, WhitelistComponent],
  templateUrl: './soar-rules.component.html',
  styleUrl: './soar-rules.component.scss',
})
export class SoarRulesComponent {}
