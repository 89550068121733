import { Routes } from '@angular/router';
import { ServicenowComponent } from './components/servicenow/servicenow.component';
import { ConnectwiseComponent } from './components/connectwise/connectwise.component';
import { IngestorSourcesComponent } from './components/ingestors/ingestor-sources/ingestor-sources.component';
import { RecordComponent } from './components/ingestors/ingestor-sources/components/record/record.component';
import { MapAccountsComponent } from './components/ingestors/ingestor-sources/components/map-accounts/map-accounts.component';

export const routes: Routes = [
    {
        path: '',
        children: [
            { path: 'ingestor-sources', component: IngestorSourcesComponent },
            { path: 'servicenow', component: ServicenowComponent },
            { path: 'connectwise', component: ConnectwiseComponent },
            { path: 'record', component: RecordComponent },
            { path: 'map-accounts', component: MapAccountsComponent },
        ]
    }
]; 