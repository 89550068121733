import { Component, ComponentRef, ElementRef, ViewChild, ViewContainerRef, ViewEncapsulation, effect, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportComponent, PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { filter, takeUntil } from 'rxjs';
import { CommonModule, formatDate } from '@angular/common';
import { OrganizationService } from '../../admin/organizations/services/organization.service';
import { ExecutiveSummaryComponent } from './components/reports/executive-summary/executive-summary.component';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { SchedulerComponent } from './components/forms/scheduler/scheduler.component';
import { PopupHostComponent } from '../../../core/components/popup-host.component';
import { CyflarePeriodComponent } from '../../../core/components/cyflare-period/cyflare-period.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CasesComponent } from './components/reports/cases/cases.component';
import { O365AccountsComponent } from './components/reports/o365-accounts/o365-accounts.component';
import { SocEventsComponent } from './components/reports/soc-events/soc-events.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-reporting',
  standalone: true,
  imports: [CyflarePeriodComponent, ButtonsModule, CommonModule, PDFExportModule, LayoutModule],
  templateUrl: './reporting.page.html',
  styleUrls: ['./reporting.page.scss'],
  providers: [AnalyticsService],
})
export class ReportingComponent extends PopupHostComponent {
  @ViewChild('pdf', { static: true }) pdf!: PDFExportComponent;
  @ViewChild('container', { read: ViewContainerRef, static: false }) container!: ViewContainerRef;
  @ViewChild('headingDiv', { static: true }) headingDiv!: ElementRef;

  public xIcon: SVGIcon = xIcon;
  private route = inject(ActivatedRoute);
  private orgService = inject(OrganizationService);
  private organizationName: string = 'Unknown';
  public selectedDateRange = signal<string[]>([]);
  private organizationId: number | null = null;
  private componentRef: ComponentRef<any> | null = null;

  selectedReport: any = null;

  currentDate = new Date();
  formattedDate = this.currentDate.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  shortDate = formatDate(this.currentDate, 'yyyyMMdd', 'en-US');

  public get exportOptions() {
    const options = [
      {
        id: 'pdf',
        text: 'PDF File',
      },
      {
        id: 'excel',
        text: 'Excel file',
      },
    ];
    if (this.selectedReport) {
      if (this.selectedReport.grid) {
        return options;
      } else {
        return options.filter((X: any) => X.id != 'excel');
      }
    } else {
      return [];
    }
  }

  public reports = [
    {
      title: 'Executive Summary',
      description: 'A high-level summary of detections, SLA violations, tickets and response time. Great for non-technical stakeholders.',
      component: ExecutiveSummaryComponent,
      landscape: false,
      grid: false,
    },
    {
      title: 'All SOC Events Report',
      description: 'A table of all alerts received and triaged by the SOC.',
      component: SocEventsComponent,
      landscape: true,
      grid: true,
    },
    {
      title: 'All Ticketed Events Report',
      description: 'A table of events that necessitated ticket creation for customer engagement and decision-making.',
      component: CasesComponent,
      landscape: true,
      grid: true,
    },
    // MF: commented out for merge to dev. Add back in when each report is ready
    // {
    //   title: 'SOC Service Report',
    //   description: 'A detailed report of alerts, potential breaches, indicators of compromise, and potential insider threats. Great for [audience].',
    // },
    // {
    //   title: 'O365 Accounts Report',
    //   description: 'something something something',
    //   component: O365AccountsComponent,
    //   landscape: true,
    // },
    // {
    //   title: 'Windows Accounts Report',
    //   description: 'something something something',
    // },
    // {
    //   title: 'AWS Accounts Report',
    //   description: 'something something something',
    // },
    // {
    //   title: 'Critical Actions Report',
    //   description: 'something something something',
    // },
  ];

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => !!params['organizationId'])).subscribe({
          next: (params) => {
            this.organizationId = +params['organizationId'];
            this.fetchOrganizationName(this.organizationId);
            this.selectReport(this.selectedReport || this.reports[0]);
          },
          error: (error) => {
            console.error('Error processing query parameters:', error);
          },
        });
      },
      { allowSignalWrites: true },
    );
  }

  onItemClick(event: any): void {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}`;

    if (event.id === 'pdf') {
      if (this.selectedReport.grid) {
        if (this.componentRef) {
          this.componentRef.instance.headerHtml = this.headingDiv.nativeElement.innerHTML;
          this.componentRef.instance.landscape = this.selectedReport.landscape;
          this.componentRef.instance.exportPDF(fileName);
        }
      } else {
        this.pdf.saveAs(fileName);
      }
    } else if (event.id === 'excel' && this.selectedReport.grid) {
      // Use exportExcel from the component instance directly
      if (this.componentRef) {
        this.componentRef.instance.exportExcel(fileName);
      }
    }
  }

  private fetchOrganizationName(organizationId: number) {
    this.orgService
      .getOrganizationById(organizationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.organizationName = response.name;
        },
        error: (error) => {
          console.error('Error fetching Organization Name:', error);
        },
      });
  }

  public manageSchedule(): void {
    this.togglePopup();
  }

  public togglePopup(): void {
    const options = {
      animate: { type: 'slide', direction: 'left', duration: 200 },
      anchorAlign: { horizontal: 'right', vertical: 'top' },
      popupAlign: { horizontal: 'right', vertical: 'top' },
    };

    // Open the scheduler popup and set reportTitle
    this.openPopup(this.bodyElement, SchedulerComponent, options);

    const schedulerComponent = this.popupRef?.content.instance as SchedulerComponent;

    // Set reportTitle based on selected report
    schedulerComponent.reportTitle = this.selectedReport?.title || '';

    schedulerComponent.close.subscribe(() => {
      this.closePopup();
    });
  }

  // Set selected report on click
  selectReport(report: any): void {
    this.container.clear();
    if (report && this.selectedDateRange()) {
      this.selectedReport = report;
      this.componentRef = this.container.createComponent(this.selectedReport.component);
      this.componentRef.instance.selectedDateRange = this.selectedDateRange;
    }
  }

  generatePdf() {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}.pdf`;
    this.pdf.saveAs(fileName);
  }

  formatDate(dateString: string, format: string) {
    return formatDate(dateString.substring(0, 10), format, 'en-US');
  }

  generateExcel() {
    const fileName = `${this.organizationName}_${this.selectedReport.title}_${this.shortDate}.pdf`;
    this.pdf.saveAs(fileName);
  }
}
