<div class="content">
  <div class="example-wrap">
    <div class="popout-box">
      <h2 class="k-form-title">Notifications</h2>
      <button
        kendoButton
        [svgIcon]="xIcon"
        (click)="cancel()"
        fillMode="flat"
      ></button>
    </div>
    <div class="notification-list">
      @if (notifications().length === 0) {
        <div>No new notifications</div>
      } @else {
        <kendo-listview
          [data]="notifications()"
          [style.height.vh]="85"
        >
          <ng-template
            kendoListViewItemTemplate
            let-dataItem="dataItem"
          >
            <div class="notification-item py-4">
              <div class="notification-item-content">
                <div class="notification-item-title">
                  <a
                    [href]="dataItem.link"
                    [innerHTML]="dataItem.description"
                    [attr.aria-label]="dataItem.description"
                    (click)="markAsReadWithDelay(dataItem)"
                  ></a>
                </div>
              </div>
              <div class="notification-item-actions">
                <button
                  kendoButton
                  [svgIcon]="xIcon"
                  (click)="markAsRead(dataItem)"
                  fillMode="flat"
                ></button>
              </div>
            </div>
          </ng-template>
        </kendo-listview>
      }
    </div>
  </div>
</div>
