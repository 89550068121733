<app-org-appbar></app-org-appbar><br />
<div class="container-fluid p-0">
  <div
    class="row"
    style="height: calc(100vh - 78px)"
  >
    <!-- Integration List Section -->
    <div class="col-4 integration-list-container">
      <div class="integration-actions-container">
        <div #integrationactions>
          <div class="row">
            <div class="integration-container pt-4">
              <div>
                <h5>Ticketing</h5>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div style="overflow-y: auto; height: calc(100vh - 245px)">
        <kendo-tilelayout-item-body class="flex-body p-0">
          @for (ingestor of ingestorIntegrations(); track ingestor.id) {
            <div
              (click)="ingestorIntegrationClick(ingestor)"
              [ngClass]="{ active: true }"
              style="margin-bottom: 5px; border-radius: 10px"
            >
              <kendo-card
                class="centered-card"
                width="100%"
              >
                <img
                  [src]="ingestor.icon"
                  alt="{{ ingestor.name }}"
                />
              </kendo-card>
            </div>
          }
        </kendo-tilelayout-item-body>
      </div>
    </div>
    <div class="col-8 integration-preview-container">
      <div style="padding: 0 20px 20px 20px; width: 100%">
        <div class="integration-app-container">
          <h4 class="mb-3">{{ selectedIngestorIntegrationType?.name }}</h4>
          <router-outlet></router-outlet>
        </div>
      </div>

      @if (saveConfirmDialog === true) {
        <kendo-dialog
          title="Successful"
          (close)="closeDialog('cancel')"
          [minWidth]="250"
          [width]="450"
        >
          <p style="margin: 30px; text-align: center">Connection Saved Successfully.</p>
          <kendo-dialog-actions>
            <button
              kendoButton
              themeColor="primary"
              type="button"
              (click)="closeDialog('no')"
            >
              Ok
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
      }
    </div>
  </div>
</div>
