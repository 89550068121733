<kendo-grid
  [rowReorderable]="true"
  [kendoGridBinding]="contacts()"
  [pageSize]="15"
  [pageable]="true"
  [navigable]="true"
  (add)="addHandler($event)"
  (save)="saveHandler($event)"
  (cancel)="cancelHandler($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (cellClick)="cellClickHandler($event)"
  (rowReorder)="dragAndDropHandler($event)"
>
  <ng-template
    kendoGridToolbarTemplate
    style="display: flex; flex-direction: row; justify-content: space-between"
  >
    <div class="initialization-container">
      <kendo-label
        text="Initialization Level"
        for="dropdownlist"
        class="mb-1"
      ></kendo-label>
      <kendo-dropdownlist
        #adminorcustomer_organizations_soccommunications_calltree_initializationlevel
        [(value)]="initLevel"
        id="dropdownlist"
        [data]="initLevels"
        (click)="$event.stopPropagation()"
        (valueChange)="onInitLevelChange($event)"
        [textField]="'text'"
        [valueField]="'value'"
        [valuePrimitive]="true"
        class="mb-1"
      ></kendo-dropdownlist>
    </div>
    <div>
      <button
        #adminorcustomer_organizations_soccommunications_calltree_addnewcontact
        kendoGridAddCommand
        style="margin-left: auto"
      >
        Add New Contact
      </button>
    </div>
  </ng-template>
  <kendo-grid-rowreorder-column [width]="40"></kendo-grid-rowreorder-column>
  <kendo-grid-column
    field="name"
    title="Name"
    editor="text"
    [width]="170"
  ></kendo-grid-column>
  <kendo-grid-column
    [width]="160"
    field="phone"
    title="Phone #"
    editor="text"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem="dataItem"
    >
      <kendo-maskedtextbox
        [value]="dataItem.phone"
        [mask]="'(000) 000-0000'"
        [disabled]="true"
      >
      </kendo-maskedtextbox>
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <kendo-maskedtextbox
        [style.width.px]="170"
        [formControl]="formGroup!.controls.phone"
        [mask]="'(000) 000-0000'"
      >
      </kendo-maskedtextbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="100"
    field="extension"
    title="Extension"
    editor="text"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [width]="100"
    field="dispatch_line"
    editor="boolean"
    title="Dispatch Line"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <input
        kendoCheckBox
        type="checkbox"
        [(ngModel)]="dataItem.dispatch_line"
        [disabled]="true"
      />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="200"
    field="working_days"
    title="Days Available"
    editor="text"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      @for (day of dataItem.working_days; track day) {
        {{ weekdayMap[day - 1] }}
      } @empty {
        <span>No working days available</span>
      }
    </ng-template>
    <ng-template kendoGridEditTemplate>
      <kendo-multiselect
        [formControl]="formGroup!.controls.working_days"
        (valueChange)="onDaysChange($event)"
        [valueField]="'value'"
        [textField]="'text'"
        [valuePrimitive]="true"
        [data]="[
          { text: 'M', value: 1 },
          { text: 'T', value: 2 },
          { text: 'W', value: 3 },
          { text: 'R', value: 4 },
          { text: 'F', value: 5 },
          { text: 'S', value: 6 },
          { text: 'U', value: 7 }
        ]"
      ></kendo-multiselect>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="150"
    field="available_from"
    title="Available From"
    editor="date"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
    >
      <kendo-timepicker [formControl]="formGroup!.controls.available_from"> </kendo-timepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="150"
    field="available_to"
    title="Available To"
    editor="date"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
    >
      <kendo-timepicker [formControl]="formGroup!.controls.available_to"></kendo-timepicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="timezone"
    title="Timezone"
    editor="text"
    [width]="150"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <kendo-dropdownlist
        defaultItem="Select timezone"
        [data]="timeZones"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('timezone')"
        [style]="{ width: '100%' }"
      >
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column
    [width]="100"
    title=""
  >
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        #adminorcustomer_organizations_soarrules_serverexceptions_save
        kendoGridSaveCommand
        [disabled]="formGroup?.invalid!"
      >
        Save
      </button>
      <button
        #adminorcustomer_organizations_soarrules_serverexceptions_remove
        kendoGridRemoveCommand
      >
        Remove
      </button>
      <button
        #adminorcustomer_organizations_soarrules_serverexceptions_cancel
        kendoGridCancelCommand
      >
        Cancel
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
