/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { EventEmitter, LOCALE_ID, Injectable, Inject, Pipe, NgModule } from '@angular/core';
import { validatePackage } from '@progress/kendo-licensing';
import * as intl from '@progress/kendo-intl';

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-intl',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1728372153,
  version: '16.11.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
const DOCS_URL = 'https://www.telerik.com/kendo-angular-ui/components/internationalization/troubleshooting/';
/**
 * @hidden
 */
const errorSolutions = {
  'NoCurrency': `Solution: ${DOCS_URL}#toc-no-currency`,
  'NoCurrencyDisplay': `Solution: ${DOCS_URL}#toc-no-currency-display`,
  'NoCurrencyRegion': `Solution: ${DOCS_URL}#toc-no-currency-region`,
  'NoDateFieldNames': `Solution: ${DOCS_URL}#toc-no-date-filed-names`,
  'NoFirstDay': `Solution: ${DOCS_URL}#toc-no-first-day`,
  'NoGMTInfo': `Solution: ${DOCS_URL}#toc-no-gmt-info`,
  'NoLocale': `Solution: ${DOCS_URL}#toc-no-locale`,
  'NoValidCurrency': `Solution: ${DOCS_URL}#toc-no-valid-currency`,
  'NoWeekData': `Solution: ${DOCS_URL}#toc-no-week-data`
};
function formatMessage(error) {
  const message = error.message;
  const errorSolution = errorSolutions[Object.keys(errorSolutions).filter(key => message.indexOf(key) === 0)[0]];
  return errorSolution ? `${message} ${errorSolution}` : message;
}
// eslint-disable-next-line @typescript-eslint/ban-types
function intlMethod(fn) {
  return function (...values) {
    try {
      return fn(...values);
    } catch (error) {
      error.message = formatMessage(error);
      throw error;
    }
  };
}
/**
 * @hidden
 */
const dateFormatNames = intlMethod(intl.dateFormatNames);
/**
 * @hidden
 */
const dateFieldName = intlMethod(intl.dateFieldName);
/**
 * @hidden
 */
const firstDay = intlMethod(intl.firstDay);
/**
 * @hidden
 */
const format = intlMethod(intl.format);
/**
 * @hidden
 */
const formatDate = intlMethod(intl.formatDate);
/**
 * @hidden
 */
const formatNumber = intlMethod(intl.formatNumber);
/**
 * @hidden
 */
const load = intlMethod(intl.load);
/**
 * @hidden
 */
const numberSymbols = intlMethod(intl.numberSymbols);
/**
 * @hidden
 */
const parseDate = intlMethod(intl.parseDate);
/**
 * @hidden
 */
const parseNumber = intlMethod(intl.parseNumber);
/**
 * @hidden
 */
const splitDateFormat = intlMethod(intl.splitDateFormat);
/**
 * @hidden
 */
const toString = intlMethod(intl.toString);
/**
 * @hidden
 */
const weekendRange = intlMethod(intl.weekendRange);
/**
 * Sets a pre-built locale.
 *
 * @params data - The pre-built locale data.
 */
const setData = data => intl.setData(data);
/**
 * Retrieves the locale data for the specified locale.
 *
 * @params locale - The locale id.
 * @returns data - The locale data.
 */
const localeData = locale => {
  try {
    return intl.localeInfo(locale);
  } catch (error) {
    error.message = formatMessage(error);
    throw error;
  }
};
const LOCALE_REGEX = /_/g;
/**
 * @hidden
 */
function cldrServiceFactory(localeId) {
  return new CldrIntlService(localeId);
}
/**
 * An abstract base class that implements
 * the Internationalization service methods
 * for the current locale.
 */
class IntlService {
  /**
   * @hidden
   */
  constructor() {
    /**
     * @hidden
     */
    this.changes = new EventEmitter();
    validatePackage(packageMetadata);
  }
  /**
   * Notifies that the service was changed.
   */
  notify() {
    this.changes.emit();
  }
}
IntlService.ɵfac = function IntlService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IntlService)();
};
IntlService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: IntlService,
  factory: function IntlService_Factory(__ngFactoryType__) {
    let __ngConditionalFactory__ = null;
    if (__ngFactoryType__) {
      __ngConditionalFactory__ = new __ngFactoryType__();
    } else {
      __ngConditionalFactory__ = cldrServiceFactory(i0.ɵɵinject(LOCALE_ID));
    }
    return __ngConditionalFactory__;
  },
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntlService, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: cldrServiceFactory,
      deps: [LOCALE_ID]
    }]
  }], function () {
    return [];
  }, null);
})();
/**
 * The Internationalization service implemented by using
 * the CLDR Database via the `@progress/kendo-intl` package.
 */
class CldrIntlService extends IntlService {
  /**
   * Creates a new instance of the service with the ID of the specified locale.
   *
   * Note that the parts of the locale ID can be separated by either `_` (underscore)
   * or `-` (dash).
   *
   * @param localeId - The default locale ID.
   */
  constructor(localeId) {
    super();
    this.localeId = localeId;
  }
  /**
   * Gets or sets the current locale ID.
   */
  get localeId() {
    return this.locale;
  }
  set localeId(value) {
    // Angular locales use underscore, for example, en_US
    // while IETF BCP-47 specifies a dash.
    // https://tools.ietf.org/html/bcp47
    const locale = value.replace(LOCALE_REGEX, '-');
    if (locale !== this.locale) {
      this.locale = locale;
      this.notify();
    }
  }
  /**
   * Formats a string with placeholders such as
   * `Total amount {0:c}`.
   *
   * @param format - The format string.
   * @param values - One or more values to output in the format string placeholders.
   * @return - The formatted string.
   */
  format(format$1, ...values) {
    return format(format$1, values, this.localeId);
  }
  /**
   * Converts an object into a string based on the specified format.
   *
   * @param value - The value to format.
   * @param format - The format to use.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return The formatted object.
   */
  toString(value, format, localeId) {
    return toString(value, format, localeId || this.localeId);
  }
  /**
   * Converts a `Date` object into a string based on the specified format.
   * If no format is provided, the default short date format is used.
   *
   * @param value - The date to format.
   * @param format - The format string or options.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return The formatted date.
   */
  formatDate(value, format, localeId) {
    return formatDate(value, format, localeId || this.localeId);
  }
  /**
   * Converts a string into a `Date` object based on the specified format.
   *
   * @param value - The string to convert.
   * @param format - The format strings or options.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return The parsed date.
   */
  parseDate(value, format, localeId) {
    return parseDate(value, format, localeId || this.localeId);
  }
  /**
   * Converts a string into a `Number`.
   *
   * @param value - The string to convert.
   * @param format - The format string or options.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return The parsed number.
   */
  parseNumber(value, format, localeId) {
    return parseNumber(value, localeId || this.localeId, format);
  }
  /**
   * Converts a `Number` into a string based on the specified format.
   *
   * @param value - The number to format.
   * @param format - The format string or options.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return The formatted number.
   */
  formatNumber(value, format, localeId) {
    return formatNumber(value, format, localeId || this.localeId);
  }
  /**
   * Returns the date names from the current locale based on the option.
   *
   * The available `type` values are:
   * - `era`
   * - `year`
   * - `quarter`
   * - `month`
   * - `week`
   * - `day`
   * - `dayperiod`
   * - `hour`
   * - `minute`
   * - `second`
   * - `zone`
   *
   * The available `nameType` values are:
   * - `wide`
   * - `narrow`
   * - `short`
   *
   * @param options - Detailed configuration for the desired date field name.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return - The day names from the current locale based on the option.
   * @returns The localized date field name from the current locale based on the option.
   *
   * @example
   * ```ts
   * dateFieldName({ type: 'day' });                      //returns 'day';
   * dateFieldName({ type: 'day', nameType: 'wide' });    //returns 'day';
   * dateFieldName({ type: 'month', nameType: 'short' }); //returns 'mo.';
   * dateFieldName({ type: 'month', nameType: 'wide' });  //returns 'month';
   * ```
   */
  dateFieldName(options, localeId) {
    return dateFieldName(options, localeId || this.localeId);
  }
  /**
   * Returns a localized date field name based on specific dateFieldName options.
   *
   * The available type values are:
   * - `day`
   * - `dayperiod`
   * - `months`
   * - `quarters`
   * - `eras`
   *
   * @param options - Detailed configuration for the desired date format.
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return - The day names from the current locale based on the option.
   */
  dateFormatNames(options, localeId) {
    return dateFormatNames(localeId || this.localeId, options);
  }
  /**
   * Splits the date format into objects containing information about each part of the pattern.
   *
   * @param format The format string or options.
   * @param localeId The optional locale id. If not specified, the `"en"` locale id is used.
   * @returns The date format parts.
   */
  splitDateFormat(format, localeId) {
    return splitDateFormat(format, localeId || this.localeId);
  }
  /**
   * Returns the number symbols from the current locale based on the option.
   *
   * @param localeId - The locale ID to use in place of the default one. Optional.
   * @return - The number symbols from the current locale.
   */
  numberSymbols(localeId) {
    return numberSymbols(localeId || this.localeId);
  }
  /**
   * Returns the first day index starting from Sunday.
   *
   * @param localeId - The locale ID. Defaults to the current locale ID.
   * @return - The index of the first day of the week (0 == Sunday).
   */
  firstDay(localeId) {
    return firstDay(localeId || this.localeId);
  }
  /**
   * Returns the start and end index of the locale weekend starting from Sunday.
   *
   * @param localeId - The locale ID. Defaults to the current locale ID.
   * @return - The start and end index of the locale weekend (0 == Sunday).
   */
  weekendRange(localeId) {
    return weekendRange(localeId || this.localeId);
  }
}
CldrIntlService.ɵfac = function CldrIntlService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CldrIntlService)(i0.ɵɵinject(LOCALE_ID));
};
CldrIntlService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CldrIntlService,
  factory: CldrIntlService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CldrIntlService, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [LOCALE_ID]
      }]
    }];
  }, null);
})();
const isNumeric = value => !isNaN(value - parseFloat(value));
/**
 * Formats a date value to a string based on the requested format.
 * This pipe uses the [IntlService]({% slug api_intl_intlservice %}).
 *
 * @example
 * ```ng-template-no-run
 * <ul>
 *    <li>{{date | kendoDate }}</li>
 *    <li>{{milliseconds | kendoDate: 'M/dd/yyy' }}</li>
 *    <li>{{stringDate | kendoDate: 'G' }}</li>
 * </ul>
 * ```
 */
class DatePipe {
  /**
   * @hidden
   */
  constructor(intlService) {
    this.intlService = intlService;
  }
  /**
   * Converts a `Date` object into a string based on the specified format.
   * If no format is provided, the default short date format is used.
   *
   * @param value - The date to format.
   * @param format - The format string or options.
   * @param localeId - (Optional) The ID of the locale which will be used instead of the default one.
   * @return - The formatted date.
   */
  transform(value, format = "", localeId) {
    value = this.normalize(value);
    if (value) {
      return this.intlService.formatDate(value, format, localeId);
    }
    return value;
  }
  normalize(value) {
    if (value && typeof value === 'string') {
      value = this.intlService.parseDate(value);
    } else if (value && isNumeric(value)) {
      value = new Date(parseFloat(value));
    }
    return value;
  }
}
DatePipe.ɵfac = function DatePipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DatePipe)(i0.ɵɵdirectiveInject(IntlService, 16));
};
DatePipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "kendoDate",
  type: DatePipe,
  pure: true,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePipe, [{
    type: Pipe,
    args: [{
      name: 'kendoDate',
      standalone: true
    }]
  }], function () {
    return [{
      type: IntlService
    }];
  }, null);
})();

/**
 * Formats a number value to a string based on the requested format.
 * This pipe uses the [`IntlService`]({% slug api_intl_intlservice %}).
 *
 * @example
 * ```ng-template-no-run
 *   <ul>
 *     <li>{{decimal | kendoNumber:'c' }}</li>
 *     <li>{{stringNumber | kendoNumber:'p' }}</li>
 *     <li>{{int | kendoNumber:'##.00' }}</li>
 *  </ul>
 * ```
 */
class NumberPipe {
  /**
   * @hidden
   */
  constructor(intlService) {
    this.intlService = intlService;
  }
  /**
   * Converts a `Number` object into a string based on the specified format.
   * If no format is provided, the value is formatted as decimal number using the
   * [`"n"`](https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md#standard) format.
   *
   * @param value - The numer that will be formatted.
   * @param format - The format string or options.
   * @param localeId - (Optional) The locale ID that will be used in place of the default one.
   * @return - The formatted number.
   */
  transform(value, format, localeId) {
    if (typeof value === 'string') {
      value = this.intlService.parseNumber(value);
    }
    if (value !== null && value !== undefined) {
      return this.intlService.formatNumber(value, format, localeId);
    }
    return value;
  }
}
NumberPipe.ɵfac = function NumberPipe_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NumberPipe)(i0.ɵɵdirectiveInject(IntlService, 16));
};
NumberPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "kendoNumber",
  type: NumberPipe,
  pure: true,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberPipe, [{
    type: Pipe,
    args: [{
      name: 'kendoNumber',
      standalone: true
    }]
  }], function () {
    return [{
      type: IntlService
    }];
  }, null);
})();

/**
 * Utility array that contains all `kendoNumber` related components and directives
 */
const KENDO_NUMBER = [NumberPipe];
/**
 * Utility array that contains all `kendoDate` related components and directives
 */
const KENDO_DATE = [DatePipe];
/**
 * Utility array that contains all `@progress/kendo-angular-intl` related components and directives
 */
const KENDO_INTL = [...KENDO_NUMBER, ...KENDO_DATE];

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
 * definition for the Intl services.
 */
class IntlModule {}
IntlModule.ɵfac = function IntlModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IntlModule)();
};
IntlModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IntlModule
});
IntlModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntlModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_INTL],
      exports: [...KENDO_INTL]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CldrIntlService, DatePipe, IntlModule, IntlService, KENDO_DATE, KENDO_INTL, KENDO_NUMBER, NumberPipe, cldrServiceFactory, dateFieldName, dateFormatNames, firstDay, format, formatDate, formatNumber, load, localeData, numberSymbols, parseDate, parseNumber, setData, splitDateFormat, toString, weekendRange };