import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  flattenErrorMessages(error: any): string {
    let messages: string[] = [];

    for (const key in error) {
      if (error.hasOwnProperty(key)) {
        const value = error[key];
        if (Array.isArray(value)) {
          messages = messages.concat(value.map(msg => msg.replace(/\.$/, '')));
        } else if (typeof value === 'string') {
          messages.push(value.replace(/\.$/, ''));
        } else if (typeof value === 'object') {
          messages.push(this.flattenErrorMessages(value).replace(/\.$/, ''));
        }
      }
    }

    return messages.join(', ');
  }
}
