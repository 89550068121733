import { Component, ViewChild, effect, inject, output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DetectionManagerService } from '../../services/manager.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { takeUntil } from 'rxjs';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { DetectionManager, Tag } from '../../../../../core/models/entities';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DetectionManagerFormService } from './service/manager-form.service';
import { TagsService } from '../../../../../core/services/tags.service';

@Component({
  selector: 'app-manager-form',
  standalone: true,
  imports: [InputsModule, LabelModule, IconsModule, ButtonModule, FormsModule, DialogsModule, ReactiveFormsModule, GridModule, DropDownListModule, LayoutModule, PopupModule, MultiSelectComponent],
  templateUrl: './manager-form.component.html',
  styleUrl: './manager-form.component.scss',
  providers: [TagsService],
})
export class ManagerFormComponent extends BaseComponent {
  @ViewChild('detectionTagsRef') detectionTagsRef!: MultiSelectComponent;
  private detectionManagerService = inject(DetectionManagerService);
  private forms = inject(DetectionManagerFormService);
  public xIcon: SVGIcon = xIcon;
  public detection = signal<DetectionManager>(new DetectionManager());
  public customValues = signal<boolean>(false);
  public detectionForm = this.forms.createDetectionManagerForm();
  close = output<void>();
  opened = false;
  private tagsService = inject(TagsService);
  status: any;
  public availableTags = signal<Tag[]>([]);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      const detection = {
        ...this.detection(),
      };

      this.detectionForm.patchValue({
        pk: detection.pk,
        title: detection.title,
        category: detection.category.name,
        entity_id: detection.entity_id,
        description: detection.description,
        status: detection.status,
        default_state: detection.default_state === 'On',
        tags: detection.tags || [],
      });
    },
      { allowSignalWrites: true });
    this.loadTags();
  }

  public save($event: Event) {
    $event.preventDefault();
    let detectionForm = {
      title: this.detectionForm.value.title,
      category: { name: this.detectionForm.value.category },
      entity_id: this.detectionForm.value.entity_id,
      description: this.detectionForm.value.description,
      status: this.detectionForm.value.status,
      default_state: this.detectionForm.value.default_state ? 'On' : 'Muted',
      tags: this.detectionForm.value.tags?.map((x: any) => x.id) || [],
    };

    this.detectionManagerService
      .addDetectionManagerItem(detectionForm)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public clear() {
    this.detectionForm.reset();
  }

  public cancel() {
    this.clear();
    this.close.emit();
  }

  update($event: Event) {
    $event.preventDefault();
    let payload = {
      title: this.detectionForm.value.title,
      category: { name: this.detectionForm.value.category },
      entity_id: this.detectionForm.value.entity_id,
      description: this.detectionForm.value.description,
      status: this.detectionForm.value.status,
      default_state: this.detectionForm.value.default_state ? 'On' : 'Muted',
      tags: this.detectionForm.value.tags?.map((x: any) => x.id) || [],
    };

    const id = this.detectionForm.value.pk!;
    this.detectionManagerService
      .updateDetectionManagerItem(id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  delete($event: Event) {
    $event.preventDefault();
    const id = this.detectionForm.value.pk!;
    this.detectionManagerService
      .deleteDetectionManagerItem(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public open(): void {
    this.opened = true;
  }

  public closeDialog(status: string): void {
    this.opened = false;
  }

  public onStatusChange(event: any): void {
    this.status = event;
    this.open();
  }

  private loadTags(): void {
    this.tagsService.getTags().subscribe({
      next: (tags) => {
        this.availableTags.set(tags); // Now "tags" is an array
      },
      error: (error) => {
        console.error('Error loading tags:', error);
      },
    });
  }

  public onTagFilterChange(searchTerm: string): void {
    this.tagsService.searchTags(searchTerm).subscribe({
      next: (data) => {
        this.availableTags.set(data);
        this.customValues.set(this.availableTags().length == 0);

        setTimeout(() => {
          if (this.customValues() && this.detectionTagsRef) {
            this.detectionTagsRef.focusItemAt(-1);
          }
          else {
            this.detectionTagsRef.focusItemAt(0);
          }
        }, 20);
      },
      error: (error) => {
        console.error('Error adding new tag:', error);
      },
    });
  }
  public onTagValueChange(tags: any[]): void {
    // ToDo: Implement odata seach
    // ToDo: Only show the Add New Tag option when the users input is not in availableTags
    // ToDo: prevent the dropdown from closing at all when the tag is added
    const newTags: Tag[] = tags.map((tag) => {
      if (typeof tag === 'string') {
        return { name: tag } as Tag;
      }
      return tag;
    });

    const newTag = newTags.at(-1);
    if (newTag && !newTag.id) {
      this.detectionForm.get('tags')?.value?.pop();
      newTags.pop();
      const tagPayload = { name: newTag.name };
      this.tagsService.addTag(tagPayload).subscribe({
        next: (addedTag) => {
          const currentTags = this.detectionForm.get('tags')?.value || [];
          this.detectionForm.get('tags')?.setValue([...currentTags, addedTag]);
          this.availableTags.update(tags => [...tags, newTag]);
          if (this.detectionTagsRef) {
            this.detectionTagsRef.toggle(true);
          }
        },
        error: (error) => {
          console.error('Error adding new tag:', error);
        },
      });
    }
  }
}
