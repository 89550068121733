<div class="hud-container">
  <div class="hud-header">
    <h2 class="hud-title">HUD</h2>
    <button
      kendoButton
      [svgIcon]="arrowRotateCwIcon"
      (click)="refreshHud()"
    >
      Refresh HUD
    </button>
  </div>

  <div class="metrics-container">
    <app-open-threats-widget [threats]="threats()"></app-open-threats-widget>
    <app-threat-reboot [threatReboot]="threatReboot()"></app-threat-reboot>
    <app-outdated-agents [agents]="outdatedAgents()"></app-outdated-agents>
    <app-coverage [coverage]="coverage()"></app-coverage>
  </div>
</div>
