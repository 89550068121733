import { Component } from '@angular/core';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';

@Component({
  selector: 'app-roadmap',
  standalone: true,
  imports: [AdminAppbarComponent],
  templateUrl: './roadmap.component.html',
  styleUrl: './roadmap.component.scss',
})
export class RoadmapComponent {}
