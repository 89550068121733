<kendo-grid
  [data]="data()"
  [pageable]="true"
  [pageSize]="ingestorService.state.take"
  [skip]="ingestorService.state.skip"
  [(filter)]="ingestorService.state.filter"
  [loading]="ingestorService.loading"
  (dataStateChange)="ingestorService.onStateChange($event)"
  [sort]="this.ingestorService.state.sort"
  [sortable]="false"
  [filterable]="false"
  #grid
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      (click)="addInjestorRecord()"
      [svgIcon]="plusIcon"
      themeColor="primary"
    >
      Add Ingestor Integration
    </button>
  </ng-template>
  <kendo-grid-column
    field="id"
    title="Id"
    [width]="75"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="mapped_customer"
    title="Mapped Customer"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="created_by"
    title="Created By"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="created_at"
    title="Created"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="last_sync"
    title="Last Sync"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="active"
    title="Active"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      @if (dataItem.active) {
        ✅
      } @else {
        ⛔
      }
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
