<app-org-appbar></app-org-appbar>
<br />

<kendo-tilelayout [columns]="2" [rowHeight]="'auto'">
    <kendo-tilelayout-item title="Enable White Labeling" [colSpan]="2">
        <kendo-tilelayout-item-body>
            <form [formGroup]="whiteLabelSettingsForm" novalidate 
                (ngSubmit)="save(whiteLabelSettingsForm.value)">
                <div class="d-flex align-items-center gap-2 mt-2">
                    <kendo-label text="Activate White Label" for="enable">
                        <br/>
                        <kendo-switch 
                            #enable 
                            class="mt-3" 
                            [formControl]="whiteLabelSettingsForm.controls.enabled">
                        </kendo-switch>    
                    </kendo-label>                    
                </div>
                <button kendoButton themeColor="primary" type="submit" class="mt-4" 
                    [disabled]="!whiteLabelSettingsForm.controls.enabled.dirty">
                    Save Settings
                </button>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>    
    <kendo-tilelayout-item title="White Labeling Logo" [row]="2" [col]="1">
        <kendo-tilelayout-item-body>
            @if (logoFilename()) {
                <img [src]="logoFilename()" alt="Logo" class="logo-image" />
                <hr class="mt-3 mb-3">
            }
            <form [formGroup]="whiteLabelFormLogo" novalidate
                (ngSubmit)="uploadLogo(whiteLabelFormLogo.value, whiteLabelFormLogo.valid)">
                <kendo-label text="Custom Logo Image Upload" for="files">
                    <kendo-fileselect class="mt-3" required name="files" #files
                        [restrictions]="restrictions" [formControl]="whiteLabelFormLogo.controls.files" [multiple]="false">
                    </kendo-fileselect>
                </kendo-label>
                <p style="color: red; font-size: .8em; margin-top: .5em;"
                    [hidden]="whiteLabelFormLogo.controls.files.valid || (whiteLabelFormLogo.controls.files.pristine && !whiteLabelFormLogoSubmitted)">
                    Selecting a file is required.
                </p>
                <button kendoButton themeColor="primary" type="submit" class="mt-4" 
                    [disabled]="!whiteLabelFormLogo.valid">
                    Upload Logo
                </button>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item title="White Labeling Favicon" [row]="2" [col]="2">
        <kendo-tilelayout-item-body>
            @if (faviconFilename()) {
                <img [src]="faviconFilename()" alt="Logo" class="logo-image" />
                <hr class="mt-3 mb-3">
            }
            <form [formGroup]="whiteLabelFormFavicon" novalidate
                (ngSubmit)="uploadFavicon(whiteLabelFormFavicon.value, whiteLabelFormFavicon.valid)">
                <kendo-label text="Favicon Upload" for="files">
                    <kendo-fileselect class="mt-3" required name="files" #files
                        [restrictions]="restrictions" [formControl]="whiteLabelFormFavicon.controls.files" [multiple]="false">
                    </kendo-fileselect>
                </kendo-label>
                <p style="color: red; font-size: .8em; margin-top: .5em;"
                    [hidden]="whiteLabelFormFavicon.controls.files.valid || (whiteLabelFormFavicon.controls.files.pristine && !whiteLabelFormFaviconSubmitted)">
                    Selecting a file is required.
                </p>
                <button kendoButton themeColor="primary" type="submit" class="mt-4" 
                    [disabled]="!whiteLabelFormFavicon.valid">
                    Upload Favicon
                </button>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
    <kendo-tilelayout-item title="White Labeling Settings" [row]="3" [colSpan]="2">
        <kendo-tilelayout-item-body>
            <form [formGroup]="whiteLabelSettingsForm" novalidate 
                (ngSubmit)="save(whiteLabelSettingsForm.value)">
                <!-- <kendo-label text="Vanity White Label Url" for="url">
                    <kendo-textbox 
                        class="mt-3" 
                        required 
                        name="url" 
                        #url 
                        [formControl]="whiteLabelSettingsForm.controls.url"
                        placeholder="example.com">
                    </kendo-textbox>
                </kendo-label>
                <br/><br/> -->
                <kendo-label text="White Label Title" for="title">
                    <kendo-textbox 
                        class="mt-3" 
                        required 
                        name="title" 
                        #title
                        [formControl]="whiteLabelSettingsForm.controls.title"
                        placeholder="Title of your White Label Instance">
                    </kendo-textbox>
                </kendo-label>
                <p style="color: red; font-size: .8em; margin-top: .5em;"
                    [hidden]="whiteLabelSettingsForm.controls.title.valid">
                    Please enter a valid title.
                </p>
                <button kendoButton themeColor="primary" type="submit" class="mt-4" 
                    [disabled]="!whiteLabelSettingsForm.controls.title.valid 
                        || !whiteLabelSettingsForm.controls.title.dirty">
                    Save Settings
                </button>
            </form>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>    
</kendo-tilelayout>