import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';

type VirusTotalResult = {
  Entity: string;
  entity_type: string;
  virustotal_score: string;
};

type AbuseIpDbResult = {
  Entity: string;
  entity_type: string;
  abuse_reports: string;
  abuse_confidence_score: string;
};

type HybridAnalysisResult = {
  Entity: string;
  entity_type: string;
  threat_score: string;
};

type CkbLookupResult = {
  Entity: string;
  'Matched Category': string;
};

// Union type for result
type ResultType = VirusTotalResult | AbuseIpDbResult | HybridAnalysisResult | CkbLookupResult;

type DataItem = {
  enrichment_source: string;
  result: ResultType[];
};

@Component({
  selector: 'app-entity-enrichment',
  standalone: true,
  imports: [ListViewModule, LayoutModule, CommonModule],
  templateUrl: './entity-enrichment.component.html',
  styleUrls: ['./entity-enrichment.component.scss'],
})
export class EntityEnrichmentComponent {
  dataItems: DataItem[] = [
    {
      enrichment_source: 'virus_total',
      result: [
        {
          Entity: '8.8.8.8',
          entity_type: 'ip',
          virustotal_score: '67',
        },
        {
          Entity: '1asfwefsweufgi1413124465fdfvbf',
          entity_type: 'file_hash',
          virustotal_score: '12',
        },
      ],
    },
    {
      enrichment_source: 'abuseip_db',
      result: [
        {
          Entity: '8.8.8.8',
          entity_type: 'ip',
          abuse_reports: '67',
          abuse_confidence_score: '50',
        },
      ],
    },
    {
      enrichment_source: 'hybrid_analysis',
      result: [
        {
          Entity: '1asfwefsweufgi1413124465fdfvbf',
          entity_type: 'file_hash',
          threat_score: '12',
        },
      ],
    },
    {
      enrichment_source: 'ckb_lookup',
      result: [
        {
          Entity: 'user@company.com',
          'Matched Category': 'Travel Advisory',
        },
        {
          Entity: '192.168.1.1',
          'Matched Category': 'Vulnerability Scanner',
        },
      ],
    },
  ];

  hasProperty(result: any, property: string): boolean {
    return result.hasOwnProperty(property);
  }

  // Type guard to check if the result has entity_type
  hasEntityType(result: ResultType): result is VirusTotalResult | AbuseIpDbResult | HybridAnalysisResult {
    return 'entity_type' in result;
  }

  // Type guard to check if the result has virustotal_score
  hasVirusTotalScore(result: ResultType): result is VirusTotalResult {
    return 'virustotal_score' in result;
  }

  // Type guard to check if the result has abuse_reports and abuse_confidence_score
  hasAbuseIpDbProperties(result: ResultType): result is AbuseIpDbResult {
    return 'abuse_reports' in result && 'abuse_confidence_score' in result;
  }

  // Type guard to check if the result has threat_score
  hasThreatScore(result: ResultType): result is HybridAnalysisResult {
    return 'threat_score' in result;
  }

  // Type guard to check if the result has Matched Category
  hasMatchedCategory(result: ResultType): result is CkbLookupResult {
    return 'Matched Category' in result;
  }
}
