import { Component, ElementRef } from '@angular/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { GridModule, CellClickEvent } from '@progress/kendo-angular-grid';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { User } from '../../../core/models/entities';
import { AnalystPdiComponent } from './components/analyst-pdi/analyst-pdi.component';
import { PopupHostComponent } from '../../../core/components/popup-host.component';

@Component({
  selector: 'app-soar-operations',
  standalone: true,
  imports: [ChartsModule, LoaderModule, GridModule],
  templateUrl: './soar-operations.component.html',
  styleUrls: ['./soar-operations.component.scss'],
})
export class SoarOperationsComponent extends PopupHostComponent {
  gridElement!: ElementRef;
  public xIcon: SVGIcon = xIcon;

  constructor() {
    super();
  }

  public viewCase($event: CellClickEvent): void {
    this.togglePopup($event.dataItem as User);
  }

  public togglePopup(user = new User()): void {
    const options = {
      animate: { type: 'slide', direction: 'left', duration: 200 },
      anchorAlign: { horizontal: 'right', vertical: 'top' },
      popupAlign: { horizontal: 'right', vertical: 'top' },
    };
    this.openPopup(this.bodyElement, AnalystPdiComponent, options);
  }
}
