import { Component, effect, signal, ViewEncapsulation, inject, ViewChild } from '@angular/core';
import { infoCircleIcon, moreVerticalIcon } from '@progress/kendo-svg-icons';
import { IconsModule, SVGIcon } from '@progress/kendo-angular-icons';
import { CyflarePeriodComponent } from '../../../../../core/components/cyflare-period/cyflare-period.component';
import { HudComponent } from './components/hud/hud.component';
import { AgentStatusComponent } from './components/agent-status/agent-status.component';
import { ThreatStatusComponent } from './components/threat-status/threat-status.component';
import { OpenThreatsComponent } from './components/hud/components/open-threats/open-threats.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AgentStatusData, AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { filter, take } from 'rxjs';
import { RiskyAssetsHeatmapComponent } from './components/risky-assets-heatmap/risky-assets-heatmap.component';
import { EventService } from '../../../../../core/services/event.service';
import { DropDownButtonComponent } from '@progress/kendo-angular-buttons';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-edr',
  standalone: true,
  imports: [CyflarePeriodComponent, HudComponent, IconsModule, AgentStatusComponent, ThreatStatusComponent, OpenThreatsComponent, RouterModule, RiskyAssetsHeatmapComponent, DropDownButtonComponent],
  templateUrl: './edr.component.html',
  styleUrl: './edr.component.scss',
  providers: [AnalyticsService],
})
export class EdrComponent extends BaseComponent {
  @ViewChild(AgentStatusComponent) edrAgentStatusComponent!: AgentStatusComponent;

  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);
  private eventService = inject(EventService);
  public moreVerticalIcon: SVGIcon = moreVerticalIcon;

  // HUD-related signals
  public threats = signal<WidgetData>({ value: 0, colors: [] });
  public threatReboot = signal<WidgetData>({ value: 0, colors: [] });
  public outdatedAgents = signal<WidgetData>({ value: 0, colors: [] });
  public coverage = signal<WidgetData>({ value: 0, colors: [] });

  // Date-dependent signals
  public status = signal<AgentStatusData[]>([]);

  public selectedDateRange = signal<string[]>([]);
  private dateRangeInternal = signal<string[]>([]);
  public organizationId: number | null = null;
  public infoCircleIcon: SVGIcon = infoCircleIcon;

  constructor() {
    super();
    this.initInitialRouteHandler();
    this.initDateRangeEffect();
    // this.initOrgChangeHandler();
  }

  private initInitialRouteHandler() {
    this.route.queryParams
      .pipe(
        filter((params) => params['organizationId']),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (params) => {
          this.organizationId = +params['organizationId'];
          this.setHudData();
        },
        error: (error) => {
          console.error('Error processing query parameters:', error);
        },
      });
  }

  private initDateRangeEffect() {
    // Handle date range changes
    // effect(
    //   () => {
    this.route.queryParams
      .pipe(
        filter((params) => params['organizationId']),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (params) => {
          this.organizationId = +params['organizationId'];
          const dateRange = this.selectedDateRange();

          if (dateRange?.length && this.organizationId) {
            // Update internal date range signal
            this.dateRangeInternal.set(dateRange);
            // Only make the API call if the date range actually changed
            this.applyFilter(dateRange);
          }
        },
        error: (error) => {
          console.error('Error processing query parameters:', error);
        },
      });
    //   },
    //   { allowSignalWrites: true },
    // );
  }

  private initOrgChangeHandler() {
    // Use effect to react to organization changes via signal
    effect(() => {
      const orgChange = this.eventService.orgChanged();
      if (orgChange?.organizationId) {
        this.organizationId = orgChange.organizationId;
        // Refresh all data
        this.setHudData();
        this.applyFilter(this.dateRangeInternal());
      }
    });
  }

  // HUD data fetching - only called on init and refresh button click
  setHudData() {
    if (!this.organizationId) return;

    this.analyticsService
      .getOpenThreats(this.organizationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.threats.set(response);
        },
        error: (error) => {
          console.error('Error fetching threats:', error);
        },
      });
    this.analyticsService
      .getThreatRebootCount(this.organizationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.threatReboot.set(response);
        },
        error: (error) => {
          console.error('Error fetching Open Threats:', error);
        },
      });
    this.analyticsService
      .getOutdatedAgents(this.organizationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.outdatedAgents.set(response);
        },
        error: (error) => {
          console.error('Error fetching Outdated Agents:', error);
        },
      });
    this.analyticsService
      .getCoverage(this.organizationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.coverage.set(response);
        },
        error: (error) => {
          console.error('Error fetching Coverage:', error);
        },
      });
  }

  // Date filter handling - only called when date range changes from cyflare-period
  applyFilter(dateRange: string[] | null) {
    if (!dateRange?.length || !this.organizationId) return;

    const params = {
      timestamp__range: dateRange.join(','),
    };

    // Use a single API call with take(1)
    this.analyticsService
      .getAgentStatus(this.organizationId, params)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.status.set(response);
        },
        error: (error) => {
          console.error('Error fetching Agent status:', error);
        },
      });
  }

  onContextMenuSelect(event: any) {
    let componentRef: any = null;
    // Determine the component reference based on the event name
    switch (event.name) {
      case 'edragentstatus':
        componentRef = this.edrAgentStatusComponent;
        break;
      default:
        console.warn(`No matching component for event name: ${event.name}`);
        break;
    }

    // If a valid componentRef is set, call the common method
    if (componentRef && typeof componentRef.onContextMenuSelect === 'function') {
      componentRef.onContextMenuSelect(event);
    } else {
      console.warn(`Component for event name "${event.name}" is not available or does not implement onContextMenuSelect.`);
    }
  }

  // Update the getter for dateRange
  get dateRange(): string[] {
    return this.dateRangeInternal();
  }

  // HUD refresh handler - only called on refresh button click
  refreshHudData() {
    this.setHudData();
  }
}
