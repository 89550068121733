/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Optional, Injectable, Input, ViewChild, HostBinding, Component, ChangeDetectionStrategy, ContentChild, ContentChildren, NgModule } from '@angular/core';
import * as i2 from '@progress/kendo-angular-intl';
import * as i3 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { exportImage, exportSVG, drawDOM } from '@progress/kendo-drawing';
import { ResizeSensorComponent } from '@progress/kendo-angular-common';
import { validatePackage } from '@progress/kendo-licensing';
import { ArcGauge, CircularGauge, LinearGauge, RadialGauge } from '@progress/kendo-charts';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * A directive that selects a [template](link:site.data.urls.angular['templatesyntax'])
 * within the `<kendo-arcgauge>` component which will be used for the center template
 * ([more information and example]({% slug centertemplate_arcgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-arcgauge [value]="value">
 *             <ng-template kendoArcGaugeCenterTemplate let-value="value">
 *                 {{ value }}%
 *             </ng-template>
 *         </kendo-arcgauge>
 *
 *     `
 * })
 * export class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
const _c0 = ["surface"];
const _c1 = ["label"];
function ArcGaugeComponent_div_2_ng_template_2_Template(rf, ctx) {}
function ArcGaugeComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5, 1);
    i0.ɵɵtemplate(2, ArcGaugeComponent_div_2_ng_template_2_Template, 0, 0, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.centerTemplate.templateRef)("ngTemplateOutletContext", ctx_r1.centerTemplateContext);
  }
}
function CircularGaugeComponent_div_2_ng_template_2_Template(rf, ctx) {}
function CircularGaugeComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5, 1);
    i0.ɵɵtemplate(2, CircularGaugeComponent_div_2_ng_template_2_Template, 0, 0, "ng-template", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.centerTemplate.templateRef)("ngTemplateOutletContext", ctx_r1.centerTemplateContext);
  }
}
class ArcCenterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ArcCenterTemplateDirective.ɵfac = function ArcCenterTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcCenterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ArcCenterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ArcCenterTemplateDirective,
  selectors: [["", "kendoArcGaugeCenterTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcCenterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoArcGaugeCenterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();
function isObject(value) {
  return typeof value === "object";
}
function diffOptions(original, current) {
  if (Object.keys(original).length !== Object.keys(current).length) {
    return true;
  }
  for (const field in original) {
    if (field !== 'value' && original.hasOwnProperty(field)) {
      const originalValue = original[field];
      const currentValue = current[field];
      const diff = isObject(originalValue) && isObject(currentValue) ? diffOptions(originalValue, currentValue) : originalValue !== currentValue;
      if (diff) {
        return true;
      }
    }
  }
}
function diffPointerOptions(original, current) {
  if (!original || !current) {
    return true;
  }
  original = [].concat(original);
  current = [].concat(current);
  if (original.length !== current.length) {
    return true;
  }
  for (let idx = 0; idx < original.length; idx++) {
    if (diffOptions(original[idx], current[idx])) {
      return true;
    }
  }
}
/**
 * @hidden
 */
class ConfigurationService {
  constructor() {
    this.options = {};
  }
  copyChanges(prefix, changes) {
    for (const propertyName in changes) {
      if (!changes.hasOwnProperty(propertyName)) {
        continue;
      }
      const value = changes[propertyName].currentValue;
      const optionName = (prefix ? prefix + '.' : '') + propertyName;
      this.set(optionName, value);
    }
  }
  read() {
    this.hasChanges = false;
    this.valueChange = false;
    return this.options;
  }
  readValues() {
    this.valueChange = false;
    const pointers = [].concat(this.options.pointer);
    return pointers.map(pointer => pointer.value);
  }
  readValue() {
    this.valueChange = false;
    return this.options.value;
  }
  set(field, value) {
    const {
      key,
      options
    } = this.optionContext(field);
    if (!this.hasChanges && (key === 'value' || key === 'pointer' && !diffPointerOptions(this.options.pointer, value))) {
      this.valueChange = true;
    } else {
      this.hasChanges = true;
      this.valueChange = false;
    }
    options[key] = value;
  }
  optionContext(field) {
    const parts = field.split('.');
    let options = this.options;
    let key = parts.shift();
    while (parts.length > 0) {
      options = options[key] = options[key] || {};
      key = parts.shift();
    }
    return {
      key: key,
      options: options
    };
  }
}
ConfigurationService.ɵfac = function ConfigurationService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConfigurationService)();
};
ConfigurationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConfigurationService,
  factory: ConfigurationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigurationService, [{
    type: Injectable
  }], null, null);
})();

/**
 * @hidden
 */
class CollectionChangesService {}
CollectionChangesService.ɵfac = function CollectionChangesService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CollectionChangesService)();
};
CollectionChangesService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CollectionChangesService,
  factory: CollectionChangesService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionChangesService, [{
    type: Injectable
  }], null, null);
})();
const template = `
    <div class="k-var--gauge-pointer"></div>
    <div class="k-var--gauge-track"></div>
    <div class="k-var--normal-text-color"></div>
`;
/**
 * @hidden
 */
class ThemeService {
  read() {
    if (!this.options) {
      this.load();
    }
    return this.options;
  }
  load() {
    if (typeof document === 'undefined') {
      this.options = {};
      return;
    }
    const container = document.createElement('div');
    container.style.display = 'none';
    container.innerHTML = template;
    document.body.appendChild(container);
    try {
      const pointerColor = this.getColor(container, 'gauge-pointer');
      const rangePlaceholder = this.getColor(container, 'gauge-track');
      const textColor = this.getColor(container, 'normal-text-color');
      this.options = {
        pointer: {
          color: pointerColor
        },
        scale: {
          labels: {
            color: textColor
          },
          rangePlaceholderColor: rangePlaceholder,
          minorTicks: {
            color: textColor
          },
          majorTicks: {
            color: textColor
          },
          line: {
            color: textColor
          }
        }
      };
    } finally {
      document.body.removeChild(container);
    }
  }
  getColor(container, varName) {
    return window.getComputedStyle(container.querySelector(`.k-var--${varName}`)).backgroundColor;
  }
}
ThemeService.ɵfac = function ThemeService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ThemeService)();
};
ThemeService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ThemeService,
  factory: ThemeService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * @hidden
 */
class CollectionComponent {
  constructor(key, configurationService, collectionChangesService) {
    this.key = key;
    this.configurationService = configurationService;
    this.collectionChangesService = collectionChangesService;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.configurationService.set(this.key, []);
  }
  ngAfterContentInit() {
    this.subscription = this.children.changes.subscribe(() => this.collectionChangesService.hasChanges = true);
  }
  ngAfterContentChecked() {
    if (this.collectionChangesService.hasChanges) {
      this.configurationService.set(this.key, this.readItems());
      this.collectionChangesService.hasChanges = false;
    }
  }
  readItems() {
    return this.children.map(child => Object.assign({}, child.configurationService.read()));
  }
}
CollectionComponent.ɵfac = function CollectionComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
CollectionComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CollectionComponent
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, null);
})();

/**
 * @hidden
 */
class CollectionItemComponent {
  constructor(configurationService, collectionChangesService) {
    this.configurationService = configurationService;
    this.collectionChangesService = collectionChangesService;
  }
  ngOnChanges(changes) {
    this.configurationService.copyChanges('', changes);
    this.collectionChangesService.hasChanges = true;
  }
}
CollectionItemComponent.ɵfac = function CollectionItemComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CollectionItemComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
CollectionItemComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CollectionItemComponent,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionItemComponent, [{
    type: Directive
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, null);
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-gauges',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1728372683,
  version: '16.11.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
const inDocument = element => {
  let node = element;
  while (node && node !== document.body) {
    node = node.parentNode;
  }
  return Boolean(node);
};
/**
 * @hidden
 */
class GaugeComponent {
  constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
    this.configurationService = configurationService;
    this.themeService = themeService;
    this.intlService = intlService;
    this.localizationService = localizationService;
    this.element = element;
    this.renderer = renderer;
    this.ngZone = ngZone;
    /**
     * The maximum number of times the Gauge resizes per second.
     * Defaults to `10`. To disable the automatic resizing, set `resizeRateLimit` to `0`.
     */
    this.resizeRateLimit = 10;
    this.className = true;
    this.theme = null;
    this.rtl = false;
    validatePackage(packageMetadata);
  }
  ngOnInit() {
    this.setDirection();
    this.subscriptions = this.intlService.changes.subscribe(this.intlChange.bind(this));
    this.subscriptions.add(this.localizationService.changes.subscribe(this.rtlChange.bind(this)));
  }
  ngAfterViewChecked() {
    if (typeof document === 'undefined') {
      return;
    }
    let updateMethod;
    if (!this.instance) {
      updateMethod = this.init;
    } else if (this.configurationService.hasChanges) {
      updateMethod = this.updateOptions;
    } else if (this.configurationService.valueChange) {
      updateMethod = this.setValues;
    }
    if (updateMethod) {
      clearTimeout(this.redrawTimeout);
      if (!this.instance && !inDocument(this.element.nativeElement)) {
        // required in case the gauge is initialized by ng-content outside of the DOM
        this.defer(() => {
          this.updateCall(updateMethod);
        });
      } else {
        this.updateCall(updateMethod);
      }
    }
  }
  updateCall(updateMethod) {
    this.updateDirection();
    updateMethod.call(this);
    this.updateSize();
  }
  updateOptions() {
    this.instance.setOptions(this.configurationService.read());
  }
  setValues() {
    this.instance.allValues(this.configurationService.readValues());
  }
  ngOnChanges(changes) {
    this.configurationService.copyChanges('', changes);
  }
  ngOnDestroy() {
    if (this.instance) {
      this.instance.destroy();
    }
    this.subscriptions.unsubscribe();
    clearTimeout(this.redrawTimeout);
  }
  /**
   * Exports the Gauge as an image. The export operation is asynchronous and returns a promise.
   *
   * @param {ImageExportOptions} options - The parameters for the exported image.
   * @returns {Promise<string>} - A promise that will be resolved with a PNG image that is encoded as a Data URI.
   */
  exportImage(options = {}) {
    return this.exportVisual().then(visual => {
      return exportImage(visual, options);
    });
  }
  /**
   * Exports the Gauge as an SVG document. The export operation is asynchronous and returns a promise.
   *
   * @param {SVGExportOptions} options - The parameters for the exported file.
   * @returns {Promise<string>} - A promise that will be resolved with an SVG document that is encoded as a Data URI.
   */
  exportSVG(options = {}) {
    return this.exportVisual().then(visual => {
      return exportSVG(visual, options);
    });
  }
  /**
   * Exports the Gauge as a Drawing `Scene`.
   *
   * @returns {Promise<Group>} - A promise that will be resolved with the export visual.
   */
  exportVisual() {
    return Promise.resolve(this.instance.exportVisual());
  }
  /**
   * @hidden
   */
  onResize(_event) {
    if (this.autoResize) {
      this.resize();
    }
  }
  /**
   * Detects the size of the container and redraws the Gauge.
   * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
   */
  resize() {
    if (this.instance) {
      this.instance.resize();
    }
  }
  init() {
    if (!this.surfaceElement) {
      return;
    }
    this.createInstance(this.surfaceElement.nativeElement, this.configurationService.read(), this.themeService.read(), {
      intlService: this.intlService,
      rtl: this.rtl
    });
  }
  get autoResize() {
    return this.resizeRateLimit > 0;
  }
  updateSize() {
    this.resizeSensor.acceptSize();
  }
  intlChange() {
    if (this.instance) {
      this.deferredRedraw();
    }
  }
  rtlChange() {
    if (this.instance && this.rtl !== this.isRTL) {
      this.deferredRedraw();
    }
  }
  deferredRedraw() {
    this.defer(() => {
      this.updateDirection();
      this.instance.noTransitionsRedraw();
    });
  }
  defer(callback) {
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.redrawTimeout);
      this.redrawTimeout = setTimeout(callback, 0);
    });
  }
  updateDirection() {
    const current = this.isRTL;
    if (this.rtl !== current) {
      this.setDirection();
      if (this.instance) {
        this.instance.setDirection(current);
      }
    }
  }
  setDirection() {
    this.rtl = this.isRTL;
    if (this.element) {
      this.renderer.setAttribute(this.element.nativeElement, 'dir', this.rtl ? 'rtl' : 'ltr');
    }
  }
  get isRTL() {
    return Boolean(this.localizationService.rtl);
  }
}
GaugeComponent.ɵfac = function GaugeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GaugeComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(ThemeService), i0.ɵɵdirectiveInject(i2.IntlService), i0.ɵɵdirectiveInject(i3.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
GaugeComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GaugeComponent,
  viewQuery: function GaugeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
      i0.ɵɵviewQuery(ResizeSensorComponent, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.surfaceElement = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resizeSensor = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function GaugeComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-gauge", ctx.className);
    }
  },
  inputs: {
    gaugeArea: "gaugeArea",
    renderAs: "renderAs",
    resizeRateLimit: "resizeRateLimit",
    scale: "scale",
    transitions: "transitions"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugeComponent, [{
    type: Directive
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: ThemeService
    }, {
      type: i2.IntlService
    }, {
      type: i3.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    gaugeArea: [{
      type: Input
    }],
    renderAs: [{
      type: Input
    }],
    resizeRateLimit: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    transitions: [{
      type: Input
    }],
    surfaceElement: [{
      type: ViewChild,
      args: ['surface', {
        static: true
      }]
    }],
    resizeSensor: [{
      type: ViewChild,
      args: [ResizeSensorComponent, {
        static: true
      }]
    }],
    className: [{
      type: HostBinding,
      args: ['class.k-gauge']
    }]
  });
})();

/**
 * @hidden
 */
class SettingsComponent {
  constructor(key, configurationService) {
    this.key = key;
    this.configurationService = configurationService;
  }
  ngOnChanges(changes) {
    this.configurationService.copyChanges(this.key, changes);
  }
  ngOnDestroy() {
    this.configurationService.set(this.key, null);
  }
}
SettingsComponent.ɵfac = function SettingsComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
SettingsComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SettingsComponent,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class GaugeAreaComponent extends SettingsComponent {
  // due to NG error: The directive GaugeAreaComponent inherits its constructor from SettingsComponent, but the latter has a constructor parameter 
  // that is not compatible with dependency injection. Either add an explicit constructor to ScaleComponent or change SettingsComponent's constructor 
  // to use parameters that are valid for DI.(-992016)
  constructor(key, configurationService) {
    super('gaugeArea', configurationService);
    this.key = key;
    this.configurationService = configurationService;
  }
}
GaugeAreaComponent.ɵfac = function GaugeAreaComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
GaugeAreaComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: GaugeAreaComponent,
  inputs: {
    background: "background",
    border: "border",
    height: "height",
    margin: "margin",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugeAreaComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }];
  }, {
    background: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    width: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class LabelsComponent extends SettingsComponent {
  // due to NG error: The directive LabelsComponent inherits its constructor from SettingsComponent, but the latter has a constructor parameter 
  // that is not compatible with dependency injection. Either add an explicit constructor to ScaleComponent or change SettingsComponent's constructor 
  // to use parameters that are valid for DI.(-992016)
  constructor(key, configurationService) {
    super('label', configurationService);
    this.key = key;
    this.configurationService = configurationService;
  }
}
LabelsComponent.ɵfac = function LabelsComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
LabelsComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LabelsComponent,
  inputs: {
    background: "background",
    border: "border",
    color: "color",
    font: "font",
    format: "format",
    margin: "margin",
    padding: "padding",
    content: "content",
    visible: "visible"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LabelsComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }];
  }, {
    background: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    font: [{
      type: Input
    }],
    format: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    padding: [{
      type: Input
    }],
    content: [{
      type: Input
    }],
    visible: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class RangeComponent extends CollectionItemComponent {}
RangeComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵRangeComponent_BaseFactory;
  return function RangeComponent_Factory(__ngFactoryType__) {
    return (ɵRangeComponent_BaseFactory || (ɵRangeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(RangeComponent)))(__ngFactoryType__ || RangeComponent);
  };
})();
RangeComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RangeComponent,
  inputs: {
    from: "from",
    to: "to",
    opacity: "opacity",
    color: "color"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RangeComponent, [{
    type: Directive
  }], null, {
    from: [{
      type: Input
    }],
    to: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    color: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
class ScaleComponent extends SettingsComponent {
  // due to NG error: The directive ScaleComponent inherits its constructor from SettingsComponent, but the latter has a constructor parameter 
  // that is not compatible with dependency injection. Either add an explicit constructor to ScaleComponent or change SettingsComponent's constructor 
  // to use parameters that are valid for DI.(-992016)
  constructor(key, configurationService) {
    super('scale', configurationService);
    this.key = key;
    this.configurationService = configurationService;
  }
}
ScaleComponent.ɵfac = function ScaleComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
ScaleComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ScaleComponent,
  inputs: {
    labels: "labels",
    majorTicks: "majorTicks",
    minorTicks: "minorTicks",
    min: "min",
    max: "max",
    minorUnit: "minorUnit",
    majorUnit: "majorUnit",
    reverse: "reverse",
    rangeSize: "rangeSize",
    rangePlaceholderColor: "rangePlaceholderColor"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScaleComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }];
  }, {
    labels: [{
      type: Input
    }],
    majorTicks: [{
      type: Input
    }],
    minorTicks: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    minorUnit: [{
      type: Input
    }],
    majorUnit: [{
      type: Input
    }],
    reverse: [{
      type: Input
    }],
    rangeSize: [{
      type: Input
    }],
    rangePlaceholderColor: [{
      type: Input
    }]
  });
})();

/**
 * Represents the [Kendo UI ArcGauge component for Angular]({% slug overview_arcgauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-arcgauge [value]="value" [scale]="{ max: 100 }">
 *          <ng-template kendoArcGaugeCenterTemplate let-value="value">
 *              {{ value }}%
 *          </ng-template>
 *      </kendo-arcgauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
class ArcGaugeComponent extends GaugeComponent {
  constructor(changeDetector, configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
    super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
    this.changeDetector = changeDetector;
    this.className = true;
    this.centerTemplateContext = {};
  }
  ngOnInit() {
    super.ngOnInit();
    if (this.element) {
      this.renderer.setStyle(this.element.nativeElement, 'position', 'relative');
    }
  }
  ngAfterViewChecked() {
    super.ngAfterViewChecked();
    if (this.labelElement && !this.centerTemplate) {
      this.changeDetector.detectChanges();
    } else if (!this.labelElement && this.centerTemplate) {
      this.updateCenterTemplate();
    }
  }
  /**
   * Exports the gauge as a Drawing `Scene`.
   *
   * @returns {Promise<Group>} - A promise that will be resolved with the export visual.
   */
  exportVisual() {
    return drawDOM(this.element.nativeElement);
  }
  /**
   * Detects the size of the container and redraws the Gauge.
   * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
   */
  resize() {
    super.resize();
    this.updateCenterTemplate();
  }
  createInstance(element, options, theme, context) {
    this.instance = new ArcGauge(element, options, theme, context);
    this.updateElements();
  }
  updateOptions() {
    super.updateOptions();
    this.updateElements();
  }
  setValues() {
    const value = this.configurationService.readValue();
    this.instance.value(value);
    this.updateCenterTemplate();
  }
  updateElements() {
    this.resizeSensor.acceptSize();
    this.updateCenterTemplate();
  }
  updateCenterTemplate() {
    if (!this.instance || !this.centerTemplate) {
      return;
    }
    this.centerTemplateContext.value = this.instance.value();
    this.centerTemplateContext.color = this.instance.currentColor();
    this.changeDetector.detectChanges();
    this.positionLabel();
  }
  positionLabel() {
    if (!this.labelElement) {
      return;
    }
    const element = this.labelElement.nativeElement;
    const width = element.offsetWidth;
    const height = element.offsetHeight;
    const position = this.instance.centerLabelPosition(width, height);
    element.style.top = `${position.top}px`;
    element.style.left = `${position.left}px`;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSize() {}
}
ArcGaugeComponent.ɵfac = function ArcGaugeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcGaugeComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(ThemeService), i0.ɵɵdirectiveInject(i2.IntlService), i0.ɵɵdirectiveInject(i3.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
ArcGaugeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ArcGaugeComponent,
  selectors: [["kendo-arcgauge"]],
  contentQueries: function ArcGaugeComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ArcCenterTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.centerTemplate = _t.first);
    }
  },
  viewQuery: function ArcGaugeComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelElement = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function ArcGaugeComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-arcgauge", ctx.className);
    }
  },
  inputs: {
    value: "value",
    color: "color",
    colors: "colors",
    opacity: "opacity",
    scale: "scale"
  },
  exportAs: ["kendoArcGauge"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.arcgauge'
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 4,
  vars: 2,
  consts: [["surface", ""], ["label", ""], [1, "k-chart-surface"], ["class", "k-arcgauge-label", 4, "ngIf"], [3, "resize", "rateLimit"], [1, "k-arcgauge-label"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function ArcGaugeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelement(0, "div", 2, 0);
      i0.ɵɵtemplate(2, ArcGaugeComponent_div_2_Template, 3, 2, "div", 3);
      i0.ɵɵelementStart(3, "kendo-resize-sensor", 4);
      i0.ɵɵlistener("resize", function ArcGaugeComponent_Template_kendo_resize_sensor_resize_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onResize($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.centerTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [NgIf, NgTemplateOutlet, ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcGaugeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoArcGauge',
      providers: [ConfigurationService, LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.arcgauge'
      }],
      selector: 'kendo-arcgauge',
      template: `
    <div #surface class='k-chart-surface'></div>
    <div class="k-arcgauge-label" *ngIf="centerTemplate" #label>
        <ng-template [ngTemplateOutlet]="centerTemplate.templateRef" [ngTemplateOutletContext]="centerTemplateContext"></ng-template>
    </div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `,
      standalone: true,
      imports: [NgIf, NgTemplateOutlet, ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: ConfigurationService
    }, {
      type: ThemeService
    }, {
      type: i2.IntlService
    }, {
      type: i3.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    value: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    colors: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    scale: [{
      type: Input
    }],
    centerTemplate: [{
      type: ContentChild,
      args: [ArcCenterTemplateDirective, {
        static: false
      }]
    }],
    labelElement: [{
      type: ViewChild,
      args: ["label", {
        static: false
      }]
    }],
    className: [{
      type: HostBinding,
      args: ['class.k-arcgauge']
    }]
  });
})();

/**
 * The configuration options for an ArcGauge color item.
 */
class ColorComponent extends CollectionItemComponent {
  constructor(configurationService, collectionChangesService) {
    super(configurationService, collectionChangesService);
  }
}
ColorComponent.ɵfac = function ColorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
ColorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColorComponent,
  selectors: [["kendo-arcgauge-color"]],
  inputs: {
    color: "color",
    opacity: "opacity",
    from: "from",
    to: "to"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ColorComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorComponent, [{
    type: Component,
    args: [{
      providers: [ConfigurationService],
      selector: 'kendo-arcgauge-color',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    color: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    from: [{
      type: Input
    }],
    to: [{
      type: Input
    }]
  });
})();

/**
 * A collection of one or more ArcGauge colors
 * ([more information and example]({% slug colorranges_arcgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-arcgauge [value]="value">
 *              <kendo-arcgauge-colors>
 *                  <kendo-arcgauge-color *ngFor="let item of colors"
 *                      [from]="item.from" [to]="item.to" [color]="item.color">
 *                  </kendo-arcgauge-color>
 *              </kendo-arcgauge-colors>
 *         </kendo-arcgauge>
 *     `
 * })
 * export class AppComponent {
 *     public value: number = 10;
 *
 *     public colors: any[] = [{
 *         to: 25,
 *         color: '#0058e9'
 *     }, {
 *         from: 25,
 *         to: 50,
 *         color: '#37b400'
 *     }, {
 *         from: 50,
 *         to: 75,
 *         color: '#ffc000'
 *     }, {
 *         from: 75,
 *         color: '#f31700'
 *     }];
 * }
 *
 * ```
 */
class ColorsComponent extends CollectionComponent {
  constructor(configurationService, collectionChangesService) {
    super('colors', configurationService, collectionChangesService);
  }
}
ColorsComponent.ɵfac = function ColorsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColorsComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
ColorsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColorsComponent,
  selectors: [["kendo-arcgauge-colors"]],
  contentQueries: function ColorsComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColorComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionChangesService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ColorsComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorsComponent, [{
    type: Component,
    args: [{
      providers: [CollectionChangesService],
      selector: 'kendo-arcgauge-colors',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [ColorComponent]
    }]
  });
})();

/**
 * The configuration options of the ArcGauge area.
 * Represents the entire visible area of the ArcGauge.
 */
class ArcGaugeAreaComponent extends GaugeAreaComponent {
  constructor(configurationService) {
    super('gaugeArea', configurationService);
    this.configurationService = configurationService;
  }
}
ArcGaugeAreaComponent.ɵfac = function ArcGaugeAreaComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcGaugeAreaComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
ArcGaugeAreaComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ArcGaugeAreaComponent,
  selectors: [["kendo-arcgauge-area"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ArcGaugeAreaComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcGaugeAreaComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-arcgauge-area',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * The configuration options for the scale labels of the RadialGauge.
 */
class RadialLabelsComponent extends LabelsComponent {
  constructor(configurationService) {
    super('scale.labels', configurationService);
    this.configurationService = configurationService;
  }
}
RadialLabelsComponent.ɵfac = function RadialLabelsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialLabelsComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
RadialLabelsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialLabelsComponent,
  selectors: [["kendo-radialgauge-scale-labels"]],
  inputs: {
    position: "position"
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialLabelsComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialLabelsComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-radialgauge-scale-labels',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, {
    position: [{
      type: Input
    }]
  });
})();

/**
 * The configuration options for the scale labels of the ArcGauge.
 */
class ArcLabelsComponent extends RadialLabelsComponent {
  constructor(configurationService) {
    super(configurationService);
    this.configurationService = configurationService;
    configurationService.set(`${this.key}.visible`, true);
  }
}
ArcLabelsComponent.ɵfac = function ArcLabelsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcLabelsComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
ArcLabelsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ArcLabelsComponent,
  selectors: [["kendo-arcgauge-scale-labels"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ArcLabelsComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcLabelsComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-arcgauge-scale-labels',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * The configuration options for the scale of the ArcGauge
 * ([see example]({% slug scaleoptions_arcgauge %})).
 */
class ArcScaleComponent extends ScaleComponent {
  constructor(configurationService) {
    super('scale', configurationService);
    this.configurationService = configurationService;
  }
}
ArcScaleComponent.ɵfac = function ArcScaleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcScaleComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
ArcScaleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ArcScaleComponent,
  selectors: [["kendo-arcgauge-scale"]],
  inputs: {
    labels: "labels",
    rangeDistance: "rangeDistance",
    rangeLineCap: "rangeLineCap",
    startAngle: "startAngle",
    endAngle: "endAngle"
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ArcScaleComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcScaleComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-arcgauge-scale',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, {
    labels: [{
      type: Input
    }],
    rangeDistance: [{
      type: Input
    }],
    rangeLineCap: [{
      type: Input
    }],
    startAngle: [{
      type: Input
    }],
    endAngle: [{
      type: Input
    }]
  });
})();

/**
 * A directive that selects a [template](link:site.data.urls.angular['templatesyntax'])
 * within the `<kendo-circulargauge>` component which will be used for the center template
 * ([more information and example]({% slug centertemplate_circulargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-circulargauge [value]="value">
 *             <ng-template kendoCircularGaugeCenterTemplate let-value="value">
 *                 {{ value }}%
 *             </ng-template>
 *         </kendo-circulargauge>
 *
 *     `
 * })
 * export class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
class CircularGaugeCenterTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
CircularGaugeCenterTemplateDirective.ɵfac = function CircularGaugeCenterTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CircularGaugeCenterTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
CircularGaugeCenterTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CircularGaugeCenterTemplateDirective,
  selectors: [["", "kendoCircularGaugeCenterTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeCenterTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoCircularGaugeCenterTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Represents the [Kendo UI CircularGauge component for Angular]({% slug overview_circulargauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-circulargauge [value]="value" [scale]="{ max: 100 }">
 *          <ng-template kendoCircularGaugeCenterTemplate let-value="value">
 *              {{ value }}%
 *          </ng-template>
 *      </kendo-circulargauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
class CircularGaugeComponent extends ArcGaugeComponent {
  createInstance(element, options, theme, context) {
    this.instance = new CircularGauge(element, options, theme, context);
    this.updateElements();
  }
}
CircularGaugeComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCircularGaugeComponent_BaseFactory;
  return function CircularGaugeComponent_Factory(__ngFactoryType__) {
    return (ɵCircularGaugeComponent_BaseFactory || (ɵCircularGaugeComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CircularGaugeComponent)))(__ngFactoryType__ || CircularGaugeComponent);
  };
})();
CircularGaugeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircularGaugeComponent,
  selectors: [["kendo-circulargauge"]],
  contentQueries: function CircularGaugeComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, CircularGaugeCenterTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.centerTemplate = _t.first);
    }
  },
  inputs: {
    scale: "scale"
  },
  exportAs: ["kendoCircularGauge"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.circulargauge'
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 4,
  vars: 2,
  consts: [["surface", ""], ["label", ""], [1, "k-chart-surface"], ["class", "k-arcgauge-label", 4, "ngIf"], [3, "resize", "rateLimit"], [1, "k-arcgauge-label"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function CircularGaugeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelement(0, "div", 2, 0);
      i0.ɵɵtemplate(2, CircularGaugeComponent_div_2_Template, 3, 2, "div", 3);
      i0.ɵɵelementStart(3, "kendo-resize-sensor", 4);
      i0.ɵɵlistener("resize", function CircularGaugeComponent_Template_kendo_resize_sensor_resize_3_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onResize($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.centerTemplate);
      i0.ɵɵadvance();
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [NgIf, NgTemplateOutlet, ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoCircularGauge',
      providers: [ConfigurationService, LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.circulargauge'
      }],
      selector: 'kendo-circulargauge',
      template: `
    <div #surface class='k-chart-surface'></div>
    <div class="k-arcgauge-label" *ngIf="centerTemplate" #label>
        <ng-template [ngTemplateOutlet]="centerTemplate.templateRef" [ngTemplateOutletContext]="centerTemplateContext"></ng-template>
    </div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `,
      standalone: true,
      imports: [NgIf, NgTemplateOutlet, ResizeSensorComponent]
    }]
  }], null, {
    scale: [{
      type: Input
    }],
    centerTemplate: [{
      type: ContentChild,
      args: [CircularGaugeCenterTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * The configuration options of the Circular Gauge area.
 * Represents the entire visible area of the Circular Gauge.
 */
class CircularGaugeAreaComponent extends ArcGaugeAreaComponent {}
CircularGaugeAreaComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCircularGaugeAreaComponent_BaseFactory;
  return function CircularGaugeAreaComponent_Factory(__ngFactoryType__) {
    return (ɵCircularGaugeAreaComponent_BaseFactory || (ɵCircularGaugeAreaComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CircularGaugeAreaComponent)))(__ngFactoryType__ || CircularGaugeAreaComponent);
  };
})();
CircularGaugeAreaComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircularGaugeAreaComponent,
  selectors: [["kendo-circulargauge-area"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function CircularGaugeAreaComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeAreaComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-circulargauge-area',
      template: '',
      standalone: true
    }]
  }], null, null);
})();

/**
 * The configuration options for the scale labels of the Circular Gauge.
 */
class CircularGaugeLabelsComponent extends ArcLabelsComponent {}
CircularGaugeLabelsComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCircularGaugeLabelsComponent_BaseFactory;
  return function CircularGaugeLabelsComponent_Factory(__ngFactoryType__) {
    return (ɵCircularGaugeLabelsComponent_BaseFactory || (ɵCircularGaugeLabelsComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CircularGaugeLabelsComponent)))(__ngFactoryType__ || CircularGaugeLabelsComponent);
  };
})();
CircularGaugeLabelsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircularGaugeLabelsComponent,
  selectors: [["kendo-circulargauge-scale-labels"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function CircularGaugeLabelsComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeLabelsComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-circulargauge-scale-labels',
      template: '',
      standalone: true
    }]
  }], null, null);
})();

/**
 * The configuration options for the scale of the Circular Gauge
 * ([see example]({% slug scaleoptions_circulargauge %})).
 */
class CircularGaugeScaleComponent extends ArcScaleComponent {}
CircularGaugeScaleComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵCircularGaugeScaleComponent_BaseFactory;
  return function CircularGaugeScaleComponent_Factory(__ngFactoryType__) {
    return (ɵCircularGaugeScaleComponent_BaseFactory || (ɵCircularGaugeScaleComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CircularGaugeScaleComponent)))(__ngFactoryType__ || CircularGaugeScaleComponent);
  };
})();
CircularGaugeScaleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CircularGaugeScaleComponent,
  selectors: [["kendo-circulargauge-scale"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function CircularGaugeScaleComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeScaleComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-circulargauge-scale',
      template: '',
      standalone: true
    }]
  }], null, null);
})();

/**
 * The configuration options for the LinearGauge area.
 * Represents the entire visible area of the LinearGauge.
 */
class LinearGaugeAreaComponent extends GaugeAreaComponent {
  constructor(configurationService) {
    super('gaugeArea', configurationService);
    this.configurationService = configurationService;
  }
}
LinearGaugeAreaComponent.ɵfac = function LinearGaugeAreaComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearGaugeAreaComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
LinearGaugeAreaComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearGaugeAreaComponent,
  selectors: [["kendo-lineargauge-area"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearGaugeAreaComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearGaugeAreaComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-lineargauge-area',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * The configuration options for the scale labels of the LinearGauge.
 */
class LinearLabelsComponent extends LabelsComponent {
  constructor(configurationService) {
    super('scale.labels', configurationService);
    this.configurationService = configurationService;
  }
}
LinearLabelsComponent.ɵfac = function LinearLabelsComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearLabelsComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
LinearLabelsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearLabelsComponent,
  selectors: [["kendo-lineargauge-scale-labels"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearLabelsComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearLabelsComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-lineargauge-scale-labels',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * Represents the [Kendo UI LinearGauge component for Angular]({% slug overview_lineargauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-lineargauge [pointer]="{ value: value }">
 *      </kendo-lineargauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
class LinearGaugeComponent extends GaugeComponent {
  constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
    super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
  }
  createInstance(element, options, theme, context) {
    this.instance = new LinearGauge(element, options, theme, context);
  }
}
LinearGaugeComponent.ɵfac = function LinearGaugeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearGaugeComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(ThemeService), i0.ɵɵdirectiveInject(i2.IntlService), i0.ɵɵdirectiveInject(i3.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
LinearGaugeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearGaugeComponent,
  selectors: [["kendo-lineargauge"]],
  inputs: {
    pointer: "pointer",
    scale: "scale"
  },
  exportAs: ["kendoLinearGauge"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.lineargauge'
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 3,
  vars: 1,
  consts: [["surface", ""], [1, "k-chart-surface"], [3, "resize", "rateLimit"]],
  template: function LinearGaugeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelement(0, "div", 1, 0);
      i0.ɵɵelementStart(2, "kendo-resize-sensor", 2);
      i0.ɵɵlistener("resize", function LinearGaugeComponent_Template_kendo_resize_sensor_resize_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onResize($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearGaugeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoLinearGauge',
      providers: [ConfigurationService, LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.lineargauge'
      }],
      selector: 'kendo-lineargauge',
      template: `
    <div #surface class='k-chart-surface'></div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `,
      standalone: true,
      imports: [ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: ThemeService
    }, {
      type: i2.IntlService
    }, {
      type: i3.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    pointer: [{
      type: Input
    }],
    scale: [{
      type: Input
    }]
  });
})();

/**
 * The configuration options for a pointer item of a LinearGauge.
 */
class LinearPointerComponent extends CollectionItemComponent {
  constructor(configurationService, collectionChangesService) {
    super(configurationService, collectionChangesService);
  }
}
LinearPointerComponent.ɵfac = function LinearPointerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearPointerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
LinearPointerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearPointerComponent,
  selectors: [["kendo-lineargauge-pointer"]],
  inputs: {
    border: "border",
    color: "color",
    margin: "margin",
    opacity: "opacity",
    shape: "shape",
    size: "size",
    value: "value"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearPointerComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearPointerComponent, [{
    type: Component,
    args: [{
      providers: [ConfigurationService],
      selector: 'kendo-lineargauge-pointer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    border: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    margin: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();

/**
 * A collection of one or more LinearGauge pointers
 * ([more information]({% slug multiplepointers_lineargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-lineargauge>
 *             <kendo-lineargauge-pointers>
 *                 <kendo-lineargauge-pointer *ngFor="let pointer of pointers"
 *                     [value]="pointer.value" [color]="pointer.color" shape="barIndicator">
 *                 </kendo-lineargauge-pointer>
 *             </kendo-lineargauge-pointers>
 *         </kendo-lineargauge>
 *     `
 * })
 * export class AppComponent {
 *     public pointers: any[] = [{
 *         value: 10,
 *         color: '#ff4500'
 *     }, {
 *         value: 12,
 *         color: '#28b4c8'
 *     }, {
 *         value: 20,
 *         color: '#8b0000'
 *     }];
 * }
 *
 * ```
 */
class LinearPointersComponent extends CollectionComponent {
  constructor(configurationService, collectionChangesService) {
    super('pointer', configurationService, collectionChangesService);
  }
}
LinearPointersComponent.ɵfac = function LinearPointersComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearPointersComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
LinearPointersComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearPointersComponent,
  selectors: [["kendo-lineargauge-pointers"]],
  contentQueries: function LinearPointersComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LinearPointerComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionChangesService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearPointersComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearPointersComponent, [{
    type: Component,
    args: [{
      providers: [CollectionChangesService],
      selector: 'kendo-lineargauge-pointers',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [LinearPointerComponent]
    }]
  });
})();

/**
 * The configuration options for a scale range item of a LinearGauge.
 */
class LinearRangeComponent extends RangeComponent {
  constructor(configurationService, collectionChangesService) {
    super(configurationService, collectionChangesService);
  }
}
LinearRangeComponent.ɵfac = function LinearRangeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearRangeComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
LinearRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearRangeComponent,
  selectors: [["kendo-lineargauge-scale-range"]],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearRangeComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearRangeComponent, [{
    type: Component,
    args: [{
      providers: [ConfigurationService],
      selector: 'kendo-lineargauge-scale-range',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, null);
})();

/**
 * A collection of one or more LinearGauge scale ranges
 * ([more information and example]({% slug scaleranghes_lineargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-lineargauge>
 *             <kendo-lineargauge-scale>
 *                 <kendo-lineargauge-scale-ranges>
 *                     <kendo-lineargauge-scale-range *ngFor="let range of ranges"
 *                         [from]="range.from" [to]="range.to" [color]="range.color">
 *                     </kendo-lineargauge-scale-range>
 *                 </kendo-lineargauge-scale-ranges>
 *             </kendo-lineargauge-scale>
 *         </kendo-lineargauge>
 *     `
 * })
 * export class AppComponent {
 *     public ranges: any[] = [{
 *         from: 0,
 *         to: 15,
 *         color: '#ffd246'
 *     }, {
 *         from: 15,
 *         to: 30,
 *         color: '#28b4c8'
 *     }, {
 *         from: 30,
 *         to: 50,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
class LinearRangesComponent extends CollectionComponent {
  constructor(configurationService, collectionChangesService) {
    super('scale.ranges', configurationService, collectionChangesService);
  }
}
LinearRangesComponent.ɵfac = function LinearRangesComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearRangesComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
LinearRangesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearRangesComponent,
  selectors: [["kendo-lineargauge-scale-ranges"]],
  contentQueries: function LinearRangesComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LinearRangeComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionChangesService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearRangesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearRangesComponent, [{
    type: Component,
    args: [{
      providers: [CollectionChangesService],
      selector: 'kendo-lineargauge-scale-ranges',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [LinearRangeComponent]
    }]
  });
})();

/**
 * The configuration options for the scale of the LinearGauge
 * ([see example]({% slug scaleoptions_lineargauge %})).
 */
class LinearScaleComponent extends ScaleComponent {
  constructor(configurationService) {
    super('scale', configurationService);
    this.configurationService = configurationService;
  }
}
LinearScaleComponent.ɵfac = function LinearScaleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearScaleComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
LinearScaleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LinearScaleComponent,
  selectors: [["kendo-lineargauge-scale"]],
  inputs: {
    line: "line",
    ranges: "ranges",
    mirror: "mirror",
    vertical: "vertical"
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LinearScaleComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearScaleComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-lineargauge-scale',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, {
    line: [{
      type: Input
    }],
    ranges: [{
      type: Input
    }],
    mirror: [{
      type: Input
    }],
    vertical: [{
      type: Input
    }]
  });
})();

/**
 * The configuration options for the RadialGauge area.
 * Represents the entire visible area of the RadialGauge.
 */
class RadialGaugeAreaComponent extends GaugeAreaComponent {
  constructor(configurationService) {
    super('gaugeArea', configurationService);
    this.configurationService = configurationService;
  }
}
RadialGaugeAreaComponent.ɵfac = function RadialGaugeAreaComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialGaugeAreaComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
RadialGaugeAreaComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialGaugeAreaComponent,
  selectors: [["kendo-radialgauge-area"]],
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialGaugeAreaComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialGaugeAreaComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-radialgauge-area',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * The configuration options for a pointer item of a RadialGauge.
 */
class RadialPointerComponent extends CollectionItemComponent {
  constructor(configurationService, collectionChangesService) {
    super(configurationService, collectionChangesService);
  }
}
RadialPointerComponent.ɵfac = function RadialPointerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialPointerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
RadialPointerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialPointerComponent,
  selectors: [["kendo-radialgauge-pointer"]],
  inputs: {
    cap: "cap",
    color: "color",
    length: "length",
    value: "value"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialPointerComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialPointerComponent, [{
    type: Component,
    args: [{
      providers: [ConfigurationService],
      selector: 'kendo-radialgauge-pointer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    cap: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    length: [{
      type: Input
    }],
    value: [{
      type: Input
    }]
  });
})();

/**
 * A collection of one or more RadialGauge pointers
 * ([more information and example]({% slug multiplepointers_radialgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-radialgauge>
 *             <kendo-radialgauge-pointers>
 *                 <kendo-radialgauge-pointer *ngFor="let pointer of pointers"
 *                     [value]="pointer.value" [color]="pointer.color">
 *                 </kendo-radialgauge-pointer>
 *             </kendo-radialgauge-pointers>
 *         </kendo-radialgauge>
 *     `
 * })
 * export class AppComponent {
 *     public pointers: any[] = [{
 *         value: 10,
 *         color: '#ffd246'
 *     }, {
 *         value: 20,
 *         color: '#28b4c8'
 *     }, {
 *         value: 30,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
class RadialPointersComponent extends CollectionComponent {
  constructor(configurationService, collectionChangesService) {
    super('pointer', configurationService, collectionChangesService);
  }
}
RadialPointersComponent.ɵfac = function RadialPointersComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialPointersComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
RadialPointersComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialPointersComponent,
  selectors: [["kendo-radialgauge-pointers"]],
  contentQueries: function RadialPointersComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, RadialPointerComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionChangesService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialPointersComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialPointersComponent, [{
    type: Component,
    args: [{
      providers: [CollectionChangesService],
      selector: 'kendo-radialgauge-pointers',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [RadialPointerComponent]
    }]
  });
})();

/**
 * Represents the [Kendo UI RadialGauge component for Angular]({% slug overview_radialgauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-radialgauge [pointer]="{ value: value }">
 *      </kendo-radialgauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
class RadialGaugeComponent extends GaugeComponent {
  constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
    super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
  }
  createInstance(element, options, theme, context) {
    this.instance = new RadialGauge(element, options, theme, context);
  }
}
RadialGaugeComponent.ɵfac = function RadialGaugeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialGaugeComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(ThemeService), i0.ɵɵdirectiveInject(i2.IntlService), i0.ɵɵdirectiveInject(i3.LocalizationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone));
};
RadialGaugeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialGaugeComponent,
  selectors: [["kendo-radialgauge"]],
  inputs: {
    pointer: "pointer",
    scale: "scale"
  },
  exportAs: ["kendoRadialGauge"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, LocalizationService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.radialgauge'
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 3,
  vars: 1,
  consts: [["surface", ""], [1, "k-chart-surface"], [3, "resize", "rateLimit"]],
  template: function RadialGaugeComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelement(0, "div", 1, 0);
      i0.ɵɵelementStart(2, "kendo-resize-sensor", 2);
      i0.ɵɵlistener("resize", function RadialGaugeComponent_Template_kendo_resize_sensor_resize_2_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onResize($event));
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialGaugeComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoRadialGauge',
      providers: [ConfigurationService, LocalizationService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.radialgauge'
      }],
      selector: 'kendo-radialgauge',
      template: `
    <div #surface class='k-chart-surface'></div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `,
      standalone: true,
      imports: [ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: ThemeService
    }, {
      type: i2.IntlService
    }, {
      type: i3.LocalizationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }];
  }, {
    pointer: [{
      type: Input
    }],
    scale: [{
      type: Input
    }]
  });
})();

/**
 * The configuration options for a scale range item of a RadialGauge.
 */
class RadialRangeComponent extends RangeComponent {
  constructor(configurationService, collectionChangesService) {
    super(configurationService, collectionChangesService);
  }
}
RadialRangeComponent.ɵfac = function RadialRangeComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialRangeComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
RadialRangeComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialRangeComponent,
  selectors: [["kendo-radialgauge-scale-range"]],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialRangeComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialRangeComponent, [{
    type: Component,
    args: [{
      providers: [ConfigurationService],
      selector: 'kendo-radialgauge-scale-range',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, null);
})();

/**
 * A collection of one or more RadialGauge scale ranges
 * ([more information and example]({% slug scaleranghes_radialgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-radialgauge>
 *             <kendo-radialgauge-scale>
 *                 <kendo-radialgauge-scale-ranges>
 *                     <kendo-radialgauge-scale-range *ngFor="let range of ranges"
 *                         [from]="range.from" [to]="range.to" [color]="range.color">
 *                     </kendo-radialgauge-scale-range>
 *                 </kendo-radialgauge-scale-ranges>
 *             </kendo-radialgauge-scale>
 *         </kendo-radialgauge>
 *     `
 * })
 * export class AppComponent {
 *     public ranges: any[] = [{
 *         from: 0,
 *         to: 15,
 *         color: '#ffd246'
 *     }, {
 *         from: 15,
 *         to: 30,
 *         color: '#28b4c8'
 *     }, {
 *         from: 30,
 *         to: 50,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
class RadialRangesComponent extends CollectionComponent {
  constructor(configurationService, collectionChangesService) {
    super('scale.ranges', configurationService, collectionChangesService);
  }
}
RadialRangesComponent.ɵfac = function RadialRangesComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialRangesComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionChangesService));
};
RadialRangesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialRangesComponent,
  selectors: [["kendo-radialgauge-scale-ranges"]],
  contentQueries: function RadialRangesComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, RadialRangeComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionChangesService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialRangesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialRangesComponent, [{
    type: Component,
    args: [{
      providers: [CollectionChangesService],
      selector: 'kendo-radialgauge-scale-ranges',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionChangesService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [RadialRangeComponent]
    }]
  });
})();

/**
 * The configuration options for the scale of the RadialGauge
 * ([more information and example]({% slug scaleoptions_radialgauge %})).
 */
class RadialScaleComponent extends ScaleComponent {
  constructor(configurationService) {
    super('scale', configurationService);
    this.configurationService = configurationService;
  }
}
RadialScaleComponent.ɵfac = function RadialScaleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialScaleComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
RadialScaleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RadialScaleComponent,
  selectors: [["kendo-radialgauge-scale"]],
  inputs: {
    labels: "labels",
    rangeDistance: "rangeDistance",
    ranges: "ranges",
    startAngle: "startAngle",
    endAngle: "endAngle"
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function RadialScaleComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialScaleComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-radialgauge-scale',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, {
    labels: [{
      type: Input
    }],
    rangeDistance: [{
      type: Input
    }],
    ranges: [{
      type: Input
    }],
    startAngle: [{
      type: Input
    }],
    endAngle: [{
      type: Input
    }]
  });
})();

/**
 * Utility array that contains all `ArcGauge` related components and directives
 */
const KENDO_ARCGAUGE = [ArcGaugeComponent, ArcCenterTemplateDirective, ArcGaugeAreaComponent, ArcScaleComponent, ArcLabelsComponent, ColorsComponent, ColorComponent];
/**
 * Utility array that contains all `CircularGauge` related components and directives
 */
const KENDO_CIRCULARGAUGE = [CircularGaugeComponent, CircularGaugeCenterTemplateDirective, CircularGaugeAreaComponent, CircularGaugeScaleComponent, CircularGaugeLabelsComponent];
/**
 * Utility array that contains all `LinearGauge` related components and directives
 */
const KENDO_LINEARGAUGE = [LinearGaugeComponent, LinearGaugeAreaComponent, LinearScaleComponent, LinearLabelsComponent, LinearPointersComponent, LinearPointerComponent, LinearRangeComponent, LinearRangesComponent];
/**
 * Utility array that contains all `RadialGauge` related components and directives
 */
const KENDO_RADIALGAUGE = [RadialGaugeComponent, RadialGaugeAreaComponent, RadialScaleComponent, RadialLabelsComponent, RadialPointersComponent, RadialPointerComponent, RadialRangeComponent, RadialRangesComponent];
/**
 * Utility array that contains all `@progress/kendo-angular-gauges` related components and directives
 */
const KENDO_GAUGES = [...KENDO_ARCGAUGE, ...KENDO_CIRCULARGAUGE, ...KENDO_LINEARGAUGE, ...KENDO_RADIALGAUGE];

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * A [module](link:site.data.urls.angular['ngmodules']) that includes the ArcGauge component and its directives.
 * Imports the `ArcGaugeModule` into your application
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity) or any other sub-module
 * that will use the ArcGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { ArcGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, ArcGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class ArcGaugeModule {}
ArcGaugeModule.ɵfac = function ArcGaugeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ArcGaugeModule)();
};
ArcGaugeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ArcGaugeModule
});
ArcGaugeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ThemeService],
  imports: [ArcGaugeComponent, ArcGaugeAreaComponent, ArcScaleComponent, ArcLabelsComponent, ColorsComponent, ColorComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArcGaugeModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_ARCGAUGE],
      imports: [...KENDO_ARCGAUGE],
      providers: [ThemeService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * A [module](link:site.data.urls.angular['ngmodules']) that includes the LinearGauge component and its directives.
 * Imports the `LinearGaugeModule` into your application
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity) or any other sub-module
 * that will use the LinearGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { LinearGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, LinearGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class LinearGaugeModule {}
LinearGaugeModule.ɵfac = function LinearGaugeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LinearGaugeModule)();
};
LinearGaugeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LinearGaugeModule
});
LinearGaugeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ThemeService],
  imports: [KENDO_LINEARGAUGE]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LinearGaugeModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_LINEARGAUGE],
      imports: [...KENDO_LINEARGAUGE],
      providers: [ThemeService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * A [module](link:site.data.urls.angular['ngmodules']) that includes the RadialGauge component and its directives.
 * Imports the `RadialGaugeModule` into your application
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity) or any other sub-module
 * that will use the RadialGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { RadialGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, RadialGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class RadialGaugeModule {}
RadialGaugeModule.ɵfac = function RadialGaugeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RadialGaugeModule)();
};
RadialGaugeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RadialGaugeModule
});
RadialGaugeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ThemeService],
  imports: [KENDO_RADIALGAUGE]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RadialGaugeModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_RADIALGAUGE],
      imports: [...KENDO_RADIALGAUGE],
      providers: [ThemeService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * A [module](link:site.data.urls.angular['ngmodules']) that includes the CircularGauge component and its directives.
 * Imports the `CircularGaugeModule` into your application
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity) or any other sub-module
 * that will use the CircularGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { CircularGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, CircularGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class CircularGaugeModule {}
CircularGaugeModule.ɵfac = function CircularGaugeModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CircularGaugeModule)();
};
CircularGaugeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CircularGaugeModule
});
CircularGaugeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ThemeService],
  imports: [CircularGaugeComponent, CircularGaugeAreaComponent, CircularGaugeScaleComponent, CircularGaugeLabelsComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CircularGaugeModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_CIRCULARGAUGE],
      imports: [...KENDO_CIRCULARGAUGE],
      providers: [ThemeService]
    }]
  }], null, null);
})();

// IMPORTANT: NgModule export kept for backwards compatibility
/**
 * A [module](link:site.data.urls.angular['ngmodules']) that includes all Gauge components and directives.
 *
 * Imports the `GaugesModule` into your application
 * [root module](link:site.data.urls.angular['ngmodules']#angular-modularity) or any other sub-module
 * that will use the Gauge components.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { GaugesModule } from '@progress/kendo-angular-charts';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, GaugesModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
class GaugesModule {}
GaugesModule.ɵfac = function GaugesModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GaugesModule)();
};
GaugesModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GaugesModule
});
GaugesModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ThemeService],
  imports: [ArcGaugeComponent, ArcGaugeAreaComponent, ArcScaleComponent, ArcLabelsComponent, ColorsComponent, ColorComponent, CircularGaugeComponent, CircularGaugeAreaComponent, CircularGaugeScaleComponent, CircularGaugeLabelsComponent, LinearGaugeComponent, LinearGaugeAreaComponent, LinearScaleComponent, LinearLabelsComponent, LinearPointersComponent, LinearPointerComponent, LinearRangeComponent, LinearRangesComponent, RadialGaugeComponent, RadialGaugeAreaComponent, RadialScaleComponent, RadialLabelsComponent, RadialPointersComponent, RadialPointerComponent, RadialRangeComponent, RadialRangesComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GaugesModule, [{
    type: NgModule,
    args: [{
      exports: [...KENDO_GAUGES],
      imports: [...KENDO_GAUGES],
      providers: [ThemeService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ArcCenterTemplateDirective, ArcGaugeAreaComponent, ArcGaugeComponent, ArcGaugeModule, ArcLabelsComponent, ArcScaleComponent, CircularGaugeAreaComponent, CircularGaugeCenterTemplateDirective, CircularGaugeComponent, CircularGaugeLabelsComponent, CircularGaugeModule, CircularGaugeScaleComponent, CollectionChangesService, CollectionComponent, CollectionItemComponent, ColorComponent, ColorsComponent, ConfigurationService, GaugeAreaComponent, GaugeComponent, GaugesModule, KENDO_ARCGAUGE, KENDO_CIRCULARGAUGE, KENDO_GAUGES, KENDO_LINEARGAUGE, KENDO_RADIALGAUGE, LabelsComponent, LinearGaugeAreaComponent, LinearGaugeComponent, LinearGaugeModule, LinearLabelsComponent, LinearPointerComponent, LinearPointersComponent, LinearRangeComponent, LinearRangesComponent, LinearScaleComponent, RadialGaugeAreaComponent, RadialGaugeComponent, RadialGaugeModule, RadialLabelsComponent, RadialPointerComponent, RadialPointersComponent, RadialRangeComponent, RadialRangesComponent, RadialScaleComponent, RangeComponent, ScaleComponent, SettingsComponent, ThemeService };