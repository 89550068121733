import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from './odata.service';
import { environment } from '../../../environments/environment';
import { Tag } from '../models/entities';
import { map } from 'rxjs';

@Injectable()
export class TagsService extends ODataService<Tag> {
  constructor() {
    const url = `${environment.apiUrl}/v1/detection/administrative/tag/`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'id', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public getTags() {
    return this.http.get<{ '@odata.count': number; value: Tag[] }>(this.url).pipe(map((response) => response.value)); // Extract only the "value" array
  }

  public addTag(newTag: Partial<Tag>) {
    return this.http.post<Tag>(this.url, newTag);
  }

  public searchTags(query: string) {
    const url = `${environment.apiUrl}/v1/detection/autocomplete/detectiontags/?q=${query}`;
    return this.http.get<Tag[]>(url);
  }
}

export interface TagResponse extends Tag {}
