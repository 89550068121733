import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-case-details',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './case-details.component.html',
  styleUrl: './case-details.component.scss',
})
export class CaseDetailsComponent {}
