import { Component, inject, ViewEncapsulation, OnInit } from '@angular/core';
import { CellClickEvent, ExcelModule, GridDataResult, GridModule } from '@progress/kendo-angular-grid';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { AdminAppbarComponent } from '../../admin/layout/components/admin-appbar/admin-appbar.component';
import { Case } from '../../../core/models/entities';
import { TicketFilterService, TicketsService } from './services/tickets.service';
import { fileExcelIcon, filterClearIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DateInputsModule, DateRangeService, SelectionRange } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { IconsModule } from '@progress/kendo-angular-icons';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

interface FilterItem {
  text: string;
  value: string | number;
}

@Component({
  selector: 'app-soar-cases',
  standalone: true,
  imports: [CommonModule, GridModule, IconsModule, LoaderModule, LabelModule, AdminAppbarComponent, ExcelModule, DropDownListModule, DateInputsModule, ButtonsModule],
  templateUrl: './tickets-log.page.html',
  styleUrls: ['./tickets-log.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TicketsService, DateRangeService, TicketFilterService],
})
export class TicketsLogPage extends BaseComponent implements OnInit {
  public gridService = inject(TicketsService);
  public ticketFilterService = inject(TicketFilterService);
  private route = inject(ActivatedRoute);
  public router = inject(Router);
  public organizationId: number | null = null;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public filterClearSVG: SVGIcon = filterClearIcon;

  private prioritiesSubject = new BehaviorSubject<FilterItem[]>([]);
  public priorities$: Observable<FilterItem[]> = this.prioritiesSubject.asObservable();

  private slaViolationsSubject = new BehaviorSubject<FilterItem[]>([]);
  public slaViolations$: Observable<FilterItem[]> = this.slaViolationsSubject.asObservable();

  private ticketSourcesSubject = new BehaviorSubject<FilterItem[]>([]);
  public ticketSources$: Observable<FilterItem[]> = this.ticketSourcesSubject.asObservable();

  private ticketStatusesSubject = new BehaviorSubject<FilterItem[]>([]);
  public ticketStatuses$: Observable<FilterItem[]> = this.ticketStatusesSubject.asObservable();

  private customerDefinedStatus = new BehaviorSubject<FilterItem[]>([]);
  public customerDefinedStatus$: Observable<FilterItem[]> = this.customerDefinedStatus.asObservable();

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  defaultTimeRange: SelectionRange = { start: null, end: null };
  public createTimeRange: SelectionRange = this.defaultTimeRange;
  public closeTimeRange: SelectionRange = this.defaultTimeRange;
  defaultDateRangeSelectionText = 'Select Date Range';

  constructor() {
    super();
  }

  ngOnInit() {
    this.initEffect();
  }

  private initEffect() {
    this.route.queryParams.subscribe((params) => {
      this.organizationId = params['organizationId'];
      this.loadFilters();
      this.gridService.getTickets(this.organizationId);
    });
  }

  private loadFilters(): void {
    this.ticketFilterService
      .getPriorities(this.organizationId?.toString() ?? '')
      .pipe(
        tap((priorities: FilterItem[]) => {
          this.prioritiesSubject.next(priorities);
        }),
      )
      .subscribe();

    this.ticketFilterService
      .getSlaViolations(this.organizationId?.toString() ?? '')
      .pipe(
        tap((slaViolations: FilterItem[]) => {
          this.slaViolationsSubject.next(slaViolations);
        }),
      )
      .subscribe();

      this.ticketFilterService
      .getCustomerDefinedStatus(this.organizationId?.toString() ?? '')
      .pipe(
        tap((customerdefinedstatus: FilterItem[]) => {
          this.customerDefinedStatus.next(customerdefinedstatus);
        }),
      )
      .subscribe();

    this.ticketFilterService
      .getTicketSources(this.organizationId?.toString() ?? '')
      .pipe(
        tap((ticketSources: FilterItem[]) => {
          this.ticketSourcesSubject.next(ticketSources);
        }),
      )
      .subscribe();

    this.ticketFilterService
      .getTicketStatuses(this.organizationId?.toString() ?? '')
      .pipe(
        tap((ticketStatuses: FilterItem[]) => {
          this.ticketStatusesSubject.next(ticketStatuses);
        }),
      )
      .subscribe();
  }

  public statusChange($event: { text: string; value: string | null }, field: string): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    const newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== field);

    if ($event.value !== null) {
      newFilters.push({
        field: field,
        operator: 'eq',
        value: $event.value,
      });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  public caseDetails($event: CellClickEvent) {
    const caseObj = $event.dataItem as Case;
    this.router.navigate(['/customer/case-dashboard'], {
      queryParams: {
        organizationId: this.organizationId,
        soarId: caseObj.id,
      },
    });
  }

  isFilterApplied(field: string) {
    const filters = this.filter.filters.filter((f) => (f as FilterDescriptor).field == field);
    return filters.length > 0;
  }

  calendarValueChange(range: SelectionRange, popup: any, label: any, field: any) {
    if (range.start && range.end) {
      this.toggleDateRangePopup(popup, label, field);
    }
  }

  public toggleDateRangePopup(element: any, label: any, field: string) {
    if (element?.calendar) {
      if (field == 'created_time') {
        this.createTimeRange = element.calendar.value;
      } else if (field == 'ticket_closed_time') {
        this.closeTimeRange = element.calendar.value;
      }

      this.onRangeChange(element.calendar.value, field);
    }

    label.text = this.formattedValue(element.calendar.value);
  }

  public formattedValue(range: SelectionRange): string {
    if (!(range && range.start && range.end)) {
      return this.defaultDateRangeSelectionText;
    }

    return this.formatDateWithEndOfDayTime(range.start) + ' - ' + this.formatDateWithEndOfDayTime(range.end);
  }

  clearFilters(element: any, label: any, field: string) {
    if (field == 'created_time') {
      this.createTimeRange = this.defaultTimeRange;
    } else if (field == 'ticket_closed_time') {
      this.closeTimeRange = this.defaultTimeRange;
    }

    this.filter.filters = this.filter.filters.filter((f) => (f as FilterDescriptor).field !== field);
    this.gridService.state.filter = {
      logic: 'and',
      filters: this.filter.filters,
    };

    label.text = this.formattedValue(element.calendar?.value);

    // Refresh the grid data
    this.gridService.read();
  }

  public onRangeChange(value: SelectionRange, field: string): void {
    // Remove any existing filters for the same field
    this.filter.filters = this.gridService.state.filter?.filters.filter((f) => (f as FilterDescriptor).field !== field) || [];

    const end = new Date(value.end!.getTime());
    // Only add filter if the date value is not null
    if (value) {
      this.filter.filters.push({
        field: field,
        operator: 'gt',
        value: this.formatDateWithEndOfDayTime(value.start!),
      });
      // Having to to add 1 day to date as ge (greater than equal to) is not working as expected.
      // need to fix issue with ge as it returns records with date less than specified date
      // and similar is the case with le (less than equal to) as it returns records with date greater than specified date.
      this.filter.filters.push({
        field: field,
        operator: 'lt',
        value: this.formatDateWithEndOfDayTime(new Date(end!.setDate(end!.getDate() + 1))), //
      });
    }

    // Update the grid filter state
    this.gridService.state.filter = {
      logic: 'and',
      filters: this.filter.filters,
    };

    // Refresh the grid data
    this.gridService.read();
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Format the selected date to exclude the time (MM/dd/yyyy)
  private formatDateWithEndOfDayTime(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
    const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
    return `${year}-${month}-${day}`;
  }
}
