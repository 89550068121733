import { ChangeDetectionStrategy, Component, ViewChild, effect, inject, output, signal } from '@angular/core';
import { FormArray, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { takeUntil } from 'rxjs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { OrganizationService } from '../../../organizations/services/organization.service';
import { IUser, Organization } from '../../../../../core/models/entities';
import { FormService } from './services/form.service';

import { NotificationService } from '@progress/kendo-angular-notification';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CommonModule } from '@angular/common';
import { User } from '../../../../../core/models/user';
import { VirtualDropdownComponent } from '../../../../../core/components/virtual-dropdown/virtual-dropdown.component';

@Component({
  standalone: true,
  selector: 'cyflare-user',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    DialogsModule,
    CommonModule,
    VirtualDropdownComponent
  ],
  providers: [
    NotificationService,
    UserService,
    FormService,
    {
      provide: OrganizationService,
      useClass: OrganizationService,
    },
  ],
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'], // Note the plural 'styleUrls'
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent extends BaseComponent {
  @ViewChild('virtualDropdown', { static: false }) orgDropDown: VirtualDropdownComponent<Organization> | undefined;
  private userService = inject(UserService);
  public orgService = inject(OrganizationService);
  private forms = inject(FormService);
  public xIcon: SVGIcon = xIcon;
  private notificationService = inject(NotificationService);
  public user = signal<IUser>(User.createUser());
  public userForm = this.forms.userForm();
  public roles: Array<string> = ['Admin', 'User'];
  close = output<void>();
  public opened = false;

  public orgs = signal<{ id: number; name: string }[]>([]);
  public cachedUser: IUser | undefined;
  public defaultItem = { name: 'Select Organization', id: 0 };

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      const user = this.user();      
      this.cachedUser = { ...user };

      const orgs = user.organizations?.map((org) => org?.id).filter((id): id is number => id !== null && id !== undefined) ?? [];

      this.userForm.patchValue({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        role: user.groups![0],
        primary_organization_id: user.primary_organization?.id,
      });

      this.userForm.setControl('organizations', new FormArray<FormControl<number | null>>([...orgs.map((orgId) => new FormControl(orgId))]));

      this.defaultItem = {
        id: user.primary_organization!.id!,
        name: user.primary_organization!.name!,
      };
    });
  }

  onOrgDropdownChange(organizationId: number) {
    const organizationsArray = this.userForm.get('organizations') as FormArray;

    if (organizationsArray.length > this.cachedUser!.organizations!.length) {
      organizationsArray.removeAt(organizationsArray.length - 1);
    }

    const alreadyExists = this.cachedUser!.organizations!.find((org) => org.id === organizationId);
    if (!alreadyExists) {
      organizationsArray.push(new FormControl(organizationId));
    }
  }

  public open(): void {
    this.opened = true;
  }

  public save($event: Event) {
    const { role, ...formValueWithoutRole } = this.userForm.value;
    const transformedValue = {
      ...formValueWithoutRole,
      groups: [role],
    };

    console.log('Form Value:', this.userForm.value); // Debugging line to check form values
    console.log('Transformed Value:', transformedValue); // Debugging line to check transformed values

    this.userService
      .addUser(transformedValue)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  public clear() {
    this.userForm.reset();
  }

  public closeDialog(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public cancel() {
    this.userForm.reset();
    this.close.emit();
  }

  update($event: Event) {
    $event.preventDefault();
    let payload = {
      first_name: this.userForm.value.first_name,
      last_name: this.userForm.value.last_name,
      email: this.userForm.value.email,
      organizations: this.userForm.value.organizations,
      primary_organization_id: this.userForm.value.primary_organization_id,
      groups: [this.userForm.value.role],
    };
    const id = this.userForm.value.id!;
    this.userService
      .updateUser(id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  delete($event: Event) {
    $event.preventDefault();
    const id = this.userForm.value.id!;
    this.userService
      .deleteUser(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.closeDialog('delete confirmed');
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  resetPassword($event: Event) {
    $event.preventDefault();
    const id = this.userForm.value.id;

    if (!id) {
      console.error('User ID is required to reset the password.');
      this.notificationService.show({
        content: 'User ID is required to reset the password.',
        type: { style: 'error' },
      });
      return;
    }

    this.userService
      .resetPassword(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.notificationService.show({
            content: "Password reset link sent to user's email.",
            type: { style: 'success' },
          });
        },
        error: (error) => {
          console.error('Error resetting password:', error);
          this.notificationService.show({
            content: 'Failed to send password reset link. Please try again.',
            type: { style: 'error' },
          });
        },
      });
  }

  resetMfa($event: Event) {
    $event.preventDefault();
    const id = this.userForm.value.id!;
    this.userService
      .resetMFA(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.close.emit();
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
