import { Component, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridModule, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { environment } from '../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { SocBulletinForm, SocBulletinFormService } from './service/bulletin-form.service';
import { SocBulletinRequest, SocBulletinService } from './service/bulletin.service';
import { format, parseISO } from 'date-fns';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-bulletin',
  standalone: true,
  imports: [CommonModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, GridModule, ReactiveFormsModule],
  templateUrl: './bulletin.component.html',
  styleUrl: './bulletin.component.scss',
})
export class BulletinComponent extends BaseComponent {
  public formGroup: FormGroup<SocBulletinForm> | undefined;
  public formBuilder = inject(FormBuilder);
  public gridService = inject(SocBulletinService);
  public route = inject(ActivatedRoute);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/bulletin`;
  private formService = inject(SocBulletinFormService);
  private editedRowIndex: number | undefined;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadSocBulletin(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadSocBulletin(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }

  public addHandler($event: AddEvent): void {
    this.closeEditor($event.sender);
    this.formGroup = this.formService.createSocBulletinForm();
    $event.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (this.formGroup) {
      grid.closeRow(rowIndex);
      this.formGroup = undefined;
    }
  }

  saveHandler($event: SaveEvent) {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      const timeBounds = formValue.timebounds ? new Date(formValue.timebounds) : null;

      const formattedTimebounds = timeBounds ? format(timeBounds, 'yyyy-MM-dd HH:mm:ss') : null;

      const payload: SocBulletinRequest = {
        organization_id: this.id,
        soc_response_bulletin: {
          pk: $event.isNew ? null : formValue?.pk!,
          description: formValue?.description!,
          xdr_alert_type: formValue?.xdr_alert_type!,
          required_action: formValue?.required_action!,
          timebounds: formattedTimebounds,
        },
      };
      if ($event.isNew) {
        this.gridService.createSocBulletin(payload).subscribe({
          next: (response) => {
            this.loadSocBulletin(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.gridService.updateSocBulletin(payload.soc_response_bulletin).subscribe({
          next: (response) => {
            this.loadSocBulletin(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    }
  }

  public editHandler($event: EditEvent): void {
    const dataItem = $event.dataItem;

    const transformedDataItem = {
      ...dataItem,
      timebounds: dataItem.timebounds ? parseISO(dataItem.timebounds) : null,
    };

    this.formGroup = this.formService.createSocBulletinForm(transformedDataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler($event: any): void {
    this.gridService.deleteSocBulletin($event.dataItem.pk).subscribe(() => {
      this.loadSocBulletin(this.id);
    });
  }
}
