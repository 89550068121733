<div style="display: flex; flex-direction: column; flex-wrap: wrap">
  <kendo-listview [data]="checks">
    <ng-template
      kendoListViewItemTemplate
      let-check
    >
      <div>{{ check }}</div>
    </ng-template>
  </kendo-listview>
</div>
