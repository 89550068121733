import { Component, CreateEffectOptions, Input, Signal, ViewChild, ViewContainerRef, effect, inject, output, signal } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { AnalyticsService, TopFiveData } from '../../../../../core/services/analytics.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ChartLabelsPopupService } from '../../../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-suspect-assets',
  standalone: true,
  imports: [ChartsModule, LoaderModule],
  templateUrl: './suspect-assets.component.html',
  styleUrl: './suspect-assets.component.scss',
  providers: [AnalyticsService],
})
export class SuspectAssetsComponent extends BaseComponent {
  public popupService = inject(ChartLabelsPopupService);
  @Input() topSuspectAssetsData!: Signal<TopFiveData[]>;
  @Input() topAssetDetailData!: Signal<TopFiveData[]>;
  public seriesClick = output<SeriesClickEvent>();

  public isLoading = signal(true);
  public ticketsByAsset = signal<TopFiveData[]>([]);
  public ticketsByEventName = signal<TopFiveData[]>([]);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const suspectAssetsData = this.topSuspectAssetsData();
        const assetDetailData = this.topAssetDetailData();
        if (suspectAssetsData && assetDetailData) {
          this.ticketsByAsset.set(suspectAssetsData.slice(0, 5) || []);
          this.ticketsByEventName.set(assetDetailData.slice(0, 5) || []);
          this.isLoading.set(false);
        } else {
          this.ticketsByAsset.set([]);
          this.ticketsByEventName.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public onSeriesClick(event: SeriesClickEvent): void {
    this.seriesClick.emit(event);
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }
}
