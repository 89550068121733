import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TravelAdvisory } from '../../../../../../../../core/models/entities';

export interface TravelAdvisoryForm {
  pk: FormControl<string | null>;
  email: FormControl<string | null>;
  destination: FormControl<string | null>;
  start_date: FormControl<string | null>;
  end_date: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class TravelAdvisoryFormService {
  public createTravelAdvisoryForm(travelAdvisory: Partial<TravelAdvisory> = {}): FormGroup<TravelAdvisoryForm> {
    return new FormGroup<TravelAdvisoryForm>({
      pk: new FormControl(travelAdvisory.pk || null, Validators.required),
      email: new FormControl(travelAdvisory.email || null, Validators.required),
      destination: new FormControl(travelAdvisory.destination ? travelAdvisory.destination.name : null, Validators.required),
      start_date: new FormControl(travelAdvisory.start_date || null, Validators.required),
      end_date: new FormControl(travelAdvisory.end_date || null, Validators.required),
    });
  }
}
