@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  @if (slaData()) {
    <div #target>
      <kendo-arcgauge
        [value]="slaData().value"
        [colors]="slaData().colors"
      >
        <ng-template
          kendoArcGaugeCenterTemplate
          let-value="value"
          let-color="color"
        >
          <span [style.color]="color">{{ value }}%</span>
        </ng-template>
      </kendo-arcgauge>
    </div>

    <kendo-dialog
      *ngIf="showPopup"
      (close)="close()"
    >
      <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
      <kendo-grid
        class="mxh-80v"
        [filterable]="true"
        [data]="gridService | async"
        [pageable]="{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [25, 50, 100, 200]
        }"
        [pageSize]="gridService.state.take"
        [skip]="gridService.state.skip"
        [(filter)]="gridService.state.filter"
        [loading]="gridService.loading"
        (dataStateChange)="gridService.onStateChange($event)"
        [sort]="this.gridService.state.sort"
        [sortable]="true"
        [resizable]="true"
      >
        <ng-template kendoGridToolbarTemplate>
          <button
            type="button"
            kendoGridExcelCommand
          >
            Export to Excel
          </button>
        </ng-template>
        <kendo-grid-column
          [filterable]="false"
          field="alert_received_on"
          title="Received On"
        ></kendo-grid-column>
        <kendo-grid-column
          field="case_id"
          title="Case #"
        ></kendo-grid-column>
        <kendo-grid-column
          field="event_name"
          title="Event Name"
        ></kendo-grid-column>
        <kendo-grid-column
          field="case_severity"
          title="Severity"
        ></kendo-grid-column>
        <kendo-grid-column
          field="case_responded_on"
          title="First Response"
        ></kendo-grid-column>
        <kendo-grid-column
          field="sla_exceeded"
          title="SLA Exceeded"
        ></kendo-grid-column>
        <kendo-grid-column
          field="case_source"
          title="Source"
        ></kendo-grid-column>
        <kendo-grid-excel
          fileName="sla-data.xlsx"
          [fetchData]="allData"
        ></kendo-grid-excel>
      </kendo-grid>
    </kendo-dialog>
  }
}
