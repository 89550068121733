/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

declare global {
  interface Window {
    heap: any;
  }
}

// Function to load Heap Analytics script
function loadHeapAnalytics(heapId: string) {
  window.heap = window.heap || [];
  window.heap.load = function (e: string, t?: any) {
    window.heap.appid = e;
    window.heap.config = t = t || {};
    var r = document.createElement('script');
    r.type = 'text/javascript';
    r.async = true;
    r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
    var a = document.getElementsByTagName('script')[0];
    a.parentNode!.insertBefore(r, a);
    for (
      var n = function (e: string) {
          return function () {
            window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        },
        p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'],
        o = 0;
      o < p.length;
      o++
    ) {
      window.heap[p[o]] = n(p[o]);
    }
  };
  window.heap.load(heapId);
}

loadHeapAnalytics(environment.heapId);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
