import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { Case } from '../../../../../../../../core/models/entities';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({ providedIn: null })
export class EdrAgentStatusDetailsService extends ODataService<Case> {
  private field = 'timestamp';

  constructor() {
    super(inject(HttpClient), '');
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public setDateRange(dateRange: string[] | null) {
    const filters = this.state.filter?.filters.filter((f) => (f as FilterDescriptor).field !== this.field) || [];

    if (dateRange) {
      filters.push({
        field: this.field,
        operator: 'gt',
        value: dateRange[0],
      });

      filters.push({
        field: this.field,
        operator: 'lt',
        value: dateRange[1],
      });
    }

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: filters,
      },
    };
  }
}
