import { Component, inject } from '@angular/core';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '../../../../core/shared/common/base.component';
import { AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { SVGIcon, eyeIcon, eyeSlashIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule, IconsModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends BaseComponent {
  private router: Router = inject(Router);
  private authService: AuthService = inject(AuthService);
  showPassword = false;
  showConfirmPassword = false;
  public eyeIcon: SVGIcon = eyeIcon;
  public eyeSlashIcon: SVGIcon = eyeSlashIcon;

  public resetPasswordForm: FormGroup = new FormGroup(
    {
      email: new FormControl<string | null>(null, [Validators.email, Validators.required, this.lowercaseValidator]),
      password: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10), Validators.pattern('^(?=.*[a-z])' + '(?=.*[A-Z])' + '(?=.*[0-9])' + "(?=.*[!@#$%^&*()_+\\-=[\\]{};:',.<>?/])" + '(?!.*[\\\\"])' + "[a-zA-Z0-9!@#$%^&*()_+\\-=[\\]{};:',.<>?/]+$")]),
      confirmPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10), Validators.pattern('^(?=.*[a-z])' + '(?=.*[A-Z])' + '(?=.*[0-9])' + "(?=.*[!@#$%^&*()_+\\-=[\\]{};:',.<>?/])" + '(?!.*[\\\\"])' + "[a-zA-Z0-9!@#$%^&*()_+\\-=[\\]{};:',.<>?/]+$")]),
      confirmationCode: new FormControl<string | null>(null, Validators.required),
    },
    { validators: this.passwordsMatch.bind(this) },
  );

  constructor() {
    super();
  }

  private lowercaseValidator(control: FormControl) {
    if (control.value && control.value !== control.value.toLowerCase()) {
      control.setValue(control.value.toLowerCase(), { emitEvent: false });
    }
    return null;
  }

  public onEmailInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const emailControl = this.resetPasswordForm.get('email');
    if (emailControl) {
      emailControl.setValue(input.value.toLowerCase());
    }
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  passwordsMatch(control: AbstractControl): { [key: string]: boolean } | null {
    const formGroup = control as FormGroup;
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password && confirmPassword) {
      if (password.value !== confirmPassword.value) {
        return { mismatch: true };
      } else {
        return null;
      }
    }
    return null;
  }

  public signup() {
    console.log('signup');
  }

  public forgot() {
    this.router.navigate(['/auth/reset-password']);
  }

  public resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.authService
        .confirmForgotPassword(this.resetPasswordForm.value.email!, this.resetPasswordForm.value.password!, this.resetPasswordForm.value.confirmationCode!)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response) => {
            this.router.navigate(['/auth/login']);
          },
        });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  public back() {
    this.router.navigate(['/auth/login']);
  }
}

export interface ResetPasswordForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  confirmationCode: FormControl<string | null>;
}
