<kendo-grid
  [style.height.%]="100"
  [data]="gridService | async"
  [filterable]="true"
  [(filter)]="gridService.state.filter"
  [skip]="gridService.state.skip"
  [loading]="gridService.loading"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="gridService.state.take"
  [sortable]="true"
  [sort]="this.gridService.state.sort"
  (dataStateChange)="gridService.onStateChange($event)"
>
  <!-- Commented out for current release by Matt. Will implement for next weeks release -->
  <!-- <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
    <button kendoButton>Save Changes</button>
    <button kendoButton>Cancel Changes</button>
  </ng-template> -->
  <kendo-grid-column
    field="name"
    title="Name"
    [locked]="true"
    [width]="400"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="connector"
    title="Connector"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'connector')"
        [data]="connectors"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="defaultStatus"
    title="Default Status"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'defaultStatus')"
        [data]="statuses"
      ></kendo-dropdownlist>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      @if (dataItem.defaultStatus === 'Active') {
        <span class="badge bg-success"> {{ dataItem.defaultStatus }} </span>
      } @else if (dataItem.defaultStatus === 'Not Active') {
        <span class="badge bg-danger"> {{ dataItem.defaultStatus }}</span>
      } @else {
        <span class="badge bg-info">{{ dataItem.defaultStatus }}</span>
      }
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="killChainStage"
    title="Kill Chain Stage"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'killChainStage')"
        [data]="killChainStages"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="mitreTactic"
    title="MITRE Tactic"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'mitreTactic')"
        [data]="mitreTactics"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="mitreTacticId"
    title="MITRE Tactic ID"
    [width]="200"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="mitreTechnique"
    title="MITRE Technique"
    [width]="200"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="mitreTechniqueId"
    title="MITRE Technique ID"
    [width]="200"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateActivated"
    title="Date Activated"
    filter="date"
    format="{0:MM/dd/yyyy}"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="xdrEventName"
    title="XDR Event Name"
    [width]="300"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="adrStatus"
    title="ADR Status"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'adrStatus')"
        [data]="adrStatuses"
      ></kendo-dropdownlist>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      @if (dataItem.adrStatus === 'Full ADR') {
        <span class="badge bg-success"> {{ dataItem.adrStatus }} </span>
      } @else if (dataItem.adrStatus === 'No ADR') {
        <span class="badge bg-danger"> {{ dataItem.adrStatus }}</span>
      } @else {
        <span class="badge bg-info">{{ dataItem.adrStatus }}</span>
      }
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="detectionOrigin"
    title="Detection Origin"
    [width]="200"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'detectionOrigin')"
        [data]="detectionOrigins"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="xdrEventName"
    title="XDR Event Name"
    [width]="300"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
