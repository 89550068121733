import { Component } from '@angular/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-redirect-landing',
  standalone: true,
  imports: [LayoutModule, ButtonModule],
  templateUrl: './redirect-landing.component.html',
  styleUrl: './redirect-landing.component.scss',
})
export class RedirectLandingComponent {
  constructor() {}

  public redirectToLogin() {
    window.location.href = '/auth/login';
  }
}
