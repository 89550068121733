<kendo-grid
  #grid
  [height]="600"
  [data]="gridService | async"
  [filterable]="false"
  [skip]="gridService.state.skip"
  [loading]="gridService.loading"
  [sortable]="false"
  (dataStateChange)="gridService.onStateChange($event)"
  [(filter)]="gridService.state.filter"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="gridService.state.take"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="loader-container">
      <kendo-loader
        type="infinite-spinner"
        size="large"
      ></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    field="event_name"
    title="Event"
    [width]="475"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="case_id"
    title="SOAR ID"
    [width]="110"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="source_ip"
    title="Source IP"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="destination_ip"
    title="Destination IP"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="alert_received_on"
    title="Source Tool Time"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="case_closed_on"
    title="Alert Triaged Timestamp"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="case_severity"
    title="Severity"
    [width]="90"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="zoho_ticket_number"
    title="Escalated Case #"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="closure_reason"
    title="Triaged Result"
    [width]="175"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="mitre_tactic"
    title="Mitre Tactic"
    [width]="150"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="event_occurred_on"
    title="True Event Time"
    [width]="175"
  >
  </kendo-grid-column>
  <kendo-grid-excel
    [fileName]="fileName"
    [fetchData]="allData"
  ></kendo-grid-excel>
</kendo-grid>
