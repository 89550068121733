<kendo-grid
  [filterable]="true"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
  [filterable]="false"
  (add)="addHandler($event)"
  (save)="saveHandler($event)"
  (edit)="editHandler($event)"
  (remove)="removeHandler($event)"
  (cancel)="cancelHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand>Add new</button>
  </ng-template>
  <kendo-grid-column
    field="xdr_alert_type"
    title="XDR Alert"
  ></kendo-grid-column>
  <kendo-grid-column
    field="description"
    title="Description"
  ></kendo-grid-column>
  <kendo-grid-column
    field="required_action"
    title="Required Action"
  ></kendo-grid-column>
  <kendo-grid-column
    field="timebounds"
    title="Expiration Date"
    editor="date"
  ></kendo-grid-column>
  <kendo-grid-command-column
    title="Action"
    [width]="220"
  >
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        kendoGridEditCommand
        [primary]="true"
      >
        Edit
      </button>
      <button kendoGridRemoveCommand>Remove</button>
      <button
        kendoGridSaveCommand
        [disabled]="false"
      >
        {{ isNew ? 'Add' : 'Update' }}
      </button>
      <button kendoGridCancelCommand>
        {{ isNew ? 'Cancel' : 'Cancel' }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
