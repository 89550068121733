<form
  class="form-signin"
  [formGroup]="resetPasswordForm"
>
  <div class="logo-container">
    <img src="/assets/logos/cyflare_one_logo.png" />
  </div>
  <div class="header-container">
    <h2>Reset Password</h2>
  </div>
  <div>
    <kendo-floatinglabel text="Enter Email">
      <input
        kendoTextBox
        class="form-control"
        formControlName="email"
        (input)="onEmailInput($event)"
      />
    </kendo-floatinglabel>
  </div>
  <div>
    <kendo-floatinglabel text="Enter Confirmation Code">
      <input
        kendoTextBox
        class="form-control"
        formControlName="confirmationCode"
      />
    </kendo-floatinglabel>
  </div>
  <div>
    <kendo-floatinglabel text="Enter Your Password">
      <kendo-textbox
        [type]="showPassword ? 'text' : 'password'"
        formControlName="password"
        class="form-control"
      >
        <ng-template kendoTextBoxSuffixTemplate>
          <button
            kendoButton
            fillMode="clear"
            class="transparent-button"
            (click)="togglePasswordVisibility()"
            [svgIcon]="showPassword ? eyeSlashIcon : eyeIcon"
          ></button>
        </ng-template>
      </kendo-textbox>
    </kendo-floatinglabel>
  </div>
  <div>
    <kendo-floatinglabel text="Confirm Your Password">
      <kendo-textbox
        [type]="showConfirmPassword ? 'text' : 'password'"
        formControlName="confirmPassword"
        class="form-control"
      >
        <ng-template kendoTextBoxSuffixTemplate>
          <button
            kendoButton
            fillMode="clear"
            class="transparent-button"
            (click)="toggleConfirmPasswordVisibility()"
            [svgIcon]="showConfirmPassword ? eyeSlashIcon : eyeIcon"
          ></button>
        </ng-template>
      </kendo-textbox>
    </kendo-floatinglabel>
  </div>

  @if (resetPasswordForm.hasError('mismatch') && (resetPasswordForm.get('confirmPassword')?.touched || resetPasswordForm.get('confirmPassword')?.dirty)) {
    <div>
      <p class="text-danger">Password do not match</p>
    </div>
  }

  <div class="d-grid gap-2 mt-4">
    <button
      [disabled]="resetPasswordForm.invalid"
      kendoButton
      themeColor="primary"
      (click)="resetPassword()"
    >
      Reset Password
    </button>
    <kendo-formhint>Password Must Contain:<br />- Minimum of 10 characters<br />- At least one uppercase charater<br />- At least one lowercase character<br />- At least one special character (no spaces allowed)</kendo-formhint>
  </div>
  <div class="mt-2">
    <div class="d-flex justify-content-between">
      <div class="form-check d-flex align-items-center">
        <label
          class="form-check-label"
          for="rememberMe"
          >Having trouble signing in?</label
        >
      </div>
      <div>
        <a
          href="mailto:support@cyflare.com"
          class="text-primary"
          kendoButton
          fillMode="clear"
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
  <div class="d-grid gap-2 mt-4">
    <button
      kendoButton
      (click)="back()"
    >
      Go Back
    </button>
  </div>
</form>
