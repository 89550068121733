<kendo-appbar class="kendo-main-appbar">
  <kendo-appbar-section>
    <img
      src="../assets/logos/cyflare_one_logo.png"
      alt="CyFlare"
      width="185"
    />
  </kendo-appbar-section>

  <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

  <kendo-appbar-spacer></kendo-appbar-spacer>

  <!-- Commenting out until ROAM resolves drop-in issue -->
  <!-- @if (isBetaTestOrg()) {
    <kendo-appbar-section>
      <div class="example-config">
        <div kendoRippleContainer>
          <button
            kendoButton
            *ngIf="!opened"
            (click)="handlePopup('vipSupport')"
          >
            VIP Support
            <kendo-chip
              class="beta-chip"
              size="small"
              >BETA</kendo-chip
            >
          </button>
        </div>
      </div>
    </kendo-appbar-section>
  } -->

  <kendo-appbar-section>
    <cyflare-virtual-dropdown
      #virtualDropdown
      [textField]="'name'"
      [valueField]="'id'"
      (valueChanged)="onOrgDropdownChanged($event)"
      [refreshOnClick]="true"
      [filterable]="true"
      [styles]="{ width: '225px' }"
      [virtual]="{ itemHeight: 36 }"
      [dataService]="orgService"
    >
    </cyflare-virtual-dropdown>
  </kendo-appbar-section>

  <kendo-appbar-section>
    <kendo-dropdownbutton
      [svgIcon]="menuIcon"
      [data]="infoNav"
      textField="text"
      (itemClick)="onItemClick($event)"
    >
    </kendo-dropdownbutton>
  </kendo-appbar-section>

  <kendo-appbar-section class="actions">
    <kendo-badge-container>
      <button
        kendoButton
        class="notification-button"
        (click)="handlePopup('notifications')"
      >
        <kendo-svgicon [icon]="bellIcon"></kendo-svgicon>
      </button>
      @if (this.notifications().length > 0) {
        <kendo-badge
          shape="dot"
          themeColor="error"
          size="small"
          position="inside"
          >{{ this.notifications().length }}</kendo-badge
        >
      }
    </kendo-badge-container>
    <span class="k-appbar-separator"></span>
  </kendo-appbar-section>

  <kendo-appbar-section>
    <kendo-dropdownbutton
      class="avatar-button"
      [data]="data"
      (itemClick)="onUserProfileClick($event)"
    >
      @if (avatarText !== null) {
        <kendo-avatar
          slot="item"
          shape="circle"
          width="36px"
          height="36px"
          >{{ avatarText }}</kendo-avatar
        >
      } @else {
        <kendo-avatar
          slot="item"
          shape="circle"
          width="36px"
          height="36px"
          [svgIcon]="userIcon"
        ></kendo-avatar>
      }
    </kendo-dropdownbutton>
  </kendo-appbar-section>
</kendo-appbar>

<kendo-drawer-container class="k-body">
  <kendo-drawer
    class="kendo-drawer-content"
    #drawer
    style="height: 100%"
    [items]="Items"
    mode="push"
    [mini]="true"
    [expanded]="drawerExpanded()"
    (select)="onSelect($event)"
    [autoCollapse]="false"
    [isItemExpanded]="isItemExpanded"
  >
    <ng-template kendoDrawerFooterTemplate>
      <div
        class="kendo-drawer-footer mb-2"
        [ngClass]="drawerExpanded() ? 'drawer-footer-row' : 'drawer-footer-column'"
      >
        <button
          kendoButton
          fillMode="flat"
          (click)="toggleDrawer()"
        >
          @if (drawerExpanded()) {
            <span><kendo-svgicon [icon]="chevronDoubleLeftIcon"></kendo-svgicon></span>
          }
          @if (!drawerExpanded()) {
            <span><kendo-svgicon [icon]="chevronDoubleRightIcon"></kendo-svgicon></span>
          }
        </button>
      </div>
    </ng-template>
  </kendo-drawer>
  <kendo-drawer-content class="scrollable-content">
    <div style="padding-left: 1rem; padding-right: 1rem; padding-bottom: 1rem">
      <router-outlet #anchor></router-outlet>
    </div>
  </kendo-drawer-content>
</kendo-drawer-container>
