///v1/organization/administrative/ticketingemail({organization_id})/

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { Organization } from '../../../../../../../../core/models/entities';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TicketingEmailService extends ODataService<TicketingEmailResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/ticketingemail`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public createEmail(request: TicketingEmailPostRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/ticketingemail/`;
    return this.http.post<TicketingEmailPostRequest>(url, request);
  }

  public updateEmail(email: TicketingEmailResponse) {
    const url = `${this.url}`;
    return this.http.patch<TicketingEmailResponse>(url, email).pipe(map((response) => response));
  }

  public deleteEmail(emailId: number) {
    const url = `${this.url}?pk=${emailId}`;
    return this.http.delete(url).pipe(map((response) => response));
  }
}

export interface TicketingEmailResponse {
  pk: number;
  email: string;
  is_primary: boolean;
}

export interface TicketingEmailPostRequest {
  organization_id: number;
  ticketing_email: {
    pk?: string; // optional uuid4
    email: string;
    is_primary: boolean;
  };
}
