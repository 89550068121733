import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { AnalyticsService, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { filter, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-ticket-count',
  standalone: true,
  imports: [LoaderModule],
  templateUrl: './ticket-count.component.html',
  styleUrl: './ticket-count.component.scss',
  providers: [AnalyticsService],
})
export class TicketCountComponent extends BaseComponent {
  ticketsData = input<WidgetData>({ value: 0, colors: [] });

  public ticketCount = signal<WidgetData>({ value: 0, colors: [] });
  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.ticketsData();
        if (data && data.value > 0) {
          this.ticketCount.set(data);
          this.isLoading.set(false);
        } else {
          this.ticketCount.set({ value: 0, colors: [] });
          this.isLoading.set(false);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }
}
