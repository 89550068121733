import { CommonModule } from '@angular/common';
import { Component, CreateEffectOptions, Input, Signal, effect, inject, output, signal } from '@angular/core';
import { ChartsModule, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { DispositionData } from '../../../core/services/analytics.service';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { ChartLabelsPopupService } from '../../../core/shared/common/chart-labels-popup.service';

@Component({
  selector: 'app-disposition-column',
  standalone: true,
  imports: [ChartsModule, LoaderModule, CommonModule],
  templateUrl: './disposition-column.component.html',
  styleUrl: './disposition-column.component.scss',
})
export class DispositionColumnComponent extends BaseComponent {
  public popupService = inject(ChartLabelsPopupService);
  @Input() dispositionData!: Signal<DispositionData[]>;

  public isLoading = signal(true);
  public detectionDisposition = signal<DispositionData[]>([]);
  public onSeriesClick = output<SeriesClickEvent>();

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const dispositionData = this.dispositionData();
        if (dispositionData) {
          this.detectionDisposition.set(dispositionData || []);
          this.isLoading.set(false);
        } else {
          this.detectionDisposition.set([]);
          this.isLoading.set(true);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  public handleSeriesClick(event: SeriesClickEvent): void {
    this.onSeriesClick.emit(event);
  }

  truncateCategoryLabel(args: any): string {
    const label = args.text;
    const maxLength = 20; // Set your desired max length
    return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
  }
}
