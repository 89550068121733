import { Component } from '@angular/core';
import { LayoutModule, TimelineEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-response-actions',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './response-actions.component.html',
  styleUrls: ['./response-actions.component.scss'],
})
export class ResponseActionsComponent {
  public responseActions: TimelineEvent[] = [
    {
      date: new Date(),
      title: 'IP Blocked on firewall',
      description: 'Palo Alto Firewall - IP Blocked on firewall',
    },
    {
      date: new Date(),
      title: 'User Disabled',
      description: 'Azure AD - User Disabled',
    },
    {
      date: new Date(),
      title: 'User session revoked',
      description: 'Azure AD - User session revoked',
    },
  ];
}
