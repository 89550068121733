<app-admin-appbar></app-admin-appbar><br />

<kendo-grid
  [style.height.%]="100"
  [data]="gridService | async"
  [filterable]="true"
  [(filter)]="gridService.state.filter"
  [skip]="gridService.state.skip"
  [loading]="gridService.loading"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [25, 50, 100, 200]
  }"
  [pageSize]="gridService.state.take"
  [sortable]="true"
  [sort]="this.gridService.state.sort"
  (dataStateChange)="gridService.onStateChange($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      type="button"
      kendoGridExcelCommand
      [svgIcon]="fileExcelIcon"
    >
      Export to Excel
    </button>
  </ng-template>
  <ng-template kendoGridLoadingTemplate>
    <div class="loader-container">
      <kendo-loader
        type="infinite-spinner"
        size="large"
      ></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    field="request_id"
    title="Ticket Number"
    [width]="150"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="created_time"
    title="Created On"
    [width]="280"
    filter="date"
    [filterable]="true"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <button
        kendoButton
        #anchor
        (click)="popup.toggle();"
      >
        <kendo-label
          #createTimeLabel
          [text]="defaultDateRangeSelectionText"
        ></kendo-label>
      </button>
      @if(isFilterApplied('created_time')) {
      <button
        kendoButton
        [svgIcon]="filterClearSVG"
        (click)="clearFilters(popup, createTimeLabel, 'created_time');"
      ></button>
      }
      <kendo-daterange-popup
        #popup
        [anchor]="anchor"
      >
        <ng-template kendoDateRangePopupTemplate>
          <kendo-multiviewcalendar
            [value]="createTimeRange"
            selection="range"
            (valueChange)="calendarValueChange($event, popup, createTimeLabel, 'created_time')"
          >
          </kendo-multiviewcalendar>
        </ng-template>
      </kendo-daterange-popup>
    </ng-template>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.created_time | date: 'MMM dd yyyy HH:mm' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="subject"
    title="Event Name"
    [width]="300"
    [filterable]="true"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="priority"
    title="Severity"
    [width]="150"
    [filterable]="true"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'priority')"
        [data]="priorities$ | async"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="ticket_source"
    title="Event Source"
    [width]="150"
    [filterable]="true"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'ticket_source')"
        [data]="ticketSources$ | async"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="ticket_status"
    title="Status"
    [width]="150"
    [filterable]="true"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'ticket_status')"
        [data]="ticketStatuses$ | async"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="sla_violation_type"
    title="SLA Violation"
    [width]="150"
    [filterable]="true"
  >
    <ng-template kendoGridFilterCellTemplate>
      <kendo-dropdownlist
        [defaultItem]="{ text: 'All', value: null }"
        textField="text"
        valueField="value"
        (valueChange)="statusChange($event, 'sla_violation_type')"
        [data]="slaViolations$ | async"
      ></kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
  field="customer_defined_status"
  title="Customer Defined Status"
  [width]="150"
  [filterable]="true"
>
  <ng-template kendoGridFilterCellTemplate>
    <kendo-dropdownlist
      [defaultItem]="{ text: 'All', value: null }"
      textField="text"
      valueField="value"
      (valueChange)="statusChange($event, 'customer_defined_status')"
      [data]="customerDefinedStatus$ | async"
    ></kendo-dropdownlist>
  </ng-template>
</kendo-grid-column>
  <kendo-grid-column
    field="ticket_closed_time"
    title="Closed On"
    [width]="280"
    [filterable]="true"
    filter="date"
  >
    <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column"
    >
      <button
        kendoButton
        #anchor
        (click)="popup.toggle();"
      >
        <kendo-label
          #closeTimeLabel
          [text]="defaultDateRangeSelectionText"
        ></kendo-label>
      </button>
      @if(isFilterApplied('ticket_closed_time')) {
      <button
        kendoButton
        [svgIcon]="filterClearSVG"
        (click)="clearFilters(popup, closeTimeLabel, 'ticket_closed_time');"
      ></button>
      }
      <kendo-daterange-popup
        #popup
        [anchor]="anchor"
      >
        <ng-template kendoDateRangePopupTemplate>
          <kendo-multiviewcalendar
            [value]="closeTimeRange"
            selection="range"
            (valueChange)="calendarValueChange($event, popup, closeTimeLabel, 'ticket_closed_time')"
          >
          </kendo-multiviewcalendar>
        </ng-template>
      </kendo-daterange-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="customer_response_time"
    title="Customer Response Time"
    [width]="200"
    [filterable]="true"
    [hidden]="true"
  ></kendo-grid-column>
  <kendo-grid-column
    field="description"
    title="Details"
    [width]="250"
    [filterable]="true"
    [hidden]="true"
  ></kendo-grid-column>
  <kendo-grid-excel
    fileName="tickets.xlsx"
    [fetchData]="allData"
  ></kendo-grid-excel>
</kendo-grid>
