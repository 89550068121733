<div class="content">
  <div class="example-wrap">
    <form
      class="k-form k-form-md"
      [formGroup]="userForm"
    >
      <div class="k-form-buttons">
        @if (user().id) {
          <h2 class="k-form-title">Edit User</h2>
        } @else {
          <h2 class="k-form-title">Add New User</h2>
        }
        <button
          kendoButton
          [svgIcon]="xIcon"
          (click)="cancel()"
          fillMode="flat"
        ></button>
      </div>
      <div class="headerButtons">
        @if (user().id) {
          <button
            kendoButton
            themeColor="secondary"
            (click)="resetPassword($event)"
          >
            Reset Password
          </button>
          <button
            kendoButton
            themeColor="secondary"
            (click)="resetMfa($event)"
          >
            Reset MFA
          </button>
        }
      </div>
      <fieldset class="k-form-fieldset">
        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="first_name"
            text="First Name (optional)"
          ></kendo-label>
          <kendo-textbox
            formControlName="first_name"
            #first_name
          ></kendo-textbox>

          <kendo-formhint>New users first name</kendo-formhint>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="last_name"
            text="Last Name (optional)"
          ></kendo-label>
          <kendo-textbox
            formControlName="last_name"
            #last_name
          ></kendo-textbox>

          <kendo-formhint>New users last name</kendo-formhint>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="email"
            text="Email*"
          ></kendo-label>
          <kendo-textbox
            formControlName="email"
            #email
            required
          ></kendo-textbox>

          <kendo-formhint>New users company email address</kendo-formhint>
          <kendo-formerror>Error: Not valid email format</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="role"
            text="Role*"
          ></kendo-label>
          <kendo-dropdownlist
            [defaultItem]="{ text: 'Select Role', value: null }"
            [data]="[
              { text: 'Cyflare Admin', value: 'admin' },
              { text: 'Customer', value: 'customer' }
            ]"
            textField="text"
            valueField="value"
            formControlName="role"
            [valuePrimitive]="true"
            #role
          ></kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label
            labelCssClass="k-form-label"
            [for]="virtualDropdown"
            text="Primary Organization*"
          >
          </kendo-label>
          <cyflare-virtual-dropdown
            #virtualDropdown
            [textField]="'name'"
            [valueField]="'id'"
            (valueChanged)="onOrgDropdownChange($event)"
            [refreshOnClick]="true"
            [filterable]="true"
            [styles]="{ width: '225px' }"
            [virtual]="{ itemHeight: 36 }"
            [dataService]="orgService"
            formControlName="primary_organization_id"
            [valuePrimitive]="true"
            [defaultItem]="defaultItem"
          >
          </cyflare-virtual-dropdown>
        </kendo-formfield>
        <div class="k-form-buttons">
          @if (user().id) {
            <button
              kendoButton
              themeColor="primary"
              (click)="update($event)"
              [disabled]="userForm.invalid"
            >
              Update User
            </button>
            <button
              kendoButton
              themeColor="error"
              (click)="open()"
            >
              Remove User
            </button>
            @if (opened === true) {
              <kendo-dialog
                title="Delete User"
                (close)="closeDialog('cancel')"
                [minWidth]="250"
                [width]="450"
              >
                <p style="margin: 30px; text-align: center">This action will permanently delete the user and all user data. Are you sure you want to continue?</p>
                <kendo-dialog-actions>
                  <button
                    kendoButton
                    (click)="closeDialog('no')"
                  >
                    Cancel
                  </button>
                  <button
                    kendoButton
                    (click)="delete($event)"
                    themeColor="primary"
                  >
                    Delete
                  </button>
                </kendo-dialog-actions>
              </kendo-dialog>
            }
          } @else {
            <button
              kendoButton
              themeColor="primary"
              (click)="save($event)"
              [disabled]="userForm.invalid"
            >
              Add User
            </button>
            <button
              kendoButton
              (click)="clear()"
            >
              Clear
            </button>
          }
        </div>
      </fieldset>
    </form>
  </div>
</div>
