import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { BaseComponent } from '../../../../../../../../../core/shared/common/base.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule, ExcelModule, GridDataResult } from '@progress/kendo-angular-grid';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { filter, Observable } from 'rxjs';
import { environment } from '../../../../../../../../../../environments/environment';
import { WidgetData } from '../../../../../../../../../core/services/analytics.service';
import { CoverageDetailsService } from './services/coverage.service';

@Component({
  selector: 'app-coverage',
  standalone: true,
  imports: [LoaderModule, DialogsModule, GridModule, ExcelModule, CommonModule],
  templateUrl: './coverage.component.html',
  styleUrl: './coverage.component.scss',
  providers: [CoverageDetailsService],
})
export class CoverageComponent extends BaseComponent {
  public gridService = inject(CoverageDetailsService);
  private route = inject(ActivatedRoute);
  public organizationId: number | null = null;
  public dateRange = input<string[] | null>(null);
  public coverage = input<WidgetData>({ value: 0, colors: [] });
  public widgetData = signal<WidgetData>({ value: 0, colors: [] });
  public isLoading = signal<boolean>(true);
  public items: any = { value: 0, colors: [] };
  public showPopup = false;
  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe({
          next: (params) => {
            this.organizationId = +params['organizationId'];
            const data = this.coverage();
            const filteredData = data ? { value: data.value, colors: data.colors } : { value: 0, colors: [] };
            this.widgetData.set(filteredData);
            this.isLoading.set(false);
          },
          error: (error) => {
            console.error('Error processing query parameters:', error);
          },
        });
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  onContextMenuSelect(event: any) {
    if (event?.text === 'View Data') {
      this.gridService.url = `${environment.apiUrl}/v1/analytics/administrative/edrcoveragedetails(${this.organizationId})`;
      this.gridService.read();
      this.showPopup = true;
    } else {
      console.warn('Invalid event structure:', event);
    }
  }

  public allData = (): Observable<GridDataResult> => {
    return this.gridService.queryAll();
  };

  // Close popup
  close() {
    this.showPopup = false;
  }
}
