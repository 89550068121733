import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State, toODataString } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DestroyRef, inject } from '@angular/core';

export class ODataService<T> extends BehaviorSubject<GridDataResult> {
  private destroyRef = inject(DestroyRef);
  public gridDataResult: GridDataResult | undefined;
  public mapper: ((data: any[]) => any[]) | undefined;
  public loading = false;
  public customFilter = "";
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public url: string;

  constructor(public http: HttpClient, url: string, mapper?: (data: any[]) => any[]) {
    super({ data: [], total: 0 });
    this.url = url;
    this.mapper = mapper;
  }

  public read(onComplete?: (x: GridDataResult) => void) {
    this.loading = true;
    return this.fetch(this.state)
      .pipe(
        tap((data) => {
          this.gridDataResult = data;
          this.loading = false;
        })
      )
      .subscribe((x) => {
        super.next(x);
        if (onComplete) {
          onComplete(x);
        }
      });
  }

  private fetch(state: State): Observable<GridDataResult> {
    let fullUrl = '';
    if (!this.url.includes('?'))
      fullUrl = `${this.url}?${this.toODataString(state)}`;
    else
      fullUrl = `${this.url}&${this.toODataString(state)}`;    

    return this.http.get<{ value: T[]; '@odata.count': number }>(fullUrl).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((data) => {
        const mappedData = this.mapper ? this.mapper(data.value) : data.value;
        return {
          data: mappedData,
          total: data['@odata.count'],
        } as GridDataResult;
      })
    );
  }

  public onStateChange(state: State): void {
    this.state = state; 
    this.read();
  }

  queryAll(): Observable<GridDataResult> {
    const newstate = Object.assign({}, this.state);
    delete newstate.skip;
    newstate.take = 1000; // setting cap to max to 1000

    return this.fetch(newstate);
  }

  private toODataString(state: State): string {    
    if(this.customFilter) {
      let odataString = toODataString(state);
      if(odataString.includes('filter=')) {
        let odataStringFilterQueryParameter = odataString.substring(odataString.indexOf('filter='), odataString.length);
        odataStringFilterQueryParameter = `${odataStringFilterQueryParameter} and ${this.customFilter}`;
        odataString = odataString.replace(odataString.substring(odataString.indexOf('filter='), odataString.length), odataStringFilterQueryParameter);  
      } else {
        odataString = `${odataString}&filter=${this.customFilter}`;
      }
      return odataString;
    }

    return toODataString(state);
  }
}