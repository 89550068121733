import { Component, effect, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { SocAdvisoryService } from '../../admin/advisories/services/advisory.service';
import { SocAdvisory } from '../../../core/models/entities';
import { filter, switchMap } from 'rxjs';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { User } from '../../../core/models/user';

@Component({
  selector: 'app-advisory',
  standalone: true,
  imports: [LoaderModule, ButtonsModule],
  templateUrl: './advisory.page.html',
  styleUrls: ['./advisory.page.scss'],
})
export class AdvisoryPage extends BaseComponent {
  private user = inject(User);
  advisory = signal<SocAdvisory | null>(null);
  private advisoryService = inject(SocAdvisoryService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private sanitizer = inject(DomSanitizer);
  public isLoading = signal<boolean>(true);
  public sanitizedContent = signal<SafeHtml | null>(null);

  constructor() {
    super();
    this.initEffect();
  }

  initEffect(): void {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => {
            return !!params['advisoryId'];
          }),
          switchMap((params) => {
            return this.advisoryService.getAdvisoryById(params['advisoryId']);
          }),
        )
        .subscribe({
          next: (advisory) => {
            this.advisory.set(advisory);
            this.sanitizedContent.set(this.sanitizer.bypassSecurityTrustHtml(advisory?.content || ''));
            this.isLoading.set(false);
          },
          error: (error) => {
            console.error('Error fetching advisory:', error);
            this.isLoading.set(false);
          },
        });
    });
  }

  goBack(): void {
    let currentQueryParams = { ...this.route.snapshot.queryParams };

    if ('advisoryId' in currentQueryParams) {
      delete currentQueryParams['advisoryId'];
    }
    if ('from_email' in currentQueryParams) {
      delete currentQueryParams['from_email'];
    }

    if ('organizationId' in currentQueryParams && 'account_type' in currentQueryParams) {
      this.router.navigate(['/customer/customer-dashboard'], { queryParams: currentQueryParams });
    } else {
      const fallbackQueryParams = { organizationId: this.user.primary_organization?.id, account_type: this.user.primary_organization?.account_type };
      this.router.navigate(['/customer/customer-dashboard'], { queryParams: fallbackQueryParams });
    }
  }
}
