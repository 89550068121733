import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VulnerabilityScanner } from '../../../../../../../../core/models/entities';

export interface VulnerabilityScannerForm {
  pk: FormControl<string | null>;
  ip_address: FormControl<string | null>;
  notes: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class VulnerabilityScannerFormService {
  public createVulnerabilityScannerForm(vulnerabilityScanner = new VulnerabilityScanner()) {
    return new FormGroup<VulnerabilityScannerForm>({
      pk: new FormControl(vulnerabilityScanner.pk, Validators.required),
      ip_address: new FormControl(vulnerabilityScanner.ip_address, Validators.required),
      notes: new FormControl(vulnerabilityScanner.notes, Validators.required),
    });
  }
}
