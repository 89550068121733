<div class="tile-title">
  <h4>CyFlare Advisories</h4>
</div>
<div
  class="scrollable-container"
  #scrollContainer
  (scroll)="onScroll()"
>
  <div class="advisory-container">
    @if (!isLoading()) {
      @for (advisory of filteredAdvisories(); track $index) {
        <app-trending-article
          class="advisory-article"
          [position]="$index + 1"
          [article]="advisory"
          (click)="onAdvisoryClick(advisory.pk!)"
        ></app-trending-article>
      }
    } @else {
      <p>Loading...</p>
    }
  </div>
  <div
    class="scroll-indicator"
    #scrollIndicator
  >
    <kendo-svg-icon
      [icon]="chevronDoubleDownIcon"
      [size]="'xlarge'"
    ></kendo-svg-icon>
  </div>
</div>
