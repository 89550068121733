import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { OrganizationService } from '../../../organizations/services/organization.service';
import { User } from '../../../../../core/models/user';
import { Organization } from '../../../../../core/models/entities';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  pageTitle$ = new BehaviorSubject<string>('Breadcrumbs');
  loading$ = new BehaviorSubject<boolean>(true);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  orgService = inject(OrganizationService);
  userService = inject(User);
  orgName = '';
  private routerSubscription: Subscription | undefined;

  ngOnInit() {
    this.initEffect();
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private initEffect() {
    this.routerSubscription = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => !event.urlAfterRedirects.includes('auth'))
    ).subscribe({
      next: () => {
        this.updatePageTitle();
      },
      error: (err) => {
        console.error('Error occurred while setting page title:', err);
      },
    });
    this.updatePageTitle();
  }

  private updatePageTitle() {
    this.loading$.next(true);
    const activeRoute = this.getActiveRoute(this.activatedRoute);
    const routeData = activeRoute.snapshot.data;
    let title = routeData['title'] || 'Breadcrumbs';

    // Get organizationId from query parameters
    const organizationIdStr = activeRoute.snapshot.queryParamMap.get('organizationId');
    const organizationId = organizationIdStr ? +organizationIdStr : null;

    if (organizationId !== null) {
      console.log('Fetching organization name...');
      const selectedOrg = this.userService.selected_organization;

      if (selectedOrg && selectedOrg.id === organizationId) {
        // Use cached organization name
        this.orgName = selectedOrg.name!;
        title = title.replace('${org.name}', this.orgName);
        this.pageTitle$.next(title);
        this.loading$.next(false);
      } else {
        // Fetch new organization name
        this.orgService
          .getOrganizationById(organizationId) // Convert to number
          .pipe(
            switchMap((org) => {
              console.log('Organization fetched:', org);
              this.orgName = org.name ?? 'Unknown';
              // Cache the organization
              this.userService.selected_organization = org as Organization;
              title = title.replace('${org.name}', this.orgName);
              return of(title);
            }),
          )
          .subscribe({
            next: (fullTitle) => {
              this.pageTitle$.next(fullTitle);
              this.loading$.next(false);
            },
            error: (err) => {
              console.error('Error occurred while fetching organization:', err);
              this.pageTitle$.next(title);
              this.loading$.next(false);
            },
          });
      }
    } else {
      this.pageTitle$.next(title);
      this.loading$.next(false);
    }
  }

  private getActiveRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
