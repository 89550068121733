import { CommonModule } from '@angular/common';
import { Component, effect, inject, input, output, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { VirtualDropdownComponent } from '../../../../../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { IntegrationUtil } from '../../services/integration-util.service';

@Component({
  selector: 'app-connectwise',
  standalone: true,
  imports: [ReactiveFormsModule, LabelModule, InputsModule, DateInputsModule, ButtonsModule, DropDownsModule, DialogsModule, CommonModule, VirtualDropdownComponent],
  templateUrl: './connectwise.component.html',
  styleUrl: './connectwise.component.scss',
})
export class ConnectwiseComponent extends BaseComponent {
  public integrationUtil = inject(IntegrationUtil);

  public saved = output<void>();
  public integrationData = input<any>();
  public organizationId = input<any>();

  public testConnectionMessage: string | undefined;

  integrationForm: FormGroup;
  formInitilized = signal<any>(false);

  constructor() {
    super();
    this.integrationForm = new FormGroup({
      organization_id: new FormControl(null, [Validators.required]),
      ticket_integration_name: new FormControl('ConnectWise', [Validators.required]),
      ticket_integration_user: new FormControl(''),
      ticket_integration_secret: new FormControl('', [Validators.required]),
      ticket_integration_auth_client_id: new FormControl(''),
      ticket_integration_auth_client_secret: new FormControl(''),
      ticket_integration_url: new FormControl(''),
      ticket_integration_auth_url: new FormControl(''),
      ticket_integration_auth_method: new FormControl('basic', [Validators.required]),
      field_map_subject: new FormControl('subject'),
      field_map_description: new FormControl('description'),
      field_map_status_type: new FormControl('new'),
      field_map_status_type_as_obj: new FormControl(true),
      field_map_priority: new FormControl('low'),
      field_map_priority_as_obj: new FormControl(true),
      pk: new FormControl(),
      fieldMappings: new FormArray([])
    });
    
    this.integrationUtil.integrationForm = this.integrationForm;
    this.initEffect();
  }  

  initEffect() {
    effect(() => {
      this.integrationForm.patchValue({
        organization_id: this.organizationId()
      });
      this.initForm(this.integrationData());
    },{ allowSignalWrites: true });
  }

  initForm(data?: any) {
    this.integrationUtil.addMappingFormControls('ticket', ['contact_id','ticket_title','subject','description', 'priority']);
    
    if (data) {
      this.integrationForm.patchValue({
        ticket_integration_name: data?.ticket_integration_name,
        ticket_integration_user: data?.ticket_integration_user,
        ticket_integration_secret: data?.ticket_integration_secret || '',
        ticket_integration_url: data?.ticket_integration_url,
        ticket_integration_auth_method: data?.ticket_integration_auth_method,
        ticket_integration_auth_client_id: data?.ticket_integration_auth_client_id,
        ticket_integration_auth_url: data?.ticket_integration_auth_url,
        field_map_subject: data?.field_map_subject,
        field_map_description: data?.field_map_description,
        field_map_status_type: data?.field_map_status_type,
        field_map_status_type_as_obj: data?.field_map_status_type_as_obj,
        field_map_priority: data?.field_map_priority,
        pk: data?.pk
      });
      this.integrationUtil.patchIntegrationFormControls(data)
    }    
    this.formInitilized.set(true);
  }

  testConnection() {
    this.integrationUtil.testConnection((result: any) => this.afterTestConnection(result));
  }

  saveConnection() {
    this.integrationUtil.saveConnection(() => this.afterSaveConnection());
  }

  afterSaveConnection() {
    this.saved.emit();
  }

  afterTestConnection(result: string) {
    this.testConnectionMessage = result;
  }
}
