<div class="tile">
  <div class="tile-title">
    <h5>Entity Enrichment</h5>
  </div>
  <div class="k-card-list">
    <kendo-card
      class="k-card-item"
      *ngFor="let item of dataItems"
    >
      <kendo-card-header>
        <h5>{{ item.enrichment_source }}</h5>
      </kendo-card-header>
      <kendo-card-body>
        <ul>
          <li *ngFor="let result of item.result">
            <strong>Entity:</strong> {{ result.Entity }}<br />

            <!-- Use the type guard to check if entity_type exists -->
            @if (hasEntityType(result)) {
              <strong>Type:</strong> {{ result.entity_type }}<br />
            }

            <!-- Use the type guard to check if virustotal_score exists -->
            @if (hasVirusTotalScore(result)) {
              <strong>VirusTotal Score:</strong> {{ result.virustotal_score }}<br />
            }

            <!-- Use the type guard to check if abuse_reports and abuse_confidence_score exist -->
            @if (hasAbuseIpDbProperties(result)) {
              <strong>Abuse Reports:</strong> {{ result.abuse_reports }}<br />
              <strong>Abuse Confidence Score:</strong> {{ result.abuse_confidence_score }}<br />
            }

            <!-- Use the type guard to check if threat_score exists -->
            @if (hasThreatScore(result)) {
              <strong>Threat Score:</strong> {{ result.threat_score }}<br />
            }

            <!-- Use the type guard to check if Matched Category exists -->
            @if (hasMatchedCategory(result)) {
              <strong>Matched Category:</strong> {{ result['Matched Category'] }}<br />
            }
          </li>
        </ul>
      </kendo-card-body>
    </kendo-card>
  </div>
</div>
