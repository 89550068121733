import { Component, inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ErrorResponse, AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../core/shared/common/base.component';
import { Router } from '@angular/router';
import { SVGIcon, checkCircleIcon, xOutlineIcon } from '@progress/kendo-svg-icons';
import { SVGIconModule } from '@progress/kendo-angular-icons';

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule, SVGIconModule],
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss',
})
export class UpdatePasswordComponent extends BaseComponent {
  private router = inject(Router);
  private location = inject(Location);
  private authService = inject(AuthService);
  public checkCircleIcon: SVGIcon = checkCircleIcon;
  public xOutlineIcon: SVGIcon = xOutlineIcon;

  public criteria = {
    minLength: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
  };

  public passwordForm = new FormGroup(
    {
      password: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10), Validators.pattern('^(?=.*[a-z])' + '(?=.*[A-Z])' + '(?=.*[0-9])' + "(?=.*[!@#$%^&*()_+\\-=[\\]{};:',.<>?/])" + '(?!.*[\\\\"])' + "[a-zA-Z0-9!@#$%^&*()_+\\-=[\\]{};:',.<>?/]+$")]),
      confirmPassword: new FormControl<string | null>(null, [Validators.required, Validators.minLength(10), Validators.pattern('^(?=.*[a-z])' + '(?=.*[A-Z])' + '(?=.*[0-9])' + "(?=.*[!@#$%^&*()_+\\-=[\\]{};:',.<>?/])" + '(?!.*[\\\\"])' + "[a-zA-Z0-9!@#$%^&*()_+\\-=[\\]{};:',.<>?/]+$")]),
    },
    { validators: this.passwordsMatch },
  );

  constructor() {
    super();
  }

  passwordsMatch(control: AbstractControl): { [key: string]: boolean } | null {
    const formGroup = control as FormGroup;
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password && confirmPassword) {
      if (password.value !== confirmPassword.value) {
        return { mismatch: true };
      } else {
        return null;
      }
    }
    return null;
  }

  validatePassword(password: string) {
    this.criteria.minLength = password.length >= 10;
    this.criteria.uppercase = /[A-Z]/.test(password);
    this.criteria.lowercase = /[a-z]/.test(password);
    this.criteria.specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password) && !/\s/.test(password);
  }

  public resetPassword($event: Event) {
    $event.preventDefault();
    if (this.passwordForm.valid) {
      const user = this.authService.user;
      this.authService
        .resetPassword(user.userData.email, this.passwordForm.value.password!)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response: any) => {
            const data = window.atob(response);
            const responseData = JSON.parse(data);
            user.updateUser(responseData);
            if (responseData.mfa_setup_required === true) {
              this.router.navigate(['auth/setup-mfa']);
            } else {
              this.router.navigate(['auth/enter-mfa']);
            }
          },
          error: (error: ErrorResponse) => {
            console.error(error);
            this.router.navigate(['auth/login']);
          },
        });
    }
  }

  public back() {
    this.location.back();
  }
}
