import { Component, CreateEffectOptions, input, effect, signal } from '@angular/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { FunnelData } from '../../../core/services/analytics.service';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-distillation-funnel',
  standalone: true,
  imports: [ChartsModule, LoaderModule],
  templateUrl: './distillation-funnel.component.html',
  styleUrl: './distillation-funnel.component.scss',
})
export class DistillationFunnelComponent extends BaseComponent {
  funnelDataGet = input<FunnelData[]>([]);
  public funnelData = signal<FunnelData[]>([]);

  public isLoading = signal(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.funnelDataGet();
        if (data && data.length > 0) {
          this.funnelData.set(data);
          this.isLoading.set(false);
        } else {
          this.funnelData.set([]);
          this.isLoading.set(false);
        }
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }

  labelContent(args: any): string {
    return `${args.dataItem.stat}: ${args.dataItem.count}`;
  }
}
