import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../../../../../../../../core/models/entities';
import { environment } from '../../../../../../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IrcContactService {
  private url = `${environment.apiUrl}/v1/organization/administrative/irccontact`;
  private http = inject(HttpClient);
  constructor() {}

  public createContact(ircContactRequest: IrcContactRequest) {
    const url = `${this.url}/`;
    return this.http.post<IrcContactResponse>(url, ircContactRequest);
  }

  public getContacts(orgId: number) {
    const url = `${this.url}(${orgId})/`;
    return this.http.get<IrcContactResponse>(url);
  }

  public updateContact(ircContactRequest: IrcContactRequest) {
    const url = `${this.url}(${ircContactRequest.organization_id})/`;
    return this.http.put<IrcContactResponse>(url, ircContactRequest);
  }

  public deleteContact(orgId: number, pk: number) {
    const url = `${this.url}(${orgId})/`;
    const params = { pk };
    return this.http.delete<IrcContactResponse>(url, { params });
  }
}

export interface IrcContactResponse {
  ['odata.count']: number;
  value: Contact[];
}

export interface IrcContactRequest extends Contact {}

export interface IrcContactResponse extends IrcContactRequest {}
