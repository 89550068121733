@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  @if (chartData().length > 0) {
    <div>
      <input
        type="checkbox"
        size="large"
        rounded="large"
        kendoCheckBox
        checked
        #caseFilterCheckbox
        [(ngModel)]="checked"
        (ngModelChange)="caseFilter()"
      />
      <kendo-label
        [for]="caseFilterCheckbox"
        text="Show Only Escalated Cases"
      ></kendo-label>
    </div>

    <kendo-chart
      class="chart-container"
      (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
    >
      <kendo-chart-tooltip> </kendo-chart-tooltip>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: 'Minutes' }"> </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>

      <kendo-chart-legend
        position="bottom"
        orientation="horizontal"
      >
      </kendo-chart-legend>

      <kendo-chart-series>
        <kendo-chart-series-item
          [data]="chartData()"
          field="count"
          categoryField="description"
        >
          <kendo-chart-series-item-tooltip format="Average Resolution time: {0} minutes"> </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>

    <kendo-dialog
      *ngIf="showPopup"
      [width]="'calc(100% - 60px)'"
      (close)="close()"
    >
      <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
      <div class="dialog-content">
        <kendo-grid
          class="mxh-80v"
          [filterable]="true"
          [data]="gridService | async"
          [pageable]="{
            buttonCount: 5,
            info: true,
            type: 'numeric',
            pageSizes: [25, 50, 100, 200]
          }"
          [pageSize]="gridService.state.take"
          [skip]="gridService.state.skip"
          [(filter)]="gridService.state.filter"
          [loading]="gridService.loading"
          (dataStateChange)="gridService.onStateChange($event)"
          [sort]="this.gridService.state.sort"
          [sortable]="true"
          [resizable]="true"
        >
          <ng-template kendoGridToolbarTemplate>
            <button
              type="button"
              kendoGridExcelCommand
            >
              Export to Excel
            </button>
          </ng-template>
          <kendo-grid-column
            [filterable]="false"
            field="alert_received_on"
            title="Created On"
          ></kendo-grid-column>
          <kendo-grid-column
            [filterable]="false"
            field="case_closed_on"
            title="Closed On"
          ></kendo-grid-column>
          <kendo-grid-column
            field="case_id"
            title="SOAR ID"
          ></kendo-grid-column>
          <kendo-grid-column
            field="zoho_ticket_number"
            title="Escalated Case #"
          ></kendo-grid-column>
          <kendo-grid-column
            field="event_name"
            title="Event Name"
          ></kendo-grid-column>
          <kendo-grid-column
            field="case_severity"
            title="Priority"
          ></kendo-grid-column>
          <kendo-grid-excel
            fileName="mttd-data.xlsx"
            [fetchData]="allData"
          ></kendo-grid-excel>
        </kendo-grid>
      </div>
    </kendo-dialog>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
  } @else {
    <div class="no-data-message">
      <h2>No Data Available</h2>
      <p>There is currently no MTTD data to display.</p>
    </div>
  }
}
