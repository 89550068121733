import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SocBulletin } from '../../../../../../../../core/models/entities';

export interface SocBulletinForm {
  pk: FormControl<string | null>;
  description: FormControl<string | null>;
  xdr_alert_type: FormControl<string | null>;
  required_action: FormControl<string | null>;
  timebounds: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class SocBulletinFormService {
  public createSocBulletinForm(socBulletin = new SocBulletin()) {
    return new FormGroup<SocBulletinForm>({
      pk: new FormControl(socBulletin.pk, Validators.required),
      description: new FormControl(socBulletin.description, Validators.required),
      xdr_alert_type: new FormControl(socBulletin.xdr_alert_type, Validators.required),
      required_action: new FormControl(socBulletin.required_action, Validators.required),
      timebounds: new FormControl(socBulletin.timebounds, Validators.required),
    });
  }
}
