<kendo-appbar position="top">
  <div class="appbar-content">
    <kendo-appbar-section>
      <h5 (click)="scrollToCard('summary-card')">Summary</h5>
    </kendo-appbar-section>
    <kendo-appbar-section>
      <h5 (click)="scrollToCard('details-card')">Event Log</h5>
    </kendo-appbar-section>
    <kendo-appbar-section>
      <h5 (click)="scrollToCard('chronology-card')">Chronology</h5>
    </kendo-appbar-section>
    <kendo-appbar-section>
      <h5 (click)="scrollToCard('remediation-card')">Remediation</h5>
    </kendo-appbar-section>
  </div>
</kendo-appbar>
<div class="content">
  <div class="example-wrap">
    <kendo-stacklayout
      [gap]="15"
      [orientation]="'vertical'"
    >
      <kendo-card
        class="scrollable-card-body"
        id="summary-card"
        width="auto"
      >
        <kendo-card-header class="k-hstack">
          <div class="hstack">
            <h1 kendoCardTitle>Case Summary</h1>
            <p kendoCardSubtitle>Enhanced by AI</p>
            <div class="shimmer-container">
              <img
                src="../../../../../../assets/icons/spark.png"
                class="shimmer"
                alt="Sparkle"
              />
            </div>
          </div>
        </kendo-card-header>
        <kendo-card-body
          class="scrollable-card-body"
          (scroll)="checkScroll($event, 'summary-card')"
        >
          <div
            class="scroll-indicator"
            id="summary-card-scroll-indicator"
          >
            ...
          </div>
          <p class="photo-desc">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>Morbi cursus augue nec purus vehicula eleifend.</li>
            <li>Quisque vestibulum massa non commodo efficitur.</li>
            <li>Vivamus placerat urna ac egestas porta.</li>
            <li>Nam id libero non nulla ultricies laoreet et non tortor.</li>
            <li>Nunc gravida eros quis sem cursus pharetra.</li>
            <li>Quisque venenatis ante commodo dictum vehicula.</li>
            <li>Vivamus mollis mauris at lectus semper accumsan.</li>
          </ul>
        </kendo-card-body>
      </kendo-card>
      <kendo-card
        id="details-card"
        width="auto"
      >
        <kendo-card-header class="k-hstack">
          <div>
            <h1 kendoCardTitle>Case Event Log</h1>
          </div>
        </kendo-card-header>
        <kendo-card-body
          class="scrollable-card-body"
          (scroll)="checkScroll($event, 'details-card')"
        >
          <div
            class="scroll-indicator"
            id="details-card-scroll-indicator"
          >
            ...
          </div>
          @for (detail of caseDetails; track $index) {
            <p>
              <strong>Raw Event Link:</strong>
              <a
                href="https://cyflare.stellarcyber.cloud/alerts/alert/aella-ser-1719792435054-/amsg/1720450268828374a66c79475dd844f0bb609896f28d8d5f"
                target="_blank"
                >XDR Alert Link</a
              >
            </p>
            <p><strong>Case Name:</strong> {{ detail.case_name }}</p>
            <p><strong>Case Source:</strong> {{ detail.case_source }}</p>
            <p><strong>Alert Generated Time:</strong> {{ detail.alert_generated_time }}</p>
            <p><strong>Case Created Time:</strong> {{ detail.case_created_time }}</p>
            <p><strong>Case Assigned Time:</strong> {{ detail.case_assigned_time }}</p>
            <p><strong>Ticket Creation Time:</strong> {{ detail.ticket_creation_time }}</p>
            <p><strong>Ticket Number:</strong> {{ detail.ticket_number }}</p>
            <p><strong>Ticket Closed:</strong> NA - Status is Open</p>
          }
        </kendo-card-body>
      </kendo-card>
      <kendo-card
        id="chronology-card"
        width="auto"
      >
        <kendo-card-header class="k-hstack">
          <div>
            <h1 kendoCardTitle>Analyst Chronology</h1>
          </div>
        </kendo-card-header>
        <kendo-card-body
          class="scrollable-card-body"
          (scroll)="checkScroll($event, 'chronology-card')"
        >
          <div
            class="scroll-indicator"
            id="chronology-card-scroll-indicator"
          >
            ...
          </div>

          <h4>Stellar Searches:</h4>
          @for (search of threatHunting.stellar_searches; track $index) {
            <p><strong>Stellar Search Query:</strong> {{ search.query }}</p>
            <kendo-chip
              themeColor="info"
              class="indented-result"
              ><strong>Result:</strong> {{ search.result }}</kendo-chip
            >
          }

          <hr />
          <!-- Line separator -->

          <h4>SentinelOne Searches:</h4>
          @for (search of threatHunting.sentinelOne_searches; track $index) {
            <p><strong>SentinelOne Search Query:</strong> {{ search.query }}</p>
            <kendo-chip
              themeColor="info"
              class="indented-result"
              ><strong>Result:</strong> {{ search.result }}</kendo-chip
            >
          }

          <hr />
          <!-- Line separator -->

          <h4>Vicarius Searches:</h4>
          @for (entity of threatHunting.vicarius; track $index) {
            <div class="indented-result">
              <p><strong>Entity:</strong> {{ entity.Entity }}</p>
              <p><strong>Entity Type:</strong> {{ entity.entity_type }}</p>
              <p><strong>Hostname:</strong> {{ entity.vrx_host_info.hostname }}</p>
              <p><strong>Active CVEs:</strong> {{ entity.vrx_host_info.active_CVEs }}</p>
              <p><strong>Risk Score:</strong> {{ entity.vrx_host_info.risk_score }}</p>
              <p><strong>OS:</strong> {{ entity.vrx_host_info.OS }}</p>
              <p><strong>Patches Available:</strong> {{ entity.vrx_host_info.patches_available }}</p>
            </div>
          }
        </kendo-card-body>
      </kendo-card>
      <kendo-card
        id="remediation-card"
        width="auto"
        class="remediation-card"
      >
        <kendo-card-header>
          <div>
            <h1 kendoCardTitle>Rx Remediation</h1>
          </div>
        </kendo-card-header>
        <kendo-card-body class="scrollable-card-body-short">
          <div style="display: flex; flex-direction: column; flex-wrap: wrap; gap: 12px">
            <button
              kendoButton
              style="padding: 10px"
              (click)="open()"
            >
              Disable User Access for Mfarry (Revoke Session)
            </button>
            <button
              kendoButton
              style="padding: 10px"
              (click)="open()"
            >
              Isolate mfarry-macbook
            </button>
          </div>
          @if (opened === true) {
            <kendo-dialog
              title="Disable User Access"
              (close)="closeDialog('cancel')"
              [minWidth]="250"
              [width]="450"
            >
              <p style="margin: 30px; text-align: center">This action will disable mfarry's user access and instantly end all user sessions. Are you sure you want to continue?</p>
              <kendo-dialog-actions>
                <button
                  kendoButton
                  (click)="closeDialog('no')"
                >
                  Cancel
                </button>
                <button
                  kendoButton
                  themeColor="primary"
                  (click)="closeDialog('no')"
                >
                  Continue
                </button>
              </kendo-dialog-actions>
            </kendo-dialog>
          }
        </kendo-card-body>
      </kendo-card>
    </kendo-stacklayout>
  </div>
</div>
