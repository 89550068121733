<div style="padding: 20px">
  <kendo-card class="landing-page-container w-100">
    <kendo-card-header>
      <div class="logo-container">
        <img
          src="/assets/logos/cyflare_one_logo.png"
          alt="logo"
        />
      </div>
    </kendo-card-header>
    <kendo-card-body>
      <div class="title-container">
        <h2>Upgrade Release Notice</h2>
      </div>
      <div>
        <p>We are excited to announce the release of our new CyFlare ONE product</p>
        <p>
          As part of this transition, new user account invites have been emailed to all existing customers<br />
          <strong>*Your previous account login will not work for the new platform*</strong>.
        </p>
        <p>If you already have an account for the new platform and hit this page by mistake, you can login using the button below.</p>
      </div>
      <div class="button-container">
        <a href="https://prod.cyflare.one">
          <button
            kendoButton
            [primary]="true"
          >
            Go To Login
          </button>
        </a>
      </div>
      <p>
        If you have not received your new account invite or are having trouble signing in, please contact the Cyflare
        <a href="mailto:customersuccess@cyflare.com">Customer Success Team</a>.
      </p>
    </kendo-card-body>
  </kendo-card>
</div>
