@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (chartData != null) {
  <kendo-chart>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="pie"
        [data]="chartData()"
        field="value"
        categoryField="category"
        [autoFit]="autofit"
        [labels]="{
          visible: true,
          content: labelContent
        }"
      >
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>
  <kendo-dialog
    *ngIf="showPopup"
    (close)="close()"
  >
    <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
    <kendo-grid
      class="mxh-80v"
      [filterable]="true"
      [data]="gridService | async"
      [pageable]="{
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [25, 50, 100, 200]
      }"
      [pageSize]="gridService.state.take"
      [skip]="gridService.state.skip"
      [(filter)]="gridService.state.filter"
      [loading]="gridService.loading"
      (dataStateChange)="gridService.onStateChange($event)"
      [sort]="this.gridService.state.sort"
      [sortable]="true"
    >
      <ng-template kendoGridToolbarTemplate>
        <button
          type="button"
          kendoGridExcelCommand
        >
          Export to Excel
        </button>
      </ng-template>
      <kendo-grid-column
        [filterable]="false"
        field="alert_received_on"
        title="Received On"
      ></kendo-grid-column>
      <kendo-grid-column
        field="case_id"
        title="Case #"
      ></kendo-grid-column>
      <kendo-grid-column
        field="event_name"
        title="Name"
      ></kendo-grid-column>
      <kendo-grid-column
        field="case_severity"
        title="Case Severity"
      ></kendo-grid-column>
      <kendo-grid-column
        field="closure_reason"
        title="Closure Reason"
      ></kendo-grid-column>
      <kendo-grid-column
        field="triage_type"
        title="Triage Type"
      ></kendo-grid-column>
      <kendo-grid-excel
        fileName="adr-data.xlsx"
        [fetchData]="allData"
      ></kendo-grid-excel>
    </kendo-grid>
  </kendo-dialog>
} @else {
  <h1>No Data</h1>
}
