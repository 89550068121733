/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Injectable, SimpleChange, Directive, TemplateRef, Component, ChangeDetectionStrategy, ContentChild, SecurityContext, Input, forwardRef, ElementRef, EventEmitter, ViewChild, Output, ContentChildren, Optional, NgModule } from '@angular/core';
import * as i3 from '@angular/platform-browser';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { isDocumentAvailable, hasObservers, ResizeSensorComponent, ResizeBatchService } from '@progress/kendo-angular-common';
import * as i3$1 from '@progress/kendo-angular-l10n';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import * as i4 from '@progress/kendo-angular-icons';
import { IconsService } from '@progress/kendo-angular-icons';
import { mapMarkerTargetIcon, mapMarkerIcon, plusIcon, minusIcon, caretAltUpIcon, caretAltDownIcon, caretAltLeftIcon, caretAltRightIcon } from '@progress/kendo-svg-icons';
import { InstanceObserver, Map } from '@progress/kendo-charts';
const _c0 = ["content"];
function TooltipPopupComponent_ng_template_0_ng_template_2_Template(rf, ctx) {}
function TooltipPopupComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, TooltipPopupComponent_ng_template_0_ng_template_2_Template, 0, 0, "ng-template", 3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r0.popupClasses)("ngStyle", ctx_r0.style);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.tooltipTemplateRef)("ngTemplateOutletContext", ctx_r0.tooltipContext);
  }
}
export { Extent, Location } from '@progress/kendo-charts';
import { validatePackage } from '@progress/kendo-licensing';
import { tap } from 'rxjs/operators';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import * as i1 from '@progress/kendo-angular-popup';
import { PopupService, POPUP_CONTAINER } from '@progress/kendo-angular-popup';

/**
 * @hidden
 */
class ItemChange {
  constructor(sender, options) {
    this.sender = sender;
    this.options = options;
  }
}
/**
 * @hidden
 */
class CollectionService {
  constructor() {
    this.source = new Subject();
    this.itemChanges = this.source.asObservable();
  }
  notify(change) {
    this.source.next(change);
  }
}
CollectionService.ɵfac = function CollectionService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CollectionService)();
};
CollectionService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CollectionService,
  factory: CollectionService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * @hidden
 */
class Change {
  constructor(key, value) {
    this.key = key;
    this.value = value;
  }
}
/**
 * @hidden
 */
class ConfigurationService {
  constructor(ngZone) {
    this.ngZone = ngZone;
    this.store = {};
    this.source = new BehaviorSubject({});
    this.initSource();
  }
  initSource() {
    this.changes = this.source.asObservable();
  }
  push(store) {
    this.store = store;
    this.next();
  }
  notify(change) {
    this.set(change.key, change.value);
    this.next();
  }
  set(field, value) {
    let store = this.store;
    const parts = field.split('.');
    let key = parts.shift();
    while (parts.length > 0) {
      store = store[key] = store[key] || {};
      key = parts.shift();
    }
    store[key] = value;
  }
  next() {
    this.ngZone.runOutsideAngular(() => {
      this.source.next(this.store);
    });
  }
}
ConfigurationService.ɵfac = function ConfigurationService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConfigurationService)(i0.ɵɵinject(i0.NgZone));
};
ConfigurationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConfigurationService,
  factory: ConfigurationService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigurationService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();

/**
 * @hidden
 */
function copyChanges(changes, options) {
  for (const propertyName in changes) {
    if (!changes.hasOwnProperty(propertyName)) {
      continue;
    }
    const value = changes[propertyName].currentValue;
    if (value === undefined) {
      delete options[propertyName];
    } else {
      options[propertyName] = value;
    }
  }
}

/**
 * @hidden
 */
function toSimpleChanges(changes) {
  const result = {};
  for (const propertyName in changes) {
    if (!changes.hasOwnProperty(propertyName)) {
      continue;
    }
    result[propertyName] = new SimpleChange(null, changes[propertyName], false);
  }
  return result;
}

/**
 * @hidden
 */
class CollectionItemComponent {
  constructor(configurationService, collectionService) {
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.index = -1;
    this.options = {};
    this.subscription = configurationService.changes.subscribe(store => {
      this.options = store;
      this.notify();
    });
  }
  ngOnChanges(changes) {
    const store = this.configurationService.store;
    copyChanges(changes, store);
    this.configurationService.push(store);
  }
  /**
   * Updates the component fields with the specified values and refreshes the Chart.
   *
   * Use this method when the configuration values cannot be set through the template.
   *
   * @example
   * ```ts-no-run
   * item.notifyChanges({ visible: true });
   * ```
   *
   * @param changes An object containing the updated input fields.
   */
  notifyChanges(changes) {
    this.ngOnChanges(toSimpleChanges(changes));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  notify() {
    if (!this.collectionService) {
      return;
    }
    this.collectionService.notify(new ItemChange(this, this.options));
  }
}
CollectionItemComponent.ɵfac = function CollectionItemComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CollectionItemComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService));
};
CollectionItemComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CollectionItemComponent,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionItemComponent, [{
    type: Directive
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }];
  }, null);
})();

/**
 * @hidden
 */
class SettingsComponent {
  constructor(configKey, configurationService) {
    this.configKey = configKey;
    this.configurationService = configurationService;
    this.store = {};
    if (configKey === undefined) {
      throw new Error('Configuration key not set');
    }
  }
  ngOnDestroy() {
    this.store = undefined;
    this.notify();
  }
  ngOnChanges(changes) {
    copyChanges(changes, this.store);
    this.notify();
  }
  /**
   * Updates the component fields with the specified values and refreshes the component.
   *
   * Use this method when the configuration values cannot be set through the template.
   *
   * @example
   * ```ts-no-run
   * item.notifyChanges({ visible: true });
   * ```
   *
   * @param changes An object containing the updated input fields.
   */
  notifyChanges(changes) {
    this.ngOnChanges(toSimpleChanges(changes));
  }
  markAsVisible() {
    this.store.visible = true;
    this.notify();
  }
  notify() {
    this.configurationService.notify(new Change(this.configKey, this.store));
  }
}
SettingsComponent.ɵfac = function SettingsComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
SettingsComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SettingsComponent,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingsComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }];
  }, null);
})();

/**
 * The configuration options of the Map layer tooltip.
 *
 * @hidden
 */
class LayerTooltipComponent extends SettingsComponent {
  constructor(configurationService) {
    super('tooltip', configurationService);
    this.configurationService = configurationService;
  }
  get layerTooltipTemplateRef() {
    return this.layerTooltipTemplate;
  }
}
LayerTooltipComponent.ɵfac = function LayerTooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LayerTooltipComponent)(i0.ɵɵdirectiveInject(ConfigurationService));
};
LayerTooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LayerTooltipComponent,
  selectors: [["kendo-map-layer-tooltip"]],
  contentQueries: function LayerTooltipComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TemplateRef, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.layerTooltipTemplate = _t.first);
    }
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LayerTooltipComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerTooltipComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-map-layer-tooltip',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }];
  }, {
    layerTooltipTemplate: [{
      type: ContentChild,
      args: [TemplateRef, {
        static: false
      }]
    }]
  });
})();

/**
 * @hidden
 */
class LayerComponent extends CollectionItemComponent {
  constructor(type, configurationService, collectionService, sanitizer) {
    super(configurationService, collectionService);
    this.type = type;
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.sanitizer = sanitizer;
  }
  ngOnChanges(changes) {
    const store = this.configurationService.store;
    store.type = this.type;
    const attrChange = changes['attribution'];
    if (attrChange && this.sanitizer) {
      attrChange.currentValue = this.sanitizer.sanitize(SecurityContext.HTML, attrChange.currentValue);
    }
    super.ngOnChanges(changes);
  }
  get layerTooltipTemplateRef() {
    if (this.layerTooltip) {
      return this.layerTooltip.layerTooltipTemplateRef;
    }
  }
}
LayerComponent.ɵfac = function LayerComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
LayerComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LayerComponent,
  contentQueries: function LayerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LayerTooltipComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.layerTooltip = _t.first);
    }
  },
  inputs: {
    attribution: "attribution",
    extent: "extent",
    maxZoom: "maxZoom",
    minZoom: "minZoom",
    opacity: "opacity",
    zIndex: "zIndex"
  },
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }, {
      type: CollectionService
    }, {
      type: i3.DomSanitizer
    }];
  }, {
    attribution: [{
      type: Input
    }],
    extent: [{
      type: Input
    }],
    maxZoom: [{
      type: Input
    }],
    minZoom: [{
      type: Input
    }],
    opacity: [{
      type: Input
    }],
    zIndex: [{
      type: Input
    }],
    layerTooltip: [{
      type: ContentChild,
      args: [LayerTooltipComponent, {
        static: false
      }]
    }]
  });
})();

/**
 * A vector shape layer for bubble maps. [See example](slug:bubble_layers_map).
 */
class BubbleLayerComponent extends LayerComponent {
  constructor(configurationService, collectionService, sanitizer) {
    super('bubble', configurationService, collectionService, sanitizer);
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.sanitizer = sanitizer;
  }
}
BubbleLayerComponent.ɵfac = function BubbleLayerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BubbleLayerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService), i0.ɵɵdirectiveInject(i3.DomSanitizer));
};
BubbleLayerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BubbleLayerComponent,
  selectors: [["kendo-map-bubble-layer"]],
  inputs: {
    data: "data",
    locationField: "locationField",
    valueField: "valueField",
    symbol: "symbol",
    style: "style",
    maxSize: "maxSize",
    minSize: "minSize"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, {
    provide: LayerComponent,
    useExisting: forwardRef(() => BubbleLayerComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function BubbleLayerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BubbleLayerComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ConfigurationService, {
        provide: LayerComponent,
        useExisting: forwardRef(() => BubbleLayerComponent)
      }],
      selector: 'kendo-map-bubble-layer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }, {
      type: i3.DomSanitizer
    }];
  }, {
    data: [{
      type: Input
    }],
    locationField: [{
      type: Input
    }],
    valueField: [{
      type: Input
    }],
    symbol: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    maxSize: [{
      type: Input
    }],
    minSize: [{
      type: Input
    }]
  });
})();

/**
 * @hidden
 */
function hasParent(element, parent) {
  let current = element;
  while (current && current !== parent) {
    current = current.parentNode;
  }
  return current ? true : false;
}

/**
 * @hidden
 */
class MapInstanceObserver extends InstanceObserver {
  constructor(instance) {
    super(instance);
    this.handlerMap = {
      hideTooltip: 'onHideTooltip',
      init: 'onInit',
      render: 'onRender',
      showTooltip: 'onShowTooltip',
      centerChange: 'onCenterChange',
      zoomChange: 'onZoomChange'
    };
  }
}

/**
 * @hidden
 */
class BaseEvent {
  /**
   * @hidden
   */
  constructor(sender) {
    this.sender = sender;
  }
}
BaseEvent.ɵfac = function BaseEvent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
BaseEvent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseEvent
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseEvent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }];
  }, null);
})();

/**
 * Fired immediately before the map is reset. This event is typically used for cleanup by layer implementers.
 */
class BeforeResetEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(_, sender) {
    super(sender);
  }
}

/**
 * Fired when the user clicks on the map.
 */
class MapClickEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.location = e.location;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when a marker has been displayed and has a DOM element assigned.
 */
class MarkerActivateEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.marker = e.marker;
    this.layer = e.layer;
  }
}

/**
 * Fired when a marker has been clicked or tapped.
 */
class MarkerClickEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.marker = e.marker;
    this.layer = e.layer;
  }
}

/**
 * @hidden
 */
class PreventableEvent extends BaseEvent {
  constructor() {
    super(...arguments);
    this.prevented = false;
  }
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses
   * the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * Returns `true` if the event was prevented
   * by any of its subscribers.
   *
   * @returns `true` if the default action was prevented.
   * Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}
PreventableEvent.ɵfac = /* @__PURE__ */(() => {
  let ɵPreventableEvent_BaseFactory;
  return function PreventableEvent_Factory(__ngFactoryType__) {
    return (ɵPreventableEvent_BaseFactory || (ɵPreventableEvent_BaseFactory = i0.ɵɵgetInheritedFactory(PreventableEvent)))(__ngFactoryType__ || PreventableEvent);
  };
})();
PreventableEvent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PreventableEvent,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PreventableEvent, [{
    type: Directive
  }], null, null);
})();

/**
 * Fired when a marker has been created and is about to be displayed.
 *
 * Cancelling the event will prevent the marker from being shown.
 */
class MarkerCreatedEvent extends PreventableEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.marker = e.marker;
    this.layer = e.layer;
  }
}

/**
 * Fires after the map viewport has been moved.
 */
class PanEndEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.origin = e.origin;
    this.center = e.center;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired while the map viewport is being moved.
 */
class PanEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.origin = e.origin;
    this.center = e.center;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when the map is reset.
 *
 * This typically occurs on initial load and after a zoom/center change.
 */
class ResetEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(_, sender) {
    super(sender);
  }
}

/**
 * Fired when a shape is clicked or tapped.
 */
class ShapeClickEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.layer = e.layer;
    this.shape = e.shape;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when a shape is created, but is not rendered yet.
 */
class ShapeCreatedEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.layer = e.layer;
    this.shape = e.shape;
    this.dataItem = e.shape.dataItem;
    this.location = e.shape.location;
  }
}

/**
 * Fired when a [GeoJSON Feature](https://geojson.org/geojson-spec.html#feature-objects) is created on a shape layer.
 */
class ShapeFeatureCreatedEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.dataItem = e.dataItem;
    this.layer = e.layer;
    this.group = e.group;
    this.properties = e.properties;
  }
}

/**
 * Fired when the mouse enters a shape.
 *
 * > This event will fire reliably only for shapes that have set fill color.
 * > The opacity can still be set to 0 so the shapes appear to have no fill.
 */
class ShapeMouseEnterEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.layer = e.layer;
    this.shape = e.shape;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when the mouse leaves a shape.
 *
 * > This event will fire reliably only for shapes that have set fill color.
 * > The opacity can still be set to 0 so the shapes appear to have no fill.
 */
class ShapeMouseLeaveEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.layer = e.layer;
    this.shape = e.shape;
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when the map zoom level has changed.
 */
class ZoomEndEvent extends BaseEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.originalEvent = e.originalEvent;
  }
}

/**
 * Fired when the map zoom level is about to change.
 *
 * Cancelling the event will prevent the user action.
 */
class ZoomStartEvent extends PreventableEvent {
  /**
   * @hidden
   */
  constructor(e, sender) {
    super(sender);
    this.originalEvent = e.originalEvent;
  }
}
const EVENT_MAP = {
  beforeReset: BeforeResetEvent,
  click: MapClickEvent,
  markerActivate: MarkerActivateEvent,
  markerClick: MarkerClickEvent,
  markerCreated: MarkerCreatedEvent,
  panEnd: PanEndEvent,
  pan: PanEvent,
  reset: ResetEvent,
  shapeClick: ShapeClickEvent,
  shapeCreated: ShapeCreatedEvent,
  shapeFeatureCreated: ShapeFeatureCreatedEvent,
  shapeMouseEnter: ShapeMouseEnterEvent,
  shapeMouseLeave: ShapeMouseLeaveEvent,
  zoomEnd: ZoomEndEvent,
  zoomStart: ZoomStartEvent
};
/**
 * @hidden
 */
class InstanceEventService {
  create(name, args, sender) {
    if (EVENT_MAP[name]) {
      return new EVENT_MAP[name](args, sender);
    }
  }
}

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-map',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1728372680,
  version: '16.11.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};
const POSITION_MODE = 'absolute';
const COLLISION = {
  horizontal: "fit",
  vertical: "fit"
};
/**
 * @hidden
 */
class BaseTooltip {
  constructor(popupService, localizationService) {
    this.popupService = popupService;
    this.localizationService = localizationService;
    this.animate = true;
    this.style = {
      position: 'relative'
    };
    this.popupRef = null;
  }
  get active() {
    return this.popupRef !== null;
  }
  show(e) {
    const offset = this.position(e.anchor);
    if (!this.popupRef) {
      this.popupRef = this.popupService.open(Object.assign({
        offset: offset,
        popupAlign: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        animate: this.animate,
        content: this.templateRef,
        collision: COLLISION,
        positionMode: POSITION_MODE
      }, this.popupSettings));
      if (this.localizationService.rtl) {
        this.popupRef.popupElement.setAttribute('dir', 'rtl');
      }
      this.onInit();
    } else {
      const popup = this.popupRef.popup.instance;
      popup.offset = offset;
    }
  }
  hide() {
    if (this.popupRef) {
      this.popupRef.close();
      this.popupRef = null;
    }
  }
  onInit() {
    //calling on init
  }
  ngOnDestroy() {
    this.hide();
  }
  position(offset) {
    if (!this.popupSettings || !this.popupSettings.appendTo) {
      return offset;
    }
    const appendTo = this.popupSettings.appendTo.element.nativeElement;
    const bbox = appendTo.getBoundingClientRect();
    const {
      scrollLeft,
      scrollTop
    } = this.scrollOffset(appendTo);
    return {
      left: offset.left - bbox.left - scrollLeft,
      top: offset.top - bbox.top - scrollTop
    };
  }
  scrollOffset(element) {
    if (!element) {
      return null;
    }
    let scrollLeft = element.scrollLeft;
    let scrollTop = element.scrollTop;
    let parent = element.parentElement;
    while (parent) {
      scrollLeft += parent.scrollLeft;
      scrollTop += parent.scrollTop;
      parent = parent.parentElement;
    }
    return {
      scrollLeft,
      scrollTop
    };
  }
}
BaseTooltip.ɵfac = function BaseTooltip_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BaseTooltip)(i0.ɵɵdirectiveInject(i1.PopupService), i0.ɵɵdirectiveInject(i3$1.LocalizationService));
};
BaseTooltip.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseTooltip
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseTooltip, [{
    type: Directive
  }], function () {
    return [{
      type: i1.PopupService
    }, {
      type: i3$1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class TooltipTemplateService {
  constructor() {
    this.templates = [];
  }
  registerTemplate(layerIndex, template) {
    if (layerIndex > -1) {
      this.templates[layerIndex] = template;
    }
  }
  getTemplate(layerIndex) {
    if (layerIndex > -1) {
      return this.templates[layerIndex];
    }
  }
}
TooltipTemplateService.ɵfac = function TooltipTemplateService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipTemplateService)();
};
TooltipTemplateService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TooltipTemplateService,
  factory: TooltipTemplateService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipTemplateService, [{
    type: Injectable
  }], null, null);
})();

/**
 * @hidden
 */
function bodyFactory() {
  if (isDocumentAvailable()) {
    return new ElementRef(document.body);
  }
}

/**
 * @hidden
 */
class TooltipPopupComponent extends BaseTooltip {
  constructor(popupService, templateService, localizationService, ngZone) {
    super(popupService, localizationService);
    this.popupService = popupService;
    this.templateService = templateService;
    this.localizationService = localizationService;
    this.ngZone = ngZone;
    this.tooltipContext = {};
    this.animate = true;
    this.wrapperClass = 'k-tooltip-wrapper';
    this.leave = new EventEmitter();
    this.popupClasses = {};
  }
  show(e) {
    this.tooltipTemplateRef = this.templateService.getTemplate(e.layerIndex);
    if (!this.tooltipTemplateRef) {
      return;
    }
    this.popupClasses = Object.assign({
      'k-tooltip': true,
      'k-map-tooltip': true,
      [e.className]: Boolean(e.className)
    }, this.classNames);
    this.tooltipContext = e;
    super.show(e);
  }
  containsElement(element) {
    if (this.popupRef) {
      return hasParent(element, this.popupRef.popupElement);
    }
  }
  onInit() {
    this.ngZone.runOutsideAngular(() => {
      this.mouseleaveSubscription = this.popupRef.popupElement.addEventListener('mouseleave', args => {
        this.leave.emit(args);
      });
    });
    this.popupRef.popupElement.classList.add(this.wrapperClass);
  }
  hide() {
    if (this.mouseleaveSubscription) {
      this.mouseleaveSubscription();
      this.mouseleaveSubscription = null;
    }
    this.tooltipTemplateRef = null;
    super.hide();
  }
}
TooltipPopupComponent.ɵfac = function TooltipPopupComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipPopupComponent)(i0.ɵɵdirectiveInject(i1.PopupService), i0.ɵɵdirectiveInject(TooltipTemplateService), i0.ɵɵdirectiveInject(i3$1.LocalizationService), i0.ɵɵdirectiveInject(i0.NgZone));
};
TooltipPopupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TooltipPopupComponent,
  selectors: [["kendo-map-tooltip-popup"]],
  viewQuery: function TooltipPopupComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templateRef = _t.first);
    }
  },
  inputs: {
    animate: "animate",
    classNames: "classNames",
    popupSettings: "popupSettings",
    wrapperClass: "wrapperClass"
  },
  outputs: {
    leave: "leave"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([PopupService, {
    provide: POPUP_CONTAINER,
    useFactory: bodyFactory
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 2,
  vars: 0,
  consts: [["content", ""], [3, "ngClass", "ngStyle"], [1, "k-tooltip-content"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function TooltipPopupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TooltipPopupComponent_ng_template_0_Template, 3, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
  },
  dependencies: [NgClass, NgStyle, NgTemplateOutlet],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipPopupComponent, [{
    type: Component,
    args: [{
      providers: [PopupService, {
        provide: POPUP_CONTAINER,
        useFactory: bodyFactory
      }],
      selector: 'kendo-map-tooltip-popup',
      template: `
    <ng-template #content>
        <div [ngClass]="popupClasses" [ngStyle]="style">
            <div class="k-tooltip-content">
                <ng-template [ngTemplateOutlet]="tooltipTemplateRef"
                            [ngTemplateOutletContext]="tooltipContext">
                </ng-template>
            </div>
        </div>
    </ng-template>
    `,
      standalone: true,
      imports: [NgClass, NgStyle, NgTemplateOutlet]
    }]
  }], function () {
    return [{
      type: i1.PopupService
    }, {
      type: TooltipTemplateService
    }, {
      type: i3$1.LocalizationService
    }, {
      type: i0.NgZone
    }];
  }, {
    templateRef: [{
      type: ViewChild,
      args: ['content', {
        static: true
      }]
    }],
    animate: [{
      type: Input
    }],
    classNames: [{
      type: Input
    }],
    popupSettings: [{
      type: Input
    }],
    wrapperClass: [{
      type: Input
    }],
    leave: [{
      type: Output
    }]
  });
})();

// Static SVG Icons used by the Map
const svgIcons = {
  mapMarkerTargetIcon,
  mapMarkerIcon,
  plusIcon,
  minusIcon,
  caretAltUpIcon,
  caretAltDownIcon,
  caretAltLeftIcon,
  caretAltRightIcon
};
/**
 * Represents the [Kendo UI Map component for Angular]({% slug overview_map %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-map [center]="center" [zoom]="15">
 *          <kendo-map-layers>
 *          <kendo-map-tile-layer
 *              [subdomains]="tileSubdomains"
 *              [urlTemplate]="tileUrl"
 *              attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap contributors</a>"
 *          >
 *          </kendo-map-tile-layer>
 *          <kendo-map-marker-layer
 *              [data]="markers"
 *              locationField="latlng"
 *              titleField="name"
 *          >
 *          </kendo-map-marker-layer>
 *          </kendo-map-layers>
 *      </kendo-map>
 *   `
 * })
 * class AppComponent {
 *  tileSubdomains = ["a", "b", "c"];
 *  tileUrl = (e: TileUrlTemplateArgs): string =>
 *      `https://${e.subdomain}.tile.openstreetmap.org/${e.zoom}/${e.x}/${e.y}.png`;
 *
 *  center = [30.2675, -97.7409];
 *  markers = [{ latlng: [30.2675, -97.7409], name: "Zevo Toys" }];
 * }
 *
 * ```
 */
class MapComponent {
  constructor(configurationService, instanceEventService, element, localizationService, changeDetector, ngZone, renderer, iconsService) {
    this.configurationService = configurationService;
    this.instanceEventService = instanceEventService;
    this.element = element;
    this.localizationService = localizationService;
    this.changeDetector = changeDetector;
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.iconsService = iconsService;
    /**
     * Limits the automatic resizing of the Map. Sets the maximum number of times per second
     * that the component redraws its content when the size of its container changes.
     * Defaults to `10`. To disable the automatic resizing, set it to `0`.
     *
     * @example
     * ```ts
     * _@Component({
     *     selector: 'my-app',
     *     template: `
     *         <kendo-map [resizeRateLimit]="2">
     * <!--                 ^^^^^^^^^^^^^^^^^^^^^^
     *       Will update the size of the Map up to two times a second.
     *       Resize the example pane or window to try it out.
     * -->
     *       </kendo-map>
     *   `
     * })
     * export class AppComponent {
     * }
     * ```
     */
    this.resizeRateLimit = 10;
    /**
     * The initial zoom level.
     *
     * Typical web maps use zoom levels from 0 (whole world) to 19 (sub-meter features).
     *
     * The map size is derived from the zoom level and minScale options: `size = (2 ^ zoom) * minSize`
     *
     * > Map zoom rounds floating point numbers. This is done so as the majority of web maps use the whole [`zoom levels`](https://wiki.openstreetmap.org/wiki/Zoom_levels) 0 through to 19.
     *
     * @default 3
     */
    this.zoom = 3;
    /**
     * Fired immediately before the map is reset. This event is typically used for cleanup by layer implementers.
     */
    this.beforeReset = new EventEmitter();
    /**
     * Fired when the user clicks on the map.
     */
    this.mapClick = new EventEmitter();
    /**
     * Fired when a marker has been displayed and has a DOM element assigned.
     */
    this.markerActivate = new EventEmitter();
    /**
     * Fired when a marker has been clicked or tapped.
     */
    this.markerClick = new EventEmitter();
    /**
     * Fired when a marker has been created and is about to be displayed.
     *
     * Cancelling the event will prevent the marker from being shown.
     */
    this.markerCreated = new EventEmitter();
    /**
     * Fires after the map viewport has been moved.
     */
    this.panEnd = new EventEmitter();
    /**
     * Fired while the map viewport is being moved.
     */
    this.pan = new EventEmitter();
    /**
     * Fired when the map is reset.
     *
     * This typically occurs on initial load and after a zoom/center change.
     */
    this.reset = new EventEmitter();
    /**
     * Fired when a shape is clicked or tapped.
     */
    this.shapeClick = new EventEmitter();
    /**
     * Fired when a shape is created, but is not rendered yet.
     */
    this.shapeCreated = new EventEmitter();
    /**
     * Fired when a [GeoJSON Feature](https://geojson.org/geojson-spec.html#feature-objects) is created on a shape layer.
     */
    this.shapeFeatureCreated = new EventEmitter();
    /**
     * Fired when the mouse enters a shape.
     *
     * > This event will fire reliably only for shapes that have set fill color.
     * > The opacity can still be set to 0 so the shapes appear to have no fill.
     */
    this.shapeMouseEnter = new EventEmitter();
    /**
     * Fired when the mouse leaves a shape.
     *
     * > This event will fire reliably only for shapes that have set fill color.
     * > The opacity can still be set to 0 so the shapes appear to have no fill.
     */
    this.shapeMouseLeave = new EventEmitter();
    /**
     * Fired when the map zoom level is about to change.
     *
     * Cancelling the event will prevent the user action.
     */
    this.zoomStart = new EventEmitter();
    /**
     * Fired when the map zoom level has changed.
     */
    this.zoomEnd = new EventEmitter();
    /**
     * Fired when the map center has been changed.
     */
    this.centerChange = new EventEmitter();
    /**
     * Fired when the map zoom level has been changed.
     */
    this.zoomChange = new EventEmitter();
    this.initResizeSensor = false;
    this.theme = null;
    this.rtl = false;
    validatePackage(packageMetadata);
  }
  ngAfterViewInit() {
    if (this.canRender) {
      this.ngZone.runOutsideAngular(() => {
        const mapMouseleave = this.renderer.listen(this.element.nativeElement, 'mouseleave', this.mapMouseleave.bind(this));
        this.domSubscriptions = () => {
          mapMouseleave();
        };
      });
    }
    this.setDirection();
    this.initConfig();
    this.subscriptions = this.localizationService.changes.subscribe(() => this.setDirection());
  }
  ngAfterViewChecked() {
    if (this.instance && this.autoResize) {
      this.ngZone.runOutsideAngular(() => {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          this.resize();
        }, 0);
      });
    }
  }
  ngOnChanges(changes) {
    if (this.instance) {
      if (changes['zoom']) {
        this.instance.zoom(changes['zoom'].currentValue);
        delete changes['zoom'];
      }
      if (changes['center']) {
        this.instance.center(changes['center'].currentValue);
        delete changes['center'];
      }
      if (Object.keys(changes).length === 0) {
        return;
      }
    }
    const store = this.configurationService.store;
    copyChanges(changes, store);
    store.popupSettings = null;
    this.configurationService.push(store);
  }
  ngOnDestroy() {
    this.destroyed = true;
    if (this.optionsChange) {
      this.optionsChange.unsubscribe();
    }
    if (this.domSubscriptions) {
      this.domSubscriptions();
      this.domSubscriptions = null;
    }
    if (this.instance) {
      this.instance.destroy();
      this.instance = null;
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  /**
   * The marker layers instances.
   */
  get layers() {
    return this.instance?.layers;
  }
  /**
   * Gets the extent (visible area) of the map.
   */
  get extent() {
    return this.instance?.extent();
  }
  /**
   * Sets the extent (visible area) of the map.
   */
  set extent(extent) {
    this.instance?.extent(extent);
  }
  /**
   * Detects the size of the container and redraws the Map.
   * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
   */
  resize() {
    //this.instance?.resize();
  }
  /**
   * Retrieves the size of the visible portion of the map.
   *
   * @returns The size (width and height) of the visible portion of the map.
   */
  viewSize() {
    return this.instance?.viewSize();
  }
  /**
   * Returns the event coordinates relative to the map element. Offset coordinates are not synchronized to a particular location on the map.
   *
   * @param e The mouse event.
   * @returns The event coordinates relative to the map element.
   */
  eventOffset(e) {
    return this.instance?.eventOffset(e);
  }
  /**
   * Retrieves projected (layer) coordinates that correspond to this mouse event. Layer coordinates are absolute and change only when the zoom level is changed.
   *
   * @param e The mouse event.
   * @returns The projected (layer) coordinates that correspond to this event.
   */
  eventToLayer(e) {
    return this.instance?.eventToLayer(e);
  }
  /**
   * Retrieves the geographic location that correspond to this mouse event.
   *
   * @param e The mouse event.
   * @returns The geographic location that correspond to this mouse event.
   */
  eventToLocation(e) {
    return this.instance?.eventToLocation(e);
  }
  /**
   * Retrieves relative (view) coordinates that correspond to this mouse event. Layer elements positioned on these coordinates will appear under the mouse cursor.
   * View coordinates are no longer valid after a map reset.
   *
   * @param e The mouse event.
   * @returns The relative (view) coordinates that correspond to this mouse event.
   */
  eventToView(e) {
    return this.instance?.eventToView(e);
  }
  /**
   * Transforms layer (projected) coordinates to geographical location.
   *
   * @param point The layer (projected) coordinates. An array argument is assumed to be in x, y order.
   * @param zoom Optional. Assumed zoom level. Defaults to the current zoom level.
   * @returns The geographic location that corresponds to the layer coordinates.
   */
  layerToLocation(point, zoom) {
    return this.instance?.layerToLocation(point, zoom);
  }
  /**
   * Returns the layer (projected) coordinates that correspond to a geographical location.
   *
   * @param location The geographic location. An array argument is assumed to be in [Latitude, Longitude] order.
   * @param zoom Optional. Assumed zoom level. Defaults to the current zoom level.
   * @returns The layer (projected) coordinates.
   */
  locationToLayer(location, zoom) {
    return this.instance?.locationToLayer(location, zoom);
  }
  /**
   * Returns the view (relative) coordinates that correspond to a geographical location.
   *
   * @param location The geographic location. An array argument is assumed to be in [Latitude, Longitude] order.
   * @returns The view coordinates that correspond to a geographical location.
   */
  locationToView(location) {
    return this.instance?.locationToView(location);
  }
  /**
   * Returns the geographical location that correspond to the view (relative) coordinates.
   *
   * @param point The view coordinates. An array argument is assumed to be in x, y order.
   * @param zoom Optional. Assumed zoom level. Defaults to the current zoom level.
   * @returns The geographic location that corresponds to the view coordinates.
   */
  viewToLocation(point, zoom) {
    return this.instance?.viewToLocation(point, zoom);
  }
  /**
   * @hidden
   */
  onResize() {
    if (this.autoResize) {
      this.resize();
    }
  }
  /**
   * @hidden
   */
  get canRender() {
    return isDocumentAvailable() && Boolean(this.element);
  }
  get autoResize() {
    return this.resizeRateLimit > 0;
  }
  init() {
    if (!this.canRender) {
      return;
    }
    const element = this.element.nativeElement;
    this.renderer.setStyle(element, 'display', 'block');
    const instanceObserver = new MapInstanceObserver(this);
    this.createInstance(element, instanceObserver);
  }
  initConfig() {
    if (!isDocumentAvailable()) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.optionsChange = combineLatest([this.configurationService.changes, this.iconsService.changes]).pipe(tap(([options, iconSettings]) => {
        this.options = {
          ...options,
          icons: {
            ...iconSettings,
            svgIcons
          }
        };
      })).subscribe(() => {
        if (!this.instance) {
          this.init();
          return;
        }
        this.instance.setOptions(this.options);
      });
    });
  }
  createInstance(element, observer) {
    this.instance = new Map(element, this.options, this.theme, {
      observer: observer,
      rtl: this.rtl,
      sender: this
    });
  }
  activeEmitter(name) {
    const alias = name === 'click' ? 'mapClick' : name;
    const emitter = this[alias];
    if (emitter && emitter.emit && hasObservers(emitter)) {
      return emitter;
    }
  }
  trigger(name, e) {
    const emitter = this.activeEmitter(name);
    if (emitter) {
      const args = this.instanceEventService.create(name, e, this);
      if (!this.instance && e.sender) {
        // Ensure instance reference is populated for events trigger during initialization.
        this.instance = e.sender;
      }
      this.run(() => {
        emitter.emit(args);
      });
      return args.isDefaultPrevented && args.isDefaultPrevented();
    }
  }
  run(callback, inZone = true, detectChanges) {
    if (inZone) {
      if (detectChanges) {
        this.changeDetector.markForCheck();
      }
      this.ngZone.run(callback);
    } else {
      callback();
      if (detectChanges) {
        this.detectChanges();
      }
    }
  }
  detectChanges() {
    if (!this.destroyed) {
      this.changeDetector.detectChanges();
    }
  }
  setDirection() {
    this.rtl = this.isRTL;
    if (this.element) {
      this.renderer.setAttribute(this.element.nativeElement, 'dir', this.rtl ? 'rtl' : 'ltr');
    }
  }
  get isRTL() {
    return Boolean(this.localizationService.rtl);
  }
  onInit(e) {
    this.instance = e.sender;
  }
  onShowTooltip(e) {
    this.run(() => {
      this.tooltipInstance.show(e);
    }, true, true);
  }
  onHideTooltip() {
    this.run(() => {
      this.tooltipInstance.hide();
    }, true, true);
  }
  onCenterChange(e) {
    this.centerChange.next(e.center);
  }
  onZoomChange(e) {
    this.zoomChange.next(e.zoom);
  }
  /**
   * @hidden
   */
  tooltipMouseleave(e) {
    const relatedTarget = e.relatedTarget;
    const chartElement = this.element.nativeElement;
    if (this.instance && (!relatedTarget || !hasParent(relatedTarget, chartElement))) {
      this.tooltipInstance.hide();
    }
  }
  /**
   * @hidden
   */
  mapMouseleave(e) {
    const relatedTarget = e.relatedTarget;
    const chartElement = this.element.nativeElement;
    if (this.instance && (!relatedTarget || !(this.tooltipInstance.containsElement(relatedTarget) || hasParent(relatedTarget, chartElement)))) {
      this.tooltipInstance.hide();
    }
  }
}
MapComponent.ɵfac = function MapComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MapComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(InstanceEventService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i3$1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i4.IconsService));
};
MapComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MapComponent,
  selectors: [["kendo-map"]],
  viewQuery: function MapComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TooltipPopupComponent, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipInstance = _t.first);
    }
  },
  inputs: {
    resizeRateLimit: "resizeRateLimit",
    center: "center",
    controls: "controls",
    minZoom: "minZoom",
    maxZoom: "maxZoom",
    minSize: "minSize",
    pannable: "pannable",
    popupSettings: "popupSettings",
    wraparound: "wraparound",
    zoom: "zoom",
    zoomable: "zoomable"
  },
  outputs: {
    beforeReset: "beforeReset",
    mapClick: "mapClick",
    markerActivate: "markerActivate",
    markerClick: "markerClick",
    markerCreated: "markerCreated",
    panEnd: "panEnd",
    pan: "pan",
    reset: "reset",
    shapeClick: "shapeClick",
    shapeCreated: "shapeCreated",
    shapeFeatureCreated: "shapeFeatureCreated",
    shapeMouseEnter: "shapeMouseEnter",
    shapeMouseLeave: "shapeMouseLeave",
    zoomStart: "zoomStart",
    zoomEnd: "zoomEnd",
    centerChange: "centerChange",
    zoomChange: "zoomChange"
  },
  exportAs: ["kendoMap"],
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, InstanceEventService, LocalizationService, TooltipTemplateService, {
    provide: L10N_PREFIX,
    useValue: 'kendo.map'
  }]), i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
  decls: 3,
  vars: 6,
  consts: [[3, "leave", "popupSettings"], [3, "resize", "rateLimit"]],
  template: function MapComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "kendo-map-tooltip-popup", 0);
      i0.ɵɵlistener("leave", function MapComponent_Template_kendo_map_tooltip_popup_leave_0_listener($event) {
        return ctx.tooltipMouseleave($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(1, "div")(2, "kendo-resize-sensor", 1);
      i0.ɵɵlistener("resize", function MapComponent_Template_kendo_resize_sensor_resize_2_listener() {
        return ctx.onResize();
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("popupSettings", ctx.popupSettings);
      i0.ɵɵadvance();
      i0.ɵɵstyleProp("width", 100, "%")("height", 100, "%");
      i0.ɵɵadvance();
      i0.ɵɵproperty("rateLimit", ctx.resizeRateLimit);
    }
  },
  dependencies: [TooltipPopupComponent, ResizeSensorComponent],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-map',
      changeDetection: ChangeDetectionStrategy.OnPush,
      exportAs: 'kendoMap',
      providers: [ConfigurationService, InstanceEventService, LocalizationService, TooltipTemplateService, {
        provide: L10N_PREFIX,
        useValue: 'kendo.map'
      }],
      template: `
        <kendo-map-tooltip-popup (leave)="tooltipMouseleave($event)" [popupSettings]="popupSettings">
        </kendo-map-tooltip-popup>
        <div [style.width.%]="100" [style.height.%]="100"><!-- required for resize sensor to initialize properly -->
            <kendo-resize-sensor (resize)="onResize()" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
        </div>
    `,
      standalone: true,
      imports: [TooltipPopupComponent, ResizeSensorComponent]
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: InstanceEventService
    }, {
      type: i0.ElementRef
    }, {
      type: i3$1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.Renderer2
    }, {
      type: i4.IconsService
    }];
  }, {
    resizeRateLimit: [{
      type: Input
    }],
    center: [{
      type: Input
    }],
    controls: [{
      type: Input
    }],
    minZoom: [{
      type: Input
    }],
    maxZoom: [{
      type: Input
    }],
    minSize: [{
      type: Input
    }],
    pannable: [{
      type: Input
    }],
    popupSettings: [{
      type: Input
    }],
    wraparound: [{
      type: Input
    }],
    zoom: [{
      type: Input
    }],
    zoomable: [{
      type: Input
    }],
    beforeReset: [{
      type: Output
    }],
    mapClick: [{
      type: Output
    }],
    markerActivate: [{
      type: Output
    }],
    markerClick: [{
      type: Output
    }],
    markerCreated: [{
      type: Output
    }],
    panEnd: [{
      type: Output
    }],
    pan: [{
      type: Output
    }],
    reset: [{
      type: Output
    }],
    shapeClick: [{
      type: Output
    }],
    shapeCreated: [{
      type: Output
    }],
    shapeFeatureCreated: [{
      type: Output
    }],
    shapeMouseEnter: [{
      type: Output
    }],
    shapeMouseLeave: [{
      type: Output
    }],
    zoomStart: [{
      type: Output
    }],
    zoomEnd: [{
      type: Output
    }],
    centerChange: [{
      type: Output
    }],
    zoomChange: [{
      type: Output
    }],
    tooltipInstance: [{
      type: ViewChild,
      args: [TooltipPopupComponent, {
        static: true
      }]
    }]
  });
})();

/**
 * @hidden
 */
class CollectionComponent {
  constructor(configKey, configurationService, collectionService) {
    this.configKey = configKey;
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.items = [];
    this.subscription = collectionService.itemChanges.subscribe(changes => this.processChanges(changes));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterContentInit() {
    this.readItems();
    this.children.changes.subscribe(() => this.readItems());
  }
  processChanges(changes) {
    if (!this.children) {
      return;
    }
    const index = this.children.toArray().indexOf(changes.sender);
    if (index < 0) {
      return;
    }
    this.items[index] = changes.options;
    changes.sender.index = index;
    this.change();
  }
  readItems() {
    this.items = this.children.map((item, index) => {
      item.index = index;
      return item.options;
    });
    this.change();
  }
  change() {
    this.configurationService.notify(new Change(this.configKey, this.items.length === 0 ? undefined : this.items));
  }
}
CollectionComponent.ɵfac = function CollectionComponent_Factory(__ngFactoryType__) {
  i0.ɵɵinvalidFactory();
};
CollectionComponent.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CollectionComponent
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CollectionComponent, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: ConfigurationService
    }, {
      type: CollectionService
    }];
  }, null);
})();

/**
 * A collection of one or more layers.
 */
class LayersComponent extends CollectionComponent {
  constructor(configurationService, collectionService) {
    super('layers', configurationService, collectionService);
    this.configurationService = configurationService;
    this.collectionService = collectionService;
  }
}
LayersComponent.ɵfac = function LayersComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LayersComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService));
};
LayersComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LayersComponent,
  selectors: [["kendo-map-layers"]],
  contentQueries: function LayersComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LayerComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([CollectionService]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function LayersComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayersComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [CollectionService],
      selector: 'kendo-map-layers',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }];
  }, {
    children: [{
      type: ContentChildren,
      args: [LayerComponent]
    }]
  });
})();

/**
 * A vector shape layer for bubble maps. [See example](slug:marker_layers_map).
 */
class MarkerLayerComponent extends LayerComponent {
  constructor(configurationService, collectionService, sanitizer) {
    super('marker', configurationService, collectionService, sanitizer);
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.sanitizer = sanitizer;
  }
}
MarkerLayerComponent.ɵfac = function MarkerLayerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MarkerLayerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService), i0.ɵɵdirectiveInject(i3.DomSanitizer));
};
MarkerLayerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MarkerLayerComponent,
  selectors: [["kendo-map-marker-layer"]],
  inputs: {
    data: "data",
    locationField: "locationField",
    titleField: "titleField",
    shape: "shape"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, {
    provide: LayerComponent,
    useExisting: forwardRef(() => MarkerLayerComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function MarkerLayerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerLayerComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ConfigurationService, {
        provide: LayerComponent,
        useExisting: forwardRef(() => MarkerLayerComponent)
      }],
      selector: 'kendo-map-marker-layer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }, {
      type: i3.DomSanitizer
    }];
  }, {
    data: [{
      type: Input
    }],
    locationField: [{
      type: Input
    }],
    titleField: [{
      type: Input
    }],
    shape: [{
      type: Input
    }]
  });
})();

/**
 * Defines a vector shape layer bound to GeoJSON data. [See example](slug:shape_layers_map).
 */
class ShapeLayerComponent extends LayerComponent {
  constructor(configurationService, collectionService, sanitizer) {
    super('shape', configurationService, collectionService, sanitizer);
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.sanitizer = sanitizer;
  }
}
ShapeLayerComponent.ɵfac = function ShapeLayerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShapeLayerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService), i0.ɵɵdirectiveInject(i3.DomSanitizer));
};
ShapeLayerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ShapeLayerComponent,
  selectors: [["kendo-map-shape-layer"]],
  inputs: {
    data: "data",
    style: "style"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, {
    provide: LayerComponent,
    useExisting: forwardRef(() => ShapeLayerComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ShapeLayerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShapeLayerComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ConfigurationService, {
        provide: LayerComponent,
        useExisting: forwardRef(() => ShapeLayerComponent)
      }],
      selector: 'kendo-map-shape-layer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }, {
      type: i3.DomSanitizer
    }];
  }, {
    data: [{
      type: Input
    }],
    style: [{
      type: Input
    }]
  });
})();

/**
 * Defines a vector shape layer bound to GeoJSON data. [See example](slug:tile_layers_map).
 */
class TileLayerComponent extends LayerComponent {
  constructor(configurationService, collectionService, sanitizer) {
    super('tile', configurationService, collectionService, sanitizer);
    this.configurationService = configurationService;
    this.collectionService = collectionService;
    this.sanitizer = sanitizer;
  }
}
TileLayerComponent.ɵfac = function TileLayerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TileLayerComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(CollectionService), i0.ɵɵdirectiveInject(i3.DomSanitizer));
};
TileLayerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TileLayerComponent,
  selectors: [["kendo-map-tile-layer"]],
  inputs: {
    tileSize: "tileSize",
    subdomains: "subdomains",
    urlTemplate: "urlTemplate"
  },
  standalone: true,
  features: [i0.ɵɵProvidersFeature([ConfigurationService, {
    provide: LayerComponent,
    useExisting: forwardRef(() => TileLayerComponent)
  }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function TileLayerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TileLayerComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [ConfigurationService, {
        provide: LayerComponent,
        useExisting: forwardRef(() => TileLayerComponent)
      }],
      selector: 'kendo-map-tile-layer',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: CollectionService
    }, {
      type: i3.DomSanitizer
    }];
  }, {
    tileSize: [{
      type: Input
    }],
    subdomains: [{
      type: Input
    }],
    urlTemplate: [{
      type: Input
    }]
  });
})();

/**
 * A directive that sets a [template](link:site.data.urls.angular['templatesyntax'])
 * within the `<kendo-map-tooltip>` component for the Map
 * [Bubble layer tooltip](slug:bubble_layers_map#toc-tooltips).
 *
 * The available inputs for the bubble tooltip template are:
 *    * `dataItem: any`&mdash;The original data item used to create the bubble.
 *    * `layerIndex: number`&mdash;The index of the layer for the tooltip.
 *    * `location: Location`&mdash;The location of the bubble.
 *    * `value: number`&mdash;The value of the bubble.
 */
class BubbleTooltipTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
BubbleTooltipTemplateDirective.ɵfac = function BubbleTooltipTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BubbleTooltipTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
BubbleTooltipTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BubbleTooltipTemplateDirective,
  selectors: [["", "kendoMapBubbleTooltipTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BubbleTooltipTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoMapBubbleTooltipTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * A directive that sets a [template](link:site.data.urls.angular['templatesyntax'])
 * within the `<kendo-map-tooltip>` component for the Map
 * [Marker layer tooltip](slug:marker_layers_map#toc-tooltips).
 *
 * The available inputs for the marker tooltip template are:
 *    * `title: string`&mdash;The marker title.
 *    * `layerIndex: number`&mdash;The index of the layer for the tooltip.
 *    * `location: Location`&mdash;The marker location.
 */
class MarkerTooltipTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
MarkerTooltipTemplateDirective.ɵfac = function MarkerTooltipTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MarkerTooltipTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
MarkerTooltipTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MarkerTooltipTemplateDirective,
  selectors: [["", "kendoMapMarkerTooltipTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerTooltipTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoMapMarkerTooltipTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * A directive that sets a [template](link:site.data.urls.angular['templatesyntax'])
 * within the `<kendo-map-tooltip>` component for the Map
 * [Shape layer tooltip](slug:shape_layers_map#toc-tooltips).
 *
 * The available inputs for the shape tooltip template are:
 *    * `dataItem: any`&mdash;The original data item used to create the shape.
 *    * `layerIndex: number`&mdash;The index of the layer for the tooltip.
 *    * `location: Location`&mdash;The location of the center of the shape.
 */
class ShapeTooltipTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
ShapeTooltipTemplateDirective.ɵfac = function ShapeTooltipTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShapeTooltipTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
ShapeTooltipTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ShapeTooltipTemplateDirective,
  selectors: [["", "kendoMapShapeTooltipTemplate", ""]],
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShapeTooltipTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoMapShapeTooltipTemplate]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * The configuration options of the [Bubble Layer Tooltip](slug:bubble_layers_map#toc-tooltips).
 */
class BubbleTooltipComponent extends SettingsComponent {
  constructor(configurationService, templateService, layer) {
    super('tooltip', configurationService);
    this.configurationService = configurationService;
    this.templateService = templateService;
    this.layer = layer;
  }
  ngAfterContentChecked() {
    this.templateService.registerTemplate(this.layer.index, this.bubbleTooltipTemplate?.templateRef);
  }
}
BubbleTooltipComponent.ɵfac = function BubbleTooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BubbleTooltipComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(TooltipTemplateService), i0.ɵɵdirectiveInject(LayerComponent));
};
BubbleTooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BubbleTooltipComponent,
  selectors: [["kendo-map-bubble-layer-tooltip"]],
  contentQueries: function BubbleTooltipComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, BubbleTooltipTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bubbleTooltipTemplate = _t.first);
    }
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function BubbleTooltipComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BubbleTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-map-bubble-layer-tooltip',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: TooltipTemplateService
    }, {
      type: LayerComponent
    }];
  }, {
    bubbleTooltipTemplate: [{
      type: ContentChild,
      args: [BubbleTooltipTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * The configuration options of the [Marker Layer Tooltip](slug:marker_layers_map#toc-tooltips).
 */
class MarkerTooltipComponent extends SettingsComponent {
  constructor(configurationService, templateService, layer) {
    super('tooltip', configurationService);
    this.configurationService = configurationService;
    this.templateService = templateService;
    this.layer = layer;
  }
  ngAfterContentChecked() {
    this.templateService.registerTemplate(this.layer.index, this.markerTooltipTemplate?.templateRef);
  }
}
MarkerTooltipComponent.ɵfac = function MarkerTooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MarkerTooltipComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(TooltipTemplateService), i0.ɵɵdirectiveInject(LayerComponent));
};
MarkerTooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MarkerTooltipComponent,
  selectors: [["kendo-map-marker-layer-tooltip"]],
  contentQueries: function MarkerTooltipComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, MarkerTooltipTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.markerTooltipTemplate = _t.first);
    }
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function MarkerTooltipComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkerTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-map-marker-layer-tooltip',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: TooltipTemplateService
    }, {
      type: LayerComponent
    }];
  }, {
    markerTooltipTemplate: [{
      type: ContentChild,
      args: [MarkerTooltipTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
 * The configuration options of the [Shape Layer Tooltip](slug:shape_layers_map#toc-tooltips).
 */
class ShapeTooltipComponent extends SettingsComponent {
  constructor(configurationService, templateService, layer) {
    super('tooltip', configurationService);
    this.configurationService = configurationService;
    this.templateService = templateService;
    this.layer = layer;
  }
  ngAfterContentChecked() {
    this.templateService.registerTemplate(this.layer.index, this.shapeTooltipTemplate?.templateRef);
  }
}
ShapeTooltipComponent.ɵfac = function ShapeTooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShapeTooltipComponent)(i0.ɵɵdirectiveInject(ConfigurationService), i0.ɵɵdirectiveInject(TooltipTemplateService), i0.ɵɵdirectiveInject(LayerComponent));
};
ShapeTooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ShapeTooltipComponent,
  selectors: [["kendo-map-shape-layer-tooltip"]],
  contentQueries: function ShapeTooltipComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ShapeTooltipTemplateDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.shapeTooltipTemplate = _t.first);
    }
  },
  standalone: true,
  features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
  decls: 0,
  vars: 0,
  template: function ShapeTooltipComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShapeTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-map-shape-layer-tooltip',
      template: '',
      standalone: true
    }]
  }], function () {
    return [{
      type: ConfigurationService
    }, {
      type: TooltipTemplateService
    }, {
      type: LayerComponent
    }];
  }, {
    shapeTooltipTemplate: [{
      type: ContentChild,
      args: [ShapeTooltipTemplateDirective, {
        static: false
      }]
    }]
  });
})();

/**
* Utility array that contains all `Map` related components and directives.
*/
const KENDO_MAP = [BubbleLayerComponent, BubbleTooltipComponent, BubbleTooltipTemplateDirective, LayersComponent, MapComponent, MarkerLayerComponent, MarkerTooltipComponent, MarkerTooltipTemplateDirective, ShapeLayerComponent, ShapeTooltipComponent, ShapeTooltipTemplateDirective, TileLayerComponent];

//IMPORTANT: NgModule export kept for backwards compatibility
/**
 * Represents the [NgModule](link:site.data.urls.angular['ngmoduleapi'])
* definition for the Map component.
* @example
*
* ```ts
* import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
* import { BrowserModule } from '@angular/platform-browser';
* import { NgModule } from '@angular/core';
* import { MapModule } from '@progress/kendo-angular-map';
* import { AppComponent } from './app.component';
*
* @NgModule({
*     declarations: [AppComponent],
*     imports:      [BrowserModule, BrowserAnimationsModule, MapModule],
*     bootstrap:    [AppComponent]
* })
* export class AppModule {}
*```
*/
class MapModule {}
MapModule.ɵfac = function MapModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MapModule)();
};
MapModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MapModule
});
MapModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IconsService, ResizeBatchService],
  imports: [BubbleLayerComponent, BubbleTooltipComponent, LayersComponent, MapComponent, MarkerLayerComponent, MarkerTooltipComponent, ShapeLayerComponent, ShapeTooltipComponent, TileLayerComponent]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MapModule, [{
    type: NgModule,
    args: [{
      imports: [...KENDO_MAP],
      exports: [...KENDO_MAP],
      providers: [IconsService, ResizeBatchService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BeforeResetEvent, BubbleLayerComponent, BubbleTooltipComponent, BubbleTooltipTemplateDirective, KENDO_MAP, LayerTooltipComponent, LayersComponent, MapClickEvent, MapComponent, MapModule, MarkerActivateEvent, MarkerClickEvent, MarkerCreatedEvent, MarkerLayerComponent, MarkerTooltipComponent, MarkerTooltipTemplateDirective, PanEndEvent, PanEvent, ResetEvent, ShapeClickEvent, ShapeCreatedEvent, ShapeFeatureCreatedEvent, ShapeLayerComponent, ShapeMouseEnterEvent, ShapeMouseLeaveEvent, ShapeTooltipComponent, ShapeTooltipTemplateDirective, TileLayerComponent, TooltipPopupComponent, ZoomEndEvent, ZoomStartEvent, bodyFactory };