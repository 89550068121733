<div class="example-wrap">
    <kendo-stepper [steps]="[
        { label: 'Authenticate' },
        { label: 'Map Accounts' }
      ]" 
      stepType="full" 
      [currentStep]="1" 
      [linear]="true" 
      [style.width.px]="570">
    </kendo-stepper>
  </div>
  <br />
  <br />

  <div class="example-wrap">
    <kendo-grid
  [data]="data()"
  [pageable]="true"
  [pageSize]="ingestorService.state.take"
  [skip]="ingestorService.state.skip"
  [(filter)]="ingestorService.state.filter"
  [loading]="ingestorService.loading"
  (dataStateChange)="ingestorService.onStateChange($event)"
  [sort]="this.ingestorService.state.sort"
  [sortable]="false"
  [filterable]="false"
  #grid
>
  <ng-template kendoGridToolbarTemplate>
  </ng-template>
  <!-- remove after development -->
  <kendo-grid-column
    field="pk"
    title="Id"
    [width]="300"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="account_name"
    title="Account Name"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="one_stellar_id"
    title="One Stellar Id"
  >
  </kendo-grid-column>
  <!-- remove after development, only editable field (freehand), visual context to user on Integration column being 
   editable and once in edit mode, what does user type in? -->
  <kendo-grid-column
    field="integration_id"
    title="Integration Id"
  >
  </kendo-grid-column>
</kendo-grid>
    <!-- <form class="k-form k-form-md" [formGroup]="mapAccountForm">
      <div class="k-form-buttons">
        <h2 class="k-form-title">Add Map Account</h2>
      </div>
      <fieldset class="k-form-fieldset">
        <kendo-formfield>
          <kendo-label labelCssClass="k-form-label" [for]="integration_url" text="Integration Url"></kendo-label>
          <kendo-textbox formControlName="integration_url" #integration_url></kendo-textbox>
  
          <kendo-formhint>Please enter the integration url</kendo-formhint>
        </kendo-formfield>
  
        <kendo-formfield>
          <kendo-label labelCssClass="k-form-label" [for]="api_key" text="Api Key*"></kendo-label>
          <kendo-textbox formControlName="api_key" #api_key required></kendo-textbox>
  
          <kendo-formhint>Api Key</kendo-formhint>
          <kendo-formerror>Error: Not valid Api Key</kendo-formerror>
        </kendo-formfield>
  
        <div class="k-form-buttons">
          <button kendoButton (click)="clear($event)">
            Clear
          </button>
          <button kendoButton themeColor="primary" (click)="save($event)" [disabled]="mapAccountForm.invalid">
            Next
          </button>
        </div>
      </fieldset>
    </form> -->
  </div>
  