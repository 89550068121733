import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { ServerException } from '../../../../../../../../core/models/entities';

@Injectable()
export class ServerExceptionService extends ODataService<ServerExceptionResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/serverexceptions`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }
  public createServerException(request: ServerExceptionRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/serverexceptions/`;
    return this.http.post<ServerExceptionRequest>(url, request);
  }
  public updateServerException(request: ServerException) {
    const url = `${this.url}`;
    return this.http.patch<ServerExceptionResponse>(url, request);
  }

  public deleteServerException(serverExceptionId: number) {
    const url = `${this.url}?pk=${serverExceptionId}`;
    return this.http.delete(url);
  }
}

export interface ServerExceptionResponse extends ServerException {}

export interface ServerExceptionRequest {
  organization_id: number;
  servers_exceptions: ServerException;
}

export interface ServerExceptionResponse extends ServerExceptionRequest {}
