import { Component, effect, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../../../core/shared/common/base.component';
import { AnalyticsService } from '../../../../../../core/services/analytics.service';
import { filter, takeUntil, map } from 'rxjs';
import { OrganizationService } from '../../../../../admin/organizations/services/organization.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DistillationFunnelComponent } from '../../../../../charting/distillation-funnel/distillation-funnel.component';
import { DestinationMapComponent } from '../../../../analytics/components/destination-map/destination-map.component';
import { OutcomeStatusComponent } from '../../../../analytics/components/outcome-status/outcome-status.component';
import { SourceMapComponent } from '../../../../analytics/components/source-map/source-map.component';
import { SuspectAssetsComponent } from '../../../../analytics/components/suspect-assets/suspect-assets.component';
import { SuspectUsersComponent } from '../../../../analytics/components/suspect-users/suspect-users.component';
import { TopDetectionsComponent } from '../../../../analytics/components/top-detections/top-detections.component';
import { MttdWidgetComponent } from '../../../../customer-dashboard/components/mttd-widget/mttd-widget.component';
import { TprWidgetComponent } from '../../../../customer-dashboard/components/tpr-widget/tpr-widget.component';
import { DetectionCountComponent } from '../../detection-count/detection-count.component';
import { SlaViolationsComponent } from '../../sla-violations/sla-violations.component';
import { TicketCountComponent } from '../../ticket-count/ticket-count.component';
import { TpRateComponent } from '../../tp-rate/tp-rate.component';
import { CyflarePeriodComponent } from '../../../../../../core/components/cyflare-period/cyflare-period.component';

@Component({
  selector: 'app-executive-summary',
  standalone: true,
  imports: [TpRateComponent, TprWidgetComponent, CyflarePeriodComponent, DistillationFunnelComponent, LabelModule, LayoutModule, TicketCountComponent, MttdWidgetComponent, SuspectUsersComponent, SuspectAssetsComponent, DestinationMapComponent, SourceMapComponent, OutcomeStatusComponent, RouterModule, SlaViolationsComponent, DetectionCountComponent, TopDetectionsComponent],
  templateUrl: './executive-summary.component.html',
  styleUrl: './executive-summary.component.scss',
})
export class ExecutiveSummaryComponent extends BaseComponent {
  private analyticsService = inject(AnalyticsService);
  public destinationMapData = signal<any>(null);
  public sourceMapData = signal<any>(null);
  public topSuspectUsersData = signal<any>(null);
  public topUserDetailData = signal<any>(null);
  public topSuspectAssetsData = signal<any>(null);
  public topAssetDetailData = signal<any>(null);
  public outcomeStatusData = signal<any>(null);
  public funnelDataGet = signal<any>(null);
  public soarParams = signal<any>(null);
  public slaData = signal<any>(null);
  public ticketsData = signal<any>(null);
  public truePositiveData = signal<any>(null);
  public topDetectionsData = signal<any>(null);

  private route = inject(ActivatedRoute);
  private orgService = inject(OrganizationService);
  private organizationName: string = 'Unknown';
  private organizationId: number | null = null;
  public formattedDateRange = signal<string>('');
  public selectedDateRange = signal<string[]>([]);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => !!params['organizationId'])).subscribe({
          next: (params) => {
            this.organizationId = +params['organizationId'];
            this.fetchOrganizationName(this.organizationId);
            this.applyFilter();
          },
          error: (error) => {
            console.error('Error processing query parameters:', error);
          },
        });
      },
      { allowSignalWrites: true },
    );
  }

  private fetchOrganizationName(organizationId: number) {
    this.orgService
      .getOrganizationById(organizationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.organizationName = response.name;
        },
        error: (error) => {
          console.error('Error fetching Organization Name:', error);
        },
      });
  }

  applyFilter() {
    const dateRange = this.selectedDateRange();
    if(!dateRange)
      return;
    
    const soarparams = {
      alert_received_on__range: dateRange.join(','),
    };
    const ticketparams = {
      created_time__range: dateRange.join(','),
    };
    this.soarParams.set(soarparams);

    const usaRegions = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming', 'American Samoa', 'Guam', 'Northern Mariana Islands', 'Puerto Rico', 'United States Minor Outlying Islands', 'Virgin Islands'];

    if (this.organizationId !== null) {
      this.analyticsService.getDestinationMap(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.destinationMapData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Destination Map:', error);
        },
      });
      this.analyticsService
        .getSourceMap(this.organizationId, soarparams)
        .pipe(map((data: any[]) => data.filter((item) => !usaRegions.includes(item.region))))
        .subscribe({
          next: (filteredResponse) => {
            this.sourceMapData.set(filteredResponse);
          },
          error: (error) => {
            console.error('Error fetching Source Map:', error);
          },
        });
      this.analyticsService.getTopDetectionData(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.topDetectionsData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top User Detail:', error);
        },
      });
      this.analyticsService.getTopSuspectUsers(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.topSuspectUsersData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top Suspect Users:', error);
        },
      });
      this.analyticsService.getTopUserDetail(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.topUserDetailData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top User Detail:', error);
        },
      });
      this.analyticsService.getTopSuspectAssets(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.topSuspectAssetsData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top Suspect Assets:', error);
        },
      });
      this.analyticsService.getTopAssetDetail(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.topAssetDetailData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top Asset Detail:', error);
        },
      });
      this.analyticsService.getOutcomeStatus(this.organizationId, ticketparams).subscribe({
        next: (response) => {
          this.outcomeStatusData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Outcome Status:', error);
        },
      });
      this.analyticsService.getFunnelData(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.funnelDataGet.set(response);
        },
        error: (error) => {
          console.error('Error fetching Funnel Data:', error);
        },
      });
      this.analyticsService.getSlaViolations(this.organizationId, soarparams).subscribe({
        next: (response) => {
          this.slaData.set(response);
        },
        error: (error) => {
          console.error('Error fetching SLA Violations:', error);
        },
      });
      this.analyticsService.getTotalTickets(this.organizationId, ticketparams).subscribe({
        next: (response) => {
          this.ticketsData.set(response);
        },
        error: (error) => {
          console.error('Error fetching New Tickets:', error);
        },
      });
      this.analyticsService.getTpr(this.organizationId, ticketparams).subscribe({
        next: (response) => {
          this.truePositiveData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Funnel Data:', error);
        },
      });
    } else {
      console.error('Organization ID is not set.');
    }
  }
}
