import { Component, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridModule, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { environment } from '../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { VulnerabilityScannerForm, VulnerabilityScannerFormService } from './service/vulnerability-form.service';
import { filter } from 'rxjs';
import { VulnerabilityScannerRequest, VulnerabilityScannerService } from './service/vulnerability.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-vulnerability',
  standalone: true,
  imports: [LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, GridModule, ReactiveFormsModule, CommonModule],
  templateUrl: './vulnerability.component.html',
  styleUrl: './vulnerability.component.scss',
})
export class VulnerabilityComponent extends BaseComponent {
  public formGroup: FormGroup<VulnerabilityScannerForm> | undefined;
  public formBuilder = inject(FormBuilder);
  public gridService = inject(VulnerabilityScannerService);
  public route = inject(ActivatedRoute);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/vulnerabilityscanner`;
  private formService = inject(VulnerabilityScannerFormService);
  private editedRowIndex: number | undefined;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadVulnerabilityScanner(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadVulnerabilityScanner(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }

  public addHandler($event: AddEvent): void {
    this.closeEditor($event.sender);
    this.formGroup = this.formService.createVulnerabilityScannerForm();
    $event.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (this.formGroup) {
      grid.closeRow(rowIndex);
      this.formGroup = undefined;
    }
  }

  saveHandler($event: SaveEvent) {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      const payload: VulnerabilityScannerRequest = {
        organization_id: this.id,
        vulnerability_scanner: {
          pk: $event.isNew ? null : formValue?.pk!,
          ip_address: formValue?.ip_address!,
          notes: formValue?.notes!,
        },
      };
      if ($event.isNew) {
        this.gridService.createVulnerabilityScanner(payload).subscribe({
          next: (response) => {
            this.loadVulnerabilityScanner(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.gridService.updateVulnerabilityScanner(payload.vulnerability_scanner).subscribe({
          next: (response) => {
            this.loadVulnerabilityScanner(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    }
  }

  public editHandler($event: EditEvent): void {
    this.formGroup = this.formService.createVulnerabilityScannerForm($event.dataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler($event: any): void {
    this.gridService.deleteVulnerabilityScanner($event.dataItem.pk).subscribe(() => {
      this.loadVulnerabilityScanner(this.id);
    });
  }
}
