import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../core/services/odata.service';
import { environment } from '../../../../../../../../../environments/environment';
import { Whitelist } from '../../../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class WhitelistService extends ODataService<WhitelistResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/organization/administrative/whitelist`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'name', dir: 'asc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public createWhitelist(request: WhitelistRequest) {
    const url = `${environment.apiUrl}/v1/organization/administrative/whitelist/`;
    return this.http.post<WhitelistResponse>(url, request);
  }

  public updateWhitelist(request: Whitelist) {
    const url = `${this.url}`;
    return this.http.patch(url, request);
  }

  public deleteWhitelist(whitelistId: number) {
    const url = `${this.url}?pk=${whitelistId}`;
    return this.http.delete(url);
  }
}

export interface WhitelistResponse extends Whitelist {}

export interface WhitelistRequest {
  organization_id: number;
  whitelist: Whitelist;
}

export interface WhitelistResponse extends WhitelistRequest {}
