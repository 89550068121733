import { Component, DestroyRef, inject, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: 'base-component',
  template: ``
})
export class BaseComponent implements OnDestroy {
  public destroyRef = inject(DestroyRef)
  public destroyed$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
