import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { User } from '../models/user';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ErrorHandlingService } from '../services/error-handling.service';

let errorMessages: string[] = [];

export const OneApiAuthInterceptor: HttpInterceptorFn = (req, next) => {
  const user = inject(User);
  const router = inject(Router);
  const notification = inject(NotificationService);
  const errorHandlingService = inject(ErrorHandlingService);


  const authReq = req.clone({
    withCredentials: true,
    setHeaders: {
      Authorization: `Bearer ${user?.access_token || ''}`,
    },
  });

  return next(authReq).pipe(
    catchError((err) => {
      console.log(err);

      let errorMessage = 'An error occurred. Please try again.';

      if (err.error) {
        if (typeof err.error === 'object') {
          errorMessage = errorHandlingService.flattenErrorMessages(err.error);
        } else {
          errorMessage = err.error;
        }
      }

      if(errorMessages.indexOf(errorMessage) < 0) {
        notification.show({
          content: errorMessage,
          cssClass: 'error-notification',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'center', vertical: 'top' },
          type: { style: 'error', icon: true },
          hideAfter: 5000,
        });

        errorMessages.push(errorMessage);
        setTimeout(() => {
          removeMessage(errorMessages, errorMessage);
        }, 5000);
      }



      console.log(errorMessages);
      if (err.status === 401) {
        console.log('authInterceptor 401');
        router.navigate(['/auth/login']);
      }

      if (err.status === 400) {
        console.log('authInterceptor 400');
        return throwError(() => new Error(errorMessage));
      }

      return throwError(() => new Error(errorMessage));
    }),
  );
};

function removeMessage(array: string[], item: string) {
  var i = array.length;

  while (i--) {
    if (array[i] === item) {
      array.splice(i, 1);
    }
  }
}
