import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../core/services/odata.service';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { SocAdvisory } from '../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class SocAdvisoryService extends ODataService<SocAdvisoryResponse> {
  constructor() {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory/`;
    super(inject(HttpClient), url);

    this.state = {
      skip: 0,
      take: 25,
      sort: [{ field: 'created_at', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public getSocAdvisoryItems() {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory/`;
    return this.http.get<{ value: SocAdvisoryResponse[] }>(url).pipe(map((response) => response.value));
  }

  public addSocAdvisoryItem(advisory: any) {
    let payload = {
      title: advisory.title,
      advisory_for: advisory.advisory_for,
      content: advisory.content,
    };
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory/`;
    return this.http.post(this.url, payload);
  }

  public updateSocAdvisoryItem(pk: any, request: any) {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory(${pk})/`;
    return this.http.put(url, request);
  }

  public deleteSocAdvisoryItem(pk: number) {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory(${pk})/`;
    return this.http.delete(url);
  }

  public getAdvisoryById(pk: any) {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory(${pk})/`;
    return this.http.get<SocAdvisoryResponse>(url).pipe(map((response) => response));
  }

  public publishAdvisory(pk: any, payload: any) {
    const url = `${environment.apiUrl}/v1/advisory/administrative/advisory(${pk})/publish/`;
    return this.http.post<SocAdvisoryRequest>(url, payload);
  }
}

export interface SocAdvisoryRequest {
  pk: number | null;
  title: string | null;
  advisory_for: string[];
  content: string | null;
}

export interface SocAdvisoryResponse extends SocAdvisory {}
