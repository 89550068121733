import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule, SVGIcon } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { OrgAppbarComponent } from '../../admin/layout/components/org-appbar/org-appbar.component';
import { CalltreeComponent } from '../../admin/organizations/components/soc-comms/components/calltree/calltree.component';
import { OncallComponent } from '../../admin/organizations/components/soc-comms/components/oncall/oncall.component';
import { TicketingComponent } from '../../admin/organizations/components/soc-comms/components/ticketing/ticketing.component';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { infoCircleIcon } from '@progress/kendo-svg-icons';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-customer-soc-comms',
  standalone: true,
  imports: [FormsModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, ReactiveFormsModule, DateInputsModule, GridModule, OrgAppbarComponent, TicketingComponent, OncallComponent, CalltreeComponent],
  templateUrl: './customer-soc-comms.page.html',
  styleUrl: './customer-soc-comms.page.scss',
})
export class CustomerSocCommsPage extends BaseComponent {
  public infoCircleIcon: SVGIcon = infoCircleIcon;
  constructor() {
    super();
  }
}
