<kendo-grid
  [data]="gridService | async"
  [pageSize]="5"
  [pageable]="true"
  [sortable]="true"
  [navigable]="true"
  [loading]="gridService.loading"
  (remove)="removeHandler($event)"
  (save)="saveHandler($event)"
  (edit)="editHandler($event)"
  (add)="addHandler($event)"
  (cancel)="cancelHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      #adminorcustomer_organizations_soccommunications_ticketingemails_addemail
      kendoGridAddCommand
      style="margin-left: auto"
    >
      Add Email
    </button>
  </ng-template>
  <kendo-grid-column
    field="pk"
    title="ID"
    [hidden]="true"
  ></kendo-grid-column>
  <kendo-grid-column
    field="email"
    title="Email"
  ></kendo-grid-column>
  <kendo-grid-column
    field="is_primary"
    editor="boolean"
    title="Primary"
  ></kendo-grid-column>
  <kendo-grid-command-column title="Action">
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
    >
      <button
        #adminorcustomer_organizations_soccommunications_ticketingemails_remove
        kendoGridRemoveCommand
      >
        Remove
      </button>
      <button
        #adminorcustomer_organizations_soccommunications_ticketingemails_edit
        kendoGridEditCommand
      >
        Edit
      </button>
      <button
        kendoGridSaveCommand
        [id]="isNew ? 'adminorcustomer_organizations_soccommunications_ticketingemails_add' : 'adminorcustomer_organizations_soccommunications_ticketingemails_save'"
      >
        {{ isNew ? 'Add' : 'Save' }}
      </button>
      <button
        #adminorcustomer_organizations_soccommunications_ticketingemails_discard
        kendoGridCancelCommand
      >
        Discard
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
