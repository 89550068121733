<div class="alert-preferences-container">
  @if (totalRecords === 0) {
  <div class="alert-preferences-empty-state"></div>
  } @else {
  <div class="filter-controls">
    <div class="tag-filter-container">
      <button
        #tagFilterAnchor
        kendoButton
        [toggleable]="true"
        [(selected)]="showTagFilter"
        class="tag-filter-button"
      >
        Filter by tag {{ selectedTags.length ? '(' + selectedTags.length + ')' : '' }}
      </button>

      @if (showTagFilter) {
      <kendo-popup
        [anchor]="tagFilterAnchor"
        [animate]="false"
        (close)="showTagFilter = false"
        [popupClass]="'tag-filter-popup'"
      >
        <kendo-multiselect
          style="width: 400px"
          #detectionTagsRef
          [(ngModel)]="selectedTags"
          [filterable]="true"
          [data]="availableTags()"
          textField="name"
          valueField="id"
          [valuePrimitive]="false"
          (valueChange)="filterDetetections()"
          (filterChange)="onTagFilterChange($event)"
        ></kendo-multiselect>
      </kendo-popup>
      }
    </div>

    <kendo-label
      class="ms-3 me-1"
      text="Show new only"
      labelCssClass="k-form-label"
    ></kendo-label>
    <kendo-switch
      class="filter-switch"
      [(ngModel)]="showNewOnly"
      (valueChange)="filterDetetections()"
    ></kendo-switch>
  </div>
  <hr />
  <div>
    <div
      class="k-card-list"
      (scroll)="onScroll($event)"
    >
      @for (detection of filteredDetections(); track detection.detectionId) {
      <div>
        <kendo-card class="k-card-item">
          <kendo-card-header>
            <div class="card-header-content">
              <h5>{{ detection.title }}</h5>
              @if (detection.isNew) {
              <kendo-chip
                size="small"
                label="New"
              ></kendo-chip>
              }
            </div>
          </kendo-card-header>
          <kendo-card-body>
            <p>{{ detection.description }}</p>
            <p>
              @for (tag of detection.tags; track tag.id) {
              <kendo-chip
                size="small"
                [label]="tag.name"
                class="me-1 mb-1"
              ></kendo-chip>
              }
            </p>
          </kendo-card-body>
          <kendo-card-footer>
            <kendo-switch
              [checked]="detection.active"
              onLabel="Active"
              offLabel="Muted"
              (valueChange)="onToggleChange(detection, $event)"
              [disabled]="updatingDetectionId() === detection.detectionId"
            ></kendo-switch>
          </kendo-card-footer>
        </kendo-card>
      </div>
      }
    </div>
  </div>
  }
</div>

@if (isLoading()) {
<div class="loading-overlay">
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
</div>
}
