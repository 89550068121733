import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../core/services/odata.service';
import { environment } from '../../../../../environments/environment';
import { Case } from '../../../../core/models/entities';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { FilterDescriptor } from '@progress/kendo-data-query';

@Injectable({ providedIn: null })
export class TicketsService extends ODataService<Case> {
  private serviceUrl: string;
  private field = 'created_time';
  constructor() {
    const url = `${environment.apiUrl}/v1/analytics/administrative/ticket`;
    super(inject(HttpClient), url);
    this.serviceUrl = url;

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'created_time', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }

  public setDateRange(dateRange: string[] | null) {
    const filters = this.state.filter?.filters.filter((f) => (f as FilterDescriptor).field !== this.field) || [];

    if (dateRange) {
      filters.push({
        field: this.field,
        operator: 'gt',
        value: dateRange[0],
      });

      filters.push({
        field: this.field,
        operator: 'lt',
        value: dateRange[1],
      });
    }

    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'created_time', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: filters,
      },
    };
  }

  public getTickets(organizationId: number | null) {
    this.url = organizationId ? this.serviceUrl + `(${organizationId})/` : this.serviceUrl;
    return this.read();
  }
}

@Injectable({ providedIn: null })
export class TicketFilterService {
  private http = inject(HttpClient);

  getPriorities(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/ticketpriority(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  getSlaViolations(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/ticketslaviolationtype(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  getCustomerDefinedStatus(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/customerdefinedstatus(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  getTicketSources(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/tickersource(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  getTicketStatuses(organizationId: string): Observable<FilterItem[]> {
    if (!organizationId) {
      return of([]); // Return an empty array if organizationId is empty
    }
    return this.http.get<any>(`${environment.apiUrl}/v1/analytics/administrative/ticketstatus(${organizationId})/`).pipe(
      map((response) => response.results[0].value),
      catchError(this.handleError),
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }
}

interface FilterItem {
  text: string;
  value: string | number;
}
