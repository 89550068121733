import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Whitelist } from '../../../../../../../../core/models/entities';

export interface WhitelistForm {
  pk: FormControl<string | null>;
  ip_address: FormControl<string | null>;
  file_hash: FormControl<string | null>;
  domain: FormControl<string | null>;
  analyst_notes: FormControl<string | null>;
}

@Injectable({
  providedIn: 'root',
})
export class WhitelistFormService {
  public createWhitelistForm(whitelist = new Whitelist()) {
    return new FormGroup<WhitelistForm>({
      pk: new FormControl(whitelist.pk, Validators.required),
      ip_address: new FormControl(whitelist.ip_address, Validators.required),
      file_hash: new FormControl(whitelist.file_hash, Validators.required),
      domain: new FormControl(whitelist.domain, Validators.required),
      analyst_notes: new FormControl(whitelist.analyst_notes, Validators.required),
    });
  }
}
