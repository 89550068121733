import { ChangeDetectionStrategy, Component, effect, inject, output, signal, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IngestorRecord, IngestorService } from '../../../../../services/ingestor.service';
import { BaseComponent } from '../../../../../../../../../../core/shared/common/base.component';
import { RecordFormService } from './services/record-form.service';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { VirtualDropdownComponent } from '../../../../../../../../../../core/components/virtual-dropdown/virtual-dropdown.component';
import { Organization } from '../../../../../../../../../../core/models/entities';
import { OrganizationService } from '../../../../../../../services/organization.service';
import { takeUntil } from 'rxjs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-record',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    DropDownsModule,
    DialogsModule,
    CommonModule,
    VirtualDropdownComponent,
    LayoutModule,
  ],
  providers: [
    NotificationService,
    RecordFormService,
    {
      provide: OrganizationService,
      useClass: OrganizationService,
    },
    {
      provide: IngestorService,
      useClass: IngestorService,
    }
  ],
  templateUrl: './record.component.html',
  styleUrl: './record.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordComponent extends BaseComponent {
  @ViewChild('virtualDropdown', { static: false }) orgDropDown: VirtualDropdownComponent<Organization> | undefined;

  public defaultItem = { name: 'Select Organization', id: 0 };
  private forms = inject(RecordFormService);
  public orgService = inject(OrganizationService);
  public injestorService = inject(IngestorService);
  public injestorRecord = signal<IngestorRecord>(new IngestorRecord());
  public xIcon: SVGIcon = xIcon;
  public recordForm = this.forms.init();
  public opened = false;
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  close = output<void>();

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.activatedRoute.queryParams.subscribe(params => {
        let record = this.injestorRecord();
        record.ingestor = parseInt(params['ingestorIntegrationId']);
        this.recordForm.patchValue({
          ingestor: record.ingestor,
          integration_url: record.integration_url,
          organization_parent: record.organization_parent,
          api_key: record.api_key,
        });
      });
    });
  }

  save($event: any) {
    $event.preventDefault();
    this.injestorService
      .addIngestorRecord(this.recordForm.value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.router.navigate(['../map-accounts'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
          });
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  clear() {
    console.log('clear');
  }

  public cancel() {
    this.recordForm.reset();
    this.close.emit();
  }

  public closeDialog(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }
}
