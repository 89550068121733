import { Component } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-ticket-correlation',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './ticket-correlation.component.html',
  styleUrl: './ticket-correlation.component.scss',
})
export class TicketCorrelationComponent {
  ticketCorrelationData = [
    {
      Search_query: 'Search for duplicate open tickets by username',
      results: '0',
    },
    {
      Search_query: 'Search for duplicate open tickets by IP',
      results: '0',
    },
  ];
}
