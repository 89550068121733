export const detectionData = [
  {
    name: 'Potential PHP RCE Attempt',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2024, 5, 8),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potentially Malicious Windows Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2024, 5, 8),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'OilRig APT Scheduled Task Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Scheduled Task/Job',
    mitreTechniqueId: 'T1053',
    connector: null,
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'OilRig APT Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Create or Modify System Process',
    mitreTechniqueId: 'T1543',
    connector: null,
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'custom_oilrig_apt_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Kapeka Backdoor Loaded Via Rundll32',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'System Binary Proxy Execution',
    mitreTechniqueId: 'T1218',
    connector: null,
    dateActivated: new Date(2024, 5, 4),
    xdrEventName: 'custom_kapeka_backdoor_loaded_via_rundll32',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Jumpcloud Radius Brute Force Attempt',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'JumpCloud',
    dateActivated: new Date(2024, 5, 4),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Multiple JumpCloud Admin Login Failures',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'JumpCloud',
    dateActivated: new Date(2024, 5, 4),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Jumpcloud MFA Push Failure',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'JumpCloud',
    dateActivated: new Date(2024, 5, 4),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Exploitation of CVE-2022-42475',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: 'Fortigate',
    dateActivated: new Date(2024, 4, 14),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Duo User Bypass Status Update',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'Duo',
    dateActivated: new Date(2024, 3, 25),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Jumpcloud Command Run',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'JumpCloud',
    dateActivated: new Date(2024, 3, 24),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Jumpcloud Login Outside the US',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'JumpCloud',
    dateActivated: new Date(2024, 3, 24),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Linux Recon Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Gather Victim Host Information',
    mitreTechniqueId: 'T1592',
    connector: null,
    dateActivated: new Date(2024, 3, 24),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS Access Key Created',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Create Account',
    mitreTechniqueId: 'T1136',
    connector: 'AWS',
    dateActivated: new Date(2024, 3, 22),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS Console Successful Logins Outside the US',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 3, 21),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential DarkGate Loader',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2024, 3, 18),
    xdrEventName: 'custom_potential_darkgate_loader',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Raspberry Robin CPL Execution Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'System Binary Proxy Execution',
    mitreTechniqueId: 'T1218',
    connector: null,
    dateActivated: new Date(2024, 3, 17),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace MFA Disabled',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 3, 17),
    xdrEventName: 'custom_google_workspace_mfa_disabled',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Metasploit Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Resource Development',
    mitreTacticId: 'TA0042',
    mitreTechnique: 'Obtain Capabilities',
    mitreTechniqueId: 'T1588',
    connector: null,
    dateActivated: new Date(2024, 3, 16),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'SonicFW Brute Force Attempt',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: 'Sonic FW',
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Outbytes Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Automated Exfiltration',
    mitreTechniqueId: 'T1020',
    connector: null,
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'custom_outbyte_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'High Fidelity External URL Scanning',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2024, 3, 10),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Emerging Threat',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Intel',
    mitreTacticId: 'XTA0005',
    mitreTechnique: 'XDR Emerging Threat',
    mitreTechniqueId: 'XT5003',
    connector: null,
    dateActivated: new Date(2024, 3, 8),
    xdrEventName: 'custom_emerging_threat',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Sophos EDR Events',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'User Execution: Malicious File',
    mitreTechniqueId: 'T1204',
    connector: null,
    dateActivated: new Date(2024, 3, 8),
    xdrEventName: 'custom_sophos_edr',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Suspicious Login',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'GSuite',
    dateActivated: new Date(2024, 3, 8),
    xdrEventName: 'custom_google_workspace_suspicious_login',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Forest Blizzard Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Scheduled Task/Job',
    mitreTechniqueId: 'T1053',
    connector: null,
    dateActivated: new Date(2024, 3, 7),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Impossible Travel',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: null,
    dateActivated: new Date(2024, 3, 6),
    xdrEventName: 'custom_user_impossible_travel',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Token Theft',
    subitems: null,
    defaultStatus: 'Testing',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Access Token Manipulation',
    mitreTechniqueId: 'T1134',
    connector: null,
    dateActivated: new Date(2024, 3, 2),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential ArcaneDoor Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over C2 Channel',
    mitreTechniqueId: 'T1041',
    connector: null,
    dateActivated: new Date(2024, 3, 1),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS CloudTrail Important Change',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Cloud Compute Infrastructure',
    mitreTechniqueId: 'T1578',
    connector: 'AWS',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS Config Disabling Channel/Recorder',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Cloud Compute Infrastructure',
    mitreTechniqueId: 'T1578',
    connector: 'AWS',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS IAM S3Browser User or AccessKey Creation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Create Account',
    mitreTechniqueId: 'T1136',
    connector: 'AWS',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS Identity Center Identity Provider Change',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'AWS',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS SecurityHub Findings Evasion',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: 'AWS',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Restore Public AWS RDS Instance',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Cloud Compute Infrastructure',
    mitreTechniqueId: 'T1578',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 2, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Application Access Level Modified',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'custom_google_workspace_application_access_level_modified',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Application Removed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Destruction',
    mitreTechniqueId: 'T1485',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'custom_google_workspace_application_removed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Granted Domain API Access',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'custom_google_workspace_granted_domain_api_access',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Role Modified or Deleted',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'custom_google_workspace_role_modified_or_deleted',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace Role Privilege Deleted',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Google Workspace User Granted Admin Privileges',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Abuse Elevation Control Mechanism',
    mitreTechniqueId: 'T1548',
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2024, 2, 6),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS - Malicious Host Access',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: 'AWS',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_AWS - Malicious Host Access',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS - Root logon detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'AWS',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_AWS - Root logon detected',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'AWS Not MFA Authenticated',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Trusted Relationship',
    mitreTechniqueId: 'T1199',
    connector: 'AWS',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_AWS Not MFA Authenticated',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Azure - Successful Login Outside the US',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_azure_successful_login_outside_the_us',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Azure AD Risk Detection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_azure_ad_risk_detection',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Azure AD Sign in from AzureHound',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Azure User Added to Global Admin Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_azure_user_added_to_global_admin_group',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Bifrost Malware VMWare Domain',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Remote Access Software',
    mitreTechniqueId: 'T1219',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Bitrat Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Remote Access Software',
    mitreTechniqueId: 'T1219',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Bitrat Activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Bloodhound and Sharphound Hack Tools Detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Automated Collection',
    mitreTechniqueId: 'T1119',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Box - Change Admin Role',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Box',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_box_change_admin_role',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Box - Content Workflow Policy Violation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data Staged',
    mitreTechniqueId: 'T1074',
    connector: 'Box',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Box - File Marked Malicious',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Miscellaneous Malware',
    mitreTechniqueId: 'XT6001',
    connector: 'Box',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_box_file_marked_malicious',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Box - Information Barrier Disabled',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Resource Development',
    mitreTacticId: 'TA0042',
    mitreTechnique: 'Compromise Infrastructure',
    mitreTechniqueId: 'T1584',
    connector: 'Box',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Box - Shield Alert',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Anomaly',
    mitreTechniqueId: 'XT1000',
    connector: 'Box',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_box_shield_alert',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'CVE-2023-29298 - Cold Fusion',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'External Remote Services',
    mitreTechniqueId: 'T1133',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_cold_fusion_vulnerability_cve_2023_29298',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Cisco Umbrella Malicious Site Access',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Intel',
    mitreTacticId: 'XTA0005',
    mitreTechnique: 'XDR Bad Reputation',
    mitreTechniqueId: 'XT2010',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_cisco_umbrella_malicious_site_access',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Account Lockout Event',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Conditional Access Blocked Login',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_conditional_access_blocked_login',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'ConnectWise Vulnerability',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Forced Authentication',
    mitreTechniqueId: 'T1187',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'DNS Exfiltration Tools Execution Detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Web Service',
    mitreTechniqueId: 'T1567',
    connector: null,
    dateActivated: new Date(2024, 0, 12),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'DPAPI Domain Backup Key Extraction',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Darktrace Detection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Intel',
    mitreTacticId: 'XTA0005',
    mitreTechnique: 'XDR Threat Intelligence',
    mitreTechniqueId: 'XT5000',
    connector: 'Darktrace',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_darktrace',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Data Exfiltration to Text Storage Sites and Cloud Storage',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Web Service',
    mitreTechniqueId: 'T1567',
    connector: null,
    dateActivated: new Date(2024, 0, 12),
    xdrEventName: 'custom_data_exfiltration_to_text_storage_sites_and_cloud_storage',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Data Ingestion Anomaly for Network and Security Sensors',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Duo Login Outside the US',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Duo',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_duo_login_attempt_outside_the_us',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Duo Security Failed Authentication',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Duo',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_duo_security_failed_authentication',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Exfiltration and Tunneling Tools Execution',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Web Service',
    mitreTechniqueId: 'T1567',
    connector: null,
    dateActivated: new Date(2024, 0, 12),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Fortigate FW Brute Force Attempt',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: 'FortiGate',
    dateActivated: new Date(2024, 5, 10),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'GSuite - Account Security Settings Disabling',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_GSuite - Account Security Settings Disabling',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'GSuite - Admin Privilege Granted',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Abuse Elevation Control Mechanism',
    mitreTechniqueId: 'T1548',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'GSuite - Two Factor Auth Failure',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Trusted Relationship',
    mitreTechniqueId: 'T1199',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Gsuite - Successful Login Outside the US',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'GSuite',
    dateActivated: new Date(2024, 6, 9),
    xdrEventName: null,
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Kerberos Replay Attack Detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Steal or Forge Kerberos Tickets',
    mitreTechniqueId: 'T1558',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_kerberos_replay_attack_detected',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Login RCE Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'ML_Disconnected_Sensors',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Multiple Login Failures from One Source (Destination IP)',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_multiple_login_failures_from_one_destination_ip',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Multiple Login Failures from One Source (Source IP)',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_multiple_login_failures_from_one_source',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Multiple Login Failures from One Source (Workstation)',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_multiple_login_failures_from_one_workstation',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Multiple Users Deleted',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_multiple_users_deleted',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - A potentially malicious URL click was detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_o365_a_potentially_malicious_url_click_was_detected',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Elevation of Exchange admin privilege',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - Elevation of Exchange admin privilege',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Creation of forwarding or redirect rule',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Email Collection',
    mitreTechniqueId: 'T1114',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - Creation of forwarding or redirect rule',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - File Malware Detected',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'User Execution',
    mitreTechniqueId: 'T1204',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_o365_file_malware_detected',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Malware campaign detected after delivery',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'User Execution',
    mitreTechniqueId: 'T1204',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Malware campaign detected in SharePoint and OneDrive',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'User Execution',
    mitreTechniqueId: 'T1204',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Phish delivered due to an IP allow policy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - Phish delivered due to an IP allow policy',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Successful Login Outside the US',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_o365_successful_login_outside_the_us',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Suspicious connector activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Connection Proxy',
    mitreTechniqueId: 'T0884',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Suspicious email forwarding activity',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Email Collection',
    mitreTechniqueId: 'T1114',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Suspicious email sending patterns detected',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - Suspicious email sending patterns detected',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Tenant restricted from sending email',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Unusual external user file activity',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'User Execution',
    mitreTechniqueId: 'T1204',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - Unusual volume of external file sharing',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Data Transfer Size Limits',
    mitreTechniqueId: 'T1030',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - Unusual volume of external file sharing',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - User impersonation phish delivered to inbox or folder',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impersonation',
    mitreTechniqueId: 'T1656',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'O365 - User restricted from sending email',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_O365 - User restricted from sending email',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Office 365 Multi-factor Authentication Disabled',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_o365_multi_factor_authentication_disabled',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Okta - Attempt to Deactivate Okta Application',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Destruction',
    mitreTechniqueId: 'T1485',
    connector: 'Okta',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Okta - Attempt to Deactivate Okta Application',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Okta - Attempt to Deactivate Okta Policy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Okta',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_okta_attempt_to_deactivate_okta_policy',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Okta - Attempt to Modify Okta Policy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Okta',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Okta - Attempt to Modify Okta Policy',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Okta - Threat Detected by Okta Threat Insight',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Anomaly',
    mitreTechniqueId: 'XT1000',
    connector: 'Okta',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Okta - Threat Detected by Okta Threat Insight',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Okta Login Outside the US',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Okta',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_okta_login_outside_the_us',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Possible Emotet Evasion Techniques',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Direct Volume Access',
    mitreTechniqueId: 'T1006',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Possible Realtek SDK Vulnerability Exploit',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Supply Chain Compromise',
    mitreTechniqueId: 'T1195',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Possible Volt Typhoon Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Masquerading',
    mitreTechniqueId: 'T1036',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Apache ActiveMQ Exploit Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Rootkit',
    mitreTechniqueId: 'T1014',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential IceID compromise',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Exploitation for Credential Access',
    mitreTechniqueId: 'T1212',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Linux ESXiRansomware Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'File and Directory Discovery',
    mitreTechniqueId: 'T1083',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential ManageEngine RCE Attempt',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Outlook Vulnerability Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Server Software Component',
    mitreTechniqueId: 'T1505',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Royal Ransomware Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Encrypted for Impact',
    mitreTechniqueId: 'T1486',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential ScreenConnect Vulnerability Exploitation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Remote Access Software',
    mitreTechniqueId: 'T1219',
    connector: null,
    dateActivated: new Date(2024, 7, 10),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Shell Shock User Agent Request',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Gather Victim Host Information',
    mitreTechniqueId: 'T1592',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_potential_shell_shock_user_agent_request',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential Windows ESXiRansomware Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'File and Directory Discovery',
    mitreTechniqueId: 'T1083',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Pupyrat Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'No Alerts',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Sensor Not Receiving Logs',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Agent Ingestion Reduced',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'SoftPerfect Network Scanner Execution',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_softperfect_network_scanner_execution',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Sophos EDR Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Miscellaneous Malware',
    mitreTechniqueId: 'XT6001',
    connector: 'Sophos',
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Suspicious PowerShell Script Detection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_suspicious_powershell_script_detection',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Suspicious Process Creation Commandline Detection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Signed Binary Proxy Execution',
    mitreTechniqueId: 'T1218',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_suspicious_process_creation_commandline_detection',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Suspicious Scan Loop on Network',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Remote System Discovery',
    mitreTechniqueId: 'T1018',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_suspicious_scan_loop_on_network',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Suspicious User Agent Detection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_suspicious_user_agent_detection',
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Account Lockout Event',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Audit Log Cleared',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Indicator Removal on Host',
    mitreTechniqueId: 'T1070',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Domain Policy Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows Domain Policy Changed',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Security-Enabled Global Group Created',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows Security-Enabled Global Group Created',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Security-Enabled Local Group Created',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows Security-Enabled Local Group Created',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows Security-Enabled Universal Group Created',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows Security-Enabled Universal Group Created',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Added to Security-Enabled Global Domain Admins Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows User Added to Security-Enabled Global Domain Admins Group',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Global - Windows User Added to Security-Enabled Local Admininstrators Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: null,
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Added to Security-Enabled Local Administrators Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Global - Windows User Added to Security-Enabled Local Administrators Group',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Added to Universal Group - Enterprise Admins',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows User Added to Universal Group - Enterprise Admins',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Added to Universal Group - Schema Admins',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_Windows User Added to Universal Group - Schema Admins',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Removed from Domain Admins Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_windows_user_removed_from_domain_admins_group',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Windows User Removed from Local Administrators Group',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: null,
    dateActivated: new Date(2023, 11, 30),
    xdrEventName: 'custom_windows_user_removed_from_local_administrators_group',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Potential APT29 Related Scheduled Tasks',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Scheduled Task/Job',
    mitreTechniqueId: 'T1053',
    connector: null,
    dateActivated: new Date(2023, 10, 27),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Cyflare',
  },
  {
    name: 'Huntress Incident',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Huntress',
    dateActivated: new Date(2024, 7, 5),
    xdrEventName: 'huntress_incident-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Suspicious Sign-in Activity',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'azure_suspicious_sign_in_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Sign-in Failures',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_sign_in_failures',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra ID Discovery Using AzureHound',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Account Discovery',
    mitreTechniqueId: 'T1087',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_discovery_using_azurehound',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Changes to Privileged Role Assignment',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_changes_to_privileged_role_assignment',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra PIM Setting Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_pim_setting_changed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Application Configuration Changes',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_application_configuration_changes',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Unusual Account Creation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_unusual_account_creation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Application Permission Changes',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'azure_application_permission_changes',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Suspicious Changes to Conditional Access Policy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_suspicious_changes_to_conditional_access_policy',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Guest User Invited By Non Approved Inviters',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_guest_user_invited_by_non_approved_inviters',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Domain Federation Settings Modified',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_federation_modified',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Privileged Account Assignment or Elevation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_privileged_account_assignment_or_elevation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Changes to Privileged Account',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_changes_to_privileged_account',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Bitlocker Key Retrieval',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Credentials from Password Stores',
    mitreTechniqueId: 'T1555',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_bitlocker_key_retrieval',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Entra Changes to Device Registration Policy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Azure AD',
    dateActivated: new Date(2024, 6, 1),
    xdrEventName: 'azure_changes_to_device_registration_policy',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious LSASS Process Access',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'suspicious_process_access_lsass',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Network Connection',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'suspicious_windows_network_connection',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Registry Event: Impact',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'suspicious_windows_registry_event_impact',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Registry Event: Persistence',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2024, 3, 15),
    xdrEventName: 'suspicious_windows_registry_event_persistence',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Scanner Behavior Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_scan_anomalies',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Scanner Reputation Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'scanner_rep',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External URL Reconnaissance Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_url_scan',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External IP / Port Scan Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_port_scan',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Handshake Failure',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Reconnaissance',
    mitreTacticId: 'TA0043',
    mitreTechnique: 'Active Scanning',
    mitreTechniqueId: 'T1595',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_handshake_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Private to Public Exploit Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'exploit_attempt_pub_priv',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Public to Private Exploit Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'exploit_attempt_pub_priv',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Public to Public Exploit Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'exploit_attempt_pub_pub',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SQL Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_mysql_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SMB Read Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_smb_read_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Possible Encrypted Phishing Site Visit',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'encrypted_phishing_site',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Possible Unencrypted Phishing Site Visit',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'unencrypted_phishing_site',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Phishing URL',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'phishing',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Possible Phishing Site Visit from Email',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'email_recent_domain_correlation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External User Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_user_login_fail',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Account Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_cloud_account_login_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Account Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_cloud_account_login_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Protocol Account Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_protocol_account_login_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Credential Stuffing',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_credential_stuffing',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External RDP Brute Force Attack',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_rdp_brute_forcer',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Google Workspace Attack Warning',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'gsuite_attack_warning',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SMB Username Enumeration',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_smb_user_scan',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Brute-Forced Successful User Login',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_user_success_brute_forcer',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bad Source Reputation Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Bad Reputation',
    mitreTechniqueId: 'XT2010',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'srcip_bad_reps',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Outbound Destination Country Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Location Anomaly',
    mitreTechniqueId: 'XT2001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'country_communication_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Suspected Malicious User Agent',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR User Agent Anomaly',
    mitreTechniqueId: 'XT2012',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Firewall Denial Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Firewall Anomaly',
    mitreTechniqueId: 'XT2002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_fw_action',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Firewall Policy Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Firewall Anomaly',
    mitreTechniqueId: 'XT2002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_fw_policy_id',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Application Usage Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR App Anomaly',
    mitreTechniqueId: 'XT2003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_user_uncommon_app',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Uncommon Application Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR App Anomaly',
    mitreTechniqueId: 'XT2003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'network_uncommon_app',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'WAF Rule Violation Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Rule Violation',
    mitreTechniqueId: 'XT2004',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'waf_rule_violation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bad Reputation Login',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Bad Reputation',
    mitreTechniqueId: 'XT2010',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'bad_reputation_login',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Plain Text Passwords Detected',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Clear Password',
    mitreTechniqueId: 'XT2006',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_clear_password',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Malicious Site Access',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Bad Reputation',
    mitreTechniqueId: 'XT2010',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'mal_access',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Recently Registered Domains',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR New Domain',
    mitreTechniqueId: 'XT2008',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'new_registered_domain',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External RDP Suspicious Outbound',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR App Anomaly',
    mitreTechniqueId: 'XT2003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_rdp_suspicious_outbound',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Exploited Vulnerability',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Exploitated Vulnerability',
    mitreTechniqueId: 'XT2015',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_vuln_exploit_correlation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Exploited C&C Connection',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Command and Control Connection Exploitation',
    mitreTechniqueId: 'XT2014',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'exploit_attempt_correlation',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'User Login Location Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Location Anomaly',
    mitreTechniqueId: 'XT2001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'user_login_region',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Impossible Travel Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Location Anomaly',
    mitreTechniqueId: 'XT2001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'user_impossible_travel',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External User Application Usage Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR App Anomaly',
    mitreTechniqueId: 'XT2003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_user_uncommon_app',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External User Data Volume Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Bytes Anomaly',
    mitreTechniqueId: 'XT3001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_user_bytes_sum',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Login Time Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'Login Time Anomaly',
    mitreTechniqueId: 'XT4005',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'user_login_time.',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Google Workspace Suspicious Activities',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Login Anomaly',
    mitreTechniqueId: 'XT4006',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'gsuite_suspicious_activities',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Google Workspace User Suspended',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Account Anomaly',
    mitreTechniqueId: 'XT4007',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'gsuite_user_suspended',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Google Workspace Account Manipulation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Account Anomaly',
    mitreTechniqueId: 'XT4007',
    connector: 'GSuite',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'gsuite_account_manipulation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Blocked User',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Suspicious User',
    mitreTechniqueId: 'XT4008',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_blocked_user',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Data Ingestion Volume Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR SBA',
    mitreTacticId: 'XTA0003',
    mitreTechnique: 'XDR Bytes Anomaly',
    mitreTechniqueId: 'XT3001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ade_outbytes_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Sensor Status Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'XDR SBA',
    mitreTacticId: 'XTA0003',
    mitreTechnique: 'XDR Bytes Anomaly',
    mitreTechniqueId: 'XT3001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ade_outbytes_anomaly_flip',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External IDS Signature Spike',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_ids_signature_spike',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Password Spraying',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_password_spray',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Password Spraying Attempts Using Dsacls',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'System Binary Proxy Execution',
    mitreTechniqueId: 'T1218',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'password_spraying_attempts_using_dsacls',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Command Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SQL Shell Command',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_database_command',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SQL Shell Command',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_database_command',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Encoded PowerShell',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'encoded_powershell',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'PowerShell Remote Access',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'powershell_cnc',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'SMB Specific Service Installation',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'System Services',
    mitreTechniqueId: 'T1569',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'smb_hack_smbexec',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'SMB Impacket Lateralization',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Windows Management Instrumentation',
    mitreTechniqueId: 'T1047',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'smb_impacket_lateralization',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Azure AD Apps Modified To Allow Multi-Tenant Access',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'azure_ad_add_app_multitenant',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Content Filter Policy Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_content_filter_policy_changed',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Network Security Configuration Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_security_conf_changed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Sharing Policy Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_sharing_policy_changed',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 User Network Admin Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_user_network_admin_changed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'AWS Logging Stopped',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'aws_stoplogging',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Backup Catalogs Deleted by Ransomware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Indicator Removal on Host',
    mitreTechniqueId: 'T1070',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ransomware_delete_backup_catalogs',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Azure AD Custom Domains Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'azure_ad_change_domain',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Admin Audit Logging Disabled',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_admin_audit_logging_disabled',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Malware Filter Policy Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_malware_filter_policy_changed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Password Policy Changed',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Authentication Process',
    mitreTechniqueId: 'T1556',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_password_policy_changed',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Port Opening',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_port_opening',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Registry Modification',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Registry',
    mitreTechniqueId: 'T1112',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_registry_modification',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Settings Hijacking',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Modify Registry',
    mitreTechniqueId: 'T1112',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_settings_hijack',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Non-Standard Port Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Non-Standard Port',
    mitreTechniqueId: 'T1571',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_non_std_port_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'DGA',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Dynamic Resolution',
    mitreTechniqueId: 'T1568',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'dga_resolvable',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Reverse Tunnel',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Protocol Tunneling',
    mitreTechniqueId: 'T1572',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_reverse_tunnel',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Suspicious Logon',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Protocol Tunneling',
    mitreTechniqueId: 'T1572',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_suspicious_logon',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Encrypted C&C',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Command and Control',
    mitreTacticId: 'TA0011',
    mitreTechnique: 'Encrypted Channel',
    mitreTechniqueId: 'T1573',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ssl_certificate',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Other Malware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Miscellaneous Malware',
    mitreTechniqueId: 'XT6001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_malware_activity',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External PUA',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR PUA',
    mitreTechniqueId: 'XT6002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_pua',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Spyware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Spyware',
    mitreTechniqueId: 'XT6003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_spyware_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Trojan',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Trojan',
    mitreTechniqueId: 'XT6004',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_trojan_activity',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Command & Control Reputation Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Intel',
    mitreTacticId: 'XTA0005',
    mitreTechnique: 'XDR Command and Control Reputation',
    mitreTechniqueId: 'XT5001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'cnc_reputation',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bad Destination Reputation Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR Intel',
    mitreTacticId: 'XTA0005',
    mitreTechnique: 'XDR Bad Reputation',
    mitreTechniqueId: 'XT2010',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'dstip_bad_reps',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Process Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR Process Anomaly',
    mitreTechniqueId: 'XT1001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'bad_process',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Abnormal Parent / Child Process',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Event Triggered Execution',
    mitreTechniqueId: 'T1546',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'parent_child',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'File Creation Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR File Anomaly',
    mitreTechniqueId: 'XT1003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'file_creation',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Uncommon Process Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR Process Anomaly',
    mitreTechniqueId: 'XT1001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'network_uncommon_process',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'User Process Usage Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR Process Anomaly',
    mitreTechniqueId: 'XT1001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'user_uncommon_process',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Carbon Black:XDR Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR Anomaly',
    mitreTechniqueId: 'XT1000',
    connector: 'Carbon Black',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'carbonblack_edr_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'CylanceOPTICS:XDR Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'XDR EBA',
    mitreTacticId: 'XTA0001',
    mitreTechnique: 'XDR Anomaly',
    mitreTechniqueId: 'XT1000',
    connector: 'Cylance',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'cylance_edr_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Scanner Behavior Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Network Service Scanning',
    mitreTechniqueId: 'T1046',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_scan_anomalies',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal URL Reconnaissance Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Network Service Scanning',
    mitreTechniqueId: 'T1046',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_url_scan',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal IP / Port Scan Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Network Service Scanning',
    mitreTechniqueId: 'T1046',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_port_Scan',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Handshake Failure',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Network Service Scanning',
    mitreTechniqueId: 'T1046',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_handshake_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Multiple Files Restored',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data Staged',
    mitreTechniqueId: 'T1074',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_multi_file_restore',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'SMB Suspicious Copy',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data from Network Shared Drive',
    mitreTechniqueId: 'T1039',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'smb_suspicious_copy',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SQL Dumpfile Execution',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data Staged',
    mitreTechniqueId: 'T1074',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_sql_db_dump',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SQL Dumpfile Execution',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data Staged',
    mitreTechniqueId: 'T1074',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_sql_db_dump',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Non-Standard Port Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Service on Non-Standard Port',
    mitreTechniqueId: 'XT2011',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_non_std_port_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Suspected Malicious User Agent',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR User Agent Anomaly',
    mitreTechniqueId: 'XT2012',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_suspected_malicious_user_agent',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Firewall Denial Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Firewall Anomaly',
    mitreTechniqueId: 'XT2002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_fw_action',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Firewall Policy Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Firewall Anomaly',
    mitreTechniqueId: 'XT2002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_fw_policy_id',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Plain Text Passwords Detected',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Clear Password',
    mitreTechniqueId: 'XT2006',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_clear_password',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'DHCP Server Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Server Anomaly',
    mitreTechniqueId: 'XT2007',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'dhcp_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'WAF Internal Attacker Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR WAF Anomaly',
    mitreTechniqueId: 'XT2009',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'waf_internal_attacker',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Exploited Vulnerability',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Exploitated Vulnerability',
    mitreTechniqueId: 'XT2015',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_vuln_exploit_correlation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Unapproved Asset Activity',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exploration',
    mitreTactic: 'XDR NBA',
    mitreTacticId: 'XTA0002',
    mitreTechnique: 'XDR Unapproved Asset Activity',
    mitreTechniqueId: 'XT2013',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'asset_violation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal RDP Suspicious Outbound',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Remote Services',
    mitreTechniqueId: 'T1021',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_rdp_suspicious_outbound',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Password Cracking With Hashcat',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'password_cracking_with_hashcat',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Hydra Password Guessing Hack Tool',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'hydra_password_guessing_hack_tool',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal User Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_user_login_fail',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Account Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_cloud_account_login_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Protocol Account Login Failure Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_protocol_account_login_failure',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Credential Stuffing',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_credential_stuffing',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal RDP Brute Force Attack',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_rdp_brute_force',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimikatz Credential Dump',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'mimikatz_mem_scan',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Suspicious Logon Attempt',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_suspicious_logon_attempt',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SMB Username Enumeration',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_smb_user_scan',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Brute-Forced Successful User Login',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_user_success_brute_forcer',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimikatz DCSync',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'mimikatz_dcsync',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'AWS AMI Made Public',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'aws_ami_public',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Access Governance Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_access_governance_alert',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal RDP BlueKeep',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Exploitation for Privilege Escalation',
    mitreTechniqueId: 'T1068',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_rdp_bluekeep',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External RDP BlueKeep',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Privilege Escalation',
    mitreTacticId: 'TA0004',
    mitreTechnique: 'Exploitation for Privilege Escalation',
    mitreTechniqueId: 'T1068',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_rdp_bluekeep',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Private to Private Exploit Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Exploitation of Remote Services',
    mitreTechniqueId: 'T1210',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'exploit_attempt_priv_priv',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SQL Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Exploitation of Remote Services',
    mitreTechniqueId: 'T1210',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_mysql_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SMB Read Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Exploitation of Remote Services',
    mitreTechniqueId: 'T1210',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_smb_read_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Session Hijacking',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Remote Service Session Hijacking',
    mitreTechniqueId: 'T1563',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_settings_hijack',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal User Application Usage Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR App Anomaly',
    mitreTechniqueId: 'XT2003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_user_uncommon_app',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal User Data Volume Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Bytes Anomaly',
    mitreTechniqueId: 'XT3001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_user_bytes_sum',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'User Asset Access Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR UBA',
    mitreTacticId: 'XTA0004',
    mitreTechnique: 'XDR Asset Anomaly',
    mitreTechniqueId: 'XT4004',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'user_asset_access',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Other Malware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Miscellaneous Malware',
    mitreTechniqueId: 'XT6001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_malware_activity',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Malware on Disk',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Miscellaneous Malware',
    mitreTechniqueId: 'XT6001',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'malware_on_disk',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal PUA',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR PUA',
    mitreTechniqueId: 'XT6002',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_pua',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Spyware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Spyware',
    mitreTechniqueId: 'XT6003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_spyware_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Trojan',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'XDR Malware',
    mitreTacticId: 'XTA0006',
    mitreTechnique: 'XDR Trojan',
    mitreTechniqueId: 'XT6004',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_trojan_activity',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal IDS Signature Spike',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Exploitation of Remote Services',
    mitreTechniqueId: 'T1210',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_ids_signature_spike',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Password Spraying',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'Brute Force',
    mitreTechniqueId: 'T1110',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_ids_signature_spike',
    adrStatus: 'Hybrid ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'DNS Tunneling Anomaly',
    subitems: null,
    defaultStatus: 'Muted',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Alternative Protocol',
    mitreTechniqueId: 'T1048',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'dns_tunnel',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'RDP Outbytes Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Alternative Protocol',
    mitreTechniqueId: 'T1048',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'rdp_outbytes_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Data Exfiltration Attempt Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Alternative Protocol',
    mitreTechniqueId: 'T1048',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_data_exfiltration_attempt',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Data Loss Prevention',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Exfiltration Over Alternative Protocol',
    mitreTechniqueId: 'T1048',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_data_loss_prevention',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 File Sharing with Outside Entities',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Transfer Data to Cloud Account',
    mitreTechniqueId: 'T1537',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_outside_entity_file_sharing',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal PII Leaked',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Automated Exfiltration',
    mitreTechniqueId: 'T1020',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_pii_leak',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External PII Leaked',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Automated Exfiltration',
    mitreTechniqueId: 'T1020',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_pii_leak',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'File Action Anomaly',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Manipulation',
    mitreTechniqueId: 'T1565',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'anomalous_file_action',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SMB Write Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Remote Services',
    mitreTechniqueId: 'T1021',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_smb_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SMB Write Anomaly',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Manipulation',
    mitreTechniqueId: 'T1565',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_smb_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'AWS S3 Ransomware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Encrypted for Impact',
    mitreTechniqueId: 'T1486',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'aws_s3_ransomware',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Cryptojacking',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Resource Hijacking',
    mitreTechniqueId: 'T1496',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'cryptojacking',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Volume Shadow Copy Deletion via VssAdmin',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Inhibit System Recovery',
    mitreTechniqueId: 'T1490',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ransomware_volume_shadow_copy_deletion_via_vssadminedi',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Volume Shadow Copy Deletion via WMIC',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Inhibit System Recovery',
    mitreTechniqueId: 'T1490',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'ransomware_volume_shadow_copy_deletion_via_wmicredit',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Office 365 Multiple Users Deleted',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Account Access Removal',
    mitreTechniqueId: 'T1531',
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'office365_multi_user_deleted',
    adrStatus: 'Full ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal Ransomware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Encrypted for Impact',
    mitreTechniqueId: 'T1486',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_ransomware',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External Ransomware',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Encrypted for Impact',
    mitreTechniqueId: 'T1486',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_ransomware',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SYN Flood Attacker',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Endpoint Denial of Service',
    mitreTechniqueId: 'T1499',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_syn_flood_attacker',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SYN Flood Attacker',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Endpoint Denial of Service',
    mitreTechniqueId: 'T1499',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_syn_flood_attacker',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Internal SYN Flood Victim',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Endpoint Denial of Service',
    mitreTechniqueId: 'T1499',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'internal_syn_flood',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'External SYN Flood Victim',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Endpoint Denial of Service',
    mitreTechniqueId: 'T1499',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'external_syn_flood',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious PowerShell Script',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'suspicious_powershell_script',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Process Creation Commandline',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'suspicious_commandline',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Potentially Malicious Windows Event',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_malicious_event',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Sensitive Windows Active Directory Attribute Modification',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Domain Policy Modification',
    mitreTechniqueId: 'T1484',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_ad_sensitive_attribute_modification',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Sensitive Windows Network Share File or Folder Access',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Remote Services',
    mitreTechniqueId: 'T1021',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_sensitive_networkshare',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Access Attempt to Windows Object',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Phishing',
    mitreTechniqueId: 'T1566',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_object_access_suspicious_attempt',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Activity Related to Security-Enabled Group',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_suspicious_activity_related_to_security_enabled_group',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Connection to Another Process',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_suspicious_connection_process',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Handle Request to Sensitive Object',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_suspicious_handle_request',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Active Directory Operation',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Credential Access',
    mitreTacticId: 'TA0006',
    mitreTechnique: 'OS Credential Dumping',
    mitreTechniqueId: 'T1003',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_ad_suspicious_operation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Logon Event',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_suspicious_logon_event',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Windows Suspicious Process Creation',
    subitems: null,
    defaultStatus: 'Not Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Signed Binary Proxy Execution',
    mitreTechniqueId: 'T1218',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Windows Service Installation',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Execution',
    mitreTacticId: 'TA0002',
    mitreTechnique: 'Command and Scripting Interpreter',
    mitreTechniqueId: 'T1059',
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'windows_security_suspicious_service_installation',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Potentially Malicious AWS Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_malicious_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS Bucket Enumeration',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Discovery',
    mitreTacticId: 'TA0007',
    mitreTechnique: 'Cloud Infrastructure Discovery',
    mitreTechniqueId: 'T1580',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_bucket_enumeration',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS EC2 Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Transfer Data to Cloud Account',
    mitreTechniqueId: 'T1537',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_suspicious_ec2_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS IAM Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_iam_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS RDS Event',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Impact',
    mitreTacticId: 'TA0040',
    mitreTechnique: 'Data Destruction',
    mitreTechniqueId: 'T1485',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_suspicious_rds_event',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS Root Account Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Valid Accounts',
    mitreTechniqueId: 'T1078',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_root_account_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS Route 53 Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_route53_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS VPC Flow Logs Modification',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Defense Evasion',
    mitreTacticId: 'TA0005',
    mitreTechnique: 'Impair Defenses',
    mitreTechniqueId: 'T1562',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_vpc_flow_logs_modification',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Modification of AWS CloudTrail Logs',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data from Cloud Storage Object',
    mitreTechniqueId: 'T1530',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_suspicious_cloudtrail_logs_modification',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Modification of AWS Route Table',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 5),
    xdrEventName: 'aws_suspicious_modification_of_route_table',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious Modification of S3 Bucket',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exfiltration & Impact',
    mitreTactic: 'Exfiltration',
    mitreTacticId: 'TA0010',
    mitreTechnique: 'Transfer Data to Cloud Account',
    mitreTechniqueId: 'T1537',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_suspicious_modification_of_s3_bucket',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Acronis Cyber Protect Cloud - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Acronis Cyber Protect',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'AWS GuardDuty - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'AWS GuardDuty',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'AzureAdRisk - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Azure AD',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bitdefender IP - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'BitDefender',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bitdefender Threat - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'BitDefender',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Bitdefender URL - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'BitDefender',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Carbon Black - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Carbon Black',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'CrowdStrike - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'CrowdStrike',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Cybereason - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Cybereason',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Fireeye AMSI - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'FireEye HX',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'CylancePROTECT - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Cylance',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'DeepInstinct - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Deep Instinct',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Cynet - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Cynet',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Fireeye IOC - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'FireEye HX',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Fireeye MAL - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'FireEye HX',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Fireeye PROCGUARD - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'FireEye HX',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Google Workspace Alert - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Google Cloud Audit Logging',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'LimaCharlie - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'LimaCharlie',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft 365 - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Office 365',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast Attachment Protect - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast AV - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast Impersonation Protect - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast Internal Email Protect - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast Malicious Receipt Log - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Mimecast URL Protect - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Mimecast',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Microsoft Defender ATP - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Microsoft Defender',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'OCI CloudGuard - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Oracle Cloud Infrastructure',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Proofpoint TAP - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'ProofPoint TAP',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'SentinelOne - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'SentinelOne',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: 'sentinelone-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Trend Micro Vision One - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Trend Micro Vision One',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Varonis DatAdvantage - Detections',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Varonis-Datadvantage',
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'WindowsDefenderAntivirus',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: null,
    dateActivated: new Date(2023, 11, 31),
    xdrEventName: null,
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Suspicious AWS EBS Activity',
    subitems: null,
    defaultStatus: 'Active',
    killChainStage: 'Exploration',
    mitreTactic: 'Collection',
    mitreTacticId: 'TA0009',
    mitreTechnique: 'Data Staged',
    mitreTechniqueId: 'T1074',
    connector: 'AWS CloudTrail',
    dateActivated: new Date(2024, 5, 3),
    xdrEventName: 'aws_suspicious_ebs_activity',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Private to Public IPS Signature Spike',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'ips_signature_spike_priv_pub',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Public to Private IPS Signature Spike',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'ips_signature_spike_pub_priv',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Public to Public IPS Signature Spike',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Initial Attempts',
    mitreTactic: 'Initial Access',
    mitreTacticId: 'TA0001',
    mitreTechnique: 'Exploit Public-Facing Application',
    mitreTechniqueId: 'T1190',
    connector: null,
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'ips_signature_spike_pub_pub',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Private to Private IPS Signature Spike',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Propagation',
    mitreTactic: 'Lateral Movement',
    mitreTacticId: 'TA0008',
    mitreTechnique: 'Exploitation of Remote Services',
    mitreTechniqueId: 'T1210',
    connector: null,
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'ips_signature_spike_priv_priv',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Password Resets Anomaly',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: 'Persistent Foothold',
    mitreTactic: 'Persistence',
    mitreTacticId: 'TA0003',
    mitreTechnique: 'Account Manipulation',
    mitreTechniqueId: 'T1098',
    connector: null,
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'password_resets_anomaly',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Netskope Alert (Breach)',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Netskope',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'netskope_alert_breach_-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Netskope Alert (Connection)',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Netskope',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'netskope_alert_connection_-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Netskope Alert (Malsite)',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Netskope',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'netskope_alert_malsite_-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Sophos Alert',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Sophos',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'sophos_alerts-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Sophos Event',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Sophos',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'sophos_events-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
  {
    name: 'Huntress Incident',
    subitems: null,
    defaultStatus: 'New Detection',
    killChainStage: null,
    mitreTactic: null,
    mitreTacticId: null,
    mitreTechnique: null,
    mitreTechniqueId: null,
    connector: 'Huntress',
    dateActivated: new Date(2024, 6, 5),
    xdrEventName: 'huntress_incident-*',
    adrStatus: 'No ADR',
    detectionOrigin: 'Stellar Cyber',
  },
];
