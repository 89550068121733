
  <form [formGroup]="integrationForm" class="k-form k-form-md" (ngSubmit)="saveConnection()">
    <div class="k-form-buttons">
      <h2 class="k-form-title">ServiceNow</h2>
    </div>
    <div class="row">
      <div class="col-6">
        <fieldset class="k-form-fieldset">
          <kendo-dropdownlist
            #auth_type
            [defaultItem]="{ text: 'Select Auth Type', value: null }"
            textField="text"
            valueField="value"
            [data]="authenticationTypes"
            [valuePrimitive]="true"
            formControlName="ticket_integration_auth_method"
          ></kendo-dropdownlist>
        </fieldset>
      </div>
      <div class="col-6">
        @if (authType) {
        <fieldset class="k-form-fieldset">
          @if (authType === 'basic' || authType === 'oauth2_legacy_app') {
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="username" text="Username"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_user" #username></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="password" text="Password"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_secret" #password></kendo-textbox>
            </kendo-formfield>   
          }

          @if (authType === 'oauth2_legacy_app' || authType === 'oauth2_backend_app') {
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="clientId" text="Client Id"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_auth_client_id" #clientId></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="clientSecret" text="Client Secret"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_auth_client_secret" #clientSecret></kendo-textbox>
            </kendo-formfield>   
          }

          @if (authType === 'bearer') {
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="apiKey" text="API Key"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_secret" #apiKey></kendo-textbox>
            </kendo-formfield>   
          }
                 
          @if (authType === 'oauth2_legacy_app' || authType === 'oauth2_backend_app') {
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="instance_auth_url" text="Auth URL"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_auth_url" #instance_auth_url></kendo-textbox>
            </kendo-formfield>
          }
          
          @if (authType === 'basic' || authType === 'bearer') {
            <kendo-formfield>
              <kendo-label labelCssClass="k-form-label" [for]="instance_url" text="Instance URL"></kendo-label>
              <kendo-textbox formControlName="ticket_integration_url" #instance_url></kendo-textbox>
            </kendo-formfield>
          }
          
        </fieldset>
        }
      </div>
    </div>
    <hr/>
    @if (authType) {
      <fieldset>
        <div class="row field-mappings">
          <div class="col-12" formArrayName="fieldMappings">
            <div class="row mt-5">
              <div class="col-6">Cyflare Ticket Fields</div>
              <div class="col-6">ServiceNow Fields</div>
            </div>
            <div *ngFor="let itemControl of integrationUtil.filterByControlName('ticket')">
              <div [formGroup]="itemControl" class="row">
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="one_field_value"></kendo-textbox>
                  </kendo-formfield>
                </div>
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="integration_field_value"></kendo-textbox>
                  </kendo-formfield>              
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-6">Cyflare Priorities</div>
              <div class="col-6">ServiceNow Priorities</div>
            </div>
            <div *ngFor="let itemControl of integrationUtil.filterByControlName('priority')">
              <div [formGroup]="itemControl" class="row">
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="one_field_value"></kendo-textbox>
                  </kendo-formfield>
                </div>
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="integration_field_value"></kendo-textbox>
                  </kendo-formfield>              
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-6">Cyflare Statuses</div>
              <div class="col-6">ServiceNow Statuses</div>
            </div>
            <div *ngFor="let itemControl of integrationUtil.filterByControlName('status_type')">
              <div [formGroup]="itemControl" class="row">
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="one_field_value"></kendo-textbox>
                  </kendo-formfield>
                </div>
                <div class="col-6">
                  <kendo-formfield>
                    <kendo-textbox formControlName="integration_field_value"></kendo-textbox>
                  </kendo-formfield>              
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="k-form-buttons">
                  <button kendoButton themeColor="primary" type="button" (click)="testConnection()" [disabled]="integrationForm.invalid">
                    Test Connection
                  </button>
                </div>
                  <p *ngIf="testConnectionMessage">{{testConnectionMessage}}</p>
                <div class="k-form-buttons">
                  <button kendoButton themeColor="primary" type="submit" [disabled]="integrationForm.invalid">
                    Save Connection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    }
  </form>