<div class="content">
  <div class="example-wrap">
    <form [formGroup]="chartForm()" class="k-form k-form-md">
      <div class="k-form-buttons">
        <h2 class="k-form-title">Add New Detection</h2>
        <button kendoButton [svgIcon]="xIcon" (click)="onClose()" fillMode="flat"></button>
      </div>
      <fieldset class="k-form-fieldset">
        @for (field of ['zohoViewName', 'friendlyName', 'tags', 'displayColumn', 'sortOrder']; track field) {
          <kendo-formfield
            >
            <kendo-label [text]="field | titlecase" labelCssClass="k-form-label"></kendo-label>
            <kendo-textbox [formControlName]="field"></kendo-textbox>
          </kendo-formfield>
        }
        <div class="k-form-buttons">
          <button kendoButton themeColor="primary" (click)="onClose()">
            Add Chart
          </button>
          <button kendoButton (click)="clear()">Clear</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>