<div class="tile">
  <div class="tile-title">
    <h5 class="title">True Positive Rate</h5>
  </div>
  <div class="content">
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else if (tprData().value !== null) {
      <kendo-arcgauge [value]="tprData().value">
        <ng-template
          kendoArcGaugeCenterTemplate
          let-value="value"
        >
          <span>
            <h3>{{ tprData().value }}%</h3>
          </span>
        </ng-template>
      </kendo-arcgauge>
    } @else {
      <h1>No Data</h1>
    }
  </div>
</div>
