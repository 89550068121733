<div class="container p-1">
  <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-12 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <div style="flex: 1; display: flex; justify-content: center">
                <h5>
                  Ticketing Emails
                  <span
                    kendoTooltip
                    tooltipClass="custom-tooltip"
                    title="Emails added here will receive emails for all ticket creations and updates."
                  >
                    <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <app-ticketing></app-ticketing>
        </div>
      </div>
    </div>
  </div>
  <div class="py-3">
    <div class="row g-3">
      <div class="col-lg-12 col-md-12">
        <div class="tile">
          <div class="tile-title">
            <div style="display: flex; align-items: center; justify-content: space-between">
              <div style="flex: 1; display: flex; justify-content: center">
                <h5>
                  Call Tree Setup
                  <span
                    kendoTooltip
                    tooltipClass="custom-tooltip"
                    title="Personnel added here will be called in the event of a suspected incident. Calls will be made in the order they are listed here."
                  >
                    <kendo-svg-icon [icon]="infoCircleIcon"></kendo-svg-icon>
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <app-calltree></app-calltree>
        </div>
      </div>
    </div>
  </div>
</div>
