import { ChangeDetectionStrategy, Component, inject, signal, type OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MapAccountsFormsService } from './services/map-accounts-forms.service';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ActivatedRoute } from '@angular/router';
import { IngestorService, MapAccount } from '../../../../../services/ingestor.service';
import { GridModule } from '@progress/kendo-angular-grid';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../../../../../../../core/shared/common/base.component';

@Component({
  selector: 'app-map-accounts',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    InputsModule,
    LabelModule,
    FormFieldModule,
    ButtonModule,
    GridModule,
  ],
  templateUrl: './map-accounts.component.html',
  styleUrl: './map-accounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapAccountsComponent extends BaseComponent implements OnInit {

  private activatedRoute = inject(ActivatedRoute);
  public ingestorIntegrationId = signal<number>(0);
  public organizationId = signal<number>(0);
  public ingestorRecordId = signal<number>(0);
  private mapAccountsFormsService = inject(MapAccountsFormsService);
  public ingestorService = inject(IngestorService);
  public data = signal<MapAccount[]>([]);
  
  ngOnInit(): void { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.organizationId.set(parseInt(params['organizationId']));
      this.ingestorIntegrationId.set(parseInt(params['ingestorIntegrationId']));
      this.ingestorRecordId.set(parseInt(params['ingestorRecordId']));
      //api possibly: https://api.dev.cyflare.one/api/schema/swagger-ui/#/Organization%20Management%20API/v1_organization_administrative_organization()_list_child_organizations_list
      this.ingestorService.getIngestorAccountMap(this.ingestorIntegrationId())
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          this.data.set(response.values as any);
      });
    });
  }

  public mapAccountForm = this.mapAccountsFormsService.init();

  public clear($event: Event) {
    $event.preventDefault();
    this.mapAccountForm.reset();
  }

  public save($event: Event) {
    $event.preventDefault();
    console.log(this.mapAccountForm.value);
    let mapAccount: MapAccount = {
      integration_unique_name: this.mapAccountForm.value.integration_unique_name!,
      integration_url: this.mapAccountForm.value.integration_url!,
      api_key: this.mapAccountForm.value.api_key!,
    };
    
    this.ingestorService.addMapAccount(mapAccount).subscribe((response) => {
      console.log(response);
    });
  }
}
