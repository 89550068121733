<app-org-appbar></app-org-appbar><br />

<div class="k-card-list">
  @for (detection of detections(); track detection.detectionId) {
    <kendo-card class="k-card-item">
      <kendo-card-header>
        <h5>{{ detection.title }}</h5>
      </kendo-card-header>
      <kendo-card-body>
        <p>{{ detection.description }}</p>
      </kendo-card-body>
      <kendo-card-footer>
        <kendo-switch
          [checked]="detection.active"
          onLabel="Active"
          offLabel="Muted"
          (valueChange)="onToggleChange(detection, $event)"
        ></kendo-switch>
      </kendo-card-footer>
    </kendo-card>
  }
</div>
