import { Component, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridModule, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { ServerExceptionRequest, ServerExceptionService } from './service/server.service';
import { environment } from '../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { ServerExceptionForm, ServerExceptionFormService } from './service/server-form.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-server',
  standalone: true,
  imports: [CommonModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, GridModule, ReactiveFormsModule],
  templateUrl: './server.component.html',
  styleUrl: './server.component.scss',
  providers: [ServerExceptionService],
})
export class ServerComponent extends BaseComponent {
  public formGroup: FormGroup<ServerExceptionForm> | undefined;
  public formBuilder = inject(FormBuilder);
  public gridService = inject(ServerExceptionService);
  public route = inject(ActivatedRoute);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/serverexceptions`;
  private formService = inject(ServerExceptionFormService);
  private editedRowIndex: number | undefined;

  public listData = [
    { text: 'Backup Server', value: 'backup' },
    { text: 'Vulnerability Scanner', value: 'vulnerability scanner' },
    { text: 'Spam and Email', value: 'spam and email' },
    { text: 'DNS Server', value: 'DNS' },
    { text: 'DHCP Server', value: 'DHCP' },
    { text: 'Public Web Server', value: 'Public Web' },
    { text: 'RDP Server', value: 'RDP' },
    { text: 'Other - Public', value: 'Other publicly accessible servers & services' },
    { text: 'Other - Crictical', value: 'Other critical server' },
  ];

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadServerException(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadServerException(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }

  public addHandler($event: AddEvent): void {
    this.closeEditor($event.sender);
    this.formGroup = this.formService.createServerExceptionForm();
    $event.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (this.formGroup) {
      grid.closeRow(rowIndex);
      this.formGroup = undefined;
    }
  }

  saveHandler($event: SaveEvent) {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      const payload: ServerExceptionRequest = {
        organization_id: this.id,
        servers_exceptions: {
          pk: $event.isNew ? null : formValue?.pk!,
          ip_address: formValue?.ip_address!,
          server_name: formValue?.server_name!,
          notes_for_analyst: formValue?.notes_for_analyst!,
          server_type: formValue?.server_type!,
        },
      };
      if ($event.isNew) {
        this.gridService.createServerException(payload).subscribe({
          next: (response) => {
            this.loadServerException(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.gridService.updateServerException(payload.servers_exceptions).subscribe({
          next: (response) => {
            this.loadServerException(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    }
  }

  public editHandler($event: EditEvent): void {
    this.formGroup = this.formService.createServerExceptionForm($event.dataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler($event: any): void {
    this.gridService.deleteServerException($event.dataItem.pk).subscribe(() => {
      this.loadServerException(this.id);
    });
  }
}
