<app-org-appbar></app-org-appbar><br />
<div>
  <div class="tile-title">
    <h5>Ticketing</h5>
  </div>
  <div class="k-card-list integrations-list">
    <div (click)="addIntegration('connectWise')" [ngClass]="{ 'active': isIntegrationActive('connectWise') }">
      <kendo-card class="centered-card" [ngClass]="{ 'disabled-card': isDisabled('connectWise') }">
        <img src="../../../assets/logos/connectwise.png" alt="stellarcyber" />
      </kendo-card>
    </div>

    <div (click)="addIntegration('serviceNow')" [ngClass]="{ 'active': isIntegrationActive('serviceNow') }">
      <kendo-card class="centered-card" [ngClass]="{ 'disabled-card': isDisabled('serviceNow') }">
        <img src="../../../assets/logos/servicenow.png" alt="sentinelone" />
      </kendo-card>
    </div>

    <div class="card-wrapper">
      <kendo-badge class="coming-soon-badge" themeColor="primary">Coming Soon</kendo-badge>
      <kendo-card class="centered-card disabled-card">
        <img src="../../../assets/logos/manageengine.png" alt="sentinelone" />
      </kendo-card>
    </div>

    <div class="card-wrapper">
      <kendo-badge class="coming-soon-badge" themeColor="primary">Coming Soon</kendo-badge>
      <kendo-card class="centered-card disabled-card">
        <img src="../../../assets/logos/autotask.png" alt="sentinelone" />
      </kendo-card>
    </div>
  </div>

  @if (selectedIntegrationType) {
  <div class="my-5">
    <kendo-tabstrip>
      <kendo-tabstrip-tab [title]="selectedIntegrationType.title" [selected]="true">
        <ng-template kendoTabContent>
          <app-servicenow [organizationId]="organizationId" [integrationData]="activeIntegration()"
            *ngIf="selectedIntegrationType.integrationType == 'serviceNow'" (saved)="onSave()"></app-servicenow>
          <app-connectwise [organizationId]="organizationId" [integrationData]="activeIntegration()"
            *ngIf="selectedIntegrationType.integrationType == 'connectWise'" (saved)="onSave()"></app-connectwise>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
  }

  @if (saveConfirmDialog === true) {
  <kendo-dialog title="Successful" (close)="closeDialog('cancel')" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center">Connection Saved Successfully.</p>
    <kendo-dialog-actions>
      <button kendoButton themeColor="primary" type="button" (click)="closeDialog('no')">
        Ok
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
  }
</div>