@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else {
  @if (chartData().length > 0) {
    <div>
      <input
        type="checkbox"
        size="large"
        rounded="large"
        kendoCheckBox
        checked
        #caseFilterCheckbox
        [(ngModel)]="checked"
        (ngModelChange)="caseFilter()"
      />
      <kendo-label
        [for]="caseFilterCheckbox"
        text="Show Only Escalated Cases"
      ></kendo-label>
    </div>

    <kendo-chart class="chart-container" (render)="popupService.attachSurfaceHandlers($event, popupTemplate)">
      <kendo-chart-tooltip> </kendo-chart-tooltip>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [title]="{ text: 'Minutes' }"> </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>

      <kendo-chart-legend
        position="bottom"
        orientation="horizontal"
      >
      </kendo-chart-legend>

      <kendo-chart-series>
        <kendo-chart-series-item
          [data]="chartData()"
          field="count"
          categoryField="description"
        >
          <kendo-chart-series-item-tooltip format="Average Resolution time: {0} minutes"> </kendo-chart-series-item-tooltip>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>

    <ng-template #popupTemplate>
      <div class="chart-popup k-tooltip">
        {{ popupService.popupContent }}
      </div>
    </ng-template>
  } @else {
    <div class="no-data-message">
      <h2>No Data Available</h2>
      <p>There is currently no MTTD data to display.</p>
    </div>
  }
}
