import { Component, inject, signal } from '@angular/core';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { BaseComponent } from '../../../../core/shared/common/base.component';
import { ErrorStateService } from '../../services/error.state.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  providers: [NotificationService],
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule, ErrorMessageComponent],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends BaseComponent {
  public forgotPasswordForm: FormGroup = new FormGroup<forgotPasswordForm>({
    email: new FormControl<string | null>(null, [Validators.email, Validators.required, this.lowercaseValidator]),
  });

  private notificationService = inject(NotificationService);
  private router = inject(Router);
  private errorService = inject(ErrorStateService);
  private authService = inject(AuthService);
  public errorMessage = signal<string | null>(null);

  constructor() {
    super();
  }

  public back() {
    this.router.navigate(['/auth/login']);
  }

  override ngOnDestroy() {
    this.errorService.errorMessage.set(null);
  }

  private lowercaseValidator(control: FormControl) {
    if (control.value && control.value !== control.value.toLowerCase()) {
      control.setValue(control.value.toLowerCase(), { emitEvent: false });
    }
    return null;
  }

  public onEmailInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const emailControl = this.forgotPasswordForm.get('email');
    if (emailControl) {
      emailControl.setValue(input.value.toLowerCase());
    }
  }

  public show(): void {
    if (this.forgotPasswordForm.valid) {
      this.authService
        .forgotPassword(this.forgotPasswordForm.value.email!)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response) => {
            this.router.navigate(['/auth/login']);
            this.notificationService.show({
              content: 'If your email is registered, you will receive an email with instructions to reset your password.',
              cssClass: 'button-notification',
              animation: { type: 'slide', duration: 400 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 5000,
            });
          },
        });
    } else {
      this.errorService.errorMessage.set('Please enter a valid email address.');
    }
  }
}

export interface forgotPasswordForm {
  email: FormControl<string | null>;
}
