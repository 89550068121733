import { Component, CreateEffectOptions, effect, inject, input, signal } from '@angular/core';
import { AnalyticsService, FunnelData, WidgetData } from '../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { filter, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoaderModule } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'app-detection-count',
  standalone: true,
  imports: [LoaderModule],
  templateUrl: './detection-count.component.html',
  styleUrl: './detection-count.component.scss',
  providers: [AnalyticsService],
})
export class DetectionCountComponent extends BaseComponent {
  funnelDataGet = input<FunnelData[]>([]);

  public detectionData = signal<FunnelData | null>(null);
  public isLoading = signal<boolean>(true);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        const data = this.funnelDataGet();
        if (data && data.length > 0) {
          // Find the first entry with the stat "Detections"
          const detection = data.find((item) => item.stat === 'Detections') || null;
          this.detectionData.set(detection);
        } else {
          this.detectionData.set(null);
        }
        this.isLoading.set(false);
      },
      { allowSignalWrites: true } as CreateEffectOptions,
    );
  }
}
