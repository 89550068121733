<app-admin-appbar></app-admin-appbar><br>

<kendo-grid filterable="menu" [height]="350">
    <ng-template kendoGridToolbarTemplate>
        <kendo-textbox placeholder="Search Charts" [style.width.px]="180"></kendo-textbox>
        <button kendoButton (click)="addChart(bodyElement)" [svgIcon]="plusIcon" themeColor="primary">Add
            Chart</button>
    </ng-template>
    <kendo-grid-column field="friendlyName" title="Title" [width]="300">
    </kendo-grid-column>
    <kendo-grid-column field="zohoViewName" title="Zoho Name" [width]="300">
    </kendo-grid-column>
    <kendo-grid-column field="tags" title="Tags">
    </kendo-grid-column>
    <kendo-grid-column field="status" title="Status">
    </kendo-grid-column>
    <kendo-grid-column field="displayColumn" title="Display Column">
    </kendo-grid-column>
    <kendo-grid-column field="sortOrder" title="Sort Order">
    </kendo-grid-column>
</kendo-grid>