import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { TestEmailPayload } from '../scheduler.component';

interface SchedulerPayload {
  report_identifier: string | undefined;
  monthly_email: string | null;
  weekly_email: string | null;
  monthly_enabled: boolean | null;
  weekly_enabled: boolean | null;
  is_default: boolean;
  organization_id: number;
}

interface ScheduleResponse {
  id: number;
  organization: number;
  organization_name: string;
  report_template: number;
  template_name: string;
  report_subject: string;
  report_period: number;
  period_type: string;
  period_type_display: string;
  report_schedule: number;
  schedule_type: string;
  schedule_type_display: string;
  report_recipients: string;
}

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  saveSchedule(payload: SchedulerPayload): Observable<any> {
    console.log('Payload:', payload);
    console.log('Payload:', JSON.stringify(payload, null, 2));
    return this.http.post<any>(`${this.apiUrl}/v1/reporting/reports/`, payload);
  }

  getSchedules(organizationId: number, reportIdentifier: string): Observable<ScheduleResponse[]> {
    console.log('SchedulerService.getSchedules called with:', {
      organizationId,
      reportIdentifier,
    });

    const url = `${this.apiUrl}/v1/reporting/reports/?organization_id=${organizationId}&report_identifier=${reportIdentifier}`;
    console.log('Making GET request to:', url);

    return this.http.get<ScheduleResponse[]>(url);
  }

  public sendTestEmail(payload: TestEmailPayload): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/v1/reporting/reports/trigger_report/`, payload);
  }
}
