<div class="chart-container">
  <kendo-chart>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="funnel"
        [data]="model"
        [dynamicHeight]="false"
        field="count"
        categoryField="stat"
        colorField="color"
      >
        <kendo-chart-series-item-labels
          background="none"
          color="white"
          [content]="labelContent"
        >
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
</div>
