import { Component, ViewEncapsulation, input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenThreatsComponent } from './components/open-threats/open-threats.component';
import { CoverageData, WidgetData } from '../../../../../../../core/services/analytics.service';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { arrowRotateCwIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ThreatRebootComponent } from './components/threat-reboot/threat-reboot.component';
import { OutdatedAgentsComponent } from './components/outdated-agents/outdated-agents.component';
import { CoverageComponent } from './components/coverage/coverage.component';
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-hud',
  standalone: true,
  imports: [CommonModule, OpenThreatsComponent, IconsModule, ButtonModule, ThreatRebootComponent, OutdatedAgentsComponent, CoverageComponent],
  templateUrl: './hud.component.html',
  styleUrl: './hud.component.scss',
})
export class HudComponent extends BaseComponent {
  public threats = input<WidgetData>({ value: 0, colors: [] });
  public threatReboot = input<WidgetData>({ value: 0, colors: [] });
  public outdatedAgents = input<WidgetData>({ value: 0, colors: [] });
  public coverage = input<CoverageData>({ value: [] });
  @Output() refresh = new EventEmitter<void>();
  public arrowRotateCwIcon: SVGIcon = arrowRotateCwIcon;

  refreshHud() {
    this.refresh.emit();
  }
}
