import { Component } from '@angular/core';
import { DetectionsGridComponent } from './detections-grid/detections-grid.component';
import { BaseComponent } from '../../../core/shared/common/base.component';

@Component({
  selector: 'app-detections-board',
  standalone: true,
  imports: [DetectionsGridComponent],
  templateUrl: './detections-board.page.html',
  styleUrl: './detections-board.page.scss',
})
export class DetectionsBoardComponent extends BaseComponent {}
