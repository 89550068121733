import { Component, inject } from '@angular/core';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { CellClickEvent, GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { SVGIcon, plusIcon } from '@progress/kendo-svg-icons';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { SocAdvisoryService } from './services/advisory.service';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { SocAdvisory } from '../../../core/models/entities';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-advisories',
  standalone: true,
  imports: [CommonModule, AdminAppbarComponent, GridModule, InputsModule, LabelModule, IconsModule, ButtonModule],
  templateUrl: './advisories.page.html',
  styleUrl: './advisories.page.scss',
})
export class AdvisoriesPage extends BaseComponent {
  public plusIcon: SVGIcon = plusIcon;
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  public gridService = inject(SocAdvisoryService);
  advisoryForm: any;
  socAdivsoryService: any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.gridService.read();
  }

  public statusChange($event: { text: string; value: number }): void {
    const currentFilters = this.gridService.state.filter?.filters || [];

    let newFilters = currentFilters.filter((f) => (f as FilterDescriptor).field !== 'status');

    if ($event.value !== null) {
      newFilters.push({ field: 'status', operator: 'eq', value: $event.value });
    }

    this.gridService.state.filter = {
      logic: 'and',
      filters: newFilters,
    };

    this.gridService.read();
  }

  public addAdvisory() {
    this.router.navigate(['new-advisory'], { relativeTo: this.route });
  }

  public editAdvisory($event: CellClickEvent) {
    const advisory = $event.dataItem as SocAdvisory;
    this.router.navigate(['/admin/advisories/new-advisory'], { queryParams: { id: advisory.pk } });
  }

  public clear() {
    this.advisoryForm.reset();
  }

  delete($event: Event) {
    $event.preventDefault();
    const id = this.advisoryForm.value.pk!;
    this.socAdivsoryService
      .deleteSocAdvisoryItem(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
        },
        error: (error: any) => {
          console.error(error);
        },
      });
  }
}
