import { inject, Injectable } from "@angular/core";
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { combineLatestWith, Observable } from "rxjs";
import { IntegrationService } from "../../../../../../core/services/integration.service";

@Injectable({ providedIn: 'root' })
export class IntegrationUtil {
  private integrationService = inject(IntegrationService);

  public integrationForm!: FormGroup;

  constructor() {}

  get integrationFieldMappings(): FormArray {
    return this.integrationForm.get('fieldMappings') as FormArray;
  }

  filterByControlName(name: string): FormGroup[] {
    return this.integrationFieldMappings.controls
      .filter((control) => (control as FormGroup).get('field_name')?.value === name)
      .map(control => control as FormGroup);
  }

  public createFieldFormGroup(fieldType: string, oneFieldValue: string): FormGroup {
    return new FormGroup({
      field_name: new FormControl(fieldType, Validators.required),
      one_field_value: new FormControl(oneFieldValue, Validators.required),
      integration_field_value: new FormControl(''),
      integration_field_type: new FormControl('str', Validators.required),
      use_as_default: new FormControl(true),
      integration: new FormControl(null),
      pk: new FormControl(null)
    });
  }

  public addMappingFormControls(fieldType: string, items: string[]) {
    for (var i = 0; i < items.length; i++) {
      this.integrationFieldMappings.push(this.createFieldFormGroup(fieldType, items[i]));
    }
  }

  public patchIntegrationFormControls(data: any) {
    data?.fieldMappings.forEach((field: any) => {
      const control = this.integrationFieldMappings.controls
      .filter((control) => (control as FormGroup).get('one_field_value')?.value === field.one_field_value)
      .map(control => control as FormGroup)[0];

      const controlData = data.fieldMappings.find((x: any)=>x.one_field_value == control.value.one_field_value);
      control.patchValue({
        integration_field_value: controlData.integration_field_value,
        integration: controlData.integration,
        pk: controlData.pk
      });
    });
  }

  updateFieldMappings(integrationId: number) {
    let requests: Observable<Object>[] = [];
    this.integrationFieldMappings.value.forEach((formData: any) => {
      if(formData.integration_field_value) {
        if (!formData.pk) {
          formData.integration = integrationId;
          requests.push(this.integrationService.addFieldMappings(formData));
        }
        else {
          requests.push(this.integrationService.updateFieldMappings(formData));
        }
      }
    });
    return requests;
  }

  async testConnection(callback: (result: string) => void) {
    this.integrationService.testServiceNowCredentials(this.integrationForm.value).subscribe({
      next: () => {
        callback('Test Connection Succeeded.');
      },
      error: () => {
        callback('Test Connection Failed');
      },
    });
  }

  saveConnection(callback: () => void) {
    if (this.integrationForm.value.pk && this.integrationForm.value.pk > 0) {
      this.updateConnection(this.integrationForm.value.pk, callback);
    }
    else {
      this.addConnection(callback);
    }
  }

  addConnection(callback: () => void) {
    this.integrationService.addServiceNowCredentials(this.integrationForm.value)
      .subscribe({
        next: (response: any) => {
          if (response.pk > 0) {
            this.updateConnection(response.pk, callback);
          }
        },
        error: (error) => {
          console.error('Error adding connection:', error);
        },
      });
  }

  updateConnection(integrationId: number, callback: () => void) {
    this.integrationService.updateServiceNowCredentials(this.integrationForm.value)
      .pipe(
        combineLatestWith(
          this.updateFieldMappings(integrationId)
        )
      ).subscribe({
        next: () => {
          const fieldMappings = this.integrationForm.get('fieldMappings') as FormArray;
          fieldMappings.clear();

          callback();
        },
        error: (error) => {
          console.error('Error updating connection:', error);
        },
      });
  }
}