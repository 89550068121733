@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (funnelData() !== null) {
  <kendo-chart>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="funnel"
        [data]="funnelData()"
        [dynamicHeight]="false"
        field="count"
        categoryField="stat"
        colorField="color"
      >
        <kendo-chart-series-item-labels
          background="none"
          color="white"
          [content]="labelContent"
        >
        </kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
} @else {
  <h1>No Data</h1>
}
