import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation, output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';

export interface SchedulerForm {
  id: FormControl<number | null>;
  monthlyEmail: FormControl<string | null>;
  weeklyEmail: FormControl<string | null>;
  monthlySwitch: FormControl<boolean | null>;
  weeklySwitch: FormControl<boolean | null>;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scheduler',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, LabelModule, InputsModule, DateInputsModule, ButtonsModule, DropDownsModule, DialogsModule, CommonModule],
  templateUrl: './scheduler.component.html',
  styleUrl: './scheduler.component.scss',
})
export class SchedulerComponent {
  public xIcon: SVGIcon = xIcon;
  public monthlySwitch = false;
  public weeklySwitch = false;
  @Input() reportTitle!: string;
  // Output event to notify parent components of cancellation
  @Output() close = new EventEmitter<void>();

  // Form group for the scheduler form
  schedulerForm: FormGroup<SchedulerForm> = new FormGroup<SchedulerForm>({
    id: new FormControl<number | null>(null),
    monthlyEmail: new FormControl<string | null>(null),
    weeklyEmail: new FormControl<string | null>(null),
    monthlySwitch: new FormControl<boolean>(false), // Initialize with false
    weeklySwitch: new FormControl<boolean>(false), // Initialize with false
  });

  public save($event: Event) {
    this.close.emit();
  }

  // Method to cancel and emit close event
  public cancel() {
    this.close.emit();
  }

  public monthlySchedule(value: boolean): void {
    this.monthlySwitch = value;
  }

  public weeklySchedule(value: boolean): void {
    this.weeklySwitch = value;
  }
}
