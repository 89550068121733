import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AuthService, ErrorResponse } from '../../services/auth.service';
import { takeUntil, throwError } from 'rxjs';
import { ErrorStateService } from '../../services/error.state.service';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../../core/shared/common/base.component';

@Component({
  selector: 'app-enter-mfa',
  standalone: true,
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule, ErrorMessageComponent],
  templateUrl: './enter-mfa.component.html',
  styleUrl: './enter-mfa.component.scss',
})
export class EnterMfaComponent extends BaseComponent {
  public loginForm!: FormGroup;
  private authService = inject(AuthService);
  private errorService = inject(ErrorStateService);
  public session: string | null = null;
  public email: string | null = null;  
  public redirectUrl: string | null = null;
  private location = inject(Location);
  private route = inject(ActivatedRoute);

  constructor() {
    super();
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      oneTimeCode: new FormControl(null, [Validators.required, this.noWhitespaceValidator()]),
    });

    // Subscribe to value changes to clean up the input
    this.loginForm.get('oneTimeCode')?.valueChanges.subscribe((value) => {
      if (value) {
        const cleanedValue = value.replace(/\s+/g, '');
        this.loginForm.get('oneTimeCode')?.setValue(cleanedValue, { emitEvent: false });
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.session = params['session'];
      this.email = params['email'];
      this.redirectUrl = params['redirectUrl'];
    });
  }

  public back() {
    this.location.back();
  }

  public submit() {
    if (this.loginForm.valid) {
      if (this.email === null) {
        this.errorService.errorMessage.set('Email is required.');
        return;
      }
      if (this.session === null) {
        this.errorService.errorMessage.set('Session is required.');
        return;
      }
      const oneTimeCode = this.loginForm.value.oneTimeCode.replace(/\s+/g, '');
      this.authService
        .mfaLogin(oneTimeCode, this.session, this.email)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (response) => {
            this.authService.user.checksum = response.headers.get('auth-check')!;
            this.authService.user.encryptedData = response.body!;            
            const userData = this.authService.user.decryptedUserData;
            this.authService.user.updateUser(userData);
            window.heap.identify(this.authService.user.email);
            window.heap.addUserProperties({'id': this.authService.user.id, 'first_name': `${this.authService.user.first_name}`, 'last_name': `${this.authService.user.last_name}`});
            this.authService.loginRedirect(this.redirectUrl);
          },
          error: (error: ErrorResponse) => {
            this.errorService.errorMessage.set(error?.message ?? 'An error occurred');
            return throwError(() => error.message);
          },
        });
    }
  }

  // Custom validator to check for whitespace
  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }
}

export interface LoginForm {
  oneTimeCode: FormControl<string | null>;
}
