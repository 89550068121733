<app-admin-appbar></app-admin-appbar><br />

<kendo-grid
  (cellClick)="editAdvisory($event)"
  [data]="gridService | async"
  [pageable]="true"
  [pageSize]="gridService.state.take"
  [skip]="gridService.state.skip"
  [(filter)]="gridService.state.filter"
  [loading]="gridService.loading"
  (dataStateChange)="gridService.onStateChange($event)"
  [sort]="this.gridService.state.sort"
  [sortable]="true"
  [filterable]="true"
  #grid
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      (click)="addAdvisory()"
      [svgIcon]="plusIcon"
      themeColor="primary"
    >
      Add Advisory
    </button>
  </ng-template>
  <kendo-grid-column
    field="title"
    title="Title"
    [width]="300"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="author"
    title="Author"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="is_published"
    title="Published"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="created_at"
    title="Date Created"
  >
  </kendo-grid-column>
</kendo-grid>
