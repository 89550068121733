import { Injectable, signal, type Signal } from '@angular/core';

export interface Event {
  organizationId?: number;
  // Add other relevant properties
}

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly writableSignal = signal<Event>({});

  get orgChanged(): Signal<Event> {
    return this.writableSignal.asReadonly();
  }

  orgChange(value: Event): void {
    console.log('orgChange', value);
    this.writableSignal.set(value);
  }
}
