import { Component, effect, inject, signal, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { TrendingArticleComponent } from './trending-article/trending-article.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SocAdvisoryResponse, SocAdvisoryService } from '../../../../admin/advisories/services/advisory.service';
import { debounceTime, filter, fromEvent, switchMap } from 'rxjs';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { Renderer2 } from '@angular/core';
import { SVGIcon, chevronDoubleDownIcon } from '@progress/kendo-svg-icons';
import { IconsModule } from '@progress/kendo-angular-icons';

@Component({
  selector: 'app-advisories-main',
  standalone: true,
  imports: [TrendingArticleComponent, IconsModule],
  templateUrl: './advisories-main.component.html',
  styleUrls: ['./advisories-main.component.scss'],
})
export class AdvisoriesMainComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('scrollIndicator') scrollIndicator!: ElementRef;
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private advisoryService = inject(SocAdvisoryService);
  private renderer = inject(Renderer2);

  public advisoryData = signal<SocAdvisoryResponse[]>([]);
  public filteredAdvisories = signal<SocAdvisoryResponse[]>([]);
  public isLoading = signal<boolean>(true);
  public chevronDoubleDownIcon: SVGIcon = chevronDoubleDownIcon;

  private isScrolling = false;

  constructor() {
    super();
    this.initEffect();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkScroll();
    }, 0);

    fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => this.checkScroll());
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => !!params['account_type']),
          switchMap((params) => this.advisoryService.getSocAdvisoryItems()),
        )
        .subscribe({
          next: (data) => {
            this.advisoryData.set(data);
            this.filterAdvisories();
            this.isLoading.set(false);
            setTimeout(() => {
              this.checkScroll();
            }, 0);
          },
          error: (error) => {
            console.error(error);
            this.isLoading.set(false);
          },
        });
    });
  }

  private filterAdvisories() {
    const queryParams = this.route.snapshot.queryParams;
    const accountType: number = parseInt(queryParams['account_type'], 10);

    const accountTypeMap: { [key: number]: string } = {
      1: 'MMSP',
      2: 'MSP',
      3: 'Customer',
    };

    const accountTypeString = accountTypeMap[accountType];

    const advisories = this.advisoryData();
    const filtered = advisories.filter((advisory) => advisory.is_published && advisory.advisory_for.includes(accountTypeString)).sort((a, b) => new Date(b.published_at!).getTime() - new Date(a.published_at!).getTime());
    this.filteredAdvisories.set(filtered);
  }

  public onAdvisoryClick(advisoryId: number) {
    const queryParams = this.route.snapshot.queryParams;
    const newQueryParams = { ...queryParams, advisoryId: advisoryId };
    this.router.navigate(['/customer/advisory'], { queryParams: newQueryParams });
  }

  public onScroll() {
    const scrollElement = this.scrollContainer.nativeElement;

    if (scrollElement.scrollTop === 0) {
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'visibility', 'visible');
      this.isScrolling = false;
    } else if (!this.isScrolling) {
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'opacity', '0');
      setTimeout(() => {
        this.renderer.setStyle(this.scrollIndicator.nativeElement, 'visibility', 'hidden');
      }, 300);
      this.isScrolling = true;
    }
  }

  private checkScroll() {
    const scrollElement = this.scrollContainer.nativeElement;
    if (scrollElement.scrollHeight > scrollElement.clientHeight) {
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'display', 'block');
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'opacity', '1');
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'visibility', 'visible');
    } else {
      this.renderer.setStyle(this.scrollIndicator.nativeElement, 'display', 'none');
    }
  }
}
