<div style="padding: 20px">
  <form
    class="form-signin"
    [formGroup]="forgotPasswordForm"
  >
    @if (errorMessage()) {
      <div class="alert alert-danger">{{ errorMessage() }}</div>
    }
    <div class="logo-container">
      <img src="/assets/logos/cyflare_one_logo.png" />
    </div>
    <div class="header-container">
      <h2>Reset Password</h2>
    </div>
    <p class="description">Enter the email address associated with your account and an email will be sent with a link to reset your password.</p>
    <app-error-message></app-error-message>
    <div>
      <kendo-floatinglabel text="Enter Your Email Address">
        <input
          kendoTextBox
          class="form-control"
          formControlName="email"
          (input)="onEmailInput($event)"
        />
      </kendo-floatinglabel>
    </div>

    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        themeColor="primary"
        (click)="show()"
      >
        Submit
      </button>
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-between">
        <div class="form-check d-flex align-items-center">
          <label
            class="form-check-label"
            for="rememberMe"
            >Having trouble signing in?</label
          >
        </div>
        <div>
          <a
            href="mailto:support@cyflare.com"
            class="text-primary"
            kendoButton
            fillMode="clear"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        (click)="back()"
      >
        Go Back
      </button>
    </div>
  </form>
</div>
