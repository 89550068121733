<div class="example-wrap">
  <kendo-stepper [steps]="[
      { label: 'Authenticate' },
      { label: 'Map Accounts' }
    ]" 
    stepType="full" 
    [currentStep]="0" 
    [linear]="true" 
    [style.width.px]="570">
  </kendo-stepper>
</div>
<br />
<br />
<!-- <div class="content"> -->
<div class="example-wrap">
  <form class="k-form k-form-md" [formGroup]="recordForm">
    <div class="k-form-buttons">
      <h2 class="k-form-title">Add Ingestor Record</h2>
    </div>
    <fieldset class="k-form-fieldset">
      <kendo-formfield>
        <kendo-label labelCssClass="k-form-label" [for]="integration_url" text="Integration Url"></kendo-label>
        <kendo-textbox formControlName="integration_url" #integration_url></kendo-textbox>

        <kendo-formhint>Please enter the integration url</kendo-formhint>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label labelCssClass="k-form-label" [for]="api_key" text="Api Key*"></kendo-label>
        <kendo-textbox formControlName="api_key" #api_key required></kendo-textbox>

        <kendo-formhint>Api Key</kendo-formhint>
        <kendo-formerror>Error: Not valid Api Key</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label labelCssClass="k-form-label" [for]="virtualDropdown" text="Organization Parent">
        </kendo-label>
        <cyflare-virtual-dropdown #virtualDropdown [textField]="'name'" [valueField]="'id'" [refreshOnClick]="true"
          [filterable]="true" [styles]="{ width: '225px' }" [virtual]="{ itemHeight: 36 }" [dataService]="orgService"
          formControlName="organization_parent" [valuePrimitive]="true" [defaultItem]="defaultItem" required>
        </cyflare-virtual-dropdown>
        <kendo-formhint>Please select the parent organization</kendo-formhint>
      </kendo-formfield>
      <div class="k-form-buttons">
        <button kendoButton (click)="clear()">
          Clear
        </button>
        <button kendoButton themeColor="primary" (click)="save($event)" [disabled]="recordForm.invalid">
          Next
        </button>
      </div>
    </fieldset>
  </form>
</div>
<!-- </div> -->