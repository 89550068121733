<form [formGroup]="form" class="contact-box">
    <fieldset class="form-fields" style="display: flex; flex-direction: row; padding: 20px;">
        <kendo-formfield class="field-group">
            <kendo-label for="name" text="Name"></kendo-label>
            <kendo-textbox class="contact-textbox" formControlName="name" fillMode="flat"
                [readonly]="true"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield class="field-group">
            <kendo-label for="phone" text="Phone #"></kendo-label>
            <kendo-textbox class="contact-textbox" formControlName="phone" fillMode="flat"
                [readonly]="true"></kendo-textbox>
        </kendo-formfield>
    </fieldset>
</form>