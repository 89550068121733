@if (isLoading()) {
  <kendo-loader
    [type]="'infinite-spinner'"
    [size]="'large'"
  ></kendo-loader>
} @else if (ticketsByUser().length > 0) {
  <kendo-chart-breadcrumb [chart]="instance"></kendo-chart-breadcrumb>

  <kendo-chart
    #instance
    class="chart-container"
    (seriesClick)="handleSeriesClick($event)"
    (render)="popupService.attachSurfaceHandlers($event, popupTemplate)"
  >
    <kendo-chart-tooltip> </kendo-chart-tooltip>
    <kendo-chart-series>
      <kendo-chart-series-item
        type="column"
        [data]="ticketsByUser()"
        field="count"
        categoryField="description"
        drilldownField="description"
      >
        <kendo-chart-series-item
          *kendoChartDrilldownSeries="let make = drilldownValue"
          [data]="ticketsByEventName()"
          field="count"
          categoryField="description"
        >
        </kendo-chart-series-item>
      </kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-category-axis>
      <kendo-chart-category-axis-item>
        <kendo-chart-category-axis-item-labels
          [content]="truncateCategoryLabel"
          rotation="auto"
        ></kendo-chart-category-axis-item-labels>
      </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>
  </kendo-chart>

  <ng-template #popupTemplate>
    <div class="chart-popup k-tooltip">
      {{ popupService.popupContent }}
    </div>
  </ng-template>
} @else {
  <h1>No Data</h1>
}
