import { Component, inject, signal } from '@angular/core';
import { ErrorStateService } from '../../services/error.state.service';


@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  standalone: true,
  imports: []
})
export class ErrorMessageComponent {
  private errorService = inject(ErrorStateService);

  get errorMessage() {
    return this.errorService.errorMessage;
  }
}
