import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { User } from '../../../core/models/user';
import { Organization } from '../../../core/models/entities';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private router = inject(Router);
  private httpClient = inject(HttpClient);
  private _user = inject(User);

  public get user(): User {
    return this._user;
  }

  public set user(value: User) {
    this._user.updateUser(value);
  }

  public login(email: string, password: string) {
    return this.httpClient.post<AuthResponse>(`${environment.apiUrl}/v1/user/administrative/authentication/initiate_auth/`, { email, password });
  }

  public mfaLogin(authenticator_code: string, session: string, email: string) {
    return this.httpClient.post<string>(`${environment.apiUrl}/v1/user/administrative/authentication/verify_mfa_for_auth/`, {
      email: email,
      authenticator_code: authenticator_code,
      session: session,
    }, { observe: 'response', responseType: "json" });
  }

  public verifyMfaService(authenticator_code: string) {
    return this.httpClient.post<AuthResponse>(`${environment.apiUrl}/v1/user/administrative/mfa_setup/verify_mfa_device/`, { authenticator_code });
  }

  public confirmForgotPassword(email: string, new_password: string, confirmation_code: string) {
    return this.httpClient.post<void>(`${environment.apiUrl}/v1/user/administrative/authentication/confirm_forgot_password/`, {
      email: email,
      new_password: new_password,
      confirmation_code: confirmation_code,
    });
  }

  public forgotPassword(email: string) {
    return this.httpClient.post<void>(`${environment.apiUrl}/v1/user/administrative/authentication/forgot_password/`, { email });
  }

  public associateMfaDevice() {
    return this.httpClient.post<SetupMfaResponse>(`${environment.apiUrl}/v1/user/administrative/mfa_setup/associate_mfa_device/`, {});
  }

  public resetPassword(email: string, password: string) {
    const session = this._user.session;
    return this.httpClient.post<AuthResponse>(`${environment.apiUrl}/v1/user/administrative/authentication/new_password_for_auth/`, { email, new_password: password, session });
  }

  public loginRedirect(redirectUrl?: string | null) {
    const user = this._user;
    const queryParams: any = {};
    if (user.primary_organization!.id) {
      queryParams.organizationId = user.primary_organization!.id;
    }
    if (user.primary_organization!.account_type) {
      queryParams.account_type = user.primary_organization?.account_type;
    }
    if (!user.groups.length) {
      this.router.navigate(['customer/unauthorized']);
    } else if (redirectUrl) {
      if (redirectUrl.startsWith('http')) {
        window.location.href = decodeURIComponent(redirectUrl);
      } else {
        this.router.navigateByUrl(redirectUrl);
      }
    } else if (user.groups.includes('admin')) {
      this.router.navigate(['admin/dashboard']);
    } else if (user.groups.includes('customer')) {
      this.router.navigate(['customer/customer-dashboard'], { queryParams });
    }
  }
}

interface AuthResponse {
  groups: string[];
  next_step?: string;
  session?: string;
  organizations?: Organization[];

  access_token?: string;
  expires_in?: number;
  mfa_setup_required?: boolean;
  refresh_token?: string;
  token_type?: string;
  primary_organization_id?: number;
  primary_organization_account_type?: number;
}

interface SetupMfaResponse {
  secret_code?: string;
  qr_image?: string;
}

export interface ErrorResponse extends HttpErrorResponse {
  error: {
    detail: string;
  };
}
