import { Component, OnInit, computed, effect, inject, signal } from '@angular/core';
import { EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BaseComponent } from '../../../../core/shared/common/base.component';
import { SocAdvisory } from '../../../../core/models/entities';
import { SocAdvisoryForm, SocAdvisoryFormService } from './services/advisory-form.service';
import { SocAdvisoryRequest, SocAdvisoryService } from '../services/advisory.service';
import { filter, switchMap, takeUntil, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-advisory',
  standalone: true,
  imports: [FormsModule, EditorModule, LabelModule, InputsModule, ButtonsModule, DropDownsModule],
  templateUrl: './new-advisory.component.html',
  styleUrl: './new-advisory.component.scss',
})
export class NewAdvisoryComponent extends BaseComponent implements OnInit {
  private apiService = inject(SocAdvisoryService);
  private formService = inject(SocAdvisoryFormService);
  public route = inject(ActivatedRoute);
  public router = inject(Router);
  public socAdvisory = signal<SocAdvisory>(new SocAdvisory());
  public advisoryForm = signal(this.SocAdvisoryForm());
  public formGroup = this.formService.SocAdvisoryForm();
  public listItems: Array<string> = ['All', 'Customer', 'MSP', 'MMSP'];
  public id = computed(() => this.advisoryForm().get('pk')?.value ?? 0);

  constructor() {
    super();
    this.initEffect();
  }

  ngOnInit(): void {}

  private initEffect() {
    effect(
      () => {
        this.route.queryParams
          .pipe(
            filter((params) => params['id']),
            switchMap((params) => this.apiService.getAdvisoryById(params['id'])),
            tap((advisory) => {
              this.formGroup.patchValue({
                pk: advisory.pk,
                title: advisory.title,
                advisory_for: advisory.advisory_for,
                content: advisory.content,
              });
            }),
          )
          .subscribe();
      },
      { allowSignalWrites: true },
    );
  }

  SocAdvisoryForm(socAdvisory = new SocAdvisory()) {
    return new FormGroup<SocAdvisoryForm>({
      pk: new FormControl(socAdvisory.pk || null, Validators.required),
      title: new FormControl(socAdvisory.title || null, Validators.required),
      advisory_for: new FormControl<string[] | null>(socAdvisory.advisory_for || [], Validators.required),
      content: new FormControl(socAdvisory.content || null),
    });
  }

  public onValueChange(selectedItems: string[]) {
    if (this.formGroup) {
      const allSelected = selectedItems.includes('All');
      const otherItems = this.listItems.filter((item) => item !== 'All');

      if (allSelected) {
        // If "All" is selected, add all other items
        this.formGroup.get('advisory_for')?.setValue(otherItems);
      } else {
        // If "All" is deselected, remove all other items
        const newSelectedItems = selectedItems.filter((item) => item !== 'All');
        if (newSelectedItems.length === 0) {
          this.formGroup.get('advisory_for')?.setValue([]);
        } else {
          this.formGroup.get('advisory_for')?.setValue(newSelectedItems);
        }
      }
    }
  }

  public saveDraft($event: Event) {
    $event.preventDefault();
    this.checkAdivosryState();
  }

  public clear() {
    this.formGroup?.reset();
  }

  back() {
    this.clear();
    this.router.navigate(['admin/advisories']);
  }

  public publish($event: Event) {
    $event.preventDefault();
    this.checkAdivosryState(true);
  }

  delete($event: Event) {
    $event.preventDefault();
    const pk = this.formGroup?.value.pk!;
    this.apiService
      .deleteSocAdvisoryItem(pk)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clear();
          this.router.navigate(['admin/advisories']);
        },
        error: (error: any) => {
          console.error(error);
        },
      });
  }

  checkAdivosryState(publish = false) {
    const advisoryForm = {
      pk: this.formGroup?.value.pk,
      title: this.formGroup?.value.title,
      advisory_for: this.formGroup?.value.advisory_for,
      content: this.formGroup?.value.content,
    };

    if (advisoryForm.pk !== null) {
      this.apiService
        .updateSocAdvisoryItem(advisoryForm.pk, advisoryForm)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.clear();
            if (publish) {
              this.apiService
                .publishAdvisory(advisoryForm.pk, advisoryForm)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: () => {
                    this.back();
                  },
                  error: (error) => {
                    console.error(error);
                  },
                });
            } else {
              this.back();
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
    } else {
      this.apiService
        .addSocAdvisoryItem(advisoryForm)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (newAdvisory: any) => {
            this.clear();
            if (publish) {
              this.apiService
                .publishAdvisory(newAdvisory.pk, newAdvisory)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({
                  next: () => {
                    this.back();
                  },
                  error: (error) => {
                    console.error(error);
                  },
                });
            } else {
              this.back();
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
    }
  }
}
