import { Component, EventEmitter, Output, Renderer2, inject, output } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SVGIcon, xIcon, checkIcon, arrowLeftIcon } from '@progress/kendo-svg-icons';
import { BaseComponent } from '../../../../../core/shared/common/base.component';

@Component({
  selector: 'app-vip-support',
  standalone: true,
  imports: [ButtonsModule, ListViewModule, LayoutModule],
  templateUrl: './vip-support.component.html',
  styleUrl: './vip-support.component.scss',
})
export class VipSupportComponent extends BaseComponent {
  @Output() close = new EventEmitter<void>();
  public xIcon: SVGIcon = xIcon;
  public checkIcon: SVGIcon = checkIcon;
  private renderer = inject(Renderer2);
  public showBackButton = false;
  public arrowLeftIcon: SVGIcon = arrowLeftIcon;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    // This ensures the view is fully loaded
    console.log('View initialized, DOM elements should be available now.');
  }

  navigateToSection(section: string): void {
    this.showBackButton = true; // Show the back button after navigation
    // Use setTimeout to allow DOM changes to reflect before attempting to load the widget
    setTimeout(() => {
      this.loadRoamLobby(section); // Pass the selected section
    }, 0);
  }

  showLobbyButtons(): void {
    this.showBackButton = false; // Hide back button to show initial buttons
  }

  loadRoamLobby(section: string) {
    const script = this.renderer.createElement('script');
    script.src = 'https://ro.am/lobbylinks/embed.js';
    script.onload = () => {
      const parentElement = document.getElementById('roam-lobby');
      if (parentElement) {
        const url = `https://ro.am/cyflarehq/${section.toLowerCase()}/`; // Dynamic URL based on section
        (window as any).Roam.initLobbyEmbed({
          url,
          parentElement,
          lobbyConfiguration: 'default',
          theme: 'dark',
          onSizeChange: (width: number, height: number) => {
            if (parentElement) {
              parentElement.style.height = `${height}px`;
            }
          },
        });
      } else {
        console.error('Parent element for Roam widget not found');
      }
    };
    document.body.appendChild(script);
  }

  public cancel() {
    this.close.emit();
  }
}
