import { Component, effect, EventEmitter, inject, Output, signal, ViewChild, ViewEncapsulation } from '@angular/core';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { SuspectUsersComponent } from './components/suspect-users/suspect-users.component';
import { SuspectAssetsComponent } from './components/suspect-assets/suspect-assets.component';
import { DestinationMapComponent } from './components/destination-map/destination-map.component';
import { SourceMapComponent } from './components/source-map/source-map.component';
import { OutcomeStatusComponent } from './components/outcome-status/outcome-status.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { AnalyticsService } from '../../../core/services/analytics.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { filter } from 'rxjs';
import { LabelModule } from '@progress/kendo-angular-label';
import { PDFExportModule, PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TopDetectionsComponent } from './components/top-detections/top-detections.component';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DispositionColumnComponent } from '../../charting/disposition-column/disposition-column.component';
import { AdrManualComponent } from './components/adr-manual/adr-manual.component';
import { MttrComponent } from './components/mttr/mttr.component';
import { TicketClosureBreakdownComponent } from './components/ticket-closure-breakdown/ticket-closure-breakdown.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { infoCircleIcon, moreVerticalIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ConnectorStatusComponent } from '../customer-dashboard/components/connector-status/connector-status.component';
import { MttdComponent } from './components/mttd/mttd.component';
import { SlaAdherenceComponent } from './components/sla-adherence/sla-adherence.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { CyflarePeriodComponent } from '../../../core/components/cyflare-period/cyflare-period.component';
import { DropDownButtonComponent } from '@progress/kendo-angular-buttons';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-analytics',
  standalone: true,
  imports: [DropDownListModule, DateInputsModule, DropDownButtonComponent, ButtonsModule, PDFExportModule, LabelModule, LayoutModule, SuspectUsersComponent, SuspectAssetsComponent, DestinationMapComponent, SourceMapComponent, OutcomeStatusComponent, RouterModule, TopDetectionsComponent, DispositionColumnComponent, AdrManualComponent, TicketClosureBreakdownComponent, MttrComponent, IconsModule, TooltipsModule, ConnectorStatusComponent, MttdComponent, SlaAdherenceComponent, CyflarePeriodComponent],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [AnalyticsService],
})
export class AnalyticsComponent extends BaseComponent {
  @ViewChild('pdf', { static: true }) pdf!: PDFExportComponent;
  @ViewChild(SlaAdherenceComponent) slaAdherenceComponent!: SlaAdherenceComponent;
  public infoCircleIcon: SVGIcon = infoCircleIcon;
  public moreVerticalIcon: SVGIcon = moreVerticalIcon;
  private route = inject(ActivatedRoute);
  private analyticsService = inject(AnalyticsService);

  public dateRange: string[] = [];
  public organizationId: number | null = null;

  public destinationMapData = signal<any>(null);
  public sourceMapData = signal<any>(null);
  public topSuspectUsersData = signal<any>(null);
  public topUserDetailData = signal<any>([]);
  public topSuspectAssetsData = signal<any>(null);
  public topAssetDetailData = signal<any>(null);
  public outcomeStatusData = signal<any>(null);
  public dispositionData = signal<any>(null);
  public topDetectionsData = signal<any>(null);
  public adrManualData = signal<any>(null);
  public mttrData = signal<any>(null);
  public ticketClosureData = signal<any>(null);
  public mttdData = signal<any>(null);
  public slaVoilationsData = signal<any>(null);
  public range = { start: null, end: null };
  public selectedDateRange = signal<string[]>([]);
  public moreDropdown: any[] = [{ text: 'View Data' }];

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe({
        next: (params) => {
          this.organizationId = +params['organizationId'];
          this.applyFilter();
        },
        error: (error) => {
          console.error('Error processing query parameters:', error);
        },
      });
    });
  }

  onSuspectUsersSeriesClick($event: SeriesClickEvent) {
    const seriesData = $event.sender.configurationService.store.series[0].data[0];
    const topSuspectUsersData = this.topSuspectUsersData()[0];
    if (seriesData.description !== topSuspectUsersData.description) {
      return;
    }

    const dateRange = this.selectedDateRange();
    debugger;
    const params = {
      alert_received_on__range: dateRange.join(','),
      username__icontains: $event.category,
    };

    this.analyticsService
      .getTopUserDetail(this.organizationId!, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.topUserDetailData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top User Detail:', error);
        },
      });
  }

  onCaseEscalatedCheckboxChanged($event: boolean) {
    const dateRange = this.selectedDateRange();
    const params = {
      alert_received_on__range: dateRange.join(','),
      is_escalated: $event ? $event : null,
    };

    this.fetchMttdData(this.organizationId!, params);
  }

  onSuspectAssetsSeriesClick($event: SeriesClickEvent) {
    const seriesData = $event.sender.configurationService.store.series[0].data[0];
    const topSuspectAssetsData = this.topSuspectAssetsData()[0];

    if (seriesData.description !== topSuspectAssetsData.description) {
      return;
    }

    const dateRange = this.selectedDateRange();
    const params = {
      alert_received_on__range: dateRange.join(','),
      source_ip: $event.category,
    };

    this.analyticsService
      .getTopAssetDetail(this.organizationId!, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.topAssetDetailData.set(response);
        },
        error: (error) => {
          console.error('Error fetching Top User Detail:', error);
        },
      });
  }

  onContextMenuSelect(event: any) {
    if (this.slaAdherenceComponent) {
      this.slaAdherenceComponent.onContextMenuSelect(event);
    } else {
      console.warn('SlaAdherenceComponent is not available.');
    }
  }

  applyFilter() {
    this.dateRange = this.selectedDateRange();
    if (!this.dateRange) return;

    const params = {
      alert_received_on__range: this.dateRange.join(','),
    };
    const ticketDataParams = {
      created_time__range: this.dateRange.join(','),
    };
    const mttdParams = {
      alert_received_on__range: this.dateRange.join(','),
      is_escalated: null,
    };

    if (this.organizationId !== null) {
      this.analyticsService
        .getDestinationMap(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.destinationMapData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Destination Map:', error);
          },
        });
      this.analyticsService
        .getSourceMap(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.sourceMapData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Source Map:', error);
          },
        });
      this.analyticsService
        .getTopSuspectUsers(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.topSuspectUsersData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Top Suspect Users:', error);
          },
        });
      this.analyticsService
        .getTopDetectionData(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.topDetectionsData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Top User Detail:', error);
          },
        });
      this.analyticsService
        .getTopUserDetail(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.topUserDetailData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Top User Detail:', error);
          },
        });
      this.analyticsService
        .getTopSuspectAssets(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.topSuspectAssetsData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Top Suspect Assets:', error);
          },
        });
      this.analyticsService
        .getTopAssetDetail(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.topAssetDetailData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Top Asset Detail:', error);
          },
        });
      this.analyticsService
        .getOutcomeStatus(this.organizationId, ticketDataParams)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.outcomeStatusData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Outcome Status:', error);
          },
        });
      this.analyticsService
        .getDisposition(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.dispositionData.set(response);
          },
          error: (error) => {
            console.error('Error fetching Outcome Status:', error);
          },
        });
      this.analyticsService
        .getAdrManualData(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.adrManualData.set(response);
          },
          error: (error) => {
            console.error('Error fetching ADR vs Manual Sata:', error);
          },
        });
      this.analyticsService
        .getMttrData(this.organizationId, ticketDataParams)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.mttrData.set(response);
          },
          error: (error) => {
            console.error('Error fetching MTTR Data:', error);
          },
        });
      this.analyticsService
        .getTicketClosureBreakdownData(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.ticketClosureData.set(response);
          },
          error: (error) => {
            console.error('Error fetching ADR vs Manual Data:', error);
          },
        });
      this.analyticsService
        .getSlaAdherence(this.organizationId, params)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            this.slaVoilationsData.set(response);
          },
          error: (error) => {
            console.error('Error fetching SLA voilations Data:', error);
          },
        });
      this.fetchMttdData(this.organizationId, mttdParams);
    } else {
      console.error('Organization ID is not set.');
    }
  }

  fetchMttdData(organizationId: number, params: any) {
    this.analyticsService
      .getMttdData(organizationId, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.mttdData.set(response);
        },
        error: (error) => {
          console.error('Error fetching MTTR Data:', error);
        },
      });
  }
}
