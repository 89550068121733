<div
  class="tile hud-tile"
  (click)="onContextMenuSelect({ text: 'View Data' })"
>
  <div class="tile-title">
    <h5 class="title">Total Agents Observed</h5>
  </div>
  <div class="content">
    @if (isLoading()) {
      <kendo-loader
        [type]="'infinite-spinner'"
        [size]="'large'"
      ></kendo-loader>
    } @else if (widgetData().value !== null) {
      <h1>{{ widgetData().value }}</h1>
    } @else {
      <h1>No Data</h1>
    }
  </div>
</div>

<kendo-dialog
  *ngIf="showPopup"
  (close)="close()"
>
  <kendo-dialog-titlebar>View Data</kendo-dialog-titlebar>
  <kendo-grid
    class="mxh-80v"
    [filterable]="true"
    [data]="gridService | async"
    [pageable]="{
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: [25, 50, 100, 200]
    }"
    [pageSize]="gridService.state.take"
    [skip]="gridService.state.skip"
    [(filter)]="gridService.state.filter"
    [loading]="gridService.loading"
    (dataStateChange)="gridService.onStateChange($event)"
    [sort]="this.gridService.state.sort"
    [sortable]="true"
    [resizable]="true"
  >
    <ng-template kendoGridToolbarTemplate>
      <button
        type="button"
        kendoGridExcelCommand
      >
        Export to Excel
      </button>
    </ng-template>
    <kendo-grid-column
      field="endpoint_name"
      title="Endpoint Name"
    ></kendo-grid-column>
    <kendo-grid-column
      field="source"
      title="Source"
    ></kendo-grid-column>
    <kendo-grid-column
      field="network_status"
      title="Network Status"
    ></kendo-grid-column>
    <kendo-grid-column
      field="updated_at"
      title="Last Observed"
    ></kendo-grid-column>
    <kendo-grid-column
      field="tenant_name"
      title="Tenant Name"
    ></kendo-grid-column>
    <kendo-grid-column
      field="group_name"
      title="Group Name"
    ></kendo-grid-column>
    <kendo-grid-excel
      fileName="sla-data.xlsx"
      [fetchData]="allData"
    ></kendo-grid-excel>
  </kendo-grid>
</kendo-dialog>
