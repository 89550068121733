import { Component, inject, signal } from '@angular/core';
import { Location } from '@angular/common';
import { TextBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule, FloatingLabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BarcodesModule } from '@progress/kendo-angular-barcodes';
import { AuthService } from '../../services/auth.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../core/shared/common/base.component';

@Component({
  selector: 'app-setup-mfa',
  standalone: true,
  imports: [TextBoxModule, InputsModule, FloatingLabelModule, LabelModule, ButtonsModule, ReactiveFormsModule, BarcodesModule],
  templateUrl: './setup-mfa.component.html',
  styleUrl: './setup-mfa.component.scss',
})
export class SetupMfaComponent extends BaseComponent {
  public setupMfaForm: FormGroup = new FormGroup<SetupMfaForm>({
    oneTimeCode: new FormControl(null, Validators.required),
  });
  private authService = inject(AuthService);
  public image = signal<string | null>(null);
  public secretCode = signal<string | null>(null);
  public enterMfaForm!: FormGroup;
  private location = inject(Location);

  constructor() {
    super();
  }

  ngOnInit() {
    this.enterMfaForm = new FormGroup({
      oneTimeCode: new FormControl(null, [Validators.required, this.noWhitespaceValidator()]),
    });

    // Subscribe to value changes to clean up the input
    this.enterMfaForm.get('oneTimeCode')?.valueChanges.subscribe((value) => {
      if (value) {
        const cleanedValue = value.replace(/\s+/g, '');
        this.enterMfaForm.get('oneTimeCode')?.setValue(cleanedValue, { emitEvent: false });
      }
    });

    this.authService
      .associateMfaDevice()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.qr_image) {
            this.image.set(`${response.qr_image}`);
            this.secretCode.set(`${response.secret_code}` || '');
          } else {
            this.image.set(null); // Or a fallback string/image
            this.secretCode.set('');
          }
        }
      });
  }

  public back() {
    this.location.back();
  }

  public setupMfa() {
    if (this.enterMfaForm.valid) {
      // Remove spaces from the oneTimeCode just in case
      const oneTimeCode = this.enterMfaForm.value.oneTimeCode.replace(/\s+/g, '');

      this.authService
        .verifyMfaService(oneTimeCode)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.authService.loginRedirect();
          },
        });
    }
  }

  // Custom validator to check for whitespace
  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }
}

export interface SetupMfaForm {
  oneTimeCode: FormControl<string | null>;
}
