import { Component } from '@angular/core';
import { AdminAppbarComponent } from '../layout/components/admin-appbar/admin-appbar.component';
import { IconsModule } from '@progress/kendo-angular-icons';
import { GridModule } from "@progress/kendo-angular-grid";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { LabelModule } from "@progress/kendo-angular-label";
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { BaseComponent } from '../../../core/shared/common/base.component';

@Component({
  selector: 'app-syslog',
  standalone: true,
  imports: [
    AdminAppbarComponent,
    GridModule,
    InputsModule,
    LabelModule,
    IconsModule,
    ButtonModule,
  ],
  templateUrl: './syslog.page.html',
  styleUrl: './syslog.page.scss'
})
export class SyslogPage extends BaseComponent {

}
