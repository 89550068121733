<div style="padding: 20px">
  <form
    class="form-signin"
    [formGroup]="loginForm"
    (ngSubmit)="submit()"
  >
    <div class="logo-container">
      <img src="/assets/logos/cyflare_one_logo.png" />
    </div>
    <div class="header-container">
      <h3>Two-Factor Authentcation</h3>
    </div>
    <div>
      <app-error-message></app-error-message>
      <kendo-floatinglabel text="Enter One Time Code">
        <input
          kendoTextBox
          class="form-control"
          formControlName="oneTimeCode"
          autofocus
        />
      </kendo-floatinglabel>
    </div>

    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        themeColor="primary"
        type="submit"
      >
        Submit
      </button>
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-between">
        <div class="form-check d-flex align-items-center">
          <label
            class="form-check-label"
            for="rememberMe"
            >Having trouble signing in?</label
          >
        </div>
        <div>
          <a
            href="mailto:support@cyflare.com"
            class="text-primary"
            kendoButton
            fillMode="clear"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
    <div class="d-grid gap-2 mt-4">
      <button
        kendoButton
        (click)="back()"
      >
        Go Back
      </button>
    </div>
  </form>
</div>
