import { Component, effect, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CancelEvent, EditEvent, GridComponent, GridModule, SaveEvent } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { BaseComponent } from '../../../../../../../core/shared/common/base.component';
import { ServiceAccountRequest, SoarServiceAccountService } from './service/soar-service-account.service';
import { environment } from '../../../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { ServiceAccountsForm, AccountsFormService } from './service/account-form.service';
import { ServiceAccount } from '../../../../../../../core/models/entities';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-accounts',
  standalone: true,
  imports: [CommonModule, LayoutModule, IconsModule, InputsModule, LabelModule, ButtonsModule, DropDownsModule, NavigationModule, GridModule, ReactiveFormsModule, FormsModule],
  templateUrl: './accounts.component.html',
  styleUrl: './accounts.component.scss',
})
export class AccountsComponent extends BaseComponent {
  public formBuilder = inject(FormBuilder);
  public gridService = inject(SoarServiceAccountService);
  public route = inject(ActivatedRoute);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/organization/administrative/serviceaccounts`; // Store the base URL
  private formService = inject(AccountsFormService);
  public formGroup: FormGroup<ServiceAccountsForm> | undefined;
  public serviceAccounts = signal<ServiceAccount[]>([]);
  private editedRowIndex: number | undefined;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadSoarServiceAccount(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadSoarServiceAccount(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }

  public addHandler(args: AddEvent): void {
    this.closeEditor(args.sender);
    this.formGroup = this.formService.createAccountsForm();
    args.sender.addRow(this.formGroup);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  saveHandler($event: SaveEvent) {
    if (this.formGroup) {
      const formValue = this.formGroup.value;

      const payload: ServiceAccountRequest = {
        organization_id: this.id,
        service_account: {
          pk: $event.isNew ? null : formValue?.pk!,
          username: formValue.username!,
          related_function: formValue.related_function!,
          sensitivity: formValue.sensitivity!,
        },
      };
      if ($event.isNew) {
        this.gridService.createSoarServiceAccount(payload).subscribe({
          next: (response) => {
            this.loadSoarServiceAccount(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        this.gridService.updateSoarServiceAccount(payload.service_account).subscribe({
          next: (response) => {
            this.loadSoarServiceAccount(this.id);
            $event.sender.closeRow($event.rowIndex);
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    }
  }

  public editHandler($event: EditEvent): void {
    this.formGroup = this.formService.createAccountsForm($event.dataItem);
    $event.sender.editRow($event.rowIndex, this.formGroup);
  }

  cancelHandler($event: CancelEvent) {
    this.closeEditor($event.sender, $event.rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public removeHandler(args: any): void {
    this.gridService.deleteSoarServiceAccount(args.dataItem.pk).subscribe(() => {
      this.loadSoarServiceAccount(this.id);
    });
  }
}
