import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiredIf(conditionFn: () => boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!conditionFn()) {
      return null;
    }

    return control.value ? null : { requiredIf: true };
  };
}

export function updateValidatorsOnDependencyChange(form: FormGroup, dependentControls: string[], targetControls: string[]) {
    dependentControls.forEach(controlName => {
      const control = form.get(controlName);
      if (control) {
        control.valueChanges.subscribe(() => {
          targetControls.forEach(targetControlName => {
            const targetControl = form.get(targetControlName);
            targetControl?.updateValueAndValidity();
          });
        });
      }
    });
  }