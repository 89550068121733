import { Component, effect, inject } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { AdminAppbarComponent } from '../../../../admin/layout/components/admin-appbar/admin-appbar.component';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { ConnectorStatusService } from '../../../../../core/services/connector-status.service';
import { filter } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-connector-status',
  standalone: true,
  imports: [GridModule, AdminAppbarComponent, CommonModule],
  templateUrl: './connector-status.component.html',
  providers: [ConnectorStatusService],
})
export class ConnectorStatusComponent extends BaseComponent {
  public gridService = inject(ConnectorStatusService);
  public route = inject(ActivatedRoute);
  public id!: number;
  private baseUrl = `${environment.apiUrl}/v1/analytics/administrative/downconnects`;

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.route.queryParams.pipe(filter((params) => params['organizationId'])).subscribe((data) => {
          this.id = parseInt(data['organizationId'], 10);
          return this.loadDownConnections(this.id);
        });
      },
      { allowSignalWrites: true },
    );
  }

  private loadDownConnections(orgId: number) {
    this.gridService.url = `${this.baseUrl}(${orgId})/`;
    return this.gridService.read();
  }
}
