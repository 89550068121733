import { inject, Injectable } from "@angular/core";
import { PopupRef, PopupService } from "@progress/kendo-angular-popup";

@Injectable({
    providedIn: 'root',
})
export class ChartLabelsPopupService {
    public popupContent!: string;

    private popupRef!: PopupRef | null;
    private popupService = inject(PopupService);
    private lastTooltip = '';

    public attachSurfaceHandlers(e: any, popupTemplate: any): void {
        e.sender.surface.bind('mouseenter', (args: any) => this.onMouseEnter(args, popupTemplate));
        e.sender.surface.bind('mouseleave', (args: any) => this.onMouseLeave(args));
    }
    

    public onMouseEnter(e: any, popupTemplate: any): void {
        if(e.element.nodeType == 'Path')
            return;

        const tooltip = e.element.parent?.chartElement?.value;

        if (!tooltip) 
            return;
        
        if(this.lastTooltip === tooltip)
            return;

        this.lastTooltip = tooltip;

        if (this.popupRef) {
            this.popupRef.close();
            this.popupRef = null;
        }

        this.popupContent = tooltip;
        this.popupRef = this.popupService.open({
            content: popupTemplate,
            offset: {
                left: e.originalEvent.pageX - 5,
                top: e.originalEvent.pageY - 10
            }
        });
    }

    public onMouseLeave(e: any): void {
        if (!this.popupRef) {
            return;
        }

        setTimeout(() => {
            this.popupRef?.close();
            this.popupRef = null;
            this.lastTooltip = '';
        }, 8000);
    }
}