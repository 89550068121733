import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { AccountTypeObject } from '../../../../../../core/models/entities';

@Injectable({ providedIn: 'root' })
export class GeneralOrganizationService {
  private url = `${environment.apiUrl}/v1/organization/administrative/ckb`;
  private accountTypeUrl = `${environment.apiUrl}/v1/organization/autocomplete/accounttype`;
  private http = inject(HttpClient);
  constructor() {}

  public getAccountTypes() {
    return this.http.get<AccountTypeObject>(this.accountTypeUrl).pipe(map((response) => response));
  }
  public updateCkbApi(generalRequest: GeneralOrganizationServiceCkbRequest) {
    const url = `${this.url}(${generalRequest.organization_id})/`;
    return this.http.put<GeneralOrganizationServiceCkbRequest>(url, generalRequest);
  }

  public getCkbById(orgId: number) {
    const url = `${this.url}(${orgId})/`;
    return this.http.get<GeneralOrganizationServiceCkbRequest>(url).pipe(map((response) => response));
  }

  public assignUserToOrganization(orgId: number, user: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/add_user_to_organization/?user=${user}`;
    return this.http.post(url, { user });
  }

  public removeUserFromOrganization(orgId: number, user: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/remove_user_from_organization/?user=${user}`;
    return this.http.delete(url);
  }

  public listOrganizationUsers(orgId: number) {
    const url = `${environment.apiUrl}/v1/organization/administrative/organization(${orgId})/list_users_in_organization/`;
    return this.http.get<UsersResponse>(url).pipe(map((response) => response.value));
  }
}

export interface UsersResponse {
  value: [
    {
      id: number;
      avatar: string;
      is_admin: boolean;
      groups: string[];
      last_login: Date;
      username: string;
      first_name: string;
      last_name: string;
      email: string;
      is_active: boolean;
      date_joined: Date;
    },
  ];
}

export interface GeneralOrganizationServiceCkbRequest {
  organization_id: number;
  mdr_windows: string;
  mdr_linux: string;
  mdr_mac: string;
  xdr_windows_agents: string;
  xdr_linux_agents: string;
  xdr_syslog_senders: string;
  xdr_api_connectors: string;
  xdr_network_sensors: string;
  stellar_cyber_id: string;
  stellar_cyber_name: string;
  sentinel_one_id: string;
  sentinel_one_name: string;
  cyrisma_id: string;
  cyrisma_name: string;
  assure_id: string;
  assure_name: string;
  chronicle_id: string;
  chronicle_name: string;
  polymer_id: string;
  polymer_name: string;
  zoho_name: string;
  zoho_id: string;
}
