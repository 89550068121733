import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ODataService } from '../../../../../../../../../../core/services/odata.service';
import { Coverage } from '../../../../../../../../../../core/models/entities';

@Injectable({ providedIn: null })
export class CoverageDetailsService extends ODataService<Coverage> {
  private field = 'timestamp';

  constructor() {
    super(inject(HttpClient), '');
    this.state = {
      skip: 0,
      take: 10,
      sort: [{ field: 'timestamp', dir: 'desc' }],
      filter: {
        logic: 'and',
        filters: [],
      },
    };
  }
}
