import { Component, effect, output, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule, SVGIcon } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { xIcon } from '@progress/kendo-svg-icons';
import { ChartManager } from '../../../../../core/models/entities';
import { ChartForm } from '../../../../../core/models/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-chart-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    ButtonsModule,
    LabelModule,
    IconsModule,
    DropDownsModule,
    CommonModule
  ],
  templateUrl: './chart-form.component.html',
  styleUrl: './chart-form.component.scss'
})
export class ChartFormComponent extends BaseComponent {
  public xIcon: SVGIcon = xIcon;
  close = output<void>();
  chartManager: ChartForm[] | undefined;
  public chartForm = signal(this.getChartForm());

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(
      () => {
        this.chartForm.set(this.getChartForm());
      },
      { allowSignalWrites: true } // Enabling signal writes within this effect
    );
  }

  getChartForm(charts = new ChartManager()): FormGroup<ChartForm> {
    return new FormGroup({
      zohoViewName: new FormControl(charts.zohoViewName, Validators.required),
      friendlyName: new FormControl(charts.friendlyName, Validators.required),
      tags: new FormControl(charts.tags, Validators.required),
      status: new FormControl(charts.status, Validators.required),
      displayColumn: new FormControl(charts.displayColumn, Validators.required),
      sortOrder: new FormControl(charts.sortOrder, Validators.required),
    });
  }

  public clear() {
    this.chartForm().reset();
  }


  onClose() {
    this.close.emit();
  }

}
