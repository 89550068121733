<kendo-chart
  width="100%"
  height="100%"
>
  <kendo-chart-series>
    <kendo-chart-series-item
      type="heatmap"
      [data]="normalizedData"
      [color]="color"
    >
      <kendo-chart-series-item-tooltip>
        <ng-template let-value="value">
          <div>{{ value.value }} has {{ value.y }} open threats</div>
        </ng-template>
      </kendo-chart-series-item-tooltip>
      <kendo-chart-series-item-markers
        type="roundedRect"
        [border]="{ width: 1 }"
      >
      </kendo-chart-series-item-markers>
    </kendo-chart-series-item>
  </kendo-chart-series>

  <kendo-chart-x-axis>
    <kendo-chart-x-axis-item
      [categories]="categories.x"
      [title]="{ text: 'Agent Version' }"
      titlePadding="20"
    >
    </kendo-chart-x-axis-item>
  </kendo-chart-x-axis>

  <kendo-chart-y-axis>
    <kendo-chart-y-axis-item
      [categories]="categories.y"
      [min]="yAxisMin"
      [max]="yAxisMax"
      [title]="{ text: 'Open Threats' }"
      titlePadding="20"
    >
    </kendo-chart-y-axis-item>
  </kendo-chart-y-axis>
</kendo-chart>
