import { Component, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../../../../core/shared/common/base.component';
import { filter, takeUntil } from 'rxjs';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { OrganizationService } from '../../../organizations/services/organization.service';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-org-appbar',
  standalone: true,
  imports: [NavigationModule, DropDownsModule, ButtonsModule, IndicatorsModule, BreadcrumbComponent, CommonModule, DialogsModule],
  templateUrl: './org-appbar.component.html',
  styleUrls: ['./org-appbar.component.scss'],
})
export class OrgAppbarComponent extends BaseComponent {
  private orgService = inject(OrganizationService);
  public router = inject(Router);
  private route = inject(ActivatedRoute);
  currentRoute: string;
  public opened = false;
  isAdmin: boolean = false;

  actions: Array<{ text: string; click?: () => void }> = [
    { text: 'Change Owner', click: this.changeOwner.bind(this) },
    { text: 'Delete', click: this.open.bind(this) },
  ];

  private organizationId: number | undefined;
  private account_type: string | undefined;

  constructor() {
    super();
    this.initEffect();
    this.currentRoute = this.router.url;
  }

  private initEffect() {
    effect(
      () => {
        this.loadUserData();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
          this.currentRoute = event.urlAfterRedirects;
        });
        this.route.queryParams.pipe(filter((params) => params['organizationId'] && params['account_type'])).subscribe((params) => {
          this.organizationId = parseInt(params['organizationId']);
          this.account_type = params['account_type'];
        });
      },
      { allowSignalWrites: true },
    );
  }

  loadUserData(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const groups = user.groups || [];

    this.isAdmin = groups.includes('admin');
  }

  public open(): void {
    this.opened = true;
  }

  public closeDialog(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  public navigate($event: Event, path: string) {
    $event.preventDefault();
    this.router.navigate([path], {
      queryParams: {
        organizationId: this.organizationId,
        account_type: this.account_type,
      },
    });
  }

  isActive(path: string): boolean {
    return this.currentRoute.includes(path);
  }

  onActionClick(event: any) {
    const clickedAction = this.actions.find((action) => action.text === event.item?.text);
    if (clickedAction && clickedAction.click) {
      clickedAction.click();
    }
  }

  changeOwner() {
    // Implement the change owner logic here
  }

  delete() {
    const id = this.organizationId!;
    this.orgService
      .deleteOrg(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.closeDialog('delete');
          this.router.navigate(['/admin/organizations']);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }
}
