import { Component, ViewEncapsulation, inject, effect, signal } from '@angular/core';
import { BaseComponent } from '../../../core/shared/common/base.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { SocComponent } from './components/soc/soc.component';
import { MitreAttackComponent } from '../mitreattack/mitreattack.component';
import { EdrComponent } from './components/edr/edr.component';
import { VssComponent } from './components/vss/vss.component';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { GeneralOrganizationService } from '../../admin/organizations/components/general/service/general-organization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-analytics',
  standalone: true,
  imports: [LayoutModule, ReactiveFormsModule, SocComponent, MitreAttackComponent, EdrComponent, VssComponent],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [GeneralOrganizationService],
})
export class AnalyticsComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  private generalOrgService = inject(GeneralOrganizationService);

  showEdrTab = signal<boolean>(false);

  constructor() {
    super();
    this.initEffect();
  }

  private initEffect() {
    effect(() => {
      this.route.queryParams
        .pipe(
          filter((params) => params['organizationId']),
          switchMap((params) => {
            const orgId = +params['organizationId'];
            return this.generalOrgService.getCkbById(orgId);
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((ckbData) => {
          this.showEdrTab.set(!!ckbData.sentinel_one_id);
        });
    });
  }
}
