<div class="layout-container">
  @if (isLoading()) {
    <kendo-loader
      [type]="'infinite-spinner'"
      [size]="'large'"
    ></kendo-loader>
  } @else if (truePositivePercentage === 0 && falsePositivePercentage === 0 && unknownPercentage === 0) {
    <h1>No Data</h1>
  } @else if (data() !== null) {
    <kendo-chart class="chart-container">
      <kendo-chart-series>
        <kendo-chart-series-item
          [autoFit]="autofit"
          type="donut"
          [data]="data()"
          categoryField="description"
          field="count"
        >
          <kendo-chart-series-item-labels
            align="circle"
            position="outsideEnd"
            [content]="labelContent"
            color="#000"
            background="none"
          >
          </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-legend [visible]="false"></kendo-chart-legend>
    </kendo-chart>
  } @else if (data() === null) {
    <h1>No Data</h1>
  }
</div>
