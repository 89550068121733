export class Organization {
  id: number | null = null;
  subdomains?: { subdomain?: string | null }[] = [];
  tags?: number[] = [];
  name: string | null = null;
  status: number | null = 1;
  account_type: number | null = null;
  created_at: Date | null = null;
  owner?: string | null = null;
  owner_email?: string | null = null;
  do_not_call_client?: boolean = true;
  num_of_employee?: number = 32767;
  website?: string | null = null;
  billing_street?: string | null = null;
  billing_city?: string | null = null;
  billing_country?: string | null = null;
  billing_state?: string | null = null;
  use_same_billing_address_for_shipping?: boolean = true;
  shipping_street?: string | null = null;
  shipping_city?: string | null = null;
  shipping_country?: string | null = null;
  shipping_state?: string | null = null;
  account_manager_name?: string | null = null;
  account_manager_phone?: string | null = null;
  account_manager_email?: string | null = null;
  assigned_csm?: string | null = null;
  assigned_csm_phone?: string | null = null;
  assigned_csm_email?: string | null = null;
  soc_phone?: string | null = null;
  soc_escalation_email?: string | null = null;
  soc_ir_email?: string | null = null;
  soc_subdomain?: string | null = null;
  parent: Organization | null = null; // default to 1, until we implement parent API call to get orgs @lelong37
  irc_initialization_level: number | null = 2;
  private _whitelabel?: WhiteLabel | null | undefined = new WhiteLabel();
  private _parent_whitelabel?: WhiteLabel | null | undefined = new WhiteLabel();

  get whitelabel(): WhiteLabel | null | undefined {
    return this._whitelabel;
  }

  set whitelabel(value: WhiteLabel | null | undefined) {
    if (value) {
      if (!this._whitelabel) {
        this._whitelabel = new WhiteLabel();
      }
      // Ensure we use WhiteLabel setters
      this._whitelabel.title = value.title;
      this._whitelabel.favicon_url = value.favicon_url;
      // Copy other properties
      this._whitelabel.organization_id = value.organization_id;
      this._whitelabel.url = value.url;
      this._whitelabel.logo_url = value.logo_url;
      this._whitelabel.enabled = value.enabled;
      this._whitelabel.created_at = value.created_at;
      this._whitelabel.updated_at = value.updated_at;
    } else {
      this._whitelabel = value;
    }
  }

  get parent_whitelabel(): WhiteLabel | null | undefined {
    return this._parent_whitelabel;
  }

  set parent_whitelabel(value: WhiteLabel | null | undefined) {
    if (value) {
      if (!this._parent_whitelabel) {
        this._parent_whitelabel = new WhiteLabel();
      }
      // Ensure we use WhiteLabel setters
      this._parent_whitelabel.title = value.title;
      this._parent_whitelabel.favicon_url = value.favicon_url;
      // Copy other properties
      this._parent_whitelabel.organization_id = value.organization_id;
      this._parent_whitelabel.url = value.url;
      this._parent_whitelabel.logo_url = value.logo_url;
      this._parent_whitelabel.enabled = value.enabled;
      this._parent_whitelabel.created_at = value.created_at;
      this._parent_whitelabel.updated_at = value.updated_at;
    } else {
      this._parent_whitelabel = value;
    }
  }
}

export class Ckb {
  xdr_windows_agents: string | null = null;
  xdr_linux_agents: string | null = null;
  xdr_network_sensors: string | null = null;
  xdr_api_connectors: string | null = null;
  xdr_syslog_senders: string | null = null;
  mdr_windows: string | null = null;
  mdr_mac: string | null = null;
  mdr_linux: string | null = null;
  zoho_id: string | null = null;
  zoho_name: string | null = null;
  stellar_cyber_id: string | null = null;
  stellar_cyber_name: string | null = null;
  sentinel_one_id: string | null = null;
  sentinel_one_name: string | null = null;
  cyrisma_id: string | null = null;
  cyrisma_name: string | null = null;
  assure_id: string | null = null;
  assure_name: string | null = null;
  chronicle_id: string | null = null;
  chronicle_name: string | null = null;
  polymer_id: string | null = null;
  polymer_name: string | null = null;
}

export interface IUser {
  id: number;
  email: string | null;
  last_login?: Date;
  is_superuser?: boolean;
  username?: string;
  first_name: string | null;
  last_name: string | null;
  is_staff?: boolean;
  is_active?: boolean;
  date_joined?: Date;
  access_token?: string;
  expires_in?: number;
  token_type?: string;
  refresh_token?: string;
  mfa_setup_required?: boolean;
  session?: string;
  avatar?: string;
  organizations?: Organization[];
  groups?: string[];
  organization_id?: number;
  organization_account_type?: number;
  selected_organization?: Organization;
  primary_organization?: Organization;
}

export class DetectionManager {
  pk: string | null = null;
  title: string | null = null;
  category: { name: string | null } = { name: 'Interactive Detections' };
  entity_id: string | null = null;
  description: string | null = null;
  status: string | null = null;
  default_state: string | null = null;
  tags: Tag[] = [];
}

export class SocAdvisory {
  pk?: number | null = null;
  title: string | null = null;
  advisory_for: string[] = [];
  content: string | null = null;
  author: string | null = null;
  created_at: string | null = null;
  last_updated_by: string | null = null;
  last_updated_at: string | null = null;
  is_published: boolean | null = false;
  published_content: string | null = null;
  published_at: string | null = null;
  published_by: string | null = null;
}

export class ChartManager {
  zohoViewName: string | null = null;
  friendlyName: string | null = null;
  tags: string | null = null;
  status: string | null = null;
  displayColumn: number | null = null;
  sortOrder: number | null = null;
}

export class Contact {
  pk?: number | null = null;
  priority_order: number | null = null;
  name: string | null = null;
  phone: string | null = null;
  extension: string | null = null;
  dispatch_line: boolean | null = true;
  working_days: number[] | null = [1, 2, 3, 4, 5];
  available_from: string | null = '8:00';
  available_to: string | null = '17:00';
  timezone: string | null = 'US/Central';
  organization_id: number | null = null;
}

export class ContactPriority {
  pk: number | null = null;
  priority_order: number | null = null;
}

export class TravelAdvisory {
  pk: string | null = null;
  email: string | null = null;
  destination: { name: string | null } = { name: '' };
  start_date: string | null = null;
  end_date: string | null = null;
}

// need to create story to decommision this - @Le
export class User {
  id: number | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  email: string | null = null;
  role: string | null = null;
  organizations: Organization[] = [];
  groups: string[] = [];
}

export const timezones: string[] = ['Pacific/Midway', 'US/Samoa', 'Pacific/Honolulu', 'US/Alaska', 'US/Pacific', 'US/Arizona', 'US/Mountain', 'US/Central', 'US/Eastern', 'Canada/Atlantic', 'Canada/Newfoundland', 'GMT', 'Europe/London', 'Europe/Berlin', 'Europe/Paris', 'Europe/Moscow', 'Asia/Baghdad', 'Asia/Tehran', 'Asia/Dubai', 'Asia/Kabul', 'Asia/Karachi', 'Asia/Kolkata', 'Asia/Kathmandu', 'Asia/Dhaka', 'Asia/Bangkok', 'Asia/Hong_Kong', 'Asia/Tokyo', 'Australia/Perth', 'Australia/Adelaide', 'Australia/Sydney', 'Pacific/Guam', 'Pacific/Auckland', 'Pacific/Fiji', 'Pacific/Tongatapu'];

export class Whitelist {
  pk: string | null = null;
  ip_address: string | null = null;
  file_hash: string | null = null;
  domain: string | null = null;
  analyst_notes: string | null = null;
}

export class ServiceAccount {
  pk: string | null = null;
  username: string | null = null;
  related_function: string | null = null;
  sensitivity: string | null = null;
}

export class VulnerabilityScanner {
  pk: string | null = null;
  ip_address: string | null = null;
  notes: string | null = null;
}

export class ServerException {
  pk: string | null = null;
  ip_address: string | null = null;
  server_name: string | null = null;
  notes_for_analyst: string | null = null;
  server_type: string | null = null;
}

export class SocBulletin {
  pk: string | null = null;
  description: string | null = null;
  xdr_alert_type: string | null = null;
  required_action: string | null = null;
  timebounds: string | null = null;
}

export class TicketingEmail {
  pk: string | null = null;
  email: string | null = null;
  is_primary = false;
}

export class DetectionSettings {
  id: number | null = null;
  display_text: string | null = null;
  disabled = true;
  selected = true;
}

export class UserList {
  id: number | null = null;
  avatar: string | null = 'Unknown';
  is_admin = false;
  groups?: string[] = [];
  last_login: Date | null = null;
  username: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  email: string | null = null;
  is_active = true;
  date_joined: Date | null = null;
}

export class AccountTypeObject {
  count: number | null = null;
  next: string | null = null;
  previous: string | null = null;
  results: AccountTypeResult[] = [];
}

export class AccountTypeResult {
  id: number | null = null;
  display_text: string | null = null;
  disabled: boolean | null = null;
  selected: boolean | null = null;
}

export class OrgDetections {
  detectionId: number | null = null;
  title: string | null = null;
  category: string | null = null;
  entityId: string | null = null;
  description: string | null = null;
  status: string | null = null;
  defaultState: string | null = null;
  active!: boolean;
  organizationId: number | null = null;
  tags: Tag[] | null = null;
  createdAt: Date | null = null;
  updatedAt: Date | null = null;
  isNew: boolean = false;
}

export class DetectionSetting {
  detection_id!: number;
  active!: boolean;
}

export class DetectionData {
  name: string | null = null;
  subitems: string | null = null;
  defaultStatus: string | null = null;
  killChainStage: string | null = null;
  mitreTactic: string | null = null;
  mitreTacticId: string | null = null;
  mitreTechnique: string | null = null;
  mitreTechniqueId: string | null = null;
  connector: string | null = null;
  dateActivated: Date | null = null;
  xdrEventName: string | null = null;
  adrStatus: string | null = null;
  detectionOrigin: string | null = null;
}

export class SocEvent {
  id: number | null = null;
  case_id: number | null = null;
  case_created_on: Date | null = null;
  event_name: string | null = null;
  case_severity: string | null = null;
  zoho_ticket_number: string | null = null;
  case_source: string | null = null;
  sla_exceeded: string | null = null;
  alert_received_on: string | null = null;
  case_closed_on: string | null = null;
  closure_reason: string | null = null;
  mitre_tactic: string | null = null;
  ticketed: boolean | null = false;
  event_occurred_on: Date | null = null;
}

export class WinEvent {
  timestamp: Date | null = null;
  msg_class: string | null = null;
  event_name: string | null = null;
  updated_element_name: string | null = null;
  action_performed_by_id: string | null = null;
  action_performed_by_username: string | null = null;
}

export class AzureEvent {
  timestamp: Date | null = null;
  msg_class: string | null = null;
  operation: string | null = null;
  result_status: string | null = null;
  engid_name: string | null = null;
  updated_element_display_name: string | null = null;
  action_performed_by_id: string | null = null;
  action_performed_by_username: string | null = null;
}

export class O365Event {
  operations: string | null = null;
  timestamp: Date | null = null;
  msg_class: string | null = null;
  result_status: string | null = null;
  engid_name: string | null = null;
  modified_properties: string | null = null;
  source_ip: string | null = null;
  originating_server: string | null = null;
  authentication_type: string | null = null;
  updated_conditional_access_policy_identity: string | null = null;
  updated_element_name: string | null = null;
  updated_element_group_name: string | null = null;
  updated_element_role_name: string | null = null;
  updated_forward_rule: string | null = null;
  user_logged_in_username: string | null = null;
  user_logged_in_country: string | null = null;
  login_result: string | null = null;
  filename: string | null = null;
  file_extension: string | null = null;
  file_url: string | null = null;
  file_relative_url: string | null = null;
  file_size_in_bytes: string | null = null;
  message_released_from_quarantine: string | null = null;
  application_name: string | null = null;
  transport_rule: string | null = null;
  action_performed_by_username: string | null = null;
}

export class O365AccessEvent {
  user_logged_in_username: string | null = null;
  user_logged_in_country: string | null = null;
  timestamp: Date | null = null;
  source_ip: string | null = null;
  originating_server: string | null = null;
  authentication_type: string | null = null;
}

export class Case {
  id: number | null = null;
  request_id: string | null = null;
  created_time: Date | null = null;
  ticket_status: string | null = null;
  subject: string | null = null;
  priority: string | null = null;
  sla_violation_type: string | null = null;
  ticket_closed_time: Date | null = null;
  customer_response_time: Date | null = null;
  ticket_source: string | null = null;
  description: string | null = null;
}

export class ConnectorStatus {
  connector_name: string | null = null;
  duration: string | null = null;
}

export interface SlaViolation {
  tracking_id: string;
  case_id: number;
  case_created_on: string;
  case_source: string;
}

export interface EscalationRelevancy {
  id: number;
  created_time: Date;
  ticket_closed_time: Date;
  priority: string;
  description: string;
  ticket_source: string;
  ticket_status: string;
}

export interface AdrVsManualDetail {
  event_name: string;
  case_severity: string;
  closure_reason: string;
  triage_type: string;
}

export interface SuspectedUserDetail {
  alert_received_on: string;
  case_id: string;
  username: string;
  event_name: string;
  severity: string;
  event_source: string;
}

export interface SusspectAssetsDetail {
  alert_received_on: string;
  case_id: string;
  source_ip: string;
  event_name: string;
  severity: string;
  event_source: string;
}

export interface DispositionDetail {
  alert_received_on: string;
  case_id: string;
  closure_reason: string;
  event_name: string;
  severity: string;
  event_source: string;
}

export interface Tag {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export class XdrIngestion {
  report_time: Date | null = null;
  ser_daily_storage: number | null = null;
  ade_daily_storage: number | null = null;
  scan_daily_storage: number | null = null;
  syslog_daily_storage: number | null = null;
  cloudtrail_daily_storage: number | null = null;
  wineventlog_daily_storage: number | null = null;
  adr_daily_storage: number | null = null;
  users_daily_storage: number | null = null;
  maltrace_daily_storage: number | null = null;
  signals_daily_storage: number | null = null;
  assets_daily_storage: number | null = null;
  audit_daily_storage: number | null = null;
  perf_daily_storage: number | null = null;
}

export const roles = ['admin', 'customer'];

export interface OutdatedAgents {
  agent_up_to_date: boolean;
  agent_version: string;
  endpoint_name: string;
  tenant_name: string;
  group_name: string;
  updated_at: string;
  agent_id: string;
  timestamp: string;
}

export interface OpenThreats {
  timestamp: string;
  agent_id: string;
  threats_open: string;
  endpoint_name: string;
  tenant_name: string;
  group_name: string;
  updated_at: string;
  policy_mode_standard: string;
  policy_mode_suspicious: string;
}

export interface ThreatReboot {
  timestamp: string;
  agent_id: string;
  endpoint_name: string;
  threat_reboot_required: boolean;
  tenant_name: string;
  group_name: string;
  updated_at: string;
  policy_mode_standard: string;
  policy_mode_suspicious: string;
}

export interface Coverage {
  endpoint_name: string;
  tenant_name: string;
  group_name: string;
  updated_at: string;
  timestamp: string;
  network_status: string;
  source: string;
}

export class WhiteLabel {
  organization_id?: number | null = null;
  url?: string | null = null;
  logo_url?: string | null = null;
  created_at?: Date | null = new Date();
  updated_at?: Date | null = new Date();
  enabled?: boolean | null = null;

  constructor(data?: Partial<WhiteLabel>) {
    if (data) {
      this.organization_id = data.organization_id;
      this.url = data.url;
      this.logo_url = data.logo_url;
      this.created_at = data.created_at;
      this.updated_at = data.updated_at;
      this.enabled = data.enabled;
      this.title = data.title;
      this.favicon_url = data.favicon_url;
    }
  }

  toJSON() {
    return {
      organization_id: this.organization_id,
      url: this.url,
      logo_url: this.logo_url,
      created_at: this.created_at,
      updated_at: this.updated_at,
      enabled: this.enabled,
      title: this.title,
      favicon_url: this.favicon_url
    };
  }

  get title(): string | null | undefined {
    return document.title || null;
  }

  set title(value: string | null | undefined) {
    if (value) {
      document.title = value;
    }
  }

  get favicon_url(): string | null | undefined {
    return (document.querySelector('link[rel="icon"]') as HTMLLinkElement)?.href || null;
  }

  set favicon_url(value: string | null | undefined) {
    if (value) {
      const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      if (favicon) {
        favicon.href = value;
      } else {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = value;
        document.head.appendChild(link);
      }
    }
  }
}
