<kendo-appbar class="kendo-sub-appbar">
  <kendo-appbar-section style="display: flex; flex-direction: column; justify-content: start; align-items: start">
    <app-breadcrumb></app-breadcrumb>
    @if (isAdmin()) {
      <div>
        <button
          #adminorcustomer_organizations_general
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/general') }"
          (click)="navigate($event, 'admin/organizations/general')"
          fillMode="solid"
        >
          General
        </button>
        <button
          #adminorcustomer_organizations_soarrules
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/soar-rules') }"
          (click)="navigate($event, 'admin/organizations/soar-rules')"
          fillMode="solid"
        >
          SOAR Rules
        </button>
        <button
          #adminorcustomer_organizations_soccommunications
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/soc-comms') }"
          (click)="navigate($event, 'admin/organizations/soc-comms')"
          fillMode="solid"
        >
          SOC Communications
        </button>
        <button
          #adminorcustomer_organizations_alertpreferences
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/detection-settings') }"
          (click)="navigate($event, 'admin/organizations/detection-settings')"
          fillMode="solid"
        >
          Alert Preferences
        </button>
        <button
          #adminorcustomer_organizations_integrations
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/integrations') }"
          (click)="navigate($event, 'admin/organizations/integrations')"
          fillMode="solid"
        >
          Integrations
        </button>
        <button
          #adminorcustomer_organizations_whitelabel
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('admin/organizations/whitelabel') }"
          (click)="navigate($event, 'admin/organizations/whitelabel')"
          fillMode="solid"
        >
          White Label
        </button>
      </div>
    } @else {

      <div>
        <button
          #adminorcustomer_organizations_general
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('customer/organizations/general') }"
          (click)="navigate($event, 'customer/organizations/general')"
          fillMode="solid"
        >
          General
        </button>
        <button
          #adminorcustomer_organizations_soarrules
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('customer/organizations/soar-rules') }"
          (click)="navigate($event, 'customer/organizations/soar-rules')"
          fillMode="solid"
        >
          SOAR Rules
        </button>
        <button
          #adminorcustomer_organizations_soccommunications
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('customer/organizations/soc-comms') }"
          (click)="navigate($event, 'customer/organizations/soc-comms')"
          fillMode="solid"
        >
          SOC Communications
        </button>
        <button
          #adminorcustomer_organizations_alertpreferences
          kendoButton
          class="tertiary-button"
          [ngClass]="{ 'active-route': isActive('customer/organizations/detection-settings') }"
          (click)="navigate($event, 'customer/organizations/detection-settings')"
          fillMode="solid"
        >
          Alert Preferences
        </button>
        
      </div>
    }
  </kendo-appbar-section>
  <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

  <kendo-appbar-spacer></kendo-appbar-spacer>

  <kendo-appbar-section class="actions">
    <kendo-badge-container>
      @if (isAdmin()) {
        <kendo-dropdownbutton
          #adminorcustomer_organizations_actions
          class="primary"
          [data]="actions"
          (itemClick)="onActionClick($event)"
        >
          Actions
        </kendo-dropdownbutton>
      }
      @if (opened === true) {
        <kendo-dialog
          title="Delete Organization"
          (close)="closeDialog('cancel')"
          [minWidth]="250"
          [width]="450"
        >
          <p style="margin: 30px; text-align: center">This action will permanently delete the organization and all organization data. Are you sure you want to continue?</p>
          <kendo-dialog-actions>
            <button
              #adminorcustomer_organizations_actions_cancel
              kendoButton
              (click)="closeDialog('no')"
            >
              Cancel
            </button>
            <button
              #adminorcustomer_organizations_actions_delete
              kendoButton
              (click)="delete()"
              themeColor="primary"
            >
              Delete
            </button>
          </kendo-dialog-actions>
        </kendo-dialog>
      }
    </kendo-badge-container>
  </kendo-appbar-section>
</kendo-appbar>
